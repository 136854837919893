@charset "UTF-8";
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,700,800,900|Open+Sans:300,300i,400,400i,600,600i,700,700i);
:root {
  --blue: #2c8ef8;
  --indigo: #727cf5;
  --purple: #6b5eae;
  --pink: #ff679b;
  --red: #b80f0a;
  --orange: #f49d18;
  --yellow: #ffbc00;
  --green: #52ae32;
  --teal: #02a8b5;
  --cyan: #39afd1;
  --white: #fff;
  --gray: #98a6ad;
  --gray-dark: #343a40;
  --black: #000;
  --blue-dark: #13007c;
  --sand: #91834b;
  --primary: #52ae32;
  --secondary: #f49d18;
  --success: #52ae32;
  --info: #39afd1;
  --warning: #ffbc00;
  --danger: #b80f0a;
  --light: #e5e5e5;
  --dark: #313a46;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Montserrat, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, .accordion .accordion-title, .col-form-label, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #52ae32;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: rgb(53.9955357143, 114.5758928571, 32.9241071429);
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, .accordion .accordion-title, .col-form-label, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.5rem;
  font-weight: 600;
  line-height: 1.1;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.875rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .accordion .accordion-title, .col-form-label, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 0.9375rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

small,
.small {
  font-size: 0.75rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 6px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #39afd1;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.95rem;
  vertical-align: top;
  border-top: 1px solid #e5e5e5;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e5e5e5;
}
.table tbody + tbody {
  border-top: 2px solid #e5e5e5;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem;
}

.table-bordered {
  border: 1px solid #e5e5e5;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e5e5e5;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f3fa;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: #f1f3fa;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgb(206.56, 232.32, 197.6);
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: rgb(165.04, 212.88, 148.4);
}

.table-hover .table-primary:hover {
  background-color: rgb(191.1351748252, 225.097972028, 179.322027972);
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: rgb(191.1351748252, 225.097972028, 179.322027972);
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgb(251.92, 227.56, 190.32);
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: rgb(249.28, 204.04, 134.88);
}

.table-hover .table-secondary:hover {
  background-color: rgb(250.7609090909, 217.233553719, 165.9790909091);
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: rgb(250.7609090909, 217.233553719, 165.9790909091);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgb(206.56, 232.32, 197.6);
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: rgb(165.04, 212.88, 148.4);
}

.table-hover .table-success:hover {
  background-color: rgb(191.1351748252, 225.097972028, 179.322027972);
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: rgb(191.1351748252, 225.097972028, 179.322027972);
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgb(199.56, 232.6, 242.12);
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: rgb(152.04, 213.4, 231.08);
}

.table-hover .table-info:hover {
  background-color: rgb(178.8673770492, 224.2393442623, 237.3126229508);
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: rgb(178.8673770492, 224.2393442623, 237.3126229508);
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgb(255, 236.24, 183.6);
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: rgb(255, 220.16, 122.4);
}

.table-hover .table-warning:hover {
  background-color: rgb(255, 229.54, 158.1);
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: rgb(255, 229.54, 158.1);
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgb(235.12, 187.8, 186.4);
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: rgb(218.08, 130.2, 127.6);
}

.table-hover .table-danger:hover {
  background-color: rgb(229.3905696203, 168.4329113924, 166.6294303797);
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: rgb(229.3905696203, 168.4329113924, 166.6294303797);
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: rgb(247.72, 247.72, 247.72);
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: rgb(241.48, 241.48, 241.48);
}

.table-hover .table-light:hover {
  background-color: rgb(234.97, 234.97, 234.97);
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: rgb(234.97, 234.97, 234.97);
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: rgb(197.32, 199.84, 203.2);
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: rgb(147.88, 152.56, 158.8);
}

.table-hover .table-dark:hover {
  background-color: rgb(183.8852173913, 186.992173913, 191.1347826087);
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: rgb(183.8852173913, 186.992173913, 191.1347826087);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #98a6ad;
  background-color: #313a46;
  border-color: rgb(64.75, 76.6428571429, 92.5);
}
.table .thead-light th {
  color: #6c757d;
  background-color: #f1f3fa;
  border-color: #e5e5e5;
}

.table-dark {
  color: #98a6ad;
  background-color: #313a46;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: rgb(64.75, 76.6428571429, 92.5);
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  padding: 0.45rem 0.9rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #dee2e6;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: rgb(192.9827586207, 200.5, 208.0172413793);
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6c757d;
}
select.form-control:focus::-ms-value {
  color: #6c757d;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.45rem + 2px);
  padding-bottom: calc(0.45rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.28rem + 2px);
  padding-bottom: calc(0.28rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.45rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8725rem + 2px);
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #98a6ad;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.2125rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3.6rem !important;
  background-position: right 1.8rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.2125rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: rgb(51.6956521739, 206.3043478261, 87);
  background-color: rgb(51.6956521739, 206.3043478261, 87);
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.2125rem !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3.6rem !important;
  background-position: right 1.8rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.2125rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: rgb(227.5316455696, 96.4683544304, 109.0253164557);
  background-color: rgb(227.5316455696, 96.4683544304, 109.0253164557);
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.45rem 0.9rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #52ae32;
  border-color: #52ae32;
}
.btn-primary:hover {
  color: #fff;
  background-color: rgb(67.9977678571, 144.2879464286, 41.4620535714);
  border-color: rgb(63.3303571429, 134.3839285714, 38.6160714286);
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: rgb(67.9977678571, 144.2879464286, 41.4620535714);
  border-color: rgb(63.3303571429, 134.3839285714, 38.6160714286);
  box-shadow: 0 0 0 0.2rem rgba(107.95, 186.15, 80.75, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #52ae32;
  border-color: #52ae32;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: rgb(63.3303571429, 134.3839285714, 38.6160714286);
  border-color: rgb(58.6629464286, 124.4799107143, 35.7700892857);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107.95, 186.15, 80.75, 0.5);
}

.btn-secondary {
  color: #313a46;
  background-color: #f49d18;
  border-color: #f49d18;
}
.btn-secondary:hover {
  color: #fff;
  background-color: rgb(219.3068181818, 136.7107438017, 10.4431818182);
  border-color: rgb(207.1363636364, 129.1239669421, 9.8636363636);
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: rgb(219.3068181818, 136.7107438017, 10.4431818182);
  border-color: rgb(207.1363636364, 129.1239669421, 9.8636363636);
  box-shadow: 0 0 0 0.2rem rgba(214.75, 142.15, 30.9, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #313a46;
  background-color: #f49d18;
  border-color: #f49d18;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: rgb(207.1363636364, 129.1239669421, 9.8636363636);
  border-color: rgb(194.9659090909, 121.5371900826, 9.2840909091);
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214.75, 142.15, 30.9, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #52ae32;
  border-color: #52ae32;
}
.btn-success:hover {
  color: #fff;
  background-color: rgb(67.9977678571, 144.2879464286, 41.4620535714);
  border-color: rgb(63.3303571429, 134.3839285714, 38.6160714286);
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: rgb(67.9977678571, 144.2879464286, 41.4620535714);
  border-color: rgb(63.3303571429, 134.3839285714, 38.6160714286);
  box-shadow: 0 0 0 0.2rem rgba(107.95, 186.15, 80.75, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #52ae32;
  border-color: #52ae32;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: rgb(63.3303571429, 134.3839285714, 38.6160714286);
  border-color: rgb(58.6629464286, 124.4799107143, 35.7700892857);
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(107.95, 186.15, 80.75, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-info:hover {
  color: #fff;
  background-color: rgb(42.9364754098, 153.0778688525, 184.8135245902);
  border-color: rgb(40.5327868852, 144.5081967213, 174.4672131148);
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: rgb(42.9364754098, 153.0778688525, 184.8135245902);
  border-color: rgb(40.5327868852, 144.5081967213, 174.4672131148);
  box-shadow: 0 0 0 0.2rem rgba(86.7, 187, 215.9, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: rgb(40.5327868852, 144.5081967213, 174.4672131148);
  border-color: rgb(38.1290983607, 135.9385245902, 164.1209016393);
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86.7, 187, 215.9, 0.5);
}

.btn-warning {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-warning:hover {
  color: #313a46;
  background-color: rgb(216.75, 159.8, 0);
  border-color: rgb(204, 150.4, 0);
}
.btn-warning:focus, .btn-warning.focus {
  color: #313a46;
  background-color: rgb(216.75, 159.8, 0);
  border-color: rgb(204, 150.4, 0);
  box-shadow: 0 0 0 0.2rem rgba(224.1, 168.5, 10.5, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: rgb(204, 150.4, 0);
  border-color: rgb(191.25, 141, 0);
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224.1, 168.5, 10.5, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #b80f0a;
  border-color: #b80f0a;
}
.btn-danger:hover {
  color: #fff;
  background-color: rgb(147.7216494845, 12.0425257732, 8.0283505155);
  border-color: rgb(135.6288659794, 11.0567010309, 7.3711340206);
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: rgb(147.7216494845, 12.0425257732, 8.0283505155);
  border-color: rgb(135.6288659794, 11.0567010309, 7.3711340206);
  box-shadow: 0 0 0 0.2rem rgba(194.65, 51, 46.75, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #b80f0a;
  border-color: #b80f0a;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: rgb(135.6288659794, 11.0567010309, 7.3711340206);
  border-color: rgb(123.5360824742, 10.0708762887, 6.7139175258);
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194.65, 51, 46.75, 0.5);
}

.btn-light {
  color: #313a46;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-light:hover {
  color: #313a46;
  background-color: rgb(209.875, 209.875, 209.875);
  border-color: rgb(203.5, 203.5, 203.5);
}
.btn-light:focus, .btn-light.focus {
  color: #313a46;
  background-color: rgb(209.875, 209.875, 209.875);
  border-color: rgb(203.5, 203.5, 203.5);
  box-shadow: 0 0 0 0.2rem rgba(202, 203.35, 205.15, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #313a46;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #313a46;
  background-color: rgb(203.5, 203.5, 203.5);
  border-color: rgb(197.125, 197.125, 197.125);
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 203.35, 205.15, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-dark:hover {
  color: #fff;
  background-color: rgb(33.25, 39.3571428571, 47.5);
  border-color: rgb(28, 33.1428571429, 40);
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: rgb(33.25, 39.3571428571, 47.5);
  border-color: rgb(28, 33.1428571429, 40);
  box-shadow: 0 0 0 0.2rem rgba(79.9, 87.55, 97.75, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: rgb(28, 33.1428571429, 40);
  border-color: rgb(22.75, 26.9285714286, 32.5);
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(79.9, 87.55, 97.75, 0.5);
}

.btn-outline-primary {
  color: #52ae32;
  border-color: #52ae32;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #52ae32;
  border-color: #52ae32;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 174, 50, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #52ae32;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #52ae32;
  border-color: #52ae32;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 174, 50, 0.5);
}

.btn-outline-secondary {
  color: #f49d18;
  border-color: #f49d18;
}
.btn-outline-secondary:hover {
  color: #313a46;
  background-color: #f49d18;
  border-color: #f49d18;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 157, 24, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #f49d18;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #313a46;
  background-color: #f49d18;
  border-color: #f49d18;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 157, 24, 0.5);
}

.btn-outline-success {
  color: #52ae32;
  border-color: #52ae32;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #52ae32;
  border-color: #52ae32;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 174, 50, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #52ae32;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #52ae32;
  border-color: #52ae32;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 174, 50, 0.5);
}

.btn-outline-info {
  color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #39afd1;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}

.btn-outline-warning {
  color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:hover {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffbc00;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}

.btn-outline-danger {
  color: #b80f0a;
  border-color: #b80f0a;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #b80f0a;
  border-color: #b80f0a;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 15, 10, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #b80f0a;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #b80f0a;
  border-color: #b80f0a;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 15, 10, 0.5);
}

.btn-outline-light {
  color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-light:hover {
  color: #313a46;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #e5e5e5;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #313a46;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}

.btn-outline-dark {
  color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #313a46;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #52ae32;
  text-decoration: none;
}
.btn-link:hover {
  color: rgb(53.9955357143, 114.5758928571, 32.9241071429);
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #adb5bd;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgb(221.35, 221.35, 221.35);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-bottom: 0.25em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: rgb(38.5, 45.5714285714, 55);
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #313a46;
  text-decoration: none;
  background-color: rgb(236.65, 236.65, 236.65);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #6c757d;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}
.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.9rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #dee2e6;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8725rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.9rem;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #13007c;
  border-color: #007bff;
  background-color: #13007c;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafbfe, 0 0 0 0.15rem rgba(19, 0, 124, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgb(127.5, 189, 255);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: rgb(178.5, 215.4, 255);
  border-color: rgb(178.5, 215.4, 255);
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  padding: 0.45rem 1.9rem 0.45rem 0.9rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #dee2e6;
  border-radius: 0;
  appearance: none;
}
.custom-select:focus {
  border-color: rgb(192.9827586207, 200.5, 208.0172413793);
  outline: 0;
  box-shadow: none;
}
.custom-select:focus::-ms-value {
  color: #6c757d;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.9rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.2125rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: rgb(192.9827586207, 200.5, 208.0172413793);
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.2125rem + 2px);
  padding: 0.45rem 0.9rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.2125rem;
  padding: 0.45rem 0.9rem;
  line-height: 1.5;
  color: #6c757d;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
}

.custom-range {
  width: 100%;
  height: 1.3rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fafbfe, 0 0 0 0.15rem rgba(19, 0, 124, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fafbfe, 0 0 0 0.15rem rgba(19, 0, 124, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fafbfe, 0 0 0 0.15rem rgba(19, 0, 124, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #13007c;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: rgb(79.2943548387, 47.5, 255);
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #13007c;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: rgb(79.2943548387, 47.5, 255);
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  background-color: #13007c;
  border: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: rgb(79.2943548387, 47.5, 255);
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #13007c;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e5e5e5;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
}
.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}
.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
}
.accordion > .card > .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: calc(1.5rem / 1.5) 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #b2b2b2;
  content: "\f142";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #adb5bd;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #313a46;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #313a46;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(19, 0, 124, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #13007c;
  border-color: #13007c;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

.badge-primary {
  color: #fff;
  background-color: #52ae32;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: rgb(63.3303571429, 134.3839285714, 38.6160714286);
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(82, 174, 50, 0.5);
}

.badge-secondary {
  color: #313a46;
  background-color: #f49d18;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #313a46;
  background-color: rgb(207.1363636364, 129.1239669421, 9.8636363636);
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 157, 24, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #52ae32;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: rgb(63.3303571429, 134.3839285714, 38.6160714286);
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(82, 174, 50, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #39afd1;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: rgb(40.5327868852, 144.5081967213, 174.4672131148);
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}

.badge-warning {
  color: #313a46;
  background-color: #ffbc00;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #313a46;
  background-color: rgb(204, 150.4, 0);
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #b80f0a;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: rgb(135.6288659794, 11.0567010309, 7.3711340206);
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(184, 15, 10, 0.5);
}

.badge-light {
  color: #313a46;
  background-color: #e5e5e5;
}
a.badge-light:hover, a.badge-light:focus {
  color: #313a46;
  background-color: rgb(203.5, 203.5, 203.5);
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #313a46;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: rgb(28, 33.1428571429, 40);
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: rgb(42.64, 90.48, 26);
  background-color: rgb(220.4, 238.8, 214);
  border-color: rgb(206.56, 232.32, 197.6);
}
.alert-primary hr {
  border-top-color: rgb(191.1351748252, 225.097972028, 179.322027972);
}
.alert-primary .alert-link {
  color: rgb(23.9703571429, 50.8639285714, 14.6160714286);
}

.alert-secondary {
  color: rgb(126.88, 81.64, 12.48);
  background-color: rgb(252.8, 235.4, 208.8);
  border-color: rgb(251.92, 227.56, 190.32);
}
.alert-secondary hr {
  border-top-color: rgb(250.7609090909, 217.233553719, 165.9790909091);
}
.alert-secondary .alert-link {
  color: rgb(80.4471641791, 51.7631343284, 7.9128358209);
}

.alert-success {
  color: rgb(42.64, 90.48, 26);
  background-color: rgb(220.4, 238.8, 214);
  border-color: rgb(206.56, 232.32, 197.6);
}
.alert-success hr {
  border-top-color: rgb(191.1351748252, 225.097972028, 179.322027972);
}
.alert-success .alert-link {
  color: rgb(23.9703571429, 50.8639285714, 14.6160714286);
}

.alert-info {
  color: rgb(29.64, 91, 108.68);
  background-color: rgb(215.4, 239, 245.8);
  border-color: rgb(199.56, 232.6, 242.12);
}
.alert-info hr {
  border-top-color: rgb(178.8673770492, 224.2393442623, 237.3126229508);
}
.alert-info .alert-link {
  color: rgb(18.7114285714, 57.4473684211, 68.6085714286);
}

.alert-warning {
  color: rgb(132.6, 97.76, 0);
  background-color: rgb(255, 241.6, 204);
  border-color: rgb(255, 236.24, 183.6);
}
.alert-warning hr {
  border-top-color: rgb(255, 229.54, 158.1);
}
.alert-warning .alert-link {
  color: rgb(81.6, 60.16, 0);
}

.alert-danger {
  color: rgb(95.68, 7.8, 5.2);
  background-color: rgb(240.8, 207, 206);
  border-color: rgb(235.12, 187.8, 186.4);
}
.alert-danger hr {
  border-top-color: rgb(229.3905696203, 168.4329113924, 166.6294303797);
}
.alert-danger .alert-link {
  color: rgb(47.3088659794, 3.8567010309, 2.5711340206);
}

.alert-light {
  color: rgb(119.08, 119.08, 119.08);
  background-color: rgb(249.8, 249.8, 249.8);
  border-color: rgb(247.72, 247.72, 247.72);
}
.alert-light hr {
  border-top-color: rgb(234.97, 234.97, 234.97);
}
.alert-light .alert-link {
  color: rgb(93.58, 93.58, 93.58);
}

.alert-dark {
  color: rgb(25.48, 30.16, 36.4);
  background-color: rgb(213.8, 215.6, 218);
  border-color: rgb(197.32, 199.84, 203.2);
}
.alert-dark hr {
  border-top-color: rgb(183.8852173913, 186.992173913, 191.1347826087);
}
.alert-dark .alert-link {
  color: rgb(4.48, 5.3028571429, 6.4);
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #13007c;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #13007c;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: rgb(42.64, 90.48, 26);
  background-color: rgb(206.56, 232.32, 197.6);
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: rgb(42.64, 90.48, 26);
  background-color: rgb(191.1351748252, 225.097972028, 179.322027972);
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: rgb(42.64, 90.48, 26);
  border-color: rgb(42.64, 90.48, 26);
}

.list-group-item-secondary {
  color: rgb(126.88, 81.64, 12.48);
  background-color: rgb(251.92, 227.56, 190.32);
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: rgb(126.88, 81.64, 12.48);
  background-color: rgb(250.7609090909, 217.233553719, 165.9790909091);
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: rgb(126.88, 81.64, 12.48);
  border-color: rgb(126.88, 81.64, 12.48);
}

.list-group-item-success {
  color: rgb(42.64, 90.48, 26);
  background-color: rgb(206.56, 232.32, 197.6);
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: rgb(42.64, 90.48, 26);
  background-color: rgb(191.1351748252, 225.097972028, 179.322027972);
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: rgb(42.64, 90.48, 26);
  border-color: rgb(42.64, 90.48, 26);
}

.list-group-item-info {
  color: rgb(29.64, 91, 108.68);
  background-color: rgb(199.56, 232.6, 242.12);
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: rgb(29.64, 91, 108.68);
  background-color: rgb(178.8673770492, 224.2393442623, 237.3126229508);
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: rgb(29.64, 91, 108.68);
  border-color: rgb(29.64, 91, 108.68);
}

.list-group-item-warning {
  color: rgb(132.6, 97.76, 0);
  background-color: rgb(255, 236.24, 183.6);
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: rgb(132.6, 97.76, 0);
  background-color: rgb(255, 229.54, 158.1);
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: rgb(132.6, 97.76, 0);
  border-color: rgb(132.6, 97.76, 0);
}

.list-group-item-danger {
  color: rgb(95.68, 7.8, 5.2);
  background-color: rgb(235.12, 187.8, 186.4);
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: rgb(95.68, 7.8, 5.2);
  background-color: rgb(229.3905696203, 168.4329113924, 166.6294303797);
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: rgb(95.68, 7.8, 5.2);
  border-color: rgb(95.68, 7.8, 5.2);
}

.list-group-item-light {
  color: rgb(119.08, 119.08, 119.08);
  background-color: rgb(247.72, 247.72, 247.72);
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: rgb(119.08, 119.08, 119.08);
  background-color: rgb(234.97, 234.97, 234.97);
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: rgb(119.08, 119.08, 119.08);
  border-color: rgb(119.08, 119.08, 119.08);
}

.list-group-item-dark {
  color: rgb(25.48, 30.16, 36.4);
  background-color: rgb(197.32, 199.84, 203.2);
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: rgb(25.48, 30.16, 36.4);
  background-color: rgb(183.8852173913, 186.992173913, 191.1347826087);
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: rgb(25.48, 30.16, 36.4);
  border-color: rgb(25.48, 30.16, 36.4);
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #313a46;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.8rem;
  color: #fff;
  text-align: center;
  background-color: #000;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f1f3fa;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.7rem 0.8rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f1f3fa;
  border-bottom: 1px solid rgb(222.2105263158, 226.8947368421, 243.2894736842);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #52ae32 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: rgb(63.3303571429, 134.3839285714, 38.6160714286) !important;
}

.bg-secondary {
  background-color: #f49d18 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgb(207.1363636364, 129.1239669421, 9.8636363636) !important;
}

.bg-success {
  background-color: #52ae32 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: rgb(63.3303571429, 134.3839285714, 38.6160714286) !important;
}

.bg-info {
  background-color: #39afd1 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: rgb(40.5327868852, 144.5081967213, 174.4672131148) !important;
}

.bg-warning {
  background-color: #ffbc00 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: rgb(204, 150.4, 0) !important;
}

.bg-danger {
  background-color: #b80f0a !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: rgb(135.6288659794, 11.0567010309, 7.3711340206) !important;
}

.bg-light {
  background-color: #e5e5e5 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: rgb(203.5, 203.5, 203.5) !important;
}

.bg-dark {
  background-color: #313a46 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: rgb(28, 33.1428571429, 40) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #52ae32 !important;
}

.border-secondary {
  border-color: #f49d18 !important;
}

.border-success {
  border-color: #52ae32 !important;
}

.border-info {
  border-color: #39afd1 !important;
}

.border-warning {
  border-color: #ffbc00 !important;
}

.border-danger {
  border-color: #b80f0a !important;
}

.border-light {
  border-color: #e5e5e5 !important;
}

.border-dark {
  border-color: #313a46 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-15 {
  height: 15% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.375rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.375rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.375rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.75rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.75rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 4.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 4.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 4.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.375rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.375rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.375rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.75rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.75rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.75rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 4.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 4.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 4.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 4.5rem !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -4.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -4.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -4.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.375rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2.25rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.25rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.25rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 4.5rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2.25rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.25rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.25rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 4.5rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2.25rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.25rem !important;
  }
  .m-sm-n5 {
    margin: -4.5rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.375rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.375rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2.25rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.25rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.25rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.25rem !important;
  }
  .m-md-5 {
    margin: 4.5rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4.5rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4.5rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.375rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.375rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2.25rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.25rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.25rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.25rem !important;
  }
  .p-md-5 {
    padding: 4.5rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4.5rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4.5rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4.5rem !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2.25rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.25rem !important;
  }
  .m-md-n5 {
    margin: -4.5rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.375rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2.25rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.25rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.25rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 4.5rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2.25rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.25rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.25rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 4.5rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2.25rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.25rem !important;
  }
  .m-lg-n5 {
    margin: -4.5rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.375rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2.25rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.25rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.25rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 4.5rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2.25rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.25rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.25rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 4.5rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2.25rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.25rem !important;
  }
  .m-xl-n5 {
    margin: -4.5rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #52ae32 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(53.9955357143, 114.5758928571, 32.9241071429) !important;
}

.text-secondary {
  color: #f49d18 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(182.7954545455, 113.9504132231, 8.7045454545) !important;
}

.text-success {
  color: #52ae32 !important;
}

a.text-success:hover, a.text-success:focus {
  color: rgb(53.9955357143, 114.5758928571, 32.9241071429) !important;
}

.text-info {
  color: #39afd1 !important;
}

a.text-info:hover, a.text-info:focus {
  color: rgb(35.7254098361, 127.368852459, 153.7745901639) !important;
}

.text-warning {
  color: #ffbc00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: rgb(178.5, 131.6, 0) !important;
}

.text-danger {
  color: #b80f0a !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: rgb(111.4432989691, 9.0850515464, 6.0567010309) !important;
}

.text-light {
  color: #e5e5e5 !important;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(190.75, 190.75, 190.75) !important;
}

.text-dark {
  color: #313a46 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: rgb(17.5, 20.7142857143, 25) !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: #98a6ad !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e5e5e5;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e5e5e5;
  }
}
main.container {
  padding-top: 1rem;
  padding: 1rem;
}

.pending-request-box {
  background-color: rgba(19, 0, 124, 0.04);
}

.divider {
  background: #e5e5e5;
  height: 1px;
  margin-bottom: 32px;
  margin-top: 32px;
}

.col-form-label {
  margin: 0 !important;
}

.request-withdrawal-btn {
  margin-top: 1.34rem !important;
}

.form-control {
  -webkit-appearance: none;
}

.lead-title {
  font-weight: normal;
  font-size: 1.5rem;
}

.lead-content {
  font-weight: 500;
  font-size: 17px;
}

.btn {
  font-weight: bold;
}

.help-item {
  color: #b2b2b2;
}

.asteriskField {
  display: none;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: 60px;
}

.footer {
  background-color: #f5f5f5;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.pensionAsterisk .asteriskField {
  display: inline;
}

.btn {
  border-radius: 40px;
}

.green-text {
  color: #52ae32 !important;
}

.orange-text {
  color: #f49d18 !important;
}

.datepicker table tr td span.active {
  background-color: #52ae32 !important;
  background-image: linear-gradient(to bottom, #52ae32, #52ae32) !important;
}

.navbar-custom {
  padding: 0 30px;
  background-color: #ffffff;
  min-height: 80px;
  border-bottom: 1px solid #e5e5e5;
}
.navbar-custom .topbar-left {
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  z-index: 1;
  width: 250px;
  text-align: center;
  top: 0;
  left: 0;
}
.navbar-custom .topbar-left .logo {
  line-height: 80px;
}
.navbar-custom .topbar-left .logo i {
  display: none;
}
.navbar-custom .topbar-right-menu li {
  float: left;
}
.navbar-custom .topbar-right-menu .nav-link {
  padding: 0;
  color: #98a6ad;
  min-width: 32px;
  display: block;
  text-align: center;
  margin: 0 8px;
}

.message-box-item {
  position: relative;
}
.message-box-item .message-box-icon {
  font-size: 22px;
  vertical-align: middle;
  line-height: 27px;
}
.message-box-item .unread-icon-badge-mobile {
  display: inline-block;
  position: absolute;
  font-weight: bold;
  top: 10px;
  right: 6px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background: #b80f0a;
}
@media (max-width: 767.98px) {
  .message-box-item .unread-icon-badge-mobile {
    right: unset;
    left: 16px;
  }
}
.message-box-item .unread-icon-badge {
  position: absolute;
  top: 0px;
  right: -5px;
  padding: 2px 7px;
  border-radius: 50%;
  background: #b80f0a;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.dropdown-menu-center {
  right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.deadline {
  color: #b80f0a;
}

.portfolio-overview-box {
  border: 2px solid #dee2e6;
}

.card.card-account {
  border: 2px solid #dee2e6;
}
.card.card-account:hover {
  cursor: pointer;
  background-color: #f1f3fa;
}
.card.card-account:hover p {
  color: #313a46 !important;
}

.inbox-item {
  display: block;
  padding: 0.75rem 1.25rem;
}
.inbox-item:not(:last-child) {
  border-bottom: 1px solid #e5e5e5 !important;
}
.inbox-item.active {
  border-left: 1px solid #52ae32 !important;
  background-color: white;
}
.inbox-item .message-date {
  color: #98a6ad;
  font-size: 12px;
}
.inbox-item .message-subject {
  margin-bottom: 0;
  color: #313a46;
}
.inbox-item.unread .message-subject {
  color: #52ae32 !important;
  font-weight: 600;
}

.message-box .message .message-header {
  display: block;
  padding: 0.75rem 0;
}
.message-box .message .message-header .message-date {
  color: #313a46;
  font-size: 12px;
}
.message-box .message .message-header .message-subject {
  color: black;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0;
}
.message-box .message .message-content {
  padding: 0.75rem 0;
}

.message-box .attachments .attachment-item {
  border: 2px solid #e5e5e5;
  padding: 0.5rem 0.5rem;
  font-weight: 500;
}

.message-box .empty-message {
  text-align: center !important;
  color: #e5e5e5;
  font-size: 10rem;
}

.message-box .navbar {
  min-height: 42px;
}

.message-box-pagination ul.pagination {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid #e5e5e5 !important;
  border-bottom: 1px solid #e5e5e5 !important;
}
.message-box-pagination ul.pagination .page-link {
  padding: 0.75rem 1.25rem;
  border: 0 !important;
  font-weight: 500;
}
.message-box-pagination ul.pagination .page-link:hover {
  background-color: white !important;
}
.message-box-pagination ul.pagination .page-item.active .page-link {
  color: #52ae32 !important;
  background-color: white !important;
  font-weight: 700;
}

.message-dropdown-menu {
  -webkit-box-shadow: 0px 18px 71px -43px rgb(0, 0, 0);
  -moz-box-shadow: 0px 18px 71px -43px rgb(0, 0, 0);
  box-shadow: 0px 18px 71px -43px rgb(0, 0, 0);
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar a {
  display: block;
  color: black;
  padding: 10px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #52ae32;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #e5e5e5;
}

div.content {
  margin-left: 200px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
}
@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
.intermediare-info-box {
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.client-info-row {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.portfolio-value-column {
  width: 20%;
  font-size: 12px;
}

.badge-primary-lighten {
  color: #52ae32;
  background-color: rgba(82, 174, 50, 0.18);
}
.badge-primary-lighten[href]:hover, .badge-primary-lighten[href]:focus {
  color: #52ae32;
  text-decoration: none;
  background-color: rgba(82, 174, 50, 0.4);
}

.badge-secondary-lighten {
  color: #f49d18;
  background-color: rgba(244, 157, 24, 0.18);
}
.badge-secondary-lighten[href]:hover, .badge-secondary-lighten[href]:focus {
  color: #f49d18;
  text-decoration: none;
  background-color: rgba(244, 157, 24, 0.4);
}

.badge-success-lighten {
  color: #52ae32;
  background-color: rgba(82, 174, 50, 0.18);
}
.badge-success-lighten[href]:hover, .badge-success-lighten[href]:focus {
  color: #52ae32;
  text-decoration: none;
  background-color: rgba(82, 174, 50, 0.4);
}

.badge-info-lighten {
  color: #39afd1;
  background-color: rgba(57, 175, 209, 0.18);
}
.badge-info-lighten[href]:hover, .badge-info-lighten[href]:focus {
  color: #39afd1;
  text-decoration: none;
  background-color: rgba(57, 175, 209, 0.4);
}

.badge-warning-lighten {
  color: #ffbc00;
  background-color: rgba(255, 188, 0, 0.18);
}
.badge-warning-lighten[href]:hover, .badge-warning-lighten[href]:focus {
  color: #ffbc00;
  text-decoration: none;
  background-color: rgba(255, 188, 0, 0.4);
}

.badge-danger-lighten {
  color: #b80f0a;
  background-color: rgba(184, 15, 10, 0.18);
}
.badge-danger-lighten[href]:hover, .badge-danger-lighten[href]:focus {
  color: #b80f0a;
  text-decoration: none;
  background-color: rgba(184, 15, 10, 0.4);
}

.badge-light-lighten {
  color: #e5e5e5;
  background-color: rgba(229, 229, 229, 0.18);
}
.badge-light-lighten[href]:hover, .badge-light-lighten[href]:focus {
  color: #e5e5e5;
  text-decoration: none;
  background-color: rgba(229, 229, 229, 0.4);
}

.badge-dark-lighten {
  color: #313a46;
  background-color: rgba(49, 58, 70, 0.18);
}
.badge-dark-lighten[href]:hover, .badge-dark-lighten[href]:focus {
  color: #313a46;
  text-decoration: none;
  background-color: rgba(49, 58, 70, 0.4);
}

.btn .mdi:before {
  line-height: initial;
}

.btn-rounded {
  border-radius: 2em;
}

.btn-outline-primary {
  color: #52ae32;
  border-color: #52ae32;
}
.btn-outline-primary:hover {
  color: #52ae32;
  background-color: #f1f3fa;
  border-color: #52ae32;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 174, 50, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #52ae32;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #313a46;
  background-color: #f1f3fa;
  border-color: #52ae32;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 174, 50, 0.5);
}

.input-group .btn {
  border-width: 0 !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
.accordion .accordion-title,
.col-form-label,
h5,
h6 {
  margin: 10px 0;
}

.font-8 {
  font-size: 8px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.bg-primary-lighten {
  background-color: rgb(138.3571428571, 213.9285714286, 112.0714285714) !important;
}

.bg-secondary-lighten {
  background-color: rgb(248.6363636364, 198.305785124, 121.3636363636) !important;
}

.bg-success-lighten {
  background-color: rgb(138.3571428571, 213.9285714286, 112.0714285714) !important;
}

.bg-info-lighten {
  background-color: rgb(139.7704918033, 208.4426229508, 228.2295081967) !important;
}

.bg-warning-lighten {
  background-color: rgb(255, 214.8, 102) !important;
}

.bg-danger-lighten {
  background-color: rgb(243.9690721649, 57.5463917526, 52.0309278351) !important;
}

.bg-light-lighten {
  background-color: white !important;
}

.bg-dark-lighten {
  background-color: rgb(91, 107.7142857143, 130) !important;
}

.text-underline {
  text-decoration: underline;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.cursor-pointer {
  cursor: pointer;
}

ul.errorlist {
  color: #b80f0a;
}

.invalid-feedback {
  display: block !important;
}

.form-control {
  height: auto;
}

.arrow-down {
  display: inline-block;
}
.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  transition: all 0.3s ease-out;
  width: 0.4em;
}

.arrow-none:after {
  display: none;
}

.select {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='960px' height='560px' viewBox='0 0 960 560' enable-background='new 0 0 960 560' xml:space='preserve'%3E%3Cg id='Rounded_Rectangle_33_copy_4_1_'%3E%3Cpath d='M480,344.181L268.869,131.889c-15.756-15.859-41.3-15.859-57.054,0c-15.754,15.857-15.754,41.57,0,57.431l237.632,238.937 c8.395,8.451,19.562,12.254,30.553,11.698c10.993,0.556,22.159-3.247,30.555-11.698l237.631-238.937 c15.756-15.86,15.756-41.571,0-57.431s-41.299-15.859-57.051,0L480,344.181z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: right 5px center;
  background-repeat: no-repeat;
  background-size: auto 40%;
  color: #52ae32 !important;
  font-weight: bold !important;
  padding-right: 2rem;
}

.progress {
  height: 3px;
}

.accordion {
  border: 2px solid #e5e5e5;
}
.accordion .accordion-body {
  padding-bottom: 1rem;
}
.accordion .accordion-body .fund-name {
  font-size: 1rem;
  font-weight: normal;
}
.accordion .accordion-title {
  font-size: 1rem;
  font-weight: normal;
}
.accordion .btn-outline-primary {
  border-color: #dee2e6;
}

.nav-tabs.nav-bordered {
  border-bottom: none;
}
.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -2px;
  margin-top: 0.625rem;
}
.nav-tabs.nav-bordered .nav-item:not(:last-child) {
  margin-right: 1.25rem;
}
.nav-tabs.nav-bordered li a {
  border: 0;
  padding: 0;
  padding-bottom: 0.625rem;
}
.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid #f49d18;
}

.navbar .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23f49d18' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

meter {
  margin: 0 auto 1em;
  width: 100%;
  height: 0.5em;
  /* Applicable only to Firefox */
  background: none;
  background-color: rgba(0, 0, 0, 0.1);
}

meter[value="1"] {
  background: #f49d18;
}

meter[value="2"] {
  background: #f49d18;
}

meter[value="3"] {
  background: #f49d18;
}

meter[value="4"] {
  background: #f49d18;
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 140px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }
  .daterangepicker {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-en {
  background-image: url(../images/flags/4x3/en.svg);
}
.flag-icon-en.flag-icon-squared {
  background-image: url(../images/flags/1x1/en.svg);
}

.flag-icon-nl {
  background-image: url(../images/flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(../images/flags/1x1/nl.svg);
}

@font-face {
  font-family: "dripicons-v2";
  src: url("../fonts/dripicons-v2.eot");
  src: url("../fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("../fonts/dripicons-v2.woff") format("woff"), url("../fonts/dripicons-v2.ttf") format("truetype"), url("../fonts/dripicons-v2.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=dripicons-]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  display: inline-block;
}

.dripicons-alarm:before {
  content: "a";
}

.dripicons-align-center:before {
  content: "b";
}

.dripicons-align-justify:before {
  content: "c";
}

.dripicons-align-left:before {
  content: "d";
}

.dripicons-align-right:before {
  content: "e";
}

.dripicons-anchor:before {
  content: "f";
}

.dripicons-archive:before {
  content: "g";
}

.dripicons-arrow-down:before {
  content: "h";
}

.dripicons-arrow-left:before {
  content: "i";
}

.dripicons-arrow-right:before {
  content: "j";
}

.dripicons-arrow-thin-down:before {
  content: "k";
}

.dripicons-arrow-thin-left:before {
  content: "l";
}

.dripicons-arrow-thin-right:before {
  content: "m";
}

.dripicons-arrow-thin-up:before {
  content: "n";
}

.dripicons-arrow-up:before {
  content: "o";
}

.dripicons-article:before {
  content: "p";
}

.dripicons-backspace:before {
  content: "q";
}

.dripicons-basket:before {
  content: "r";
}

.dripicons-basketball:before {
  content: "s";
}

.dripicons-battery-empty:before {
  content: "t";
}

.dripicons-battery-full:before {
  content: "u";
}

.dripicons-battery-low:before {
  content: "v";
}

.dripicons-battery-medium:before {
  content: "w";
}

.dripicons-bell:before {
  content: "x";
}

.dripicons-blog:before {
  content: "y";
}

.dripicons-bluetooth:before {
  content: "z";
}

.dripicons-bold:before {
  content: "A";
}

.dripicons-bookmark:before {
  content: "B";
}

.dripicons-bookmarks:before {
  content: "C";
}

.dripicons-box:before {
  content: "D";
}

.dripicons-briefcase:before {
  content: "E";
}

.dripicons-brightness-low:before {
  content: "F";
}

.dripicons-brightness-max:before {
  content: "G";
}

.dripicons-brightness-medium:before {
  content: "H";
}

.dripicons-broadcast:before {
  content: "I";
}

.dripicons-browser:before {
  content: "J";
}

.dripicons-browser-upload:before {
  content: "K";
}

.dripicons-brush:before {
  content: "L";
}

.dripicons-calendar:before {
  content: "M";
}

.dripicons-camcorder:before {
  content: "N";
}

.dripicons-camera:before {
  content: "O";
}

.dripicons-card:before {
  content: "P";
}

.dripicons-cart:before {
  content: "Q";
}

.dripicons-checklist:before {
  content: "R";
}

.dripicons-checkmark:before {
  content: "S";
}

.dripicons-chevron-down:before {
  content: "T";
}

.dripicons-chevron-left:before {
  content: "U";
}

.dripicons-chevron-right:before {
  content: "V";
}

.dripicons-chevron-up:before {
  content: "W";
}

.dripicons-clipboard:before {
  content: "X";
}

.dripicons-clock:before {
  content: "Y";
}

.dripicons-clockwise:before {
  content: "Z";
}

.dripicons-cloud:before {
  content: "0";
}

.dripicons-cloud-download:before {
  content: "1";
}

.dripicons-cloud-upload:before {
  content: "2";
}

.dripicons-code:before {
  content: "3";
}

.dripicons-contract:before {
  content: "4";
}

.dripicons-contract-2:before {
  content: "5";
}

.dripicons-conversation:before {
  content: "6";
}

.dripicons-copy:before {
  content: "7";
}

.dripicons-crop:before {
  content: "8";
}

.dripicons-cross:before {
  content: "9";
}

.dripicons-crosshair:before {
  content: "!";
}

.dripicons-cutlery:before {
  content: '"';
}

.dripicons-device-desktop:before {
  content: "#";
}

.dripicons-device-mobile:before {
  content: "$";
}

.dripicons-device-tablet:before {
  content: "%";
}

.dripicons-direction:before {
  content: "&";
}

.dripicons-disc:before {
  content: "'";
}

.dripicons-document:before {
  content: "(";
}

.dripicons-document-delete:before {
  content: ")";
}

.dripicons-document-edit:before {
  content: "*";
}

.dripicons-document-new:before {
  content: "+";
}

.dripicons-document-remove:before {
  content: ",";
}

.dripicons-dot:before {
  content: "-";
}

.dripicons-dots-2:before {
  content: ".";
}

.dripicons-dots-3:before {
  content: "/";
}

.dripicons-download:before {
  content: ":";
}

.dripicons-duplicate:before {
  content: ";";
}

.dripicons-enter:before {
  content: "<";
}

.dripicons-exit:before {
  content: "=";
}

.dripicons-expand:before {
  content: ">";
}

.dripicons-expand-2:before {
  content: "?";
}

.dripicons-experiment:before {
  content: "@";
}

.dripicons-export:before {
  content: "[";
}

.dripicons-feed:before {
  content: "]";
}

.dripicons-flag:before {
  content: "^";
}

.dripicons-flashlight:before {
  content: "_";
}

.dripicons-folder:before {
  content: "`";
}

.dripicons-folder-open:before {
  content: "{";
}

.dripicons-forward:before {
  content: "|";
}

.dripicons-gaming:before {
  content: "}";
}

.dripicons-gear:before {
  content: "~";
}

.dripicons-graduation:before {
  content: "\\";
}

.dripicons-graph-bar:before {
  content: "\e000";
}

.dripicons-graph-line:before {
  content: "\e001";
}

.dripicons-graph-pie:before {
  content: "\e002";
}

.dripicons-headset:before {
  content: "\e003";
}

.dripicons-heart:before {
  content: "\e004";
}

.dripicons-help:before {
  content: "\e005";
}

.dripicons-home:before {
  content: "\e006";
}

.dripicons-hourglass:before {
  content: "\e007";
}

.dripicons-inbox:before {
  content: "\e008";
}

.dripicons-information:before {
  content: "\e009";
}

.dripicons-italic:before {
  content: "\e00a";
}

.dripicons-jewel:before {
  content: "\e00b";
}

.dripicons-lifting:before {
  content: "\e00c";
}

.dripicons-lightbulb:before {
  content: "\e00d";
}

.dripicons-link:before {
  content: "\e00e";
}

.dripicons-link-broken:before {
  content: "\e00f";
}

.dripicons-list:before {
  content: "\e010";
}

.dripicons-loading:before {
  content: "\e011";
}

.dripicons-location:before {
  content: "\e012";
}

.dripicons-lock:before {
  content: "\e013";
}

.dripicons-lock-open:before {
  content: "\e014";
}

.dripicons-mail:before {
  content: "\e015";
}

.dripicons-map:before {
  content: "\e016";
}

.dripicons-media-loop:before {
  content: "\e017";
}

.dripicons-media-next:before {
  content: "\e018";
}

.dripicons-media-pause:before {
  content: "\e019";
}

.dripicons-media-play:before {
  content: "\e01a";
}

.dripicons-media-previous:before {
  content: "\e01b";
}

.dripicons-media-record:before {
  content: "\e01c";
}

.dripicons-media-shuffle:before {
  content: "\e01d";
}

.dripicons-media-stop:before {
  content: "\e01e";
}

.dripicons-medical:before {
  content: "\e01f";
}

.dripicons-menu:before {
  content: "\e020";
}

.dripicons-message:before {
  content: "\e021";
}

.dripicons-meter:before {
  content: "\e022";
}

.dripicons-microphone:before {
  content: "\e023";
}

.dripicons-minus:before {
  content: "\e024";
}

.dripicons-monitor:before {
  content: "\e025";
}

.dripicons-move:before {
  content: "\e026";
}

.dripicons-music:before {
  content: "\e027";
}

.dripicons-network-1:before {
  content: "\e028";
}

.dripicons-network-2:before {
  content: "\e029";
}

.dripicons-network-3:before {
  content: "\e02a";
}

.dripicons-network-4:before {
  content: "\e02b";
}

.dripicons-network-5:before {
  content: "\e02c";
}

.dripicons-pamphlet:before {
  content: "\e02d";
}

.dripicons-paperclip:before {
  content: "\e02e";
}

.dripicons-pencil:before {
  content: "\e02f";
}

.dripicons-phone:before {
  content: "\e030";
}

.dripicons-photo:before {
  content: "\e031";
}

.dripicons-photo-group:before {
  content: "\e032";
}

.dripicons-pill:before {
  content: "\e033";
}

.dripicons-pin:before {
  content: "\e034";
}

.dripicons-plus:before {
  content: "\e035";
}

.dripicons-power:before {
  content: "\e036";
}

.dripicons-preview:before {
  content: "\e037";
}

.dripicons-print:before {
  content: "\e038";
}

.dripicons-pulse:before {
  content: "\e039";
}

.dripicons-question:before {
  content: "\e03a";
}

.dripicons-reply:before {
  content: "\e03b";
}

.dripicons-reply-all:before {
  content: "\e03c";
}

.dripicons-return:before {
  content: "\e03d";
}

.dripicons-retweet:before {
  content: "\e03e";
}

.dripicons-rocket:before {
  content: "\e03f";
}

.dripicons-scale:before {
  content: "\e040";
}

.dripicons-search:before {
  content: "\e041";
}

.dripicons-shopping-bag:before {
  content: "\e042";
}

.dripicons-skip:before {
  content: "\e043";
}

.dripicons-stack:before {
  content: "\e044";
}

.dripicons-star:before {
  content: "\e045";
}

.dripicons-stopwatch:before {
  content: "\e046";
}

.dripicons-store:before {
  content: "\e047";
}

.dripicons-suitcase:before {
  content: "\e048";
}

.dripicons-swap:before {
  content: "\e049";
}

.dripicons-tag:before {
  content: "\e04a";
}

.dripicons-tag-delete:before {
  content: "\e04b";
}

.dripicons-tags:before {
  content: "\e04c";
}

.dripicons-thumbs-down:before {
  content: "\e04d";
}

.dripicons-thumbs-up:before {
  content: "\e04e";
}

.dripicons-ticket:before {
  content: "\e04f";
}

.dripicons-time-reverse:before {
  content: "\e050";
}

.dripicons-to-do:before {
  content: "\e051";
}

.dripicons-toggles:before {
  content: "\e052";
}

.dripicons-trash:before {
  content: "\e053";
}

.dripicons-trophy:before {
  content: "\e054";
}

.dripicons-upload:before {
  content: "\e055";
}

.dripicons-user:before {
  content: "\e056";
}

.dripicons-user-group:before {
  content: "\e057";
}

.dripicons-user-id:before {
  content: "\e058";
}

.dripicons-vibrate:before {
  content: "\e059";
}

.dripicons-view-apps:before {
  content: "\e05a";
}

.dripicons-view-list:before {
  content: "\e05b";
}

.dripicons-view-list-large:before {
  content: "\e05c";
}

.dripicons-view-thumb:before {
  content: "\e05d";
}

.dripicons-volume-full:before {
  content: "\e05e";
}

.dripicons-volume-low:before {
  content: "\e05f";
}

.dripicons-volume-medium:before {
  content: "\e060";
}

.dripicons-volume-off:before {
  content: "\e061";
}

.dripicons-wallet:before {
  content: "\e062";
}

.dripicons-warning:before {
  content: "\e063";
}

.dripicons-web:before {
  content: "\e064";
}

.dripicons-weight:before {
  content: "\e065";
}

.dripicons-wifi:before {
  content: "\e066";
}

.dripicons-wrong:before {
  content: "\e067";
}

.dripicons-zoom-in:before {
  content: "\e068";
}

.dripicons-zoom-out:before {
  content: "\e069";
}

/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../fonts/materialdesignicons-webfont.eot?v=2.3.54");
  src: url("../fonts/materialdesignicons-webfont.eot?#iefix&v=2.3.54") format("embedded-opentype"), url("../fonts/materialdesignicons-webfont.woff2?v=2.3.54") format("woff2"), url("../fonts/materialdesignicons-webfont.woff?v=2.3.54") format("woff"), url("../fonts/materialdesignicons-webfont.ttf?v=2.3.54") format("truetype"), url("../fonts/materialdesignicons-webfont.svg?v=2.3.54#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-access-point:before {
  content: "\f002";
}

.mdi-access-point-network:before {
  content: "\f003";
}

.mdi-account:before {
  content: "\f004";
}

.mdi-account-alert:before {
  content: "\f005";
}

.mdi-account-box:before {
  content: "\f006";
}

.mdi-account-box-outline:before {
  content: "\f007";
}

.mdi-account-card-details:before {
  content: "\f5d2";
}

.mdi-account-check:before {
  content: "\f008";
}

.mdi-account-circle:before {
  content: "\f009";
}

.mdi-account-convert:before {
  content: "\f00a";
}

.mdi-account-edit:before {
  content: "\f6bb";
}

.mdi-account-group:before {
  content: "\f848";
}

.mdi-account-heart:before {
  content: "\f898";
}

.mdi-account-key:before {
  content: "\f00b";
}

.mdi-account-location:before {
  content: "\f00c";
}

.mdi-account-minus:before {
  content: "\f00d";
}

.mdi-account-multiple:before {
  content: "\f00e";
}

.mdi-account-multiple-check:before {
  content: "\f8c4";
}

.mdi-account-multiple-minus:before {
  content: "\f5d3";
}

.mdi-account-multiple-outline:before {
  content: "\f00f";
}

.mdi-account-multiple-plus:before {
  content: "\f010";
}

.mdi-account-multiple-plus-outline:before {
  content: "\f7ff";
}

.mdi-account-network:before {
  content: "\f011";
}

.mdi-account-off:before {
  content: "\f012";
}

.mdi-account-outline:before {
  content: "\f013";
}

.mdi-account-plus:before {
  content: "\f014";
}

.mdi-account-plus-outline:before {
  content: "\f800";
}

.mdi-account-remove:before {
  content: "\f015";
}

.mdi-account-search:before {
  content: "\f016";
}

.mdi-account-settings:before {
  content: "\f630";
}

.mdi-account-settings-variant:before {
  content: "\f631";
}

.mdi-account-star:before {
  content: "\f017";
}

.mdi-account-switch:before {
  content: "\f019";
}

.mdi-accusoft:before {
  content: "\f849";
}

.mdi-adjust:before {
  content: "\f01a";
}

.mdi-air-conditioner:before {
  content: "\f01b";
}

.mdi-airballoon:before {
  content: "\f01c";
}

.mdi-airplane:before {
  content: "\f01d";
}

.mdi-airplane-landing:before {
  content: "\f5d4";
}

.mdi-airplane-off:before {
  content: "\f01e";
}

.mdi-airplane-takeoff:before {
  content: "\f5d5";
}

.mdi-airplay:before {
  content: "\f01f";
}

.mdi-airport:before {
  content: "\f84a";
}

.mdi-alarm:before {
  content: "\f020";
}

.mdi-alarm-bell:before {
  content: "\f78d";
}

.mdi-alarm-check:before {
  content: "\f021";
}

.mdi-alarm-light:before {
  content: "\f78e";
}

.mdi-alarm-multiple:before {
  content: "\f022";
}

.mdi-alarm-off:before {
  content: "\f023";
}

.mdi-alarm-plus:before {
  content: "\f024";
}

.mdi-alarm-snooze:before {
  content: "\f68d";
}

.mdi-album:before {
  content: "\f025";
}

.mdi-alert:before {
  content: "\f026";
}

.mdi-alert-box:before {
  content: "\f027";
}

.mdi-alert-circle:before {
  content: "\f028";
}

.mdi-alert-circle-outline:before {
  content: "\f5d6";
}

.mdi-alert-decagram:before {
  content: "\f6bc";
}

.mdi-alert-octagon:before {
  content: "\f029";
}

.mdi-alert-octagram:before {
  content: "\f766";
}

.mdi-alert-outline:before {
  content: "\f02a";
}

.mdi-alien:before {
  content: "\f899";
}

.mdi-all-inclusive:before {
  content: "\f6bd";
}

.mdi-alpha:before {
  content: "\f02b";
}

.mdi-alphabetical:before {
  content: "\f02c";
}

.mdi-altimeter:before {
  content: "\f5d7";
}

.mdi-amazon:before {
  content: "\f02d";
}

.mdi-amazon-alexa:before {
  content: "\f8c5";
}

.mdi-amazon-drive:before {
  content: "\f02e";
}

.mdi-ambulance:before {
  content: "\f02f";
}

.mdi-amplifier:before {
  content: "\f030";
}

.mdi-anchor:before {
  content: "\f031";
}

.mdi-android:before {
  content: "\f032";
}

.mdi-android-debug-bridge:before {
  content: "\f033";
}

.mdi-android-head:before {
  content: "\f78f";
}

.mdi-android-studio:before {
  content: "\f034";
}

.mdi-angular:before {
  content: "\f6b1";
}

.mdi-angularjs:before {
  content: "\f6be";
}

.mdi-animation:before {
  content: "\f5d8";
}

.mdi-anvil:before {
  content: "\f89a";
}

.mdi-apple:before {
  content: "\f035";
}

.mdi-apple-finder:before {
  content: "\f036";
}

.mdi-apple-icloud:before {
  content: "\f038";
}

.mdi-apple-ios:before {
  content: "\f037";
}

.mdi-apple-keyboard-caps:before {
  content: "\f632";
}

.mdi-apple-keyboard-command:before {
  content: "\f633";
}

.mdi-apple-keyboard-control:before {
  content: "\f634";
}

.mdi-apple-keyboard-option:before {
  content: "\f635";
}

.mdi-apple-keyboard-shift:before {
  content: "\f636";
}

.mdi-apple-safari:before {
  content: "\f039";
}

.mdi-application:before {
  content: "\f614";
}

.mdi-approval:before {
  content: "\f790";
}

.mdi-apps:before {
  content: "\f03b";
}

.mdi-arch:before {
  content: "\f8c6";
}

.mdi-archive:before {
  content: "\f03c";
}

.mdi-arrange-bring-forward:before {
  content: "\f03d";
}

.mdi-arrange-bring-to-front:before {
  content: "\f03e";
}

.mdi-arrange-send-backward:before {
  content: "\f03f";
}

.mdi-arrange-send-to-back:before {
  content: "\f040";
}

.mdi-arrow-all:before {
  content: "\f041";
}

.mdi-arrow-bottom-left:before {
  content: "\f042";
}

.mdi-arrow-bottom-right:before {
  content: "\f043";
}

.mdi-arrow-collapse:before {
  content: "\f615";
}

.mdi-arrow-collapse-all:before {
  content: "\f044";
}

.mdi-arrow-collapse-down:before {
  content: "\f791";
}

.mdi-arrow-collapse-horizontal:before {
  content: "\f84b";
}

.mdi-arrow-collapse-left:before {
  content: "\f792";
}

.mdi-arrow-collapse-right:before {
  content: "\f793";
}

.mdi-arrow-collapse-up:before {
  content: "\f794";
}

.mdi-arrow-collapse-vertical:before {
  content: "\f84c";
}

.mdi-arrow-down:before {
  content: "\f045";
}

.mdi-arrow-down-bold:before {
  content: "\f72d";
}

.mdi-arrow-down-bold-box:before {
  content: "\f72e";
}

.mdi-arrow-down-bold-box-outline:before {
  content: "\f72f";
}

.mdi-arrow-down-bold-circle:before {
  content: "\f047";
}

.mdi-arrow-down-bold-circle-outline:before {
  content: "\f048";
}

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\f049";
}

.mdi-arrow-down-box:before {
  content: "\f6bf";
}

.mdi-arrow-down-drop-circle:before {
  content: "\f04a";
}

.mdi-arrow-down-drop-circle-outline:before {
  content: "\f04b";
}

.mdi-arrow-down-thick:before {
  content: "\f046";
}

.mdi-arrow-expand:before {
  content: "\f616";
}

.mdi-arrow-expand-all:before {
  content: "\f04c";
}

.mdi-arrow-expand-down:before {
  content: "\f795";
}

.mdi-arrow-expand-horizontal:before {
  content: "\f84d";
}

.mdi-arrow-expand-left:before {
  content: "\f796";
}

.mdi-arrow-expand-right:before {
  content: "\f797";
}

.mdi-arrow-expand-up:before {
  content: "\f798";
}

.mdi-arrow-expand-vertical:before {
  content: "\f84e";
}

.mdi-arrow-left:before {
  content: "\f04d";
}

.mdi-arrow-left-bold:before {
  content: "\f730";
}

.mdi-arrow-left-bold-box:before {
  content: "\f731";
}

.mdi-arrow-left-bold-box-outline:before {
  content: "\f732";
}

.mdi-arrow-left-bold-circle:before {
  content: "\f04f";
}

.mdi-arrow-left-bold-circle-outline:before {
  content: "\f050";
}

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\f051";
}

.mdi-arrow-left-box:before {
  content: "\f6c0";
}

.mdi-arrow-left-drop-circle:before {
  content: "\f052";
}

.mdi-arrow-left-drop-circle-outline:before {
  content: "\f053";
}

.mdi-arrow-left-thick:before {
  content: "\f04e";
}

.mdi-arrow-right:before {
  content: "\f054";
}

.mdi-arrow-right-bold:before {
  content: "\f733";
}

.mdi-arrow-right-bold-box:before {
  content: "\f734";
}

.mdi-arrow-right-bold-box-outline:before {
  content: "\f735";
}

.mdi-arrow-right-bold-circle:before {
  content: "\f056";
}

.mdi-arrow-right-bold-circle-outline:before {
  content: "\f057";
}

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\f058";
}

.mdi-arrow-right-box:before {
  content: "\f6c1";
}

.mdi-arrow-right-drop-circle:before {
  content: "\f059";
}

.mdi-arrow-right-drop-circle-outline:before {
  content: "\f05a";
}

.mdi-arrow-right-thick:before {
  content: "\f055";
}

.mdi-arrow-top-left:before {
  content: "\f05b";
}

.mdi-arrow-top-right:before {
  content: "\f05c";
}

.mdi-arrow-up:before {
  content: "\f05d";
}

.mdi-arrow-up-bold:before {
  content: "\f736";
}

.mdi-arrow-up-bold-box:before {
  content: "\f737";
}

.mdi-arrow-up-bold-box-outline:before {
  content: "\f738";
}

.mdi-arrow-up-bold-circle:before {
  content: "\f05f";
}

.mdi-arrow-up-bold-circle-outline:before {
  content: "\f060";
}

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\f061";
}

.mdi-arrow-up-box:before {
  content: "\f6c2";
}

.mdi-arrow-up-drop-circle:before {
  content: "\f062";
}

.mdi-arrow-up-drop-circle-outline:before {
  content: "\f063";
}

.mdi-arrow-up-thick:before {
  content: "\f05e";
}

.mdi-artist:before {
  content: "\f802";
}

.mdi-assistant:before {
  content: "\f064";
}

.mdi-asterisk:before {
  content: "\f6c3";
}

.mdi-at:before {
  content: "\f065";
}

.mdi-atlassian:before {
  content: "\f803";
}

.mdi-atom:before {
  content: "\f767";
}

.mdi-attachment:before {
  content: "\f066";
}

.mdi-audiobook:before {
  content: "\f067";
}

.mdi-augmented-reality:before {
  content: "\f84f";
}

.mdi-auto-fix:before {
  content: "\f068";
}

.mdi-auto-upload:before {
  content: "\f069";
}

.mdi-autorenew:before {
  content: "\f06a";
}

.mdi-av-timer:before {
  content: "\f06b";
}

.mdi-axe:before {
  content: "\f8c7";
}

.mdi-azure:before {
  content: "\f804";
}

.mdi-baby:before {
  content: "\f06c";
}

.mdi-baby-buggy:before {
  content: "\f68e";
}

.mdi-backburger:before {
  content: "\f06d";
}

.mdi-backspace:before {
  content: "\f06e";
}

.mdi-backup-restore:before {
  content: "\f06f";
}

.mdi-badminton:before {
  content: "\f850";
}

.mdi-bandcamp:before {
  content: "\f674";
}

.mdi-bank:before {
  content: "\f070";
}

.mdi-barcode:before {
  content: "\f071";
}

.mdi-barcode-scan:before {
  content: "\f072";
}

.mdi-barley:before {
  content: "\f073";
}

.mdi-barrel:before {
  content: "\f074";
}

.mdi-baseball:before {
  content: "\f851";
}

.mdi-baseball-bat:before {
  content: "\f852";
}

.mdi-basecamp:before {
  content: "\f075";
}

.mdi-basket:before {
  content: "\f076";
}

.mdi-basket-fill:before {
  content: "\f077";
}

.mdi-basket-unfill:before {
  content: "\f078";
}

.mdi-basketball:before {
  content: "\f805";
}

.mdi-battery:before {
  content: "\f079";
}

.mdi-battery-10:before {
  content: "\f07a";
}

.mdi-battery-20:before {
  content: "\f07b";
}

.mdi-battery-30:before {
  content: "\f07c";
}

.mdi-battery-40:before {
  content: "\f07d";
}

.mdi-battery-50:before {
  content: "\f07e";
}

.mdi-battery-60:before {
  content: "\f07f";
}

.mdi-battery-70:before {
  content: "\f080";
}

.mdi-battery-80:before {
  content: "\f081";
}

.mdi-battery-90:before {
  content: "\f082";
}

.mdi-battery-alert:before {
  content: "\f083";
}

.mdi-battery-charging:before {
  content: "\f084";
}

.mdi-battery-charging-10:before {
  content: "\f89b";
}

.mdi-battery-charging-100:before {
  content: "\f085";
}

.mdi-battery-charging-20:before {
  content: "\f086";
}

.mdi-battery-charging-30:before {
  content: "\f087";
}

.mdi-battery-charging-40:before {
  content: "\f088";
}

.mdi-battery-charging-50:before {
  content: "\f89c";
}

.mdi-battery-charging-60:before {
  content: "\f089";
}

.mdi-battery-charging-70:before {
  content: "\f89d";
}

.mdi-battery-charging-80:before {
  content: "\f08a";
}

.mdi-battery-charging-90:before {
  content: "\f08b";
}

.mdi-battery-charging-outline:before {
  content: "\f89e";
}

.mdi-battery-charging-wireless:before {
  content: "\f806";
}

.mdi-battery-charging-wireless-10:before {
  content: "\f807";
}

.mdi-battery-charging-wireless-20:before {
  content: "\f808";
}

.mdi-battery-charging-wireless-30:before {
  content: "\f809";
}

.mdi-battery-charging-wireless-40:before {
  content: "\f80a";
}

.mdi-battery-charging-wireless-50:before {
  content: "\f80b";
}

.mdi-battery-charging-wireless-60:before {
  content: "\f80c";
}

.mdi-battery-charging-wireless-70:before {
  content: "\f80d";
}

.mdi-battery-charging-wireless-80:before {
  content: "\f80e";
}

.mdi-battery-charging-wireless-90:before {
  content: "\f80f";
}

.mdi-battery-charging-wireless-alert:before {
  content: "\f810";
}

.mdi-battery-charging-wireless-outline:before {
  content: "\f811";
}

.mdi-battery-minus:before {
  content: "\f08c";
}

.mdi-battery-negative:before {
  content: "\f08d";
}

.mdi-battery-outline:before {
  content: "\f08e";
}

.mdi-battery-plus:before {
  content: "\f08f";
}

.mdi-battery-positive:before {
  content: "\f090";
}

.mdi-battery-unknown:before {
  content: "\f091";
}

.mdi-beach:before {
  content: "\f092";
}

.mdi-beaker:before {
  content: "\f68f";
}

.mdi-beats:before {
  content: "\f097";
}

.mdi-bed-empty:before {
  content: "\f89f";
}

.mdi-beer:before {
  content: "\f098";
}

.mdi-behance:before {
  content: "\f099";
}

.mdi-bell:before {
  content: "\f09a";
}

.mdi-bell-off:before {
  content: "\f09b";
}

.mdi-bell-outline:before {
  content: "\f09c";
}

.mdi-bell-plus:before {
  content: "\f09d";
}

.mdi-bell-ring:before {
  content: "\f09e";
}

.mdi-bell-ring-outline:before {
  content: "\f09f";
}

.mdi-bell-sleep:before {
  content: "\f0a0";
}

.mdi-beta:before {
  content: "\f0a1";
}

.mdi-bible:before {
  content: "\f0a2";
}

.mdi-bike:before {
  content: "\f0a3";
}

.mdi-bing:before {
  content: "\f0a4";
}

.mdi-binoculars:before {
  content: "\f0a5";
}

.mdi-bio:before {
  content: "\f0a6";
}

.mdi-biohazard:before {
  content: "\f0a7";
}

.mdi-bitbucket:before {
  content: "\f0a8";
}

.mdi-bitcoin:before {
  content: "\f812";
}

.mdi-black-mesa:before {
  content: "\f0a9";
}

.mdi-blackberry:before {
  content: "\f0aa";
}

.mdi-blender:before {
  content: "\f0ab";
}

.mdi-blinds:before {
  content: "\f0ac";
}

.mdi-block-helper:before {
  content: "\f0ad";
}

.mdi-blogger:before {
  content: "\f0ae";
}

.mdi-bluetooth:before {
  content: "\f0af";
}

.mdi-bluetooth-audio:before {
  content: "\f0b0";
}

.mdi-bluetooth-connect:before {
  content: "\f0b1";
}

.mdi-bluetooth-off:before {
  content: "\f0b2";
}

.mdi-bluetooth-settings:before {
  content: "\f0b3";
}

.mdi-bluetooth-transfer:before {
  content: "\f0b4";
}

.mdi-blur:before {
  content: "\f0b5";
}

.mdi-blur-linear:before {
  content: "\f0b6";
}

.mdi-blur-off:before {
  content: "\f0b7";
}

.mdi-blur-radial:before {
  content: "\f0b8";
}

.mdi-bomb:before {
  content: "\f690";
}

.mdi-bomb-off:before {
  content: "\f6c4";
}

.mdi-bone:before {
  content: "\f0b9";
}

.mdi-book:before {
  content: "\f0ba";
}

.mdi-book-minus:before {
  content: "\f5d9";
}

.mdi-book-multiple:before {
  content: "\f0bb";
}

.mdi-book-multiple-variant:before {
  content: "\f0bc";
}

.mdi-book-open:before {
  content: "\f0bd";
}

.mdi-book-open-page-variant:before {
  content: "\f5da";
}

.mdi-book-open-variant:before {
  content: "\f0be";
}

.mdi-book-plus:before {
  content: "\f5db";
}

.mdi-book-secure:before {
  content: "\f799";
}

.mdi-book-unsecure:before {
  content: "\f79a";
}

.mdi-book-variant:before {
  content: "\f0bf";
}

.mdi-bookmark:before {
  content: "\f0c0";
}

.mdi-bookmark-check:before {
  content: "\f0c1";
}

.mdi-bookmark-music:before {
  content: "\f0c2";
}

.mdi-bookmark-outline:before {
  content: "\f0c3";
}

.mdi-bookmark-plus:before {
  content: "\f0c5";
}

.mdi-bookmark-plus-outline:before {
  content: "\f0c4";
}

.mdi-bookmark-remove:before {
  content: "\f0c6";
}

.mdi-boombox:before {
  content: "\f5dc";
}

.mdi-bootstrap:before {
  content: "\f6c5";
}

.mdi-border-all:before {
  content: "\f0c7";
}

.mdi-border-all-variant:before {
  content: "\f8a0";
}

.mdi-border-bottom:before {
  content: "\f0c8";
}

.mdi-border-bottom-variant:before {
  content: "\f8a1";
}

.mdi-border-color:before {
  content: "\f0c9";
}

.mdi-border-horizontal:before {
  content: "\f0ca";
}

.mdi-border-inside:before {
  content: "\f0cb";
}

.mdi-border-left:before {
  content: "\f0cc";
}

.mdi-border-left-variant:before {
  content: "\f8a2";
}

.mdi-border-none:before {
  content: "\f0cd";
}

.mdi-border-none-variant:before {
  content: "\f8a3";
}

.mdi-border-outside:before {
  content: "\f0ce";
}

.mdi-border-right:before {
  content: "\f0cf";
}

.mdi-border-right-variant:before {
  content: "\f8a4";
}

.mdi-border-style:before {
  content: "\f0d0";
}

.mdi-border-top:before {
  content: "\f0d1";
}

.mdi-border-top-variant:before {
  content: "\f8a5";
}

.mdi-border-vertical:before {
  content: "\f0d2";
}

.mdi-bottle-wine:before {
  content: "\f853";
}

.mdi-bow-tie:before {
  content: "\f677";
}

.mdi-bowl:before {
  content: "\f617";
}

.mdi-bowling:before {
  content: "\f0d3";
}

.mdi-box:before {
  content: "\f0d4";
}

.mdi-box-cutter:before {
  content: "\f0d5";
}

.mdi-box-shadow:before {
  content: "\f637";
}

.mdi-bridge:before {
  content: "\f618";
}

.mdi-briefcase:before {
  content: "\f0d6";
}

.mdi-briefcase-check:before {
  content: "\f0d7";
}

.mdi-briefcase-download:before {
  content: "\f0d8";
}

.mdi-briefcase-outline:before {
  content: "\f813";
}

.mdi-briefcase-upload:before {
  content: "\f0d9";
}

.mdi-brightness-1:before {
  content: "\f0da";
}

.mdi-brightness-2:before {
  content: "\f0db";
}

.mdi-brightness-3:before {
  content: "\f0dc";
}

.mdi-brightness-4:before {
  content: "\f0dd";
}

.mdi-brightness-5:before {
  content: "\f0de";
}

.mdi-brightness-6:before {
  content: "\f0df";
}

.mdi-brightness-7:before {
  content: "\f0e0";
}

.mdi-brightness-auto:before {
  content: "\f0e1";
}

.mdi-broom:before {
  content: "\f0e2";
}

.mdi-brush:before {
  content: "\f0e3";
}

.mdi-buffer:before {
  content: "\f619";
}

.mdi-bug:before {
  content: "\f0e4";
}

.mdi-bulletin-board:before {
  content: "\f0e5";
}

.mdi-bullhorn:before {
  content: "\f0e6";
}

.mdi-bullseye:before {
  content: "\f5dd";
}

.mdi-bullseye-arrow:before {
  content: "\f8c8";
}

.mdi-bus:before {
  content: "\f0e7";
}

.mdi-bus-articulated-end:before {
  content: "\f79b";
}

.mdi-bus-articulated-front:before {
  content: "\f79c";
}

.mdi-bus-clock:before {
  content: "\f8c9";
}

.mdi-bus-double-decker:before {
  content: "\f79d";
}

.mdi-bus-school:before {
  content: "\f79e";
}

.mdi-bus-side:before {
  content: "\f79f";
}

.mdi-cached:before {
  content: "\f0e8";
}

.mdi-cake:before {
  content: "\f0e9";
}

.mdi-cake-layered:before {
  content: "\f0ea";
}

.mdi-cake-variant:before {
  content: "\f0eb";
}

.mdi-calculator:before {
  content: "\f0ec";
}

.mdi-calendar:before {
  content: "\f0ed";
}

.mdi-calendar-blank:before {
  content: "\f0ee";
}

.mdi-calendar-check:before {
  content: "\f0ef";
}

.mdi-calendar-clock:before {
  content: "\f0f0";
}

.mdi-calendar-edit:before {
  content: "\f8a6";
}

.mdi-calendar-multiple:before {
  content: "\f0f1";
}

.mdi-calendar-multiple-check:before {
  content: "\f0f2";
}

.mdi-calendar-plus:before {
  content: "\f0f3";
}

.mdi-calendar-question:before {
  content: "\f691";
}

.mdi-calendar-range:before {
  content: "\f678";
}

.mdi-calendar-remove:before {
  content: "\f0f4";
}

.mdi-calendar-text:before {
  content: "\f0f5";
}

.mdi-calendar-today:before {
  content: "\f0f6";
}

.mdi-call-made:before {
  content: "\f0f7";
}

.mdi-call-merge:before {
  content: "\f0f8";
}

.mdi-call-missed:before {
  content: "\f0f9";
}

.mdi-call-received:before {
  content: "\f0fa";
}

.mdi-call-split:before {
  content: "\f0fb";
}

.mdi-camcorder:before {
  content: "\f0fc";
}

.mdi-camcorder-box:before {
  content: "\f0fd";
}

.mdi-camcorder-box-off:before {
  content: "\f0fe";
}

.mdi-camcorder-off:before {
  content: "\f0ff";
}

.mdi-camera:before {
  content: "\f100";
}

.mdi-camera-account:before {
  content: "\f8ca";
}

.mdi-camera-burst:before {
  content: "\f692";
}

.mdi-camera-enhance:before {
  content: "\f101";
}

.mdi-camera-front:before {
  content: "\f102";
}

.mdi-camera-front-variant:before {
  content: "\f103";
}

.mdi-camera-gopro:before {
  content: "\f7a0";
}

.mdi-camera-image:before {
  content: "\f8cb";
}

.mdi-camera-iris:before {
  content: "\f104";
}

.mdi-camera-metering-center:before {
  content: "\f7a1";
}

.mdi-camera-metering-matrix:before {
  content: "\f7a2";
}

.mdi-camera-metering-partial:before {
  content: "\f7a3";
}

.mdi-camera-metering-spot:before {
  content: "\f7a4";
}

.mdi-camera-off:before {
  content: "\f5df";
}

.mdi-camera-party-mode:before {
  content: "\f105";
}

.mdi-camera-rear:before {
  content: "\f106";
}

.mdi-camera-rear-variant:before {
  content: "\f107";
}

.mdi-camera-switch:before {
  content: "\f108";
}

.mdi-camera-timer:before {
  content: "\f109";
}

.mdi-cancel:before {
  content: "\f739";
}

.mdi-candle:before {
  content: "\f5e2";
}

.mdi-candycane:before {
  content: "\f10a";
}

.mdi-cannabis:before {
  content: "\f7a5";
}

.mdi-car:before {
  content: "\f10b";
}

.mdi-car-battery:before {
  content: "\f10c";
}

.mdi-car-connected:before {
  content: "\f10d";
}

.mdi-car-convertible:before {
  content: "\f7a6";
}

.mdi-car-estate:before {
  content: "\f7a7";
}

.mdi-car-hatchback:before {
  content: "\f7a8";
}

.mdi-car-limousine:before {
  content: "\f8cc";
}

.mdi-car-pickup:before {
  content: "\f7a9";
}

.mdi-car-side:before {
  content: "\f7aa";
}

.mdi-car-sports:before {
  content: "\f7ab";
}

.mdi-car-wash:before {
  content: "\f10e";
}

.mdi-caravan:before {
  content: "\f7ac";
}

.mdi-cards:before {
  content: "\f638";
}

.mdi-cards-club:before {
  content: "\f8cd";
}

.mdi-cards-diamond:before {
  content: "\f8ce";
}

.mdi-cards-heart:before {
  content: "\f8cf";
}

.mdi-cards-outline:before {
  content: "\f639";
}

.mdi-cards-playing-outline:before {
  content: "\f63a";
}

.mdi-cards-spade:before {
  content: "\f8d0";
}

.mdi-cards-variant:before {
  content: "\f6c6";
}

.mdi-carrot:before {
  content: "\f10f";
}

.mdi-cart:before {
  content: "\f110";
}

.mdi-cart-off:before {
  content: "\f66b";
}

.mdi-cart-outline:before {
  content: "\f111";
}

.mdi-cart-plus:before {
  content: "\f112";
}

.mdi-case-sensitive-alt:before {
  content: "\f113";
}

.mdi-cash:before {
  content: "\f114";
}

.mdi-cash-100:before {
  content: "\f115";
}

.mdi-cash-multiple:before {
  content: "\f116";
}

.mdi-cash-usd:before {
  content: "\f117";
}

.mdi-cast:before {
  content: "\f118";
}

.mdi-cast-connected:before {
  content: "\f119";
}

.mdi-cast-off:before {
  content: "\f789";
}

.mdi-castle:before {
  content: "\f11a";
}

.mdi-cat:before {
  content: "\f11b";
}

.mdi-cctv:before {
  content: "\f7ad";
}

.mdi-ceiling-light:before {
  content: "\f768";
}

.mdi-cellphone:before {
  content: "\f11c";
}

.mdi-cellphone-android:before {
  content: "\f11d";
}

.mdi-cellphone-basic:before {
  content: "\f11e";
}

.mdi-cellphone-dock:before {
  content: "\f11f";
}

.mdi-cellphone-iphone:before {
  content: "\f120";
}

.mdi-cellphone-link:before {
  content: "\f121";
}

.mdi-cellphone-link-off:before {
  content: "\f122";
}

.mdi-cellphone-message:before {
  content: "\f8d2";
}

.mdi-cellphone-settings:before {
  content: "\f123";
}

.mdi-cellphone-text:before {
  content: "\f8d1";
}

.mdi-cellphone-wireless:before {
  content: "\f814";
}

.mdi-certificate:before {
  content: "\f124";
}

.mdi-chair-school:before {
  content: "\f125";
}

.mdi-chart-arc:before {
  content: "\f126";
}

.mdi-chart-areaspline:before {
  content: "\f127";
}

.mdi-chart-bar:before {
  content: "\f128";
}

.mdi-chart-bar-stacked:before {
  content: "\f769";
}

.mdi-chart-bubble:before {
  content: "\f5e3";
}

.mdi-chart-donut:before {
  content: "\f7ae";
}

.mdi-chart-donut-variant:before {
  content: "\f7af";
}

.mdi-chart-gantt:before {
  content: "\f66c";
}

.mdi-chart-histogram:before {
  content: "\f129";
}

.mdi-chart-line:before {
  content: "\f12a";
}

.mdi-chart-line-stacked:before {
  content: "\f76a";
}

.mdi-chart-line-variant:before {
  content: "\f7b0";
}

.mdi-chart-multiline:before {
  content: "\f8d3";
}

.mdi-chart-pie:before {
  content: "\f12b";
}

.mdi-chart-scatterplot-hexbin:before {
  content: "\f66d";
}

.mdi-chart-timeline:before {
  content: "\f66e";
}

.mdi-check:before {
  content: "\f12c";
}

.mdi-check-all:before {
  content: "\f12d";
}

.mdi-check-circle:before {
  content: "\f5e0";
}

.mdi-check-circle-outline:before {
  content: "\f5e1";
}

.mdi-check-outline:before {
  content: "\f854";
}

.mdi-checkbox-blank:before {
  content: "\f12e";
}

.mdi-checkbox-blank-circle:before {
  content: "\f12f";
}

.mdi-checkbox-blank-circle-outline:before {
  content: "\f130";
}

.mdi-checkbox-blank-outline:before {
  content: "\f131";
}

.mdi-checkbox-intermediate:before {
  content: "\f855";
}

.mdi-checkbox-marked:before {
  content: "\f132";
}

.mdi-checkbox-marked-circle:before {
  content: "\f133";
}

.mdi-checkbox-marked-circle-outline:before {
  content: "\f134";
}

.mdi-checkbox-marked-outline:before {
  content: "\f135";
}

.mdi-checkbox-multiple-blank:before {
  content: "\f136";
}

.mdi-checkbox-multiple-blank-circle:before {
  content: "\f63b";
}

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\f63c";
}

.mdi-checkbox-multiple-blank-outline:before {
  content: "\f137";
}

.mdi-checkbox-multiple-marked:before {
  content: "\f138";
}

.mdi-checkbox-multiple-marked-circle:before {
  content: "\f63d";
}

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\f63e";
}

.mdi-checkbox-multiple-marked-outline:before {
  content: "\f139";
}

.mdi-checkerboard:before {
  content: "\f13a";
}

.mdi-chemical-weapon:before {
  content: "\f13b";
}

.mdi-chess-bishop:before {
  content: "\f85b";
}

.mdi-chess-king:before {
  content: "\f856";
}

.mdi-chess-knight:before {
  content: "\f857";
}

.mdi-chess-pawn:before {
  content: "\f858";
}

.mdi-chess-queen:before {
  content: "\f859";
}

.mdi-chess-rook:before {
  content: "\f85a";
}

.mdi-chevron-double-down:before {
  content: "\f13c";
}

.mdi-chevron-double-left:before {
  content: "\f13d";
}

.mdi-chevron-double-right:before {
  content: "\f13e";
}

.mdi-chevron-double-up:before {
  content: "\f13f";
}

.mdi-chevron-down:before {
  content: "\f140";
}

.mdi-chevron-left:before {
  content: "\f141";
}

.mdi-chevron-right:before {
  content: "\f142";
}

.mdi-chevron-up:before {
  content: "\f143";
}

.mdi-chili-hot:before {
  content: "\f7b1";
}

.mdi-chili-medium:before {
  content: "\f7b2";
}

.mdi-chili-mild:before {
  content: "\f7b3";
}

.mdi-chip:before {
  content: "\f61a";
}

.mdi-church:before {
  content: "\f144";
}

.mdi-circle:before {
  content: "\f764";
}

.mdi-circle-edit-outline:before {
  content: "\f8d4";
}

.mdi-circle-outline:before {
  content: "\f765";
}

.mdi-cisco-webex:before {
  content: "\f145";
}

.mdi-city:before {
  content: "\f146";
}

.mdi-clipboard:before {
  content: "\f147";
}

.mdi-clipboard-account:before {
  content: "\f148";
}

.mdi-clipboard-alert:before {
  content: "\f149";
}

.mdi-clipboard-arrow-down:before {
  content: "\f14a";
}

.mdi-clipboard-arrow-left:before {
  content: "\f14b";
}

.mdi-clipboard-check:before {
  content: "\f14c";
}

.mdi-clipboard-check-outline:before {
  content: "\f8a7";
}

.mdi-clipboard-flow:before {
  content: "\f6c7";
}

.mdi-clipboard-outline:before {
  content: "\f14d";
}

.mdi-clipboard-plus:before {
  content: "\f750";
}

.mdi-clipboard-pulse:before {
  content: "\f85c";
}

.mdi-clipboard-pulse-outline:before {
  content: "\f85d";
}

.mdi-clipboard-text:before {
  content: "\f14e";
}

.mdi-clippy:before {
  content: "\f14f";
}

.mdi-clock:before {
  content: "\f150";
}

.mdi-clock-alert:before {
  content: "\f5ce";
}

.mdi-clock-end:before {
  content: "\f151";
}

.mdi-clock-fast:before {
  content: "\f152";
}

.mdi-clock-in:before {
  content: "\f153";
}

.mdi-clock-out:before {
  content: "\f154";
}

.mdi-clock-start:before {
  content: "\f155";
}

.mdi-close:before {
  content: "\f156";
}

.mdi-close-box:before {
  content: "\f157";
}

.mdi-close-box-outline:before {
  content: "\f158";
}

.mdi-close-circle:before {
  content: "\f159";
}

.mdi-close-circle-outline:before {
  content: "\f15a";
}

.mdi-close-network:before {
  content: "\f15b";
}

.mdi-close-octagon:before {
  content: "\f15c";
}

.mdi-close-octagon-outline:before {
  content: "\f15d";
}

.mdi-close-outline:before {
  content: "\f6c8";
}

.mdi-closed-caption:before {
  content: "\f15e";
}

.mdi-cloud:before {
  content: "\f15f";
}

.mdi-cloud-braces:before {
  content: "\f7b4";
}

.mdi-cloud-check:before {
  content: "\f160";
}

.mdi-cloud-circle:before {
  content: "\f161";
}

.mdi-cloud-download:before {
  content: "\f162";
}

.mdi-cloud-off-outline:before {
  content: "\f164";
}

.mdi-cloud-outline:before {
  content: "\f163";
}

.mdi-cloud-print:before {
  content: "\f165";
}

.mdi-cloud-print-outline:before {
  content: "\f166";
}

.mdi-cloud-sync:before {
  content: "\f63f";
}

.mdi-cloud-tags:before {
  content: "\f7b5";
}

.mdi-cloud-upload:before {
  content: "\f167";
}

.mdi-clover:before {
  content: "\f815";
}

.mdi-code-array:before {
  content: "\f168";
}

.mdi-code-braces:before {
  content: "\f169";
}

.mdi-code-brackets:before {
  content: "\f16a";
}

.mdi-code-equal:before {
  content: "\f16b";
}

.mdi-code-greater-than:before {
  content: "\f16c";
}

.mdi-code-greater-than-or-equal:before {
  content: "\f16d";
}

.mdi-code-less-than:before {
  content: "\f16e";
}

.mdi-code-less-than-or-equal:before {
  content: "\f16f";
}

.mdi-code-not-equal:before {
  content: "\f170";
}

.mdi-code-not-equal-variant:before {
  content: "\f171";
}

.mdi-code-parentheses:before {
  content: "\f172";
}

.mdi-code-string:before {
  content: "\f173";
}

.mdi-code-tags:before {
  content: "\f174";
}

.mdi-code-tags-check:before {
  content: "\f693";
}

.mdi-codepen:before {
  content: "\f175";
}

.mdi-coffee:before {
  content: "\f176";
}

.mdi-coffee-outline:before {
  content: "\f6c9";
}

.mdi-coffee-to-go:before {
  content: "\f177";
}

.mdi-cogs:before {
  content: "\f8d5";
}

.mdi-coin:before {
  content: "\f178";
}

.mdi-coins:before {
  content: "\f694";
}

.mdi-collage:before {
  content: "\f640";
}

.mdi-color-helper:before {
  content: "\f179";
}

.mdi-comment:before {
  content: "\f17a";
}

.mdi-comment-account:before {
  content: "\f17b";
}

.mdi-comment-account-outline:before {
  content: "\f17c";
}

.mdi-comment-alert:before {
  content: "\f17d";
}

.mdi-comment-alert-outline:before {
  content: "\f17e";
}

.mdi-comment-check:before {
  content: "\f17f";
}

.mdi-comment-check-outline:before {
  content: "\f180";
}

.mdi-comment-multiple:before {
  content: "\f85e";
}

.mdi-comment-multiple-outline:before {
  content: "\f181";
}

.mdi-comment-outline:before {
  content: "\f182";
}

.mdi-comment-plus-outline:before {
  content: "\f183";
}

.mdi-comment-processing:before {
  content: "\f184";
}

.mdi-comment-processing-outline:before {
  content: "\f185";
}

.mdi-comment-question:before {
  content: "\f816";
}

.mdi-comment-question-outline:before {
  content: "\f186";
}

.mdi-comment-remove:before {
  content: "\f5de";
}

.mdi-comment-remove-outline:before {
  content: "\f187";
}

.mdi-comment-text:before {
  content: "\f188";
}

.mdi-comment-text-multiple:before {
  content: "\f85f";
}

.mdi-comment-text-multiple-outline:before {
  content: "\f860";
}

.mdi-comment-text-outline:before {
  content: "\f189";
}

.mdi-compare:before {
  content: "\f18a";
}

.mdi-compass:before {
  content: "\f18b";
}

.mdi-compass-outline:before {
  content: "\f18c";
}

.mdi-console:before {
  content: "\f18d";
}

.mdi-console-line:before {
  content: "\f7b6";
}

.mdi-console-network:before {
  content: "\f8a8";
}

.mdi-contact-mail:before {
  content: "\f18e";
}

.mdi-contacts:before {
  content: "\f6ca";
}

.mdi-content-copy:before {
  content: "\f18f";
}

.mdi-content-cut:before {
  content: "\f190";
}

.mdi-content-duplicate:before {
  content: "\f191";
}

.mdi-content-paste:before {
  content: "\f192";
}

.mdi-content-save:before {
  content: "\f193";
}

.mdi-content-save-all:before {
  content: "\f194";
}

.mdi-content-save-outline:before {
  content: "\f817";
}

.mdi-content-save-settings:before {
  content: "\f61b";
}

.mdi-contrast:before {
  content: "\f195";
}

.mdi-contrast-box:before {
  content: "\f196";
}

.mdi-contrast-circle:before {
  content: "\f197";
}

.mdi-cookie:before {
  content: "\f198";
}

.mdi-copyright:before {
  content: "\f5e6";
}

.mdi-corn:before {
  content: "\f7b7";
}

.mdi-counter:before {
  content: "\f199";
}

.mdi-cow:before {
  content: "\f19a";
}

.mdi-crane:before {
  content: "\f861";
}

.mdi-creation:before {
  content: "\f1c9";
}

.mdi-credit-card:before {
  content: "\f19b";
}

.mdi-credit-card-multiple:before {
  content: "\f19c";
}

.mdi-credit-card-off:before {
  content: "\f5e4";
}

.mdi-credit-card-plus:before {
  content: "\f675";
}

.mdi-credit-card-scan:before {
  content: "\f19d";
}

.mdi-credit-card-settings:before {
  content: "\f8d6";
}

.mdi-crop:before {
  content: "\f19e";
}

.mdi-crop-free:before {
  content: "\f19f";
}

.mdi-crop-landscape:before {
  content: "\f1a0";
}

.mdi-crop-portrait:before {
  content: "\f1a1";
}

.mdi-crop-rotate:before {
  content: "\f695";
}

.mdi-crop-square:before {
  content: "\f1a2";
}

.mdi-crosshairs:before {
  content: "\f1a3";
}

.mdi-crosshairs-gps:before {
  content: "\f1a4";
}

.mdi-crown:before {
  content: "\f1a5";
}

.mdi-cube:before {
  content: "\f1a6";
}

.mdi-cube-outline:before {
  content: "\f1a7";
}

.mdi-cube-send:before {
  content: "\f1a8";
}

.mdi-cube-unfolded:before {
  content: "\f1a9";
}

.mdi-cup:before {
  content: "\f1aa";
}

.mdi-cup-off:before {
  content: "\f5e5";
}

.mdi-cup-water:before {
  content: "\f1ab";
}

.mdi-curling:before {
  content: "\f862";
}

.mdi-currency-bdt:before {
  content: "\f863";
}

.mdi-currency-btc:before {
  content: "\f1ac";
}

.mdi-currency-chf:before {
  content: "\f7b8";
}

.mdi-currency-cny:before {
  content: "\f7b9";
}

.mdi-currency-eth:before {
  content: "\f7ba";
}

.mdi-currency-eur:before {
  content: "\f1ad";
}

.mdi-currency-gbp:before {
  content: "\f1ae";
}

.mdi-currency-inr:before {
  content: "\f1af";
}

.mdi-currency-jpy:before {
  content: "\f7bb";
}

.mdi-currency-krw:before {
  content: "\f7bc";
}

.mdi-currency-kzt:before {
  content: "\f864";
}

.mdi-currency-ngn:before {
  content: "\f1b0";
}

.mdi-currency-rub:before {
  content: "\f1b1";
}

.mdi-currency-sign:before {
  content: "\f7bd";
}

.mdi-currency-try:before {
  content: "\f1b2";
}

.mdi-currency-twd:before {
  content: "\f7be";
}

.mdi-currency-usd:before {
  content: "\f1b3";
}

.mdi-currency-usd-off:before {
  content: "\f679";
}

.mdi-cursor-default:before {
  content: "\f1b4";
}

.mdi-cursor-default-outline:before {
  content: "\f1b5";
}

.mdi-cursor-move:before {
  content: "\f1b6";
}

.mdi-cursor-pointer:before {
  content: "\f1b7";
}

.mdi-cursor-text:before {
  content: "\f5e7";
}

.mdi-database:before {
  content: "\f1b8";
}

.mdi-database-minus:before {
  content: "\f1b9";
}

.mdi-database-plus:before {
  content: "\f1ba";
}

.mdi-database-search:before {
  content: "\f865";
}

.mdi-death-star:before {
  content: "\f8d7";
}

.mdi-death-star-variant:before {
  content: "\f8d8";
}

.mdi-debian:before {
  content: "\f8d9";
}

.mdi-debug-step-into:before {
  content: "\f1bb";
}

.mdi-debug-step-out:before {
  content: "\f1bc";
}

.mdi-debug-step-over:before {
  content: "\f1bd";
}

.mdi-decagram:before {
  content: "\f76b";
}

.mdi-decagram-outline:before {
  content: "\f76c";
}

.mdi-decimal-decrease:before {
  content: "\f1be";
}

.mdi-decimal-increase:before {
  content: "\f1bf";
}

.mdi-delete:before {
  content: "\f1c0";
}

.mdi-delete-circle:before {
  content: "\f682";
}

.mdi-delete-empty:before {
  content: "\f6cb";
}

.mdi-delete-forever:before {
  content: "\f5e8";
}

.mdi-delete-restore:before {
  content: "\f818";
}

.mdi-delete-sweep:before {
  content: "\f5e9";
}

.mdi-delete-variant:before {
  content: "\f1c1";
}

.mdi-delta:before {
  content: "\f1c2";
}

.mdi-deskphone:before {
  content: "\f1c3";
}

.mdi-desktop-classic:before {
  content: "\f7bf";
}

.mdi-desktop-mac:before {
  content: "\f1c4";
}

.mdi-desktop-tower:before {
  content: "\f1c5";
}

.mdi-details:before {
  content: "\f1c6";
}

.mdi-developer-board:before {
  content: "\f696";
}

.mdi-deviantart:before {
  content: "\f1c7";
}

.mdi-dialpad:before {
  content: "\f61c";
}

.mdi-diamond:before {
  content: "\f1c8";
}

.mdi-dice-1:before {
  content: "\f1ca";
}

.mdi-dice-2:before {
  content: "\f1cb";
}

.mdi-dice-3:before {
  content: "\f1cc";
}

.mdi-dice-4:before {
  content: "\f1cd";
}

.mdi-dice-5:before {
  content: "\f1ce";
}

.mdi-dice-6:before {
  content: "\f1cf";
}

.mdi-dice-d10:before {
  content: "\f76e";
}

.mdi-dice-d12:before {
  content: "\f866";
}

.mdi-dice-d20:before {
  content: "\f5ea";
}

.mdi-dice-d4:before {
  content: "\f5eb";
}

.mdi-dice-d6:before {
  content: "\f5ec";
}

.mdi-dice-d8:before {
  content: "\f5ed";
}

.mdi-dice-multiple:before {
  content: "\f76d";
}

.mdi-dictionary:before {
  content: "\f61d";
}

.mdi-dip-switch:before {
  content: "\f7c0";
}

.mdi-directions:before {
  content: "\f1d0";
}

.mdi-directions-fork:before {
  content: "\f641";
}

.mdi-discord:before {
  content: "\f66f";
}

.mdi-disk:before {
  content: "\f5ee";
}

.mdi-disk-alert:before {
  content: "\f1d1";
}

.mdi-disqus:before {
  content: "\f1d2";
}

.mdi-disqus-outline:before {
  content: "\f1d3";
}

.mdi-division:before {
  content: "\f1d4";
}

.mdi-division-box:before {
  content: "\f1d5";
}

.mdi-dna:before {
  content: "\f683";
}

.mdi-dns:before {
  content: "\f1d6";
}

.mdi-do-not-disturb:before {
  content: "\f697";
}

.mdi-do-not-disturb-off:before {
  content: "\f698";
}

.mdi-docker:before {
  content: "\f867";
}

.mdi-dolby:before {
  content: "\f6b2";
}

.mdi-domain:before {
  content: "\f1d7";
}

.mdi-donkey:before {
  content: "\f7c1";
}

.mdi-door:before {
  content: "\f819";
}

.mdi-door-closed:before {
  content: "\f81a";
}

.mdi-door-open:before {
  content: "\f81b";
}

.mdi-doorbell-video:before {
  content: "\f868";
}

.mdi-dots-horizontal:before {
  content: "\f1d8";
}

.mdi-dots-horizontal-circle:before {
  content: "\f7c2";
}

.mdi-dots-vertical:before {
  content: "\f1d9";
}

.mdi-dots-vertical-circle:before {
  content: "\f7c3";
}

.mdi-douban:before {
  content: "\f699";
}

.mdi-download:before {
  content: "\f1da";
}

.mdi-download-network:before {
  content: "\f6f3";
}

.mdi-drag:before {
  content: "\f1db";
}

.mdi-drag-horizontal:before {
  content: "\f1dc";
}

.mdi-drag-vertical:before {
  content: "\f1dd";
}

.mdi-drawing:before {
  content: "\f1de";
}

.mdi-drawing-box:before {
  content: "\f1df";
}

.mdi-dribbble:before {
  content: "\f1e0";
}

.mdi-dribbble-box:before {
  content: "\f1e1";
}

.mdi-drone:before {
  content: "\f1e2";
}

.mdi-dropbox:before {
  content: "\f1e3";
}

.mdi-drupal:before {
  content: "\f1e4";
}

.mdi-duck:before {
  content: "\f1e5";
}

.mdi-dumbbell:before {
  content: "\f1e6";
}

.mdi-ear-hearing:before {
  content: "\f7c4";
}

.mdi-earth:before {
  content: "\f1e7";
}

.mdi-earth-box:before {
  content: "\f6cc";
}

.mdi-earth-box-off:before {
  content: "\f6cd";
}

.mdi-earth-off:before {
  content: "\f1e8";
}

.mdi-edge:before {
  content: "\f1e9";
}

.mdi-eject:before {
  content: "\f1ea";
}

.mdi-elephant:before {
  content: "\f7c5";
}

.mdi-elevation-decline:before {
  content: "\f1eb";
}

.mdi-elevation-rise:before {
  content: "\f1ec";
}

.mdi-elevator:before {
  content: "\f1ed";
}

.mdi-email:before {
  content: "\f1ee";
}

.mdi-email-alert:before {
  content: "\f6ce";
}

.mdi-email-open:before {
  content: "\f1ef";
}

.mdi-email-open-outline:before {
  content: "\f5ef";
}

.mdi-email-outline:before {
  content: "\f1f0";
}

.mdi-email-secure:before {
  content: "\f1f1";
}

.mdi-email-variant:before {
  content: "\f5f0";
}

.mdi-emby:before {
  content: "\f6b3";
}

.mdi-emoticon:before {
  content: "\f1f2";
}

.mdi-emoticon-cool:before {
  content: "\f1f3";
}

.mdi-emoticon-dead:before {
  content: "\f69a";
}

.mdi-emoticon-devil:before {
  content: "\f1f4";
}

.mdi-emoticon-excited:before {
  content: "\f69b";
}

.mdi-emoticon-happy:before {
  content: "\f1f5";
}

.mdi-emoticon-neutral:before {
  content: "\f1f6";
}

.mdi-emoticon-poop:before {
  content: "\f1f7";
}

.mdi-emoticon-sad:before {
  content: "\f1f8";
}

.mdi-emoticon-tongue:before {
  content: "\f1f9";
}

.mdi-engine:before {
  content: "\f1fa";
}

.mdi-engine-outline:before {
  content: "\f1fb";
}

.mdi-equal:before {
  content: "\f1fc";
}

.mdi-equal-box:before {
  content: "\f1fd";
}

.mdi-eraser:before {
  content: "\f1fe";
}

.mdi-eraser-variant:before {
  content: "\f642";
}

.mdi-escalator:before {
  content: "\f1ff";
}

.mdi-ethereum:before {
  content: "\f869";
}

.mdi-ethernet:before {
  content: "\f200";
}

.mdi-ethernet-cable:before {
  content: "\f201";
}

.mdi-ethernet-cable-off:before {
  content: "\f202";
}

.mdi-etsy:before {
  content: "\f203";
}

.mdi-ev-station:before {
  content: "\f5f1";
}

.mdi-eventbrite:before {
  content: "\f7c6";
}

.mdi-evernote:before {
  content: "\f204";
}

.mdi-exclamation:before {
  content: "\f205";
}

.mdi-exit-to-app:before {
  content: "\f206";
}

.mdi-export:before {
  content: "\f207";
}

.mdi-eye:before {
  content: "\f208";
}

.mdi-eye-off:before {
  content: "\f209";
}

.mdi-eye-off-outline:before {
  content: "\f6d0";
}

.mdi-eye-outline:before {
  content: "\f6cf";
}

.mdi-eye-plus:before {
  content: "\f86a";
}

.mdi-eye-plus-outline:before {
  content: "\f86b";
}

.mdi-eye-settings:before {
  content: "\f86c";
}

.mdi-eye-settings-outline:before {
  content: "\f86d";
}

.mdi-eyedropper:before {
  content: "\f20a";
}

.mdi-eyedropper-variant:before {
  content: "\f20b";
}

.mdi-face:before {
  content: "\f643";
}

.mdi-face-profile:before {
  content: "\f644";
}

.mdi-facebook:before {
  content: "\f20c";
}

.mdi-facebook-box:before {
  content: "\f20d";
}

.mdi-facebook-messenger:before {
  content: "\f20e";
}

.mdi-factory:before {
  content: "\f20f";
}

.mdi-fan:before {
  content: "\f210";
}

.mdi-fan-off:before {
  content: "\f81c";
}

.mdi-fast-forward:before {
  content: "\f211";
}

.mdi-fast-forward-outline:before {
  content: "\f6d1";
}

.mdi-fax:before {
  content: "\f212";
}

.mdi-feather:before {
  content: "\f6d2";
}

.mdi-fedora:before {
  content: "\f8da";
}

.mdi-ferry:before {
  content: "\f213";
}

.mdi-file:before {
  content: "\f214";
}

.mdi-file-account:before {
  content: "\f73a";
}

.mdi-file-chart:before {
  content: "\f215";
}

.mdi-file-check:before {
  content: "\f216";
}

.mdi-file-cloud:before {
  content: "\f217";
}

.mdi-file-compare:before {
  content: "\f8a9";
}

.mdi-file-delimited:before {
  content: "\f218";
}

.mdi-file-document:before {
  content: "\f219";
}

.mdi-file-document-box:before {
  content: "\f21a";
}

.mdi-file-excel:before {
  content: "\f21b";
}

.mdi-file-excel-box:before {
  content: "\f21c";
}

.mdi-file-export:before {
  content: "\f21d";
}

.mdi-file-find:before {
  content: "\f21e";
}

.mdi-file-hidden:before {
  content: "\f613";
}

.mdi-file-image:before {
  content: "\f21f";
}

.mdi-file-import:before {
  content: "\f220";
}

.mdi-file-lock:before {
  content: "\f221";
}

.mdi-file-multiple:before {
  content: "\f222";
}

.mdi-file-music:before {
  content: "\f223";
}

.mdi-file-outline:before {
  content: "\f224";
}

.mdi-file-pdf:before {
  content: "\f225";
}

.mdi-file-pdf-box:before {
  content: "\f226";
}

.mdi-file-percent:before {
  content: "\f81d";
}

.mdi-file-plus:before {
  content: "\f751";
}

.mdi-file-powerpoint:before {
  content: "\f227";
}

.mdi-file-powerpoint-box:before {
  content: "\f228";
}

.mdi-file-presentation-box:before {
  content: "\f229";
}

.mdi-file-question:before {
  content: "\f86e";
}

.mdi-file-restore:before {
  content: "\f670";
}

.mdi-file-send:before {
  content: "\f22a";
}

.mdi-file-tree:before {
  content: "\f645";
}

.mdi-file-undo:before {
  content: "\f8db";
}

.mdi-file-video:before {
  content: "\f22b";
}

.mdi-file-word:before {
  content: "\f22c";
}

.mdi-file-word-box:before {
  content: "\f22d";
}

.mdi-file-xml:before {
  content: "\f22e";
}

.mdi-film:before {
  content: "\f22f";
}

.mdi-filmstrip:before {
  content: "\f230";
}

.mdi-filmstrip-off:before {
  content: "\f231";
}

.mdi-filter:before {
  content: "\f232";
}

.mdi-filter-outline:before {
  content: "\f233";
}

.mdi-filter-remove:before {
  content: "\f234";
}

.mdi-filter-remove-outline:before {
  content: "\f235";
}

.mdi-filter-variant:before {
  content: "\f236";
}

.mdi-finance:before {
  content: "\f81e";
}

.mdi-find-replace:before {
  content: "\f6d3";
}

.mdi-fingerprint:before {
  content: "\f237";
}

.mdi-fire:before {
  content: "\f238";
}

.mdi-fire-truck:before {
  content: "\f8aa";
}

.mdi-firefox:before {
  content: "\f239";
}

.mdi-fish:before {
  content: "\f23a";
}

.mdi-flag:before {
  content: "\f23b";
}

.mdi-flag-checkered:before {
  content: "\f23c";
}

.mdi-flag-outline:before {
  content: "\f23d";
}

.mdi-flag-triangle:before {
  content: "\f23f";
}

.mdi-flag-variant:before {
  content: "\f240";
}

.mdi-flag-variant-outline:before {
  content: "\f23e";
}

.mdi-flash:before {
  content: "\f241";
}

.mdi-flash-auto:before {
  content: "\f242";
}

.mdi-flash-circle:before {
  content: "\f81f";
}

.mdi-flash-off:before {
  content: "\f243";
}

.mdi-flash-outline:before {
  content: "\f6d4";
}

.mdi-flash-red-eye:before {
  content: "\f67a";
}

.mdi-flashlight:before {
  content: "\f244";
}

.mdi-flashlight-off:before {
  content: "\f245";
}

.mdi-flask:before {
  content: "\f093";
}

.mdi-flask-empty:before {
  content: "\f094";
}

.mdi-flask-empty-outline:before {
  content: "\f095";
}

.mdi-flask-outline:before {
  content: "\f096";
}

.mdi-flattr:before {
  content: "\f246";
}

.mdi-flip-to-back:before {
  content: "\f247";
}

.mdi-flip-to-front:before {
  content: "\f248";
}

.mdi-floor-lamp:before {
  content: "\f8dc";
}

.mdi-floor-plan:before {
  content: "\f820";
}

.mdi-floppy:before {
  content: "\f249";
}

.mdi-flower:before {
  content: "\f24a";
}

.mdi-folder:before {
  content: "\f24b";
}

.mdi-folder-account:before {
  content: "\f24c";
}

.mdi-folder-download:before {
  content: "\f24d";
}

.mdi-folder-edit:before {
  content: "\f8dd";
}

.mdi-folder-google-drive:before {
  content: "\f24e";
}

.mdi-folder-image:before {
  content: "\f24f";
}

.mdi-folder-key:before {
  content: "\f8ab";
}

.mdi-folder-key-network:before {
  content: "\f8ac";
}

.mdi-folder-lock:before {
  content: "\f250";
}

.mdi-folder-lock-open:before {
  content: "\f251";
}

.mdi-folder-move:before {
  content: "\f252";
}

.mdi-folder-multiple:before {
  content: "\f253";
}

.mdi-folder-multiple-image:before {
  content: "\f254";
}

.mdi-folder-multiple-outline:before {
  content: "\f255";
}

.mdi-folder-network:before {
  content: "\f86f";
}

.mdi-folder-open:before {
  content: "\f76f";
}

.mdi-folder-outline:before {
  content: "\f256";
}

.mdi-folder-plus:before {
  content: "\f257";
}

.mdi-folder-remove:before {
  content: "\f258";
}

.mdi-folder-star:before {
  content: "\f69c";
}

.mdi-folder-upload:before {
  content: "\f259";
}

.mdi-font-awesome:before {
  content: "\f03a";
}

.mdi-food:before {
  content: "\f25a";
}

.mdi-food-apple:before {
  content: "\f25b";
}

.mdi-food-croissant:before {
  content: "\f7c7";
}

.mdi-food-fork-drink:before {
  content: "\f5f2";
}

.mdi-food-off:before {
  content: "\f5f3";
}

.mdi-food-variant:before {
  content: "\f25c";
}

.mdi-football:before {
  content: "\f25d";
}

.mdi-football-australian:before {
  content: "\f25e";
}

.mdi-football-helmet:before {
  content: "\f25f";
}

.mdi-forklift:before {
  content: "\f7c8";
}

.mdi-format-align-bottom:before {
  content: "\f752";
}

.mdi-format-align-center:before {
  content: "\f260";
}

.mdi-format-align-justify:before {
  content: "\f261";
}

.mdi-format-align-left:before {
  content: "\f262";
}

.mdi-format-align-middle:before {
  content: "\f753";
}

.mdi-format-align-right:before {
  content: "\f263";
}

.mdi-format-align-top:before {
  content: "\f754";
}

.mdi-format-annotation-plus:before {
  content: "\f646";
}

.mdi-format-bold:before {
  content: "\f264";
}

.mdi-format-clear:before {
  content: "\f265";
}

.mdi-format-color-fill:before {
  content: "\f266";
}

.mdi-format-color-text:before {
  content: "\f69d";
}

.mdi-format-columns:before {
  content: "\f8de";
}

.mdi-format-float-center:before {
  content: "\f267";
}

.mdi-format-float-left:before {
  content: "\f268";
}

.mdi-format-float-none:before {
  content: "\f269";
}

.mdi-format-float-right:before {
  content: "\f26a";
}

.mdi-format-font:before {
  content: "\f6d5";
}

.mdi-format-header-1:before {
  content: "\f26b";
}

.mdi-format-header-2:before {
  content: "\f26c";
}

.mdi-format-header-3:before {
  content: "\f26d";
}

.mdi-format-header-4:before {
  content: "\f26e";
}

.mdi-format-header-5:before {
  content: "\f26f";
}

.mdi-format-header-6:before {
  content: "\f270";
}

.mdi-format-header-decrease:before {
  content: "\f271";
}

.mdi-format-header-equal:before {
  content: "\f272";
}

.mdi-format-header-increase:before {
  content: "\f273";
}

.mdi-format-header-pound:before {
  content: "\f274";
}

.mdi-format-horizontal-align-center:before {
  content: "\f61e";
}

.mdi-format-horizontal-align-left:before {
  content: "\f61f";
}

.mdi-format-horizontal-align-right:before {
  content: "\f620";
}

.mdi-format-indent-decrease:before {
  content: "\f275";
}

.mdi-format-indent-increase:before {
  content: "\f276";
}

.mdi-format-italic:before {
  content: "\f277";
}

.mdi-format-line-spacing:before {
  content: "\f278";
}

.mdi-format-line-style:before {
  content: "\f5c8";
}

.mdi-format-line-weight:before {
  content: "\f5c9";
}

.mdi-format-list-bulleted:before {
  content: "\f279";
}

.mdi-format-list-bulleted-type:before {
  content: "\f27a";
}

.mdi-format-list-checks:before {
  content: "\f755";
}

.mdi-format-list-numbers:before {
  content: "\f27b";
}

.mdi-format-page-break:before {
  content: "\f6d6";
}

.mdi-format-paint:before {
  content: "\f27c";
}

.mdi-format-paragraph:before {
  content: "\f27d";
}

.mdi-format-pilcrow:before {
  content: "\f6d7";
}

.mdi-format-quote-close:before {
  content: "\f27e";
}

.mdi-format-quote-open:before {
  content: "\f756";
}

.mdi-format-rotate-90:before {
  content: "\f6a9";
}

.mdi-format-section:before {
  content: "\f69e";
}

.mdi-format-size:before {
  content: "\f27f";
}

.mdi-format-strikethrough:before {
  content: "\f280";
}

.mdi-format-strikethrough-variant:before {
  content: "\f281";
}

.mdi-format-subscript:before {
  content: "\f282";
}

.mdi-format-superscript:before {
  content: "\f283";
}

.mdi-format-text:before {
  content: "\f284";
}

.mdi-format-textdirection-l-to-r:before {
  content: "\f285";
}

.mdi-format-textdirection-r-to-l:before {
  content: "\f286";
}

.mdi-format-title:before {
  content: "\f5f4";
}

.mdi-format-underline:before {
  content: "\f287";
}

.mdi-format-vertical-align-bottom:before {
  content: "\f621";
}

.mdi-format-vertical-align-center:before {
  content: "\f622";
}

.mdi-format-vertical-align-top:before {
  content: "\f623";
}

.mdi-format-wrap-inline:before {
  content: "\f288";
}

.mdi-format-wrap-square:before {
  content: "\f289";
}

.mdi-format-wrap-tight:before {
  content: "\f28a";
}

.mdi-format-wrap-top-bottom:before {
  content: "\f28b";
}

.mdi-forum:before {
  content: "\f28c";
}

.mdi-forum-outline:before {
  content: "\f821";
}

.mdi-forward:before {
  content: "\f28d";
}

.mdi-foursquare:before {
  content: "\f28e";
}

.mdi-freebsd:before {
  content: "\f8df";
}

.mdi-fridge:before {
  content: "\f28f";
}

.mdi-fridge-filled:before {
  content: "\f290";
}

.mdi-fridge-filled-bottom:before {
  content: "\f291";
}

.mdi-fridge-filled-top:before {
  content: "\f292";
}

.mdi-fuel:before {
  content: "\f7c9";
}

.mdi-fullscreen:before {
  content: "\f293";
}

.mdi-fullscreen-exit:before {
  content: "\f294";
}

.mdi-function:before {
  content: "\f295";
}

.mdi-function-variant:before {
  content: "\f870";
}

.mdi-gamepad:before {
  content: "\f296";
}

.mdi-gamepad-variant:before {
  content: "\f297";
}

.mdi-garage:before {
  content: "\f6d8";
}

.mdi-garage-alert:before {
  content: "\f871";
}

.mdi-garage-open:before {
  content: "\f6d9";
}

.mdi-gas-cylinder:before {
  content: "\f647";
}

.mdi-gas-station:before {
  content: "\f298";
}

.mdi-gate:before {
  content: "\f299";
}

.mdi-gate-and:before {
  content: "\f8e0";
}

.mdi-gate-nand:before {
  content: "\f8e1";
}

.mdi-gate-nor:before {
  content: "\f8e2";
}

.mdi-gate-not:before {
  content: "\f8e3";
}

.mdi-gate-or:before {
  content: "\f8e4";
}

.mdi-gate-xnor:before {
  content: "\f8e5";
}

.mdi-gate-xor:before {
  content: "\f8e6";
}

.mdi-gauge:before {
  content: "\f29a";
}

.mdi-gauge-empty:before {
  content: "\f872";
}

.mdi-gauge-full:before {
  content: "\f873";
}

.mdi-gauge-low:before {
  content: "\f874";
}

.mdi-gavel:before {
  content: "\f29b";
}

.mdi-gender-female:before {
  content: "\f29c";
}

.mdi-gender-male:before {
  content: "\f29d";
}

.mdi-gender-male-female:before {
  content: "\f29e";
}

.mdi-gender-transgender:before {
  content: "\f29f";
}

.mdi-gentoo:before {
  content: "\f8e7";
}

.mdi-gesture:before {
  content: "\f7ca";
}

.mdi-gesture-double-tap:before {
  content: "\f73b";
}

.mdi-gesture-swipe-down:before {
  content: "\f73c";
}

.mdi-gesture-swipe-left:before {
  content: "\f73d";
}

.mdi-gesture-swipe-right:before {
  content: "\f73e";
}

.mdi-gesture-swipe-up:before {
  content: "\f73f";
}

.mdi-gesture-tap:before {
  content: "\f740";
}

.mdi-gesture-two-double-tap:before {
  content: "\f741";
}

.mdi-gesture-two-tap:before {
  content: "\f742";
}

.mdi-ghost:before {
  content: "\f2a0";
}

.mdi-gift:before {
  content: "\f2a1";
}

.mdi-git:before {
  content: "\f2a2";
}

.mdi-github-box:before {
  content: "\f2a3";
}

.mdi-github-circle:before {
  content: "\f2a4";
}

.mdi-github-face:before {
  content: "\f6da";
}

.mdi-glass-cocktail:before {
  content: "\f356";
}

.mdi-glass-flute:before {
  content: "\f2a5";
}

.mdi-glass-mug:before {
  content: "\f2a6";
}

.mdi-glass-stange:before {
  content: "\f2a7";
}

.mdi-glass-tulip:before {
  content: "\f2a8";
}

.mdi-glass-wine:before {
  content: "\f875";
}

.mdi-glassdoor:before {
  content: "\f2a9";
}

.mdi-glasses:before {
  content: "\f2aa";
}

.mdi-globe-model:before {
  content: "\f8e8";
}

.mdi-gmail:before {
  content: "\f2ab";
}

.mdi-gnome:before {
  content: "\f2ac";
}

.mdi-golf:before {
  content: "\f822";
}

.mdi-gondola:before {
  content: "\f685";
}

.mdi-google:before {
  content: "\f2ad";
}

.mdi-google-allo:before {
  content: "\f801";
}

.mdi-google-analytics:before {
  content: "\f7cb";
}

.mdi-google-assistant:before {
  content: "\f7cc";
}

.mdi-google-cardboard:before {
  content: "\f2ae";
}

.mdi-google-chrome:before {
  content: "\f2af";
}

.mdi-google-circles:before {
  content: "\f2b0";
}

.mdi-google-circles-communities:before {
  content: "\f2b1";
}

.mdi-google-circles-extended:before {
  content: "\f2b2";
}

.mdi-google-circles-group:before {
  content: "\f2b3";
}

.mdi-google-controller:before {
  content: "\f2b4";
}

.mdi-google-controller-off:before {
  content: "\f2b5";
}

.mdi-google-drive:before {
  content: "\f2b6";
}

.mdi-google-earth:before {
  content: "\f2b7";
}

.mdi-google-glass:before {
  content: "\f2b8";
}

.mdi-google-hangouts:before {
  content: "\f2c9";
}

.mdi-google-home:before {
  content: "\f823";
}

.mdi-google-keep:before {
  content: "\f6db";
}

.mdi-google-maps:before {
  content: "\f5f5";
}

.mdi-google-nearby:before {
  content: "\f2b9";
}

.mdi-google-pages:before {
  content: "\f2ba";
}

.mdi-google-photos:before {
  content: "\f6dc";
}

.mdi-google-physical-web:before {
  content: "\f2bb";
}

.mdi-google-play:before {
  content: "\f2bc";
}

.mdi-google-plus:before {
  content: "\f2bd";
}

.mdi-google-plus-box:before {
  content: "\f2be";
}

.mdi-google-translate:before {
  content: "\f2bf";
}

.mdi-google-wallet:before {
  content: "\f2c0";
}

.mdi-gpu:before {
  content: "\f8ad";
}

.mdi-gradient:before {
  content: "\f69f";
}

.mdi-graphql:before {
  content: "\f876";
}

.mdi-grease-pencil:before {
  content: "\f648";
}

.mdi-grid:before {
  content: "\f2c1";
}

.mdi-grid-large:before {
  content: "\f757";
}

.mdi-grid-off:before {
  content: "\f2c2";
}

.mdi-group:before {
  content: "\f2c3";
}

.mdi-guitar-acoustic:before {
  content: "\f770";
}

.mdi-guitar-electric:before {
  content: "\f2c4";
}

.mdi-guitar-pick:before {
  content: "\f2c5";
}

.mdi-guitar-pick-outline:before {
  content: "\f2c6";
}

.mdi-guy-fawkes-mask:before {
  content: "\f824";
}

.mdi-hackernews:before {
  content: "\f624";
}

.mdi-hamburger:before {
  content: "\f684";
}

.mdi-hammer:before {
  content: "\f8e9";
}

.mdi-hand-pointing-right:before {
  content: "\f2c7";
}

.mdi-hanger:before {
  content: "\f2c8";
}

.mdi-harddisk:before {
  content: "\f2ca";
}

.mdi-headphones:before {
  content: "\f2cb";
}

.mdi-headphones-box:before {
  content: "\f2cc";
}

.mdi-headphones-off:before {
  content: "\f7cd";
}

.mdi-headphones-settings:before {
  content: "\f2cd";
}

.mdi-headset:before {
  content: "\f2ce";
}

.mdi-headset-dock:before {
  content: "\f2cf";
}

.mdi-headset-off:before {
  content: "\f2d0";
}

.mdi-heart:before {
  content: "\f2d1";
}

.mdi-heart-box:before {
  content: "\f2d2";
}

.mdi-heart-box-outline:before {
  content: "\f2d3";
}

.mdi-heart-broken:before {
  content: "\f2d4";
}

.mdi-heart-half:before {
  content: "\f6de";
}

.mdi-heart-half-full:before {
  content: "\f6dd";
}

.mdi-heart-half-outline:before {
  content: "\f6df";
}

.mdi-heart-off:before {
  content: "\f758";
}

.mdi-heart-outline:before {
  content: "\f2d5";
}

.mdi-heart-pulse:before {
  content: "\f5f6";
}

.mdi-help:before {
  content: "\f2d6";
}

.mdi-help-box:before {
  content: "\f78a";
}

.mdi-help-circle:before {
  content: "\f2d7";
}

.mdi-help-circle-outline:before {
  content: "\f625";
}

.mdi-help-network:before {
  content: "\f6f4";
}

.mdi-hexagon:before {
  content: "\f2d8";
}

.mdi-hexagon-multiple:before {
  content: "\f6e0";
}

.mdi-hexagon-outline:before {
  content: "\f2d9";
}

.mdi-high-definition:before {
  content: "\f7ce";
}

.mdi-high-definition-box:before {
  content: "\f877";
}

.mdi-highway:before {
  content: "\f5f7";
}

.mdi-history:before {
  content: "\f2da";
}

.mdi-hockey-puck:before {
  content: "\f878";
}

.mdi-hockey-sticks:before {
  content: "\f879";
}

.mdi-hololens:before {
  content: "\f2db";
}

.mdi-home:before {
  content: "\f2dc";
}

.mdi-home-account:before {
  content: "\f825";
}

.mdi-home-alert:before {
  content: "\f87a";
}

.mdi-home-assistant:before {
  content: "\f7cf";
}

.mdi-home-automation:before {
  content: "\f7d0";
}

.mdi-home-circle:before {
  content: "\f7d1";
}

.mdi-home-currency-usd:before {
  content: "\f8ae";
}

.mdi-home-heart:before {
  content: "\f826";
}

.mdi-home-lock:before {
  content: "\f8ea";
}

.mdi-home-lock-open:before {
  content: "\f8eb";
}

.mdi-home-map-marker:before {
  content: "\f5f8";
}

.mdi-home-modern:before {
  content: "\f2dd";
}

.mdi-home-outline:before {
  content: "\f6a0";
}

.mdi-home-variant:before {
  content: "\f2de";
}

.mdi-hook:before {
  content: "\f6e1";
}

.mdi-hook-off:before {
  content: "\f6e2";
}

.mdi-hops:before {
  content: "\f2df";
}

.mdi-hospital:before {
  content: "\f2e0";
}

.mdi-hospital-building:before {
  content: "\f2e1";
}

.mdi-hospital-marker:before {
  content: "\f2e2";
}

.mdi-hot-tub:before {
  content: "\f827";
}

.mdi-hotel:before {
  content: "\f2e3";
}

.mdi-houzz:before {
  content: "\f2e4";
}

.mdi-houzz-box:before {
  content: "\f2e5";
}

.mdi-hulu:before {
  content: "\f828";
}

.mdi-human:before {
  content: "\f2e6";
}

.mdi-human-child:before {
  content: "\f2e7";
}

.mdi-human-female:before {
  content: "\f649";
}

.mdi-human-greeting:before {
  content: "\f64a";
}

.mdi-human-handsdown:before {
  content: "\f64b";
}

.mdi-human-handsup:before {
  content: "\f64c";
}

.mdi-human-male:before {
  content: "\f64d";
}

.mdi-human-male-female:before {
  content: "\f2e8";
}

.mdi-human-pregnant:before {
  content: "\f5cf";
}

.mdi-humble-bundle:before {
  content: "\f743";
}

.mdi-ice-cream:before {
  content: "\f829";
}

.mdi-image:before {
  content: "\f2e9";
}

.mdi-image-album:before {
  content: "\f2ea";
}

.mdi-image-area:before {
  content: "\f2eb";
}

.mdi-image-area-close:before {
  content: "\f2ec";
}

.mdi-image-broken:before {
  content: "\f2ed";
}

.mdi-image-broken-variant:before {
  content: "\f2ee";
}

.mdi-image-filter:before {
  content: "\f2ef";
}

.mdi-image-filter-black-white:before {
  content: "\f2f0";
}

.mdi-image-filter-center-focus:before {
  content: "\f2f1";
}

.mdi-image-filter-center-focus-weak:before {
  content: "\f2f2";
}

.mdi-image-filter-drama:before {
  content: "\f2f3";
}

.mdi-image-filter-frames:before {
  content: "\f2f4";
}

.mdi-image-filter-hdr:before {
  content: "\f2f5";
}

.mdi-image-filter-none:before {
  content: "\f2f6";
}

.mdi-image-filter-tilt-shift:before {
  content: "\f2f7";
}

.mdi-image-filter-vintage:before {
  content: "\f2f8";
}

.mdi-image-multiple:before {
  content: "\f2f9";
}

.mdi-image-off:before {
  content: "\f82a";
}

.mdi-image-plus:before {
  content: "\f87b";
}

.mdi-import:before {
  content: "\f2fa";
}

.mdi-inbox:before {
  content: "\f686";
}

.mdi-inbox-arrow-down:before {
  content: "\f2fb";
}

.mdi-inbox-arrow-up:before {
  content: "\f3d1";
}

.mdi-inbox-multiple:before {
  content: "\f8af";
}

.mdi-incognito:before {
  content: "\f5f9";
}

.mdi-infinity:before {
  content: "\f6e3";
}

.mdi-information:before {
  content: "\f2fc";
}

.mdi-information-outline:before {
  content: "\f2fd";
}

.mdi-information-variant:before {
  content: "\f64e";
}

.mdi-instagram:before {
  content: "\f2fe";
}

.mdi-instapaper:before {
  content: "\f2ff";
}

.mdi-internet-explorer:before {
  content: "\f300";
}

.mdi-invert-colors:before {
  content: "\f301";
}

.mdi-itunes:before {
  content: "\f676";
}

.mdi-jeepney:before {
  content: "\f302";
}

.mdi-jira:before {
  content: "\f303";
}

.mdi-jquery:before {
  content: "\f87c";
}

.mdi-jsfiddle:before {
  content: "\f304";
}

.mdi-json:before {
  content: "\f626";
}

.mdi-karate:before {
  content: "\f82b";
}

.mdi-keg:before {
  content: "\f305";
}

.mdi-kettle:before {
  content: "\f5fa";
}

.mdi-key:before {
  content: "\f306";
}

.mdi-key-change:before {
  content: "\f307";
}

.mdi-key-minus:before {
  content: "\f308";
}

.mdi-key-plus:before {
  content: "\f309";
}

.mdi-key-remove:before {
  content: "\f30a";
}

.mdi-key-variant:before {
  content: "\f30b";
}

.mdi-keyboard:before {
  content: "\f30c";
}

.mdi-keyboard-backspace:before {
  content: "\f30d";
}

.mdi-keyboard-caps:before {
  content: "\f30e";
}

.mdi-keyboard-close:before {
  content: "\f30f";
}

.mdi-keyboard-off:before {
  content: "\f310";
}

.mdi-keyboard-return:before {
  content: "\f311";
}

.mdi-keyboard-tab:before {
  content: "\f312";
}

.mdi-keyboard-variant:before {
  content: "\f313";
}

.mdi-kickstarter:before {
  content: "\f744";
}

.mdi-kodi:before {
  content: "\f314";
}

.mdi-label:before {
  content: "\f315";
}

.mdi-label-outline:before {
  content: "\f316";
}

.mdi-ladybug:before {
  content: "\f82c";
}

.mdi-lambda:before {
  content: "\f627";
}

.mdi-lamp:before {
  content: "\f6b4";
}

.mdi-lan:before {
  content: "\f317";
}

.mdi-lan-connect:before {
  content: "\f318";
}

.mdi-lan-disconnect:before {
  content: "\f319";
}

.mdi-lan-pending:before {
  content: "\f31a";
}

.mdi-language-c:before {
  content: "\f671";
}

.mdi-language-cpp:before {
  content: "\f672";
}

.mdi-language-csharp:before {
  content: "\f31b";
}

.mdi-language-css3:before {
  content: "\f31c";
}

.mdi-language-go:before {
  content: "\f7d2";
}

.mdi-language-html5:before {
  content: "\f31d";
}

.mdi-language-javascript:before {
  content: "\f31e";
}

.mdi-language-lua:before {
  content: "\f8b0";
}

.mdi-language-php:before {
  content: "\f31f";
}

.mdi-language-python:before {
  content: "\f320";
}

.mdi-language-python-text:before {
  content: "\f321";
}

.mdi-language-r:before {
  content: "\f7d3";
}

.mdi-language-swift:before {
  content: "\f6e4";
}

.mdi-language-typescript:before {
  content: "\f6e5";
}

.mdi-laptop:before {
  content: "\f322";
}

.mdi-laptop-chromebook:before {
  content: "\f323";
}

.mdi-laptop-mac:before {
  content: "\f324";
}

.mdi-laptop-off:before {
  content: "\f6e6";
}

.mdi-laptop-windows:before {
  content: "\f325";
}

.mdi-lastfm:before {
  content: "\f326";
}

.mdi-lastpass:before {
  content: "\f446";
}

.mdi-launch:before {
  content: "\f327";
}

.mdi-lava-lamp:before {
  content: "\f7d4";
}

.mdi-layers:before {
  content: "\f328";
}

.mdi-layers-off:before {
  content: "\f329";
}

.mdi-lead-pencil:before {
  content: "\f64f";
}

.mdi-leaf:before {
  content: "\f32a";
}

.mdi-led-off:before {
  content: "\f32b";
}

.mdi-led-on:before {
  content: "\f32c";
}

.mdi-led-outline:before {
  content: "\f32d";
}

.mdi-led-strip:before {
  content: "\f7d5";
}

.mdi-led-variant-off:before {
  content: "\f32e";
}

.mdi-led-variant-on:before {
  content: "\f32f";
}

.mdi-led-variant-outline:before {
  content: "\f330";
}

.mdi-library:before {
  content: "\f331";
}

.mdi-library-books:before {
  content: "\f332";
}

.mdi-library-music:before {
  content: "\f333";
}

.mdi-library-plus:before {
  content: "\f334";
}

.mdi-lifebuoy:before {
  content: "\f87d";
}

.mdi-lightbulb:before {
  content: "\f335";
}

.mdi-lightbulb-on:before {
  content: "\f6e7";
}

.mdi-lightbulb-on-outline:before {
  content: "\f6e8";
}

.mdi-lightbulb-outline:before {
  content: "\f336";
}

.mdi-link:before {
  content: "\f337";
}

.mdi-link-off:before {
  content: "\f338";
}

.mdi-link-variant:before {
  content: "\f339";
}

.mdi-link-variant-off:before {
  content: "\f33a";
}

.mdi-linkedin:before {
  content: "\f33b";
}

.mdi-linkedin-box:before {
  content: "\f33c";
}

.mdi-linux:before {
  content: "\f33d";
}

.mdi-linux-mint:before {
  content: "\f8ec";
}

.mdi-loading:before {
  content: "\f771";
}

.mdi-lock:before {
  content: "\f33e";
}

.mdi-lock-alert:before {
  content: "\f8ed";
}

.mdi-lock-open:before {
  content: "\f33f";
}

.mdi-lock-open-outline:before {
  content: "\f340";
}

.mdi-lock-outline:before {
  content: "\f341";
}

.mdi-lock-pattern:before {
  content: "\f6e9";
}

.mdi-lock-plus:before {
  content: "\f5fb";
}

.mdi-lock-question:before {
  content: "\f8ee";
}

.mdi-lock-reset:before {
  content: "\f772";
}

.mdi-lock-smart:before {
  content: "\f8b1";
}

.mdi-locker:before {
  content: "\f7d6";
}

.mdi-locker-multiple:before {
  content: "\f7d7";
}

.mdi-login:before {
  content: "\f342";
}

.mdi-login-variant:before {
  content: "\f5fc";
}

.mdi-logout:before {
  content: "\f343";
}

.mdi-logout-variant:before {
  content: "\f5fd";
}

.mdi-looks:before {
  content: "\f344";
}

.mdi-loop:before {
  content: "\f6ea";
}

.mdi-loupe:before {
  content: "\f345";
}

.mdi-lumx:before {
  content: "\f346";
}

.mdi-magnet:before {
  content: "\f347";
}

.mdi-magnet-on:before {
  content: "\f348";
}

.mdi-magnify:before {
  content: "\f349";
}

.mdi-magnify-minus:before {
  content: "\f34a";
}

.mdi-magnify-minus-outline:before {
  content: "\f6eb";
}

.mdi-magnify-plus:before {
  content: "\f34b";
}

.mdi-magnify-plus-outline:before {
  content: "\f6ec";
}

.mdi-mail-ru:before {
  content: "\f34c";
}

.mdi-mailbox:before {
  content: "\f6ed";
}

.mdi-map:before {
  content: "\f34d";
}

.mdi-map-marker:before {
  content: "\f34e";
}

.mdi-map-marker-circle:before {
  content: "\f34f";
}

.mdi-map-marker-distance:before {
  content: "\f8ef";
}

.mdi-map-marker-minus:before {
  content: "\f650";
}

.mdi-map-marker-multiple:before {
  content: "\f350";
}

.mdi-map-marker-off:before {
  content: "\f351";
}

.mdi-map-marker-outline:before {
  content: "\f7d8";
}

.mdi-map-marker-plus:before {
  content: "\f651";
}

.mdi-map-marker-radius:before {
  content: "\f352";
}

.mdi-margin:before {
  content: "\f353";
}

.mdi-markdown:before {
  content: "\f354";
}

.mdi-marker:before {
  content: "\f652";
}

.mdi-marker-check:before {
  content: "\f355";
}

.mdi-material-ui:before {
  content: "\f357";
}

.mdi-math-compass:before {
  content: "\f358";
}

.mdi-matrix:before {
  content: "\f628";
}

.mdi-maxcdn:before {
  content: "\f359";
}

.mdi-medical-bag:before {
  content: "\f6ee";
}

.mdi-medium:before {
  content: "\f35a";
}

.mdi-memory:before {
  content: "\f35b";
}

.mdi-menu:before {
  content: "\f35c";
}

.mdi-menu-down:before {
  content: "\f35d";
}

.mdi-menu-down-outline:before {
  content: "\f6b5";
}

.mdi-menu-left:before {
  content: "\f35e";
}

.mdi-menu-right:before {
  content: "\f35f";
}

.mdi-menu-up:before {
  content: "\f360";
}

.mdi-menu-up-outline:before {
  content: "\f6b6";
}

.mdi-message:before {
  content: "\f361";
}

.mdi-message-alert:before {
  content: "\f362";
}

.mdi-message-bulleted:before {
  content: "\f6a1";
}

.mdi-message-bulleted-off:before {
  content: "\f6a2";
}

.mdi-message-draw:before {
  content: "\f363";
}

.mdi-message-image:before {
  content: "\f364";
}

.mdi-message-outline:before {
  content: "\f365";
}

.mdi-message-plus:before {
  content: "\f653";
}

.mdi-message-processing:before {
  content: "\f366";
}

.mdi-message-reply:before {
  content: "\f367";
}

.mdi-message-reply-text:before {
  content: "\f368";
}

.mdi-message-settings:before {
  content: "\f6ef";
}

.mdi-message-settings-variant:before {
  content: "\f6f0";
}

.mdi-message-text:before {
  content: "\f369";
}

.mdi-message-text-outline:before {
  content: "\f36a";
}

.mdi-message-video:before {
  content: "\f36b";
}

.mdi-meteor:before {
  content: "\f629";
}

.mdi-metronome:before {
  content: "\f7d9";
}

.mdi-metronome-tick:before {
  content: "\f7da";
}

.mdi-micro-sd:before {
  content: "\f7db";
}

.mdi-microphone:before {
  content: "\f36c";
}

.mdi-microphone-minus:before {
  content: "\f8b2";
}

.mdi-microphone-off:before {
  content: "\f36d";
}

.mdi-microphone-outline:before {
  content: "\f36e";
}

.mdi-microphone-plus:before {
  content: "\f8b3";
}

.mdi-microphone-settings:before {
  content: "\f36f";
}

.mdi-microphone-variant:before {
  content: "\f370";
}

.mdi-microphone-variant-off:before {
  content: "\f371";
}

.mdi-microscope:before {
  content: "\f654";
}

.mdi-microsoft:before {
  content: "\f372";
}

.mdi-midi:before {
  content: "\f8f0";
}

.mdi-midi-port:before {
  content: "\f8f1";
}

.mdi-minecraft:before {
  content: "\f373";
}

.mdi-minus:before {
  content: "\f374";
}

.mdi-minus-box:before {
  content: "\f375";
}

.mdi-minus-box-outline:before {
  content: "\f6f1";
}

.mdi-minus-circle:before {
  content: "\f376";
}

.mdi-minus-circle-outline:before {
  content: "\f377";
}

.mdi-minus-network:before {
  content: "\f378";
}

.mdi-mixcloud:before {
  content: "\f62a";
}

.mdi-mixed-reality:before {
  content: "\f87e";
}

.mdi-mixer:before {
  content: "\f7dc";
}

.mdi-monitor:before {
  content: "\f379";
}

.mdi-monitor-multiple:before {
  content: "\f37a";
}

.mdi-more:before {
  content: "\f37b";
}

.mdi-motorbike:before {
  content: "\f37c";
}

.mdi-mouse:before {
  content: "\f37d";
}

.mdi-mouse-off:before {
  content: "\f37e";
}

.mdi-mouse-variant:before {
  content: "\f37f";
}

.mdi-mouse-variant-off:before {
  content: "\f380";
}

.mdi-move-resize:before {
  content: "\f655";
}

.mdi-move-resize-variant:before {
  content: "\f656";
}

.mdi-movie:before {
  content: "\f381";
}

.mdi-movie-roll:before {
  content: "\f7dd";
}

.mdi-multiplication:before {
  content: "\f382";
}

.mdi-multiplication-box:before {
  content: "\f383";
}

.mdi-mushroom:before {
  content: "\f7de";
}

.mdi-mushroom-outline:before {
  content: "\f7df";
}

.mdi-music:before {
  content: "\f759";
}

.mdi-music-box:before {
  content: "\f384";
}

.mdi-music-box-outline:before {
  content: "\f385";
}

.mdi-music-circle:before {
  content: "\f386";
}

.mdi-music-note:before {
  content: "\f387";
}

.mdi-music-note-bluetooth:before {
  content: "\f5fe";
}

.mdi-music-note-bluetooth-off:before {
  content: "\f5ff";
}

.mdi-music-note-eighth:before {
  content: "\f388";
}

.mdi-music-note-half:before {
  content: "\f389";
}

.mdi-music-note-off:before {
  content: "\f38a";
}

.mdi-music-note-quarter:before {
  content: "\f38b";
}

.mdi-music-note-sixteenth:before {
  content: "\f38c";
}

.mdi-music-note-whole:before {
  content: "\f38d";
}

.mdi-music-off:before {
  content: "\f75a";
}

.mdi-nas:before {
  content: "\f8f2";
}

.mdi-nativescript:before {
  content: "\f87f";
}

.mdi-nature:before {
  content: "\f38e";
}

.mdi-nature-people:before {
  content: "\f38f";
}

.mdi-navigation:before {
  content: "\f390";
}

.mdi-near-me:before {
  content: "\f5cd";
}

.mdi-needle:before {
  content: "\f391";
}

.mdi-netflix:before {
  content: "\f745";
}

.mdi-network:before {
  content: "\f6f2";
}

.mdi-network-strength-1:before {
  content: "\f8f3";
}

.mdi-network-strength-1-alert:before {
  content: "\f8f4";
}

.mdi-network-strength-2:before {
  content: "\f8f5";
}

.mdi-network-strength-2-alert:before {
  content: "\f8f6";
}

.mdi-network-strength-3:before {
  content: "\f8f7";
}

.mdi-network-strength-3-alert:before {
  content: "\f8f8";
}

.mdi-network-strength-4:before {
  content: "\f8f9";
}

.mdi-network-strength-4-alert:before {
  content: "\f8fa";
}

.mdi-network-strength-off:before {
  content: "\f8fb";
}

.mdi-network-strength-off-outline:before {
  content: "\f8fc";
}

.mdi-network-strength-outline:before {
  content: "\f8fd";
}

.mdi-new-box:before {
  content: "\f394";
}

.mdi-newspaper:before {
  content: "\f395";
}

.mdi-nfc:before {
  content: "\f396";
}

.mdi-nfc-tap:before {
  content: "\f397";
}

.mdi-nfc-variant:before {
  content: "\f398";
}

.mdi-ninja:before {
  content: "\f773";
}

.mdi-nintendo-switch:before {
  content: "\f7e0";
}

.mdi-nodejs:before {
  content: "\f399";
}

.mdi-note:before {
  content: "\f39a";
}

.mdi-note-multiple:before {
  content: "\f6b7";
}

.mdi-note-multiple-outline:before {
  content: "\f6b8";
}

.mdi-note-outline:before {
  content: "\f39b";
}

.mdi-note-plus:before {
  content: "\f39c";
}

.mdi-note-plus-outline:before {
  content: "\f39d";
}

.mdi-note-text:before {
  content: "\f39e";
}

.mdi-notebook:before {
  content: "\f82d";
}

.mdi-notification-clear-all:before {
  content: "\f39f";
}

.mdi-npm:before {
  content: "\f6f6";
}

.mdi-nuke:before {
  content: "\f6a3";
}

.mdi-null:before {
  content: "\f7e1";
}

.mdi-numeric:before {
  content: "\f3a0";
}

.mdi-numeric-0-box:before {
  content: "\f3a1";
}

.mdi-numeric-0-box-multiple-outline:before {
  content: "\f3a2";
}

.mdi-numeric-0-box-outline:before {
  content: "\f3a3";
}

.mdi-numeric-1-box:before {
  content: "\f3a4";
}

.mdi-numeric-1-box-multiple-outline:before {
  content: "\f3a5";
}

.mdi-numeric-1-box-outline:before {
  content: "\f3a6";
}

.mdi-numeric-2-box:before {
  content: "\f3a7";
}

.mdi-numeric-2-box-multiple-outline:before {
  content: "\f3a8";
}

.mdi-numeric-2-box-outline:before {
  content: "\f3a9";
}

.mdi-numeric-3-box:before {
  content: "\f3aa";
}

.mdi-numeric-3-box-multiple-outline:before {
  content: "\f3ab";
}

.mdi-numeric-3-box-outline:before {
  content: "\f3ac";
}

.mdi-numeric-4-box:before {
  content: "\f3ad";
}

.mdi-numeric-4-box-multiple-outline:before {
  content: "\f3ae";
}

.mdi-numeric-4-box-outline:before {
  content: "\f3af";
}

.mdi-numeric-5-box:before {
  content: "\f3b0";
}

.mdi-numeric-5-box-multiple-outline:before {
  content: "\f3b1";
}

.mdi-numeric-5-box-outline:before {
  content: "\f3b2";
}

.mdi-numeric-6-box:before {
  content: "\f3b3";
}

.mdi-numeric-6-box-multiple-outline:before {
  content: "\f3b4";
}

.mdi-numeric-6-box-outline:before {
  content: "\f3b5";
}

.mdi-numeric-7-box:before {
  content: "\f3b6";
}

.mdi-numeric-7-box-multiple-outline:before {
  content: "\f3b7";
}

.mdi-numeric-7-box-outline:before {
  content: "\f3b8";
}

.mdi-numeric-8-box:before {
  content: "\f3b9";
}

.mdi-numeric-8-box-multiple-outline:before {
  content: "\f3ba";
}

.mdi-numeric-8-box-outline:before {
  content: "\f3bb";
}

.mdi-numeric-9-box:before {
  content: "\f3bc";
}

.mdi-numeric-9-box-multiple-outline:before {
  content: "\f3bd";
}

.mdi-numeric-9-box-outline:before {
  content: "\f3be";
}

.mdi-numeric-9-plus-box:before {
  content: "\f3bf";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\f3c0";
}

.mdi-numeric-9-plus-box-outline:before {
  content: "\f3c1";
}

.mdi-nut:before {
  content: "\f6f7";
}

.mdi-nutrition:before {
  content: "\f3c2";
}

.mdi-oar:before {
  content: "\f67b";
}

.mdi-octagon:before {
  content: "\f3c3";
}

.mdi-octagon-outline:before {
  content: "\f3c4";
}

.mdi-octagram:before {
  content: "\f6f8";
}

.mdi-octagram-outline:before {
  content: "\f774";
}

.mdi-odnoklassniki:before {
  content: "\f3c5";
}

.mdi-office:before {
  content: "\f3c6";
}

.mdi-oil:before {
  content: "\f3c7";
}

.mdi-oil-temperature:before {
  content: "\f3c8";
}

.mdi-omega:before {
  content: "\f3c9";
}

.mdi-onedrive:before {
  content: "\f3ca";
}

.mdi-onenote:before {
  content: "\f746";
}

.mdi-onepassword:before {
  content: "\f880";
}

.mdi-opacity:before {
  content: "\f5cc";
}

.mdi-open-in-app:before {
  content: "\f3cb";
}

.mdi-open-in-new:before {
  content: "\f3cc";
}

.mdi-openid:before {
  content: "\f3cd";
}

.mdi-opera:before {
  content: "\f3ce";
}

.mdi-orbit:before {
  content: "\f018";
}

.mdi-ornament:before {
  content: "\f3cf";
}

.mdi-ornament-variant:before {
  content: "\f3d0";
}

.mdi-owl:before {
  content: "\f3d2";
}

.mdi-package:before {
  content: "\f3d3";
}

.mdi-package-down:before {
  content: "\f3d4";
}

.mdi-package-up:before {
  content: "\f3d5";
}

.mdi-package-variant:before {
  content: "\f3d6";
}

.mdi-package-variant-closed:before {
  content: "\f3d7";
}

.mdi-page-first:before {
  content: "\f600";
}

.mdi-page-last:before {
  content: "\f601";
}

.mdi-page-layout-body:before {
  content: "\f6f9";
}

.mdi-page-layout-footer:before {
  content: "\f6fa";
}

.mdi-page-layout-header:before {
  content: "\f6fb";
}

.mdi-page-layout-sidebar-left:before {
  content: "\f6fc";
}

.mdi-page-layout-sidebar-right:before {
  content: "\f6fd";
}

.mdi-palette:before {
  content: "\f3d8";
}

.mdi-palette-advanced:before {
  content: "\f3d9";
}

.mdi-palette-swatch:before {
  content: "\f8b4";
}

.mdi-panda:before {
  content: "\f3da";
}

.mdi-pandora:before {
  content: "\f3db";
}

.mdi-panorama:before {
  content: "\f3dc";
}

.mdi-panorama-fisheye:before {
  content: "\f3dd";
}

.mdi-panorama-horizontal:before {
  content: "\f3de";
}

.mdi-panorama-vertical:before {
  content: "\f3df";
}

.mdi-panorama-wide-angle:before {
  content: "\f3e0";
}

.mdi-paper-cut-vertical:before {
  content: "\f3e1";
}

.mdi-paperclip:before {
  content: "\f3e2";
}

.mdi-parking:before {
  content: "\f3e3";
}

.mdi-passport:before {
  content: "\f7e2";
}

.mdi-patreon:before {
  content: "\f881";
}

.mdi-pause:before {
  content: "\f3e4";
}

.mdi-pause-circle:before {
  content: "\f3e5";
}

.mdi-pause-circle-outline:before {
  content: "\f3e6";
}

.mdi-pause-octagon:before {
  content: "\f3e7";
}

.mdi-pause-octagon-outline:before {
  content: "\f3e8";
}

.mdi-paw:before {
  content: "\f3e9";
}

.mdi-paw-off:before {
  content: "\f657";
}

.mdi-paypal:before {
  content: "\f882";
}

.mdi-peace:before {
  content: "\f883";
}

.mdi-pen:before {
  content: "\f3ea";
}

.mdi-pencil:before {
  content: "\f3eb";
}

.mdi-pencil-box:before {
  content: "\f3ec";
}

.mdi-pencil-box-outline:before {
  content: "\f3ed";
}

.mdi-pencil-circle:before {
  content: "\f6fe";
}

.mdi-pencil-circle-outline:before {
  content: "\f775";
}

.mdi-pencil-lock:before {
  content: "\f3ee";
}

.mdi-pencil-off:before {
  content: "\f3ef";
}

.mdi-pentagon:before {
  content: "\f6ff";
}

.mdi-pentagon-outline:before {
  content: "\f700";
}

.mdi-percent:before {
  content: "\f3f0";
}

.mdi-periodic-table:before {
  content: "\f8b5";
}

.mdi-periodic-table-co2:before {
  content: "\f7e3";
}

.mdi-periscope:before {
  content: "\f747";
}

.mdi-pharmacy:before {
  content: "\f3f1";
}

.mdi-phone:before {
  content: "\f3f2";
}

.mdi-phone-bluetooth:before {
  content: "\f3f3";
}

.mdi-phone-classic:before {
  content: "\f602";
}

.mdi-phone-forward:before {
  content: "\f3f4";
}

.mdi-phone-hangup:before {
  content: "\f3f5";
}

.mdi-phone-in-talk:before {
  content: "\f3f6";
}

.mdi-phone-incoming:before {
  content: "\f3f7";
}

.mdi-phone-locked:before {
  content: "\f3f8";
}

.mdi-phone-log:before {
  content: "\f3f9";
}

.mdi-phone-minus:before {
  content: "\f658";
}

.mdi-phone-missed:before {
  content: "\f3fa";
}

.mdi-phone-outgoing:before {
  content: "\f3fb";
}

.mdi-phone-paused:before {
  content: "\f3fc";
}

.mdi-phone-plus:before {
  content: "\f659";
}

.mdi-phone-return:before {
  content: "\f82e";
}

.mdi-phone-rotate-landscape:before {
  content: "\f884";
}

.mdi-phone-rotate-portrait:before {
  content: "\f885";
}

.mdi-phone-settings:before {
  content: "\f3fd";
}

.mdi-phone-voip:before {
  content: "\f3fe";
}

.mdi-pi:before {
  content: "\f3ff";
}

.mdi-pi-box:before {
  content: "\f400";
}

.mdi-piano:before {
  content: "\f67c";
}

.mdi-pickaxe:before {
  content: "\f8b6";
}

.mdi-pier:before {
  content: "\f886";
}

.mdi-pier-crane:before {
  content: "\f887";
}

.mdi-pig:before {
  content: "\f401";
}

.mdi-pill:before {
  content: "\f402";
}

.mdi-pillar:before {
  content: "\f701";
}

.mdi-pin:before {
  content: "\f403";
}

.mdi-pin-off:before {
  content: "\f404";
}

.mdi-pin-off-outline:before {
  content: "勞";
}

.mdi-pin-outline:before {
  content: "擄";
}

.mdi-pine-tree:before {
  content: "\f405";
}

.mdi-pine-tree-box:before {
  content: "\f406";
}

.mdi-pinterest:before {
  content: "\f407";
}

.mdi-pinterest-box:before {
  content: "\f408";
}

.mdi-pipe:before {
  content: "\f7e4";
}

.mdi-pipe-disconnected:before {
  content: "\f7e5";
}

.mdi-pipe-leak:before {
  content: "\f888";
}

.mdi-pistol:before {
  content: "\f702";
}

.mdi-piston:before {
  content: "\f889";
}

.mdi-pizza:before {
  content: "\f409";
}

.mdi-plane-shield:before {
  content: "\f6ba";
}

.mdi-play:before {
  content: "\f40a";
}

.mdi-play-box-outline:before {
  content: "\f40b";
}

.mdi-play-circle:before {
  content: "\f40c";
}

.mdi-play-circle-outline:before {
  content: "\f40d";
}

.mdi-play-network:before {
  content: "\f88a";
}

.mdi-play-pause:before {
  content: "\f40e";
}

.mdi-play-protected-content:before {
  content: "\f40f";
}

.mdi-play-speed:before {
  content: "\f8fe";
}

.mdi-playlist-check:before {
  content: "\f5c7";
}

.mdi-playlist-edit:before {
  content: "\f8ff";
}

.mdi-playlist-minus:before {
  content: "\f410";
}

.mdi-playlist-play:before {
  content: "\f411";
}

.mdi-playlist-plus:before {
  content: "\f412";
}

.mdi-playlist-remove:before {
  content: "\f413";
}

.mdi-playstation:before {
  content: "\f414";
}

.mdi-plex:before {
  content: "\f6b9";
}

.mdi-plus:before {
  content: "\f415";
}

.mdi-plus-box:before {
  content: "\f416";
}

.mdi-plus-box-outline:before {
  content: "\f703";
}

.mdi-plus-circle:before {
  content: "\f417";
}

.mdi-plus-circle-multiple-outline:before {
  content: "\f418";
}

.mdi-plus-circle-outline:before {
  content: "\f419";
}

.mdi-plus-network:before {
  content: "\f41a";
}

.mdi-plus-one:before {
  content: "\f41b";
}

.mdi-plus-outline:before {
  content: "\f704";
}

.mdi-pocket:before {
  content: "\f41c";
}

.mdi-pokeball:before {
  content: "\f41d";
}

.mdi-poker-chip:before {
  content: "\f82f";
}

.mdi-polaroid:before {
  content: "\f41e";
}

.mdi-poll:before {
  content: "\f41f";
}

.mdi-poll-box:before {
  content: "\f420";
}

.mdi-polymer:before {
  content: "\f421";
}

.mdi-pool:before {
  content: "\f606";
}

.mdi-popcorn:before {
  content: "\f422";
}

.mdi-pot:before {
  content: "\f65a";
}

.mdi-pot-mix:before {
  content: "\f65b";
}

.mdi-pound:before {
  content: "\f423";
}

.mdi-pound-box:before {
  content: "\f424";
}

.mdi-power:before {
  content: "\f425";
}

.mdi-power-cycle:before {
  content: "豈";
}

.mdi-power-off:before {
  content: "更";
}

.mdi-power-on:before {
  content: "車";
}

.mdi-power-plug:before {
  content: "\f6a4";
}

.mdi-power-plug-off:before {
  content: "\f6a5";
}

.mdi-power-settings:before {
  content: "\f426";
}

.mdi-power-sleep:before {
  content: "賈";
}

.mdi-power-socket:before {
  content: "\f427";
}

.mdi-power-socket-au:before {
  content: "滑";
}

.mdi-power-socket-eu:before {
  content: "\f7e6";
}

.mdi-power-socket-uk:before {
  content: "\f7e7";
}

.mdi-power-socket-us:before {
  content: "\f7e8";
}

.mdi-power-standby:before {
  content: "串";
}

.mdi-prescription:before {
  content: "\f705";
}

.mdi-presentation:before {
  content: "\f428";
}

.mdi-presentation-play:before {
  content: "\f429";
}

.mdi-printer:before {
  content: "\f42a";
}

.mdi-printer-3d:before {
  content: "\f42b";
}

.mdi-printer-alert:before {
  content: "\f42c";
}

.mdi-printer-settings:before {
  content: "\f706";
}

.mdi-priority-high:before {
  content: "\f603";
}

.mdi-priority-low:before {
  content: "\f604";
}

.mdi-professional-hexagon:before {
  content: "\f42d";
}

.mdi-projector:before {
  content: "\f42e";
}

.mdi-projector-screen:before {
  content: "\f42f";
}

.mdi-publish:before {
  content: "\f6a6";
}

.mdi-pulse:before {
  content: "\f430";
}

.mdi-puzzle:before {
  content: "\f431";
}

.mdi-qqchat:before {
  content: "\f605";
}

.mdi-qrcode:before {
  content: "\f432";
}

.mdi-qrcode-edit:before {
  content: "\f8b7";
}

.mdi-qrcode-scan:before {
  content: "\f433";
}

.mdi-quadcopter:before {
  content: "\f434";
}

.mdi-quality-high:before {
  content: "\f435";
}

.mdi-quicktime:before {
  content: "\f436";
}

.mdi-rabbit:before {
  content: "句";
}

.mdi-radar:before {
  content: "\f437";
}

.mdi-radiator:before {
  content: "\f438";
}

.mdi-radio:before {
  content: "\f439";
}

.mdi-radio-handheld:before {
  content: "\f43a";
}

.mdi-radio-tower:before {
  content: "\f43b";
}

.mdi-radioactive:before {
  content: "\f43c";
}

.mdi-radiobox-blank:before {
  content: "\f43d";
}

.mdi-radiobox-marked:before {
  content: "\f43e";
}

.mdi-raspberrypi:before {
  content: "\f43f";
}

.mdi-ray-end:before {
  content: "\f440";
}

.mdi-ray-end-arrow:before {
  content: "\f441";
}

.mdi-ray-start:before {
  content: "\f442";
}

.mdi-ray-start-arrow:before {
  content: "\f443";
}

.mdi-ray-start-end:before {
  content: "\f444";
}

.mdi-ray-vertex:before {
  content: "\f445";
}

.mdi-react:before {
  content: "\f707";
}

.mdi-read:before {
  content: "\f447";
}

.mdi-receipt:before {
  content: "\f449";
}

.mdi-record:before {
  content: "\f44a";
}

.mdi-record-rec:before {
  content: "\f44b";
}

.mdi-recycle:before {
  content: "\f44c";
}

.mdi-reddit:before {
  content: "\f44d";
}

.mdi-redo:before {
  content: "\f44e";
}

.mdi-redo-variant:before {
  content: "\f44f";
}

.mdi-refresh:before {
  content: "\f450";
}

.mdi-regex:before {
  content: "\f451";
}

.mdi-relative-scale:before {
  content: "\f452";
}

.mdi-reload:before {
  content: "\f453";
}

.mdi-reminder:before {
  content: "\f88b";
}

.mdi-remote:before {
  content: "\f454";
}

.mdi-remote-desktop:before {
  content: "\f8b8";
}

.mdi-rename-box:before {
  content: "\f455";
}

.mdi-reorder-horizontal:before {
  content: "\f687";
}

.mdi-reorder-vertical:before {
  content: "\f688";
}

.mdi-repeat:before {
  content: "\f456";
}

.mdi-repeat-off:before {
  content: "\f457";
}

.mdi-repeat-once:before {
  content: "\f458";
}

.mdi-replay:before {
  content: "\f459";
}

.mdi-reply:before {
  content: "\f45a";
}

.mdi-reply-all:before {
  content: "\f45b";
}

.mdi-reproduction:before {
  content: "\f45c";
}

.mdi-resize-bottom-right:before {
  content: "\f45d";
}

.mdi-responsive:before {
  content: "\f45e";
}

.mdi-restart:before {
  content: "\f708";
}

.mdi-restore:before {
  content: "\f6a7";
}

.mdi-rewind:before {
  content: "\f45f";
}

.mdi-rewind-outline:before {
  content: "\f709";
}

.mdi-rhombus:before {
  content: "\f70a";
}

.mdi-rhombus-outline:before {
  content: "\f70b";
}

.mdi-ribbon:before {
  content: "\f460";
}

.mdi-rice:before {
  content: "\f7e9";
}

.mdi-ring:before {
  content: "\f7ea";
}

.mdi-road:before {
  content: "\f461";
}

.mdi-road-variant:before {
  content: "\f462";
}

.mdi-robot:before {
  content: "\f6a8";
}

.mdi-robot-vacuum:before {
  content: "\f70c";
}

.mdi-robot-vacuum-variant:before {
  content: "龜";
}

.mdi-rocket:before {
  content: "\f463";
}

.mdi-room-service:before {
  content: "\f88c";
}

.mdi-rotate-3d:before {
  content: "\f464";
}

.mdi-rotate-left:before {
  content: "\f465";
}

.mdi-rotate-left-variant:before {
  content: "\f466";
}

.mdi-rotate-right:before {
  content: "\f467";
}

.mdi-rotate-right-variant:before {
  content: "\f468";
}

.mdi-rounded-corner:before {
  content: "\f607";
}

.mdi-router-wireless:before {
  content: "\f469";
}

.mdi-routes:before {
  content: "\f46a";
}

.mdi-rowing:before {
  content: "\f608";
}

.mdi-rss:before {
  content: "\f46b";
}

.mdi-rss-box:before {
  content: "\f46c";
}

.mdi-ruler:before {
  content: "\f46d";
}

.mdi-run:before {
  content: "\f70d";
}

.mdi-run-fast:before {
  content: "\f46e";
}

.mdi-sale:before {
  content: "\f46f";
}

.mdi-salesforce:before {
  content: "\f88d";
}

.mdi-sass:before {
  content: "\f7eb";
}

.mdi-satellite:before {
  content: "\f470";
}

.mdi-satellite-uplink:before {
  content: "龜";
}

.mdi-satellite-variant:before {
  content: "\f471";
}

.mdi-sausage:before {
  content: "\f8b9";
}

.mdi-saxophone:before {
  content: "\f609";
}

.mdi-scale:before {
  content: "\f472";
}

.mdi-scale-balance:before {
  content: "\f5d1";
}

.mdi-scale-bathroom:before {
  content: "\f473";
}

.mdi-scanner:before {
  content: "\f6aa";
}

.mdi-scanner-off:before {
  content: "契";
}

.mdi-school:before {
  content: "\f474";
}

.mdi-screen-rotation:before {
  content: "\f475";
}

.mdi-screen-rotation-lock:before {
  content: "\f476";
}

.mdi-screwdriver:before {
  content: "\f477";
}

.mdi-script:before {
  content: "\f478";
}

.mdi-sd:before {
  content: "\f479";
}

.mdi-seal:before {
  content: "\f47a";
}

.mdi-search-web:before {
  content: "\f70e";
}

.mdi-seat-flat:before {
  content: "\f47b";
}

.mdi-seat-flat-angled:before {
  content: "\f47c";
}

.mdi-seat-individual-suite:before {
  content: "\f47d";
}

.mdi-seat-legroom-extra:before {
  content: "\f47e";
}

.mdi-seat-legroom-normal:before {
  content: "\f47f";
}

.mdi-seat-legroom-reduced:before {
  content: "\f480";
}

.mdi-seat-recline-extra:before {
  content: "\f481";
}

.mdi-seat-recline-normal:before {
  content: "\f482";
}

.mdi-security:before {
  content: "\f483";
}

.mdi-security-account:before {
  content: "\f88e";
}

.mdi-security-home:before {
  content: "\f689";
}

.mdi-security-network:before {
  content: "\f484";
}

.mdi-select:before {
  content: "\f485";
}

.mdi-select-all:before {
  content: "\f486";
}

.mdi-select-inverse:before {
  content: "\f487";
}

.mdi-select-off:before {
  content: "\f488";
}

.mdi-selection:before {
  content: "\f489";
}

.mdi-selection-off:before {
  content: "\f776";
}

.mdi-send:before {
  content: "\f48a";
}

.mdi-send-secure:before {
  content: "\f7ec";
}

.mdi-serial-port:before {
  content: "\f65c";
}

.mdi-server:before {
  content: "\f48b";
}

.mdi-server-minus:before {
  content: "\f48c";
}

.mdi-server-network:before {
  content: "\f48d";
}

.mdi-server-network-off:before {
  content: "\f48e";
}

.mdi-server-off:before {
  content: "\f48f";
}

.mdi-server-plus:before {
  content: "\f490";
}

.mdi-server-remove:before {
  content: "\f491";
}

.mdi-server-security:before {
  content: "\f492";
}

.mdi-set-all:before {
  content: "\f777";
}

.mdi-set-center:before {
  content: "\f778";
}

.mdi-set-center-right:before {
  content: "\f779";
}

.mdi-set-left:before {
  content: "\f77a";
}

.mdi-set-left-center:before {
  content: "\f77b";
}

.mdi-set-left-right:before {
  content: "\f77c";
}

.mdi-set-none:before {
  content: "\f77d";
}

.mdi-set-right:before {
  content: "\f77e";
}

.mdi-settings:before {
  content: "\f493";
}

.mdi-settings-box:before {
  content: "\f494";
}

.mdi-settings-outline:before {
  content: "\f8ba";
}

.mdi-shape:before {
  content: "\f830";
}

.mdi-shape-circle-plus:before {
  content: "\f65d";
}

.mdi-shape-outline:before {
  content: "\f831";
}

.mdi-shape-plus:before {
  content: "\f495";
}

.mdi-shape-polygon-plus:before {
  content: "\f65e";
}

.mdi-shape-rectangle-plus:before {
  content: "\f65f";
}

.mdi-shape-square-plus:before {
  content: "\f660";
}

.mdi-share:before {
  content: "\f496";
}

.mdi-share-outline:before {
  content: "櫓";
}

.mdi-share-variant:before {
  content: "\f497";
}

.mdi-shield:before {
  content: "\f498";
}

.mdi-shield-half-full:before {
  content: "\f77f";
}

.mdi-shield-outline:before {
  content: "\f499";
}

.mdi-ship-wheel:before {
  content: "\f832";
}

.mdi-shopping:before {
  content: "\f49a";
}

.mdi-shopping-music:before {
  content: "\f49b";
}

.mdi-shovel:before {
  content: "\f70f";
}

.mdi-shovel-off:before {
  content: "\f710";
}

.mdi-shredder:before {
  content: "\f49c";
}

.mdi-shuffle:before {
  content: "\f49d";
}

.mdi-shuffle-disabled:before {
  content: "\f49e";
}

.mdi-shuffle-variant:before {
  content: "\f49f";
}

.mdi-sigma:before {
  content: "\f4a0";
}

.mdi-sigma-lower:before {
  content: "\f62b";
}

.mdi-sign-caution:before {
  content: "\f4a1";
}

.mdi-sign-direction:before {
  content: "\f780";
}

.mdi-sign-text:before {
  content: "\f781";
}

.mdi-signal:before {
  content: "\f4a2";
}

.mdi-signal-2g:before {
  content: "\f711";
}

.mdi-signal-3g:before {
  content: "\f712";
}

.mdi-signal-4g:before {
  content: "\f713";
}

.mdi-signal-cellular-1:before {
  content: "\f8bb";
}

.mdi-signal-cellular-2:before {
  content: "\f8bc";
}

.mdi-signal-cellular-3:before {
  content: "\f8bd";
}

.mdi-signal-cellular-outline:before {
  content: "\f8be";
}

.mdi-signal-hspa:before {
  content: "\f714";
}

.mdi-signal-hspa-plus:before {
  content: "\f715";
}

.mdi-signal-off:before {
  content: "\f782";
}

.mdi-signal-variant:before {
  content: "\f60a";
}

.mdi-silverware:before {
  content: "\f4a3";
}

.mdi-silverware-fork:before {
  content: "\f4a4";
}

.mdi-silverware-spoon:before {
  content: "\f4a5";
}

.mdi-silverware-variant:before {
  content: "\f4a6";
}

.mdi-sim:before {
  content: "\f4a7";
}

.mdi-sim-alert:before {
  content: "\f4a8";
}

.mdi-sim-off:before {
  content: "\f4a9";
}

.mdi-sitemap:before {
  content: "\f4aa";
}

.mdi-skip-backward:before {
  content: "\f4ab";
}

.mdi-skip-forward:before {
  content: "\f4ac";
}

.mdi-skip-next:before {
  content: "\f4ad";
}

.mdi-skip-next-circle:before {
  content: "\f661";
}

.mdi-skip-next-circle-outline:before {
  content: "\f662";
}

.mdi-skip-previous:before {
  content: "\f4ae";
}

.mdi-skip-previous-circle:before {
  content: "\f663";
}

.mdi-skip-previous-circle-outline:before {
  content: "\f664";
}

.mdi-skull:before {
  content: "\f68b";
}

.mdi-skype:before {
  content: "\f4af";
}

.mdi-skype-business:before {
  content: "\f4b0";
}

.mdi-slack:before {
  content: "\f4b1";
}

.mdi-slackware:before {
  content: "金";
}

.mdi-sleep:before {
  content: "\f4b2";
}

.mdi-sleep-off:before {
  content: "\f4b3";
}

.mdi-smoke-detector:before {
  content: "\f392";
}

.mdi-smoking:before {
  content: "\f4b4";
}

.mdi-smoking-off:before {
  content: "\f4b5";
}

.mdi-snapchat:before {
  content: "\f4b6";
}

.mdi-snowflake:before {
  content: "\f716";
}

.mdi-snowman:before {
  content: "\f4b7";
}

.mdi-soccer:before {
  content: "\f4b8";
}

.mdi-soccer-field:before {
  content: "\f833";
}

.mdi-sofa:before {
  content: "\f4b9";
}

.mdi-solid:before {
  content: "\f68c";
}

.mdi-sort:before {
  content: "\f4ba";
}

.mdi-sort-alphabetical:before {
  content: "\f4bb";
}

.mdi-sort-ascending:before {
  content: "\f4bc";
}

.mdi-sort-descending:before {
  content: "\f4bd";
}

.mdi-sort-numeric:before {
  content: "\f4be";
}

.mdi-sort-variant:before {
  content: "\f4bf";
}

.mdi-soundcloud:before {
  content: "\f4c0";
}

.mdi-source-branch:before {
  content: "\f62c";
}

.mdi-source-commit:before {
  content: "\f717";
}

.mdi-source-commit-end:before {
  content: "\f718";
}

.mdi-source-commit-end-local:before {
  content: "\f719";
}

.mdi-source-commit-local:before {
  content: "\f71a";
}

.mdi-source-commit-next-local:before {
  content: "\f71b";
}

.mdi-source-commit-start:before {
  content: "\f71c";
}

.mdi-source-commit-start-next-local:before {
  content: "\f71d";
}

.mdi-source-fork:before {
  content: "\f4c1";
}

.mdi-source-merge:before {
  content: "\f62d";
}

.mdi-source-pull:before {
  content: "\f4c2";
}

.mdi-soy-sauce:before {
  content: "\f7ed";
}

.mdi-speaker:before {
  content: "\f4c3";
}

.mdi-speaker-off:before {
  content: "\f4c4";
}

.mdi-speaker-wireless:before {
  content: "\f71e";
}

.mdi-speedometer:before {
  content: "\f4c5";
}

.mdi-spellcheck:before {
  content: "\f4c6";
}

.mdi-spotify:before {
  content: "\f4c7";
}

.mdi-spotlight:before {
  content: "\f4c8";
}

.mdi-spotlight-beam:before {
  content: "\f4c9";
}

.mdi-spray:before {
  content: "\f665";
}

.mdi-square:before {
  content: "\f763";
}

.mdi-square-edit-outline:before {
  content: "喇";
}

.mdi-square-inc:before {
  content: "\f4ca";
}

.mdi-square-inc-cash:before {
  content: "\f4cb";
}

.mdi-square-outline:before {
  content: "\f762";
}

.mdi-square-root:before {
  content: "\f783";
}

.mdi-ssh:before {
  content: "\f8bf";
}

.mdi-stack-exchange:before {
  content: "\f60b";
}

.mdi-stack-overflow:before {
  content: "\f4cc";
}

.mdi-stadium:before {
  content: "\f71f";
}

.mdi-stairs:before {
  content: "\f4cd";
}

.mdi-standard-definition:before {
  content: "\f7ee";
}

.mdi-star:before {
  content: "\f4ce";
}

.mdi-star-circle:before {
  content: "\f4cf";
}

.mdi-star-half:before {
  content: "\f4d0";
}

.mdi-star-off:before {
  content: "\f4d1";
}

.mdi-star-outline:before {
  content: "\f4d2";
}

.mdi-steam:before {
  content: "\f4d3";
}

.mdi-steam-box:before {
  content: "奈";
}

.mdi-steering:before {
  content: "\f4d4";
}

.mdi-steering-off:before {
  content: "懶";
}

.mdi-step-backward:before {
  content: "\f4d5";
}

.mdi-step-backward-2:before {
  content: "\f4d6";
}

.mdi-step-forward:before {
  content: "\f4d7";
}

.mdi-step-forward-2:before {
  content: "\f4d8";
}

.mdi-stethoscope:before {
  content: "\f4d9";
}

.mdi-sticker:before {
  content: "\f5d0";
}

.mdi-sticker-emoji:before {
  content: "\f784";
}

.mdi-stocking:before {
  content: "\f4da";
}

.mdi-stop:before {
  content: "\f4db";
}

.mdi-stop-circle:before {
  content: "\f666";
}

.mdi-stop-circle-outline:before {
  content: "\f667";
}

.mdi-store:before {
  content: "\f4dc";
}

.mdi-store-24-hour:before {
  content: "\f4dd";
}

.mdi-stove:before {
  content: "\f4de";
}

.mdi-subdirectory-arrow-left:before {
  content: "\f60c";
}

.mdi-subdirectory-arrow-right:before {
  content: "\f60d";
}

.mdi-subway:before {
  content: "\f6ab";
}

.mdi-subway-variant:before {
  content: "\f4df";
}

.mdi-summit:before {
  content: "\f785";
}

.mdi-sunglasses:before {
  content: "\f4e0";
}

.mdi-surround-sound:before {
  content: "\f5c5";
}

.mdi-surround-sound-2-0:before {
  content: "\f7ef";
}

.mdi-surround-sound-3-1:before {
  content: "\f7f0";
}

.mdi-surround-sound-5-1:before {
  content: "\f7f1";
}

.mdi-surround-sound-7-1:before {
  content: "\f7f2";
}

.mdi-svg:before {
  content: "\f720";
}

.mdi-swap-horizontal:before {
  content: "\f4e1";
}

.mdi-swap-horizontal-variant:before {
  content: "\f8c0";
}

.mdi-swap-vertical:before {
  content: "\f4e2";
}

.mdi-swap-vertical-variant:before {
  content: "\f8c1";
}

.mdi-swim:before {
  content: "\f4e3";
}

.mdi-switch:before {
  content: "\f4e4";
}

.mdi-sword:before {
  content: "\f4e5";
}

.mdi-sword-cross:before {
  content: "\f786";
}

.mdi-sync:before {
  content: "\f4e6";
}

.mdi-sync-alert:before {
  content: "\f4e7";
}

.mdi-sync-off:before {
  content: "\f4e8";
}

.mdi-tab:before {
  content: "\f4e9";
}

.mdi-tab-plus:before {
  content: "\f75b";
}

.mdi-tab-unselected:before {
  content: "\f4ea";
}

.mdi-table:before {
  content: "\f4eb";
}

.mdi-table-column:before {
  content: "\f834";
}

.mdi-table-column-plus-after:before {
  content: "\f4ec";
}

.mdi-table-column-plus-before:before {
  content: "\f4ed";
}

.mdi-table-column-remove:before {
  content: "\f4ee";
}

.mdi-table-column-width:before {
  content: "\f4ef";
}

.mdi-table-edit:before {
  content: "\f4f0";
}

.mdi-table-large:before {
  content: "\f4f1";
}

.mdi-table-of-contents:before {
  content: "\f835";
}

.mdi-table-row:before {
  content: "\f836";
}

.mdi-table-row-height:before {
  content: "\f4f2";
}

.mdi-table-row-plus-after:before {
  content: "\f4f3";
}

.mdi-table-row-plus-before:before {
  content: "\f4f4";
}

.mdi-table-row-remove:before {
  content: "\f4f5";
}

.mdi-table-search:before {
  content: "癩";
}

.mdi-table-settings:before {
  content: "\f837";
}

.mdi-tablet:before {
  content: "\f4f6";
}

.mdi-tablet-android:before {
  content: "\f4f7";
}

.mdi-tablet-ipad:before {
  content: "\f4f8";
}

.mdi-taco:before {
  content: "\f761";
}

.mdi-tag:before {
  content: "\f4f9";
}

.mdi-tag-faces:before {
  content: "\f4fa";
}

.mdi-tag-heart:before {
  content: "\f68a";
}

.mdi-tag-minus:before {
  content: "羅";
}

.mdi-tag-multiple:before {
  content: "\f4fb";
}

.mdi-tag-outline:before {
  content: "\f4fc";
}

.mdi-tag-plus:before {
  content: "\f721";
}

.mdi-tag-remove:before {
  content: "\f722";
}

.mdi-tag-text-outline:before {
  content: "\f4fd";
}

.mdi-target:before {
  content: "\f4fe";
}

.mdi-taxi:before {
  content: "\f4ff";
}

.mdi-teach:before {
  content: "\f88f";
}

.mdi-teamviewer:before {
  content: "\f500";
}

.mdi-telegram:before {
  content: "\f501";
}

.mdi-television:before {
  content: "\f502";
}

.mdi-television-box:before {
  content: "\f838";
}

.mdi-television-classic:before {
  content: "\f7f3";
}

.mdi-television-classic-off:before {
  content: "\f839";
}

.mdi-television-guide:before {
  content: "\f503";
}

.mdi-television-off:before {
  content: "\f83a";
}

.mdi-temperature-celsius:before {
  content: "\f504";
}

.mdi-temperature-fahrenheit:before {
  content: "\f505";
}

.mdi-temperature-kelvin:before {
  content: "\f506";
}

.mdi-tennis:before {
  content: "\f507";
}

.mdi-tent:before {
  content: "\f508";
}

.mdi-terrain:before {
  content: "\f509";
}

.mdi-test-tube:before {
  content: "\f668";
}

.mdi-test-tube-empty:before {
  content: "蘿";
}

.mdi-test-tube-off:before {
  content: "螺";
}

.mdi-text-shadow:before {
  content: "\f669";
}

.mdi-text-to-speech:before {
  content: "\f50a";
}

.mdi-text-to-speech-off:before {
  content: "\f50b";
}

.mdi-textbox:before {
  content: "\f60e";
}

.mdi-textbox-password:before {
  content: "\f7f4";
}

.mdi-texture:before {
  content: "\f50c";
}

.mdi-theater:before {
  content: "\f50d";
}

.mdi-theme-light-dark:before {
  content: "\f50e";
}

.mdi-thermometer:before {
  content: "\f50f";
}

.mdi-thermometer-lines:before {
  content: "\f510";
}

.mdi-thermostat:before {
  content: "\f393";
}

.mdi-thermostat-box:before {
  content: "\f890";
}

.mdi-thought-bubble:before {
  content: "\f7f5";
}

.mdi-thought-bubble-outline:before {
  content: "\f7f6";
}

.mdi-thumb-down:before {
  content: "\f511";
}

.mdi-thumb-down-outline:before {
  content: "\f512";
}

.mdi-thumb-up:before {
  content: "\f513";
}

.mdi-thumb-up-outline:before {
  content: "\f514";
}

.mdi-thumbs-up-down:before {
  content: "\f515";
}

.mdi-ticket:before {
  content: "\f516";
}

.mdi-ticket-account:before {
  content: "\f517";
}

.mdi-ticket-confirmation:before {
  content: "\f518";
}

.mdi-ticket-outline:before {
  content: "裸";
}

.mdi-ticket-percent:before {
  content: "\f723";
}

.mdi-tie:before {
  content: "\f519";
}

.mdi-tilde:before {
  content: "\f724";
}

.mdi-timelapse:before {
  content: "\f51a";
}

.mdi-timer:before {
  content: "\f51b";
}

.mdi-timer-10:before {
  content: "\f51c";
}

.mdi-timer-3:before {
  content: "\f51d";
}

.mdi-timer-off:before {
  content: "\f51e";
}

.mdi-timer-sand:before {
  content: "\f51f";
}

.mdi-timer-sand-empty:before {
  content: "\f6ac";
}

.mdi-timer-sand-full:before {
  content: "\f78b";
}

.mdi-timetable:before {
  content: "\f520";
}

.mdi-toggle-switch:before {
  content: "\f521";
}

.mdi-toggle-switch-off:before {
  content: "\f522";
}

.mdi-tooltip:before {
  content: "\f523";
}

.mdi-tooltip-edit:before {
  content: "\f524";
}

.mdi-tooltip-image:before {
  content: "\f525";
}

.mdi-tooltip-outline:before {
  content: "\f526";
}

.mdi-tooltip-outline-plus:before {
  content: "\f527";
}

.mdi-tooltip-text:before {
  content: "\f528";
}

.mdi-tooth:before {
  content: "\f8c2";
}

.mdi-tooth-outline:before {
  content: "\f529";
}

.mdi-tor:before {
  content: "\f52a";
}

.mdi-tower-beach:before {
  content: "\f680";
}

.mdi-tower-fire:before {
  content: "\f681";
}

.mdi-towing:before {
  content: "\f83b";
}

.mdi-track-light:before {
  content: "邏";
}

.mdi-trackpad:before {
  content: "\f7f7";
}

.mdi-trackpad-lock:before {
  content: "爐";
}

.mdi-tractor:before {
  content: "\f891";
}

.mdi-traffic-light:before {
  content: "\f52b";
}

.mdi-train:before {
  content: "\f52c";
}

.mdi-train-variant:before {
  content: "\f8c3";
}

.mdi-tram:before {
  content: "\f52d";
}

.mdi-transcribe:before {
  content: "\f52e";
}

.mdi-transcribe-close:before {
  content: "\f52f";
}

.mdi-transfer:before {
  content: "\f530";
}

.mdi-transit-transfer:before {
  content: "\f6ad";
}

.mdi-transition:before {
  content: "樂";
}

.mdi-transition-masked:before {
  content: "洛";
}

.mdi-translate:before {
  content: "\f5ca";
}

.mdi-treasure-chest:before {
  content: "\f725";
}

.mdi-tree:before {
  content: "\f531";
}

.mdi-trello:before {
  content: "\f532";
}

.mdi-trending-down:before {
  content: "\f533";
}

.mdi-trending-neutral:before {
  content: "\f534";
}

.mdi-trending-up:before {
  content: "\f535";
}

.mdi-triangle:before {
  content: "\f536";
}

.mdi-triangle-outline:before {
  content: "\f537";
}

.mdi-trophy:before {
  content: "\f538";
}

.mdi-trophy-award:before {
  content: "\f539";
}

.mdi-trophy-outline:before {
  content: "\f53a";
}

.mdi-trophy-variant:before {
  content: "\f53b";
}

.mdi-trophy-variant-outline:before {
  content: "\f53c";
}

.mdi-truck:before {
  content: "\f53d";
}

.mdi-truck-delivery:before {
  content: "\f53e";
}

.mdi-truck-fast:before {
  content: "\f787";
}

.mdi-truck-trailer:before {
  content: "\f726";
}

.mdi-tshirt-crew:before {
  content: "\f53f";
}

.mdi-tshirt-v:before {
  content: "\f540";
}

.mdi-tumble-dryer:before {
  content: "烙";
}

.mdi-tumblr:before {
  content: "\f541";
}

.mdi-tumblr-box:before {
  content: "珞";
}

.mdi-tumblr-reblog:before {
  content: "\f542";
}

.mdi-tune:before {
  content: "\f62e";
}

.mdi-tune-vertical:before {
  content: "\f66a";
}

.mdi-twitch:before {
  content: "\f543";
}

.mdi-twitter:before {
  content: "\f544";
}

.mdi-twitter-box:before {
  content: "\f545";
}

.mdi-twitter-circle:before {
  content: "\f546";
}

.mdi-twitter-retweet:before {
  content: "\f547";
}

.mdi-uber:before {
  content: "\f748";
}

.mdi-ubuntu:before {
  content: "\f548";
}

.mdi-ultra-high-definition:before {
  content: "\f7f8";
}

.mdi-umbraco:before {
  content: "\f549";
}

.mdi-umbrella:before {
  content: "\f54a";
}

.mdi-umbrella-outline:before {
  content: "\f54b";
}

.mdi-undo:before {
  content: "\f54c";
}

.mdi-undo-variant:before {
  content: "\f54d";
}

.mdi-unfold-less-horizontal:before {
  content: "\f54e";
}

.mdi-unfold-less-vertical:before {
  content: "\f75f";
}

.mdi-unfold-more-horizontal:before {
  content: "\f54f";
}

.mdi-unfold-more-vertical:before {
  content: "\f760";
}

.mdi-ungroup:before {
  content: "\f550";
}

.mdi-unity:before {
  content: "\f6ae";
}

.mdi-untappd:before {
  content: "\f551";
}

.mdi-update:before {
  content: "\f6af";
}

.mdi-upload:before {
  content: "\f552";
}

.mdi-upload-multiple:before {
  content: "\f83c";
}

.mdi-upload-network:before {
  content: "\f6f5";
}

.mdi-usb:before {
  content: "\f553";
}

.mdi-van-passenger:before {
  content: "\f7f9";
}

.mdi-van-utility:before {
  content: "\f7fa";
}

.mdi-vanish:before {
  content: "\f7fb";
}

.mdi-vector-arrange-above:before {
  content: "\f554";
}

.mdi-vector-arrange-below:before {
  content: "\f555";
}

.mdi-vector-circle:before {
  content: "\f556";
}

.mdi-vector-circle-variant:before {
  content: "\f557";
}

.mdi-vector-combine:before {
  content: "\f558";
}

.mdi-vector-curve:before {
  content: "\f559";
}

.mdi-vector-difference:before {
  content: "\f55a";
}

.mdi-vector-difference-ab:before {
  content: "\f55b";
}

.mdi-vector-difference-ba:before {
  content: "\f55c";
}

.mdi-vector-ellipse:before {
  content: "\f892";
}

.mdi-vector-intersection:before {
  content: "\f55d";
}

.mdi-vector-line:before {
  content: "\f55e";
}

.mdi-vector-point:before {
  content: "\f55f";
}

.mdi-vector-polygon:before {
  content: "\f560";
}

.mdi-vector-polyline:before {
  content: "\f561";
}

.mdi-vector-radius:before {
  content: "\f749";
}

.mdi-vector-rectangle:before {
  content: "\f5c6";
}

.mdi-vector-selection:before {
  content: "\f562";
}

.mdi-vector-square:before {
  content: "\f001";
}

.mdi-vector-triangle:before {
  content: "\f563";
}

.mdi-vector-union:before {
  content: "\f564";
}

.mdi-venmo:before {
  content: "\f578";
}

.mdi-verified:before {
  content: "\f565";
}

.mdi-vibrate:before {
  content: "\f566";
}

.mdi-video:before {
  content: "\f567";
}

.mdi-video-3d:before {
  content: "\f7fc";
}

.mdi-video-4k-box:before {
  content: "\f83d";
}

.mdi-video-account:before {
  content: "落";
}

.mdi-video-image:before {
  content: "酪";
}

.mdi-video-input-antenna:before {
  content: "\f83e";
}

.mdi-video-input-component:before {
  content: "\f83f";
}

.mdi-video-input-hdmi:before {
  content: "\f840";
}

.mdi-video-input-svideo:before {
  content: "\f841";
}

.mdi-video-off:before {
  content: "\f568";
}

.mdi-video-stabilization:before {
  content: "駱";
}

.mdi-video-switch:before {
  content: "\f569";
}

.mdi-view-agenda:before {
  content: "\f56a";
}

.mdi-view-array:before {
  content: "\f56b";
}

.mdi-view-carousel:before {
  content: "\f56c";
}

.mdi-view-column:before {
  content: "\f56d";
}

.mdi-view-dashboard:before {
  content: "\f56e";
}

.mdi-view-dashboard-variant:before {
  content: "\f842";
}

.mdi-view-day:before {
  content: "\f56f";
}

.mdi-view-grid:before {
  content: "\f570";
}

.mdi-view-headline:before {
  content: "\f571";
}

.mdi-view-list:before {
  content: "\f572";
}

.mdi-view-module:before {
  content: "\f573";
}

.mdi-view-parallel:before {
  content: "\f727";
}

.mdi-view-quilt:before {
  content: "\f574";
}

.mdi-view-sequential:before {
  content: "\f728";
}

.mdi-view-stream:before {
  content: "\f575";
}

.mdi-view-week:before {
  content: "\f576";
}

.mdi-vimeo:before {
  content: "\f577";
}

.mdi-violin:before {
  content: "\f60f";
}

.mdi-virtual-reality:before {
  content: "\f893";
}

.mdi-visualstudio:before {
  content: "\f610";
}

.mdi-vk:before {
  content: "\f579";
}

.mdi-vk-box:before {
  content: "\f57a";
}

.mdi-vk-circle:before {
  content: "\f57b";
}

.mdi-vlc:before {
  content: "\f57c";
}

.mdi-voice:before {
  content: "\f5cb";
}

.mdi-voicemail:before {
  content: "\f57d";
}

.mdi-volume-high:before {
  content: "\f57e";
}

.mdi-volume-low:before {
  content: "\f57f";
}

.mdi-volume-medium:before {
  content: "\f580";
}

.mdi-volume-minus:before {
  content: "\f75d";
}

.mdi-volume-mute:before {
  content: "\f75e";
}

.mdi-volume-off:before {
  content: "\f581";
}

.mdi-volume-plus:before {
  content: "\f75c";
}

.mdi-vpn:before {
  content: "\f582";
}

.mdi-vuejs:before {
  content: "\f843";
}

.mdi-walk:before {
  content: "\f583";
}

.mdi-wall:before {
  content: "\f7fd";
}

.mdi-wall-sconce:before {
  content: "亂";
}

.mdi-wall-sconce-flat:before {
  content: "卵";
}

.mdi-wall-sconce-variant:before {
  content: "欄";
}

.mdi-wallet:before {
  content: "\f584";
}

.mdi-wallet-giftcard:before {
  content: "\f585";
}

.mdi-wallet-membership:before {
  content: "\f586";
}

.mdi-wallet-travel:before {
  content: "\f587";
}

.mdi-wan:before {
  content: "\f588";
}

.mdi-washing-machine:before {
  content: "\f729";
}

.mdi-watch:before {
  content: "\f589";
}

.mdi-watch-export:before {
  content: "\f58a";
}

.mdi-watch-export-variant:before {
  content: "\f894";
}

.mdi-watch-import:before {
  content: "\f58b";
}

.mdi-watch-import-variant:before {
  content: "\f895";
}

.mdi-watch-variant:before {
  content: "\f896";
}

.mdi-watch-vibrate:before {
  content: "\f6b0";
}

.mdi-water:before {
  content: "\f58c";
}

.mdi-water-off:before {
  content: "\f58d";
}

.mdi-water-percent:before {
  content: "\f58e";
}

.mdi-water-pump:before {
  content: "\f58f";
}

.mdi-watermark:before {
  content: "\f612";
}

.mdi-waves:before {
  content: "\f78c";
}

.mdi-weather-cloudy:before {
  content: "\f590";
}

.mdi-weather-fog:before {
  content: "\f591";
}

.mdi-weather-hail:before {
  content: "\f592";
}

.mdi-weather-hurricane:before {
  content: "\f897";
}

.mdi-weather-lightning:before {
  content: "\f593";
}

.mdi-weather-lightning-rainy:before {
  content: "\f67d";
}

.mdi-weather-night:before {
  content: "\f594";
}

.mdi-weather-partlycloudy:before {
  content: "\f595";
}

.mdi-weather-pouring:before {
  content: "\f596";
}

.mdi-weather-rainy:before {
  content: "\f597";
}

.mdi-weather-snowy:before {
  content: "\f598";
}

.mdi-weather-snowy-rainy:before {
  content: "\f67e";
}

.mdi-weather-sunny:before {
  content: "\f599";
}

.mdi-weather-sunset:before {
  content: "\f59a";
}

.mdi-weather-sunset-down:before {
  content: "\f59b";
}

.mdi-weather-sunset-up:before {
  content: "\f59c";
}

.mdi-weather-windy:before {
  content: "\f59d";
}

.mdi-weather-windy-variant:before {
  content: "\f59e";
}

.mdi-web:before {
  content: "\f59f";
}

.mdi-webcam:before {
  content: "\f5a0";
}

.mdi-webhook:before {
  content: "\f62f";
}

.mdi-webpack:before {
  content: "\f72a";
}

.mdi-wechat:before {
  content: "\f611";
}

.mdi-weight:before {
  content: "\f5a1";
}

.mdi-weight-kilogram:before {
  content: "\f5a2";
}

.mdi-whatsapp:before {
  content: "\f5a3";
}

.mdi-wheelchair-accessibility:before {
  content: "\f5a4";
}

.mdi-white-balance-auto:before {
  content: "\f5a5";
}

.mdi-white-balance-incandescent:before {
  content: "\f5a6";
}

.mdi-white-balance-iridescent:before {
  content: "\f5a7";
}

.mdi-white-balance-sunny:before {
  content: "\f5a8";
}

.mdi-widgets:before {
  content: "\f72b";
}

.mdi-wifi:before {
  content: "\f5a9";
}

.mdi-wifi-off:before {
  content: "\f5aa";
}

.mdi-wifi-strength-1:before {
  content: "爛";
}

.mdi-wifi-strength-1-alert:before {
  content: "蘭";
}

.mdi-wifi-strength-1-lock:before {
  content: "鸞";
}

.mdi-wifi-strength-2:before {
  content: "嵐";
}

.mdi-wifi-strength-2-alert:before {
  content: "濫";
}

.mdi-wifi-strength-2-lock:before {
  content: "藍";
}

.mdi-wifi-strength-3:before {
  content: "襤";
}

.mdi-wifi-strength-3-alert:before {
  content: "拉";
}

.mdi-wifi-strength-3-lock:before {
  content: "臘";
}

.mdi-wifi-strength-4:before {
  content: "蠟";
}

.mdi-wifi-strength-4-alert:before {
  content: "廊";
}

.mdi-wifi-strength-4-lock:before {
  content: "朗";
}

.mdi-wifi-strength-alert-outline:before {
  content: "浪";
}

.mdi-wifi-strength-lock-outline:before {
  content: "狼";
}

.mdi-wifi-strength-off:before {
  content: "郎";
}

.mdi-wifi-strength-off-outline:before {
  content: "來";
}

.mdi-wifi-strength-outline:before {
  content: "冷";
}

.mdi-wii:before {
  content: "\f5ab";
}

.mdi-wiiu:before {
  content: "\f72c";
}

.mdi-wikipedia:before {
  content: "\f5ac";
}

.mdi-window-close:before {
  content: "\f5ad";
}

.mdi-window-closed:before {
  content: "\f5ae";
}

.mdi-window-maximize:before {
  content: "\f5af";
}

.mdi-window-minimize:before {
  content: "\f5b0";
}

.mdi-window-open:before {
  content: "\f5b1";
}

.mdi-window-restore:before {
  content: "\f5b2";
}

.mdi-windows:before {
  content: "\f5b3";
}

.mdi-wordpress:before {
  content: "\f5b4";
}

.mdi-worker:before {
  content: "\f5b5";
}

.mdi-wrap:before {
  content: "\f5b6";
}

.mdi-wrench:before {
  content: "\f5b7";
}

.mdi-wunderlist:before {
  content: "\f5b8";
}

.mdi-xamarin:before {
  content: "\f844";
}

.mdi-xamarin-outline:before {
  content: "\f845";
}

.mdi-xaml:before {
  content: "\f673";
}

.mdi-xbox:before {
  content: "\f5b9";
}

.mdi-xbox-controller:before {
  content: "\f5ba";
}

.mdi-xbox-controller-battery-alert:before {
  content: "\f74a";
}

.mdi-xbox-controller-battery-empty:before {
  content: "\f74b";
}

.mdi-xbox-controller-battery-full:before {
  content: "\f74c";
}

.mdi-xbox-controller-battery-low:before {
  content: "\f74d";
}

.mdi-xbox-controller-battery-medium:before {
  content: "\f74e";
}

.mdi-xbox-controller-battery-unknown:before {
  content: "\f74f";
}

.mdi-xbox-controller-off:before {
  content: "\f5bb";
}

.mdi-xda:before {
  content: "\f5bc";
}

.mdi-xing:before {
  content: "\f5bd";
}

.mdi-xing-box:before {
  content: "\f5be";
}

.mdi-xing-circle:before {
  content: "\f5bf";
}

.mdi-xml:before {
  content: "\f5c0";
}

.mdi-xmpp:before {
  content: "\f7fe";
}

.mdi-yammer:before {
  content: "\f788";
}

.mdi-yeast:before {
  content: "\f5c1";
}

.mdi-yelp:before {
  content: "\f5c2";
}

.mdi-yin-yang:before {
  content: "\f67f";
}

.mdi-youtube:before {
  content: "\f5c3";
}

.mdi-youtube-creator-studio:before {
  content: "\f846";
}

.mdi-youtube-gaming:before {
  content: "\f847";
}

.mdi-youtube-tv:before {
  content: "\f448";
}

.mdi-zip-box:before {
  content: "\f5c4";
}

.mdi-blank:before {
  content: "\f68c";
  visibility: hidden;
}

.mdi-18px.mdi-set,
.mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set,
.mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set,
.mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set,
.mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: white;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(45deg);
      transform: scaleX(-1) rotate(45deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: scaleY(-1) rotate(45deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(90deg);
      transform: scaleX(-1) rotate(90deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: scaleY(-1) rotate(90deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(135deg);
      transform: scaleX(-1) rotate(135deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: scaleY(-1) rotate(135deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(180deg);
      transform: scaleX(-1) rotate(180deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: scaleY(-1) rotate(180deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(225deg);
      transform: scaleX(-1) rotate(225deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: scaleY(-1) rotate(225deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(270deg);
      transform: scaleX(-1) rotate(270deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: scaleY(-1) rotate(270deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(315deg);
      transform: scaleX(-1) rotate(315deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: scaleY(-1) rotate(315deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/*
* Remix Icon v2.5.0
* https://remixicon.com
* https://github.com/Remix-Design/RemixIcon
*
* Copyright RemixIcon.com
* Released under the Apache License Version 2.0
*
* Date: 2020-05-23
*/
@font-face {
  font-family: "remixicon";
  src: url("../fonts/remixicon.eot?t=1590207869815"); /* IE9*/
  src: url("../fonts/remixicon.eot?t=1590207869815#iefix") format("embedded-opentype"), url("../fonts/remixicon.woff2?t=1590207869815") format("woff2"), url("../fonts/remixicon.woff?t=1590207869815") format("woff"), url("../fonts/remixicon.ttf?t=1590207869815") format("truetype"), url("../fonts/remixicon.svg?t=1590207869815#remixicon") format("svg"); /* iOS 4.1- */
  font-display: swap;
}
[class^=ri-],
[class*=" ri-"] {
  font-family: "remixicon" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-lg {
  font-size: 1.3333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.ri-xl {
  font-size: 1.5em;
  line-height: 0.6666em;
  vertical-align: -0.075em;
}

.ri-xxs {
  font-size: 0.5em;
}

.ri-xs {
  font-size: 0.75em;
}

.ri-sm {
  font-size: 0.875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-24-hours-fill:before {
  content: "\ea01";
}

.ri-24-hours-line:before {
  content: "\ea02";
}

.ri-4k-fill:before {
  content: "\ea03";
}

.ri-4k-line:before {
  content: "\ea04";
}

.ri-a-b:before {
  content: "\ea05";
}

.ri-account-box-fill:before {
  content: "\ea06";
}

.ri-account-box-line:before {
  content: "\ea07";
}

.ri-account-circle-fill:before {
  content: "\ea08";
}

.ri-account-circle-line:before {
  content: "\ea09";
}

.ri-account-pin-box-fill:before {
  content: "\ea0a";
}

.ri-account-pin-box-line:before {
  content: "\ea0b";
}

.ri-account-pin-circle-fill:before {
  content: "\ea0c";
}

.ri-account-pin-circle-line:before {
  content: "\ea0d";
}

.ri-add-box-fill:before {
  content: "\ea0e";
}

.ri-add-box-line:before {
  content: "\ea0f";
}

.ri-add-circle-fill:before {
  content: "\ea10";
}

.ri-add-circle-line:before {
  content: "\ea11";
}

.ri-add-fill:before {
  content: "\ea12";
}

.ri-add-line:before {
  content: "\ea13";
}

.ri-admin-fill:before {
  content: "\ea14";
}

.ri-admin-line:before {
  content: "\ea15";
}

.ri-advertisement-fill:before {
  content: "\ea16";
}

.ri-advertisement-line:before {
  content: "\ea17";
}

.ri-airplay-fill:before {
  content: "\ea18";
}

.ri-airplay-line:before {
  content: "\ea19";
}

.ri-alarm-fill:before {
  content: "\ea1a";
}

.ri-alarm-line:before {
  content: "\ea1b";
}

.ri-alarm-warning-fill:before {
  content: "\ea1c";
}

.ri-alarm-warning-line:before {
  content: "\ea1d";
}

.ri-album-fill:before {
  content: "\ea1e";
}

.ri-album-line:before {
  content: "\ea1f";
}

.ri-alert-fill:before {
  content: "\ea20";
}

.ri-alert-line:before {
  content: "\ea21";
}

.ri-aliens-fill:before {
  content: "\ea22";
}

.ri-aliens-line:before {
  content: "\ea23";
}

.ri-align-bottom:before {
  content: "\ea24";
}

.ri-align-center:before {
  content: "\ea25";
}

.ri-align-justify:before {
  content: "\ea26";
}

.ri-align-left:before {
  content: "\ea27";
}

.ri-align-right:before {
  content: "\ea28";
}

.ri-align-top:before {
  content: "\ea29";
}

.ri-align-vertically:before {
  content: "\ea2a";
}

.ri-alipay-fill:before {
  content: "\ea2b";
}

.ri-alipay-line:before {
  content: "\ea2c";
}

.ri-amazon-fill:before {
  content: "\ea2d";
}

.ri-amazon-line:before {
  content: "\ea2e";
}

.ri-anchor-fill:before {
  content: "\ea2f";
}

.ri-anchor-line:before {
  content: "\ea30";
}

.ri-ancient-gate-fill:before {
  content: "\ea31";
}

.ri-ancient-gate-line:before {
  content: "\ea32";
}

.ri-ancient-pavilion-fill:before {
  content: "\ea33";
}

.ri-ancient-pavilion-line:before {
  content: "\ea34";
}

.ri-android-fill:before {
  content: "\ea35";
}

.ri-android-line:before {
  content: "\ea36";
}

.ri-angularjs-fill:before {
  content: "\ea37";
}

.ri-angularjs-line:before {
  content: "\ea38";
}

.ri-anticlockwise-2-fill:before {
  content: "\ea39";
}

.ri-anticlockwise-2-line:before {
  content: "\ea3a";
}

.ri-anticlockwise-fill:before {
  content: "\ea3b";
}

.ri-anticlockwise-line:before {
  content: "\ea3c";
}

.ri-app-store-fill:before {
  content: "\ea3d";
}

.ri-app-store-line:before {
  content: "\ea3e";
}

.ri-apple-fill:before {
  content: "\ea3f";
}

.ri-apple-line:before {
  content: "\ea40";
}

.ri-apps-2-fill:before {
  content: "\ea41";
}

.ri-apps-2-line:before {
  content: "\ea42";
}

.ri-apps-fill:before {
  content: "\ea43";
}

.ri-apps-line:before {
  content: "\ea44";
}

.ri-archive-drawer-fill:before {
  content: "\ea45";
}

.ri-archive-drawer-line:before {
  content: "\ea46";
}

.ri-archive-fill:before {
  content: "\ea47";
}

.ri-archive-line:before {
  content: "\ea48";
}

.ri-arrow-down-circle-fill:before {
  content: "\ea49";
}

.ri-arrow-down-circle-line:before {
  content: "\ea4a";
}

.ri-arrow-down-fill:before {
  content: "\ea4b";
}

.ri-arrow-down-line:before {
  content: "\ea4c";
}

.ri-arrow-down-s-fill:before {
  content: "\ea4d";
}

.ri-arrow-down-s-line:before {
  content: "\ea4e";
}

.ri-arrow-drop-down-fill:before {
  content: "\ea4f";
}

.ri-arrow-drop-down-line:before {
  content: "\ea50";
}

.ri-arrow-drop-left-fill:before {
  content: "\ea51";
}

.ri-arrow-drop-left-line:before {
  content: "\ea52";
}

.ri-arrow-drop-right-fill:before {
  content: "\ea53";
}

.ri-arrow-drop-right-line:before {
  content: "\ea54";
}

.ri-arrow-drop-up-fill:before {
  content: "\ea55";
}

.ri-arrow-drop-up-line:before {
  content: "\ea56";
}

.ri-arrow-go-back-fill:before {
  content: "\ea57";
}

.ri-arrow-go-back-line:before {
  content: "\ea58";
}

.ri-arrow-go-forward-fill:before {
  content: "\ea59";
}

.ri-arrow-go-forward-line:before {
  content: "\ea5a";
}

.ri-arrow-left-circle-fill:before {
  content: "\ea5b";
}

.ri-arrow-left-circle-line:before {
  content: "\ea5c";
}

.ri-arrow-left-down-fill:before {
  content: "\ea5d";
}

.ri-arrow-left-down-line:before {
  content: "\ea5e";
}

.ri-arrow-left-fill:before {
  content: "\ea5f";
}

.ri-arrow-left-line:before {
  content: "\ea60";
}

.ri-arrow-left-right-fill:before {
  content: "\ea61";
}

.ri-arrow-left-right-line:before {
  content: "\ea62";
}

.ri-arrow-left-s-fill:before {
  content: "\ea63";
}

.ri-arrow-left-s-line:before {
  content: "\ea64";
}

.ri-arrow-left-up-fill:before {
  content: "\ea65";
}

.ri-arrow-left-up-line:before {
  content: "\ea66";
}

.ri-arrow-right-circle-fill:before {
  content: "\ea67";
}

.ri-arrow-right-circle-line:before {
  content: "\ea68";
}

.ri-arrow-right-down-fill:before {
  content: "\ea69";
}

.ri-arrow-right-down-line:before {
  content: "\ea6a";
}

.ri-arrow-right-fill:before {
  content: "\ea6b";
}

.ri-arrow-right-line:before {
  content: "\ea6c";
}

.ri-arrow-right-s-fill:before {
  content: "\ea6d";
}

.ri-arrow-right-s-line:before {
  content: "\ea6e";
}

.ri-arrow-right-up-fill:before {
  content: "\ea6f";
}

.ri-arrow-right-up-line:before {
  content: "\ea70";
}

.ri-arrow-up-circle-fill:before {
  content: "\ea71";
}

.ri-arrow-up-circle-line:before {
  content: "\ea72";
}

.ri-arrow-up-down-fill:before {
  content: "\ea73";
}

.ri-arrow-up-down-line:before {
  content: "\ea74";
}

.ri-arrow-up-fill:before {
  content: "\ea75";
}

.ri-arrow-up-line:before {
  content: "\ea76";
}

.ri-arrow-up-s-fill:before {
  content: "\ea77";
}

.ri-arrow-up-s-line:before {
  content: "\ea78";
}

.ri-artboard-2-fill:before {
  content: "\ea79";
}

.ri-artboard-2-line:before {
  content: "\ea7a";
}

.ri-artboard-fill:before {
  content: "\ea7b";
}

.ri-artboard-line:before {
  content: "\ea7c";
}

.ri-article-fill:before {
  content: "\ea7d";
}

.ri-article-line:before {
  content: "\ea7e";
}

.ri-aspect-ratio-fill:before {
  content: "\ea7f";
}

.ri-aspect-ratio-line:before {
  content: "\ea80";
}

.ri-asterisk:before {
  content: "\ea81";
}

.ri-at-fill:before {
  content: "\ea82";
}

.ri-at-line:before {
  content: "\ea83";
}

.ri-attachment-2:before {
  content: "\ea84";
}

.ri-attachment-fill:before {
  content: "\ea85";
}

.ri-attachment-line:before {
  content: "\ea86";
}

.ri-auction-fill:before {
  content: "\ea87";
}

.ri-auction-line:before {
  content: "\ea88";
}

.ri-award-fill:before {
  content: "\ea89";
}

.ri-award-line:before {
  content: "\ea8a";
}

.ri-baidu-fill:before {
  content: "\ea8b";
}

.ri-baidu-line:before {
  content: "\ea8c";
}

.ri-ball-pen-fill:before {
  content: "\ea8d";
}

.ri-ball-pen-line:before {
  content: "\ea8e";
}

.ri-bank-card-2-fill:before {
  content: "\ea8f";
}

.ri-bank-card-2-line:before {
  content: "\ea90";
}

.ri-bank-card-fill:before {
  content: "\ea91";
}

.ri-bank-card-line:before {
  content: "\ea92";
}

.ri-bank-fill:before {
  content: "\ea93";
}

.ri-bank-line:before {
  content: "\ea94";
}

.ri-bar-chart-2-fill:before {
  content: "\ea95";
}

.ri-bar-chart-2-line:before {
  content: "\ea96";
}

.ri-bar-chart-box-fill:before {
  content: "\ea97";
}

.ri-bar-chart-box-line:before {
  content: "\ea98";
}

.ri-bar-chart-fill:before {
  content: "\ea99";
}

.ri-bar-chart-grouped-fill:before {
  content: "\ea9a";
}

.ri-bar-chart-grouped-line:before {
  content: "\ea9b";
}

.ri-bar-chart-horizontal-fill:before {
  content: "\ea9c";
}

.ri-bar-chart-horizontal-line:before {
  content: "\ea9d";
}

.ri-bar-chart-line:before {
  content: "\ea9e";
}

.ri-barcode-box-fill:before {
  content: "\ea9f";
}

.ri-barcode-box-line:before {
  content: "\eaa0";
}

.ri-barcode-fill:before {
  content: "\eaa1";
}

.ri-barcode-line:before {
  content: "\eaa2";
}

.ri-barricade-fill:before {
  content: "\eaa3";
}

.ri-barricade-line:before {
  content: "\eaa4";
}

.ri-base-station-fill:before {
  content: "\eaa5";
}

.ri-base-station-line:before {
  content: "\eaa6";
}

.ri-basketball-fill:before {
  content: "\eaa7";
}

.ri-basketball-line:before {
  content: "\eaa8";
}

.ri-battery-2-charge-fill:before {
  content: "\eaa9";
}

.ri-battery-2-charge-line:before {
  content: "\eaaa";
}

.ri-battery-2-fill:before {
  content: "\eaab";
}

.ri-battery-2-line:before {
  content: "\eaac";
}

.ri-battery-charge-fill:before {
  content: "\eaad";
}

.ri-battery-charge-line:before {
  content: "\eaae";
}

.ri-battery-fill:before {
  content: "\eaaf";
}

.ri-battery-line:before {
  content: "\eab0";
}

.ri-battery-low-fill:before {
  content: "\eab1";
}

.ri-battery-low-line:before {
  content: "\eab2";
}

.ri-battery-saver-fill:before {
  content: "\eab3";
}

.ri-battery-saver-line:before {
  content: "\eab4";
}

.ri-battery-share-fill:before {
  content: "\eab5";
}

.ri-battery-share-line:before {
  content: "\eab6";
}

.ri-bear-smile-fill:before {
  content: "\eab7";
}

.ri-bear-smile-line:before {
  content: "\eab8";
}

.ri-behance-fill:before {
  content: "\eab9";
}

.ri-behance-line:before {
  content: "\eaba";
}

.ri-bell-fill:before {
  content: "\eabb";
}

.ri-bell-line:before {
  content: "\eabc";
}

.ri-bike-fill:before {
  content: "\eabd";
}

.ri-bike-line:before {
  content: "\eabe";
}

.ri-bilibili-fill:before {
  content: "\eabf";
}

.ri-bilibili-line:before {
  content: "\eac0";
}

.ri-bill-fill:before {
  content: "\eac1";
}

.ri-bill-line:before {
  content: "\eac2";
}

.ri-billiards-fill:before {
  content: "\eac3";
}

.ri-billiards-line:before {
  content: "\eac4";
}

.ri-bit-coin-fill:before {
  content: "\eac5";
}

.ri-bit-coin-line:before {
  content: "\eac6";
}

.ri-blaze-fill:before {
  content: "\eac7";
}

.ri-blaze-line:before {
  content: "\eac8";
}

.ri-bluetooth-connect-fill:before {
  content: "\eac9";
}

.ri-bluetooth-connect-line:before {
  content: "\eaca";
}

.ri-bluetooth-fill:before {
  content: "\eacb";
}

.ri-bluetooth-line:before {
  content: "\eacc";
}

.ri-blur-off-fill:before {
  content: "\eacd";
}

.ri-blur-off-line:before {
  content: "\eace";
}

.ri-body-scan-fill:before {
  content: "\eacf";
}

.ri-body-scan-line:before {
  content: "\ead0";
}

.ri-bold:before {
  content: "\ead1";
}

.ri-book-2-fill:before {
  content: "\ead2";
}

.ri-book-2-line:before {
  content: "\ead3";
}

.ri-book-3-fill:before {
  content: "\ead4";
}

.ri-book-3-line:before {
  content: "\ead5";
}

.ri-book-fill:before {
  content: "\ead6";
}

.ri-book-line:before {
  content: "\ead7";
}

.ri-book-mark-fill:before {
  content: "\ead8";
}

.ri-book-mark-line:before {
  content: "\ead9";
}

.ri-book-open-fill:before {
  content: "\eada";
}

.ri-book-open-line:before {
  content: "\eadb";
}

.ri-book-read-fill:before {
  content: "\eadc";
}

.ri-book-read-line:before {
  content: "\eadd";
}

.ri-booklet-fill:before {
  content: "\eade";
}

.ri-booklet-line:before {
  content: "\eadf";
}

.ri-bookmark-2-fill:before {
  content: "\eae0";
}

.ri-bookmark-2-line:before {
  content: "\eae1";
}

.ri-bookmark-3-fill:before {
  content: "\eae2";
}

.ri-bookmark-3-line:before {
  content: "\eae3";
}

.ri-bookmark-fill:before {
  content: "\eae4";
}

.ri-bookmark-line:before {
  content: "\eae5";
}

.ri-boxing-fill:before {
  content: "\eae6";
}

.ri-boxing-line:before {
  content: "\eae7";
}

.ri-braces-fill:before {
  content: "\eae8";
}

.ri-braces-line:before {
  content: "\eae9";
}

.ri-brackets-fill:before {
  content: "\eaea";
}

.ri-brackets-line:before {
  content: "\eaeb";
}

.ri-briefcase-2-fill:before {
  content: "\eaec";
}

.ri-briefcase-2-line:before {
  content: "\eaed";
}

.ri-briefcase-3-fill:before {
  content: "\eaee";
}

.ri-briefcase-3-line:before {
  content: "\eaef";
}

.ri-briefcase-4-fill:before {
  content: "\eaf0";
}

.ri-briefcase-4-line:before {
  content: "\eaf1";
}

.ri-briefcase-5-fill:before {
  content: "\eaf2";
}

.ri-briefcase-5-line:before {
  content: "\eaf3";
}

.ri-briefcase-fill:before {
  content: "\eaf4";
}

.ri-briefcase-line:before {
  content: "\eaf5";
}

.ri-bring-forward:before {
  content: "\eaf6";
}

.ri-bring-to-front:before {
  content: "\eaf7";
}

.ri-broadcast-fill:before {
  content: "\eaf8";
}

.ri-broadcast-line:before {
  content: "\eaf9";
}

.ri-brush-2-fill:before {
  content: "\eafa";
}

.ri-brush-2-line:before {
  content: "\eafb";
}

.ri-brush-3-fill:before {
  content: "\eafc";
}

.ri-brush-3-line:before {
  content: "\eafd";
}

.ri-brush-4-fill:before {
  content: "\eafe";
}

.ri-brush-4-line:before {
  content: "\eaff";
}

.ri-brush-fill:before {
  content: "\eb00";
}

.ri-brush-line:before {
  content: "\eb01";
}

.ri-bubble-chart-fill:before {
  content: "\eb02";
}

.ri-bubble-chart-line:before {
  content: "\eb03";
}

.ri-bug-2-fill:before {
  content: "\eb04";
}

.ri-bug-2-line:before {
  content: "\eb05";
}

.ri-bug-fill:before {
  content: "\eb06";
}

.ri-bug-line:before {
  content: "\eb07";
}

.ri-building-2-fill:before {
  content: "\eb08";
}

.ri-building-2-line:before {
  content: "\eb09";
}

.ri-building-3-fill:before {
  content: "\eb0a";
}

.ri-building-3-line:before {
  content: "\eb0b";
}

.ri-building-4-fill:before {
  content: "\eb0c";
}

.ri-building-4-line:before {
  content: "\eb0d";
}

.ri-building-fill:before {
  content: "\eb0e";
}

.ri-building-line:before {
  content: "\eb0f";
}

.ri-bus-2-fill:before {
  content: "\eb10";
}

.ri-bus-2-line:before {
  content: "\eb11";
}

.ri-bus-fill:before {
  content: "\eb12";
}

.ri-bus-line:before {
  content: "\eb13";
}

.ri-bus-wifi-fill:before {
  content: "\eb14";
}

.ri-bus-wifi-line:before {
  content: "\eb15";
}

.ri-cactus-fill:before {
  content: "\eb16";
}

.ri-cactus-line:before {
  content: "\eb17";
}

.ri-cake-2-fill:before {
  content: "\eb18";
}

.ri-cake-2-line:before {
  content: "\eb19";
}

.ri-cake-3-fill:before {
  content: "\eb1a";
}

.ri-cake-3-line:before {
  content: "\eb1b";
}

.ri-cake-fill:before {
  content: "\eb1c";
}

.ri-cake-line:before {
  content: "\eb1d";
}

.ri-calculator-fill:before {
  content: "\eb1e";
}

.ri-calculator-line:before {
  content: "\eb1f";
}

.ri-calendar-2-fill:before {
  content: "\eb20";
}

.ri-calendar-2-line:before {
  content: "\eb21";
}

.ri-calendar-check-fill:before {
  content: "\eb22";
}

.ri-calendar-check-line:before {
  content: "\eb23";
}

.ri-calendar-event-fill:before {
  content: "\eb24";
}

.ri-calendar-event-line:before {
  content: "\eb25";
}

.ri-calendar-fill:before {
  content: "\eb26";
}

.ri-calendar-line:before {
  content: "\eb27";
}

.ri-calendar-todo-fill:before {
  content: "\eb28";
}

.ri-calendar-todo-line:before {
  content: "\eb29";
}

.ri-camera-2-fill:before {
  content: "\eb2a";
}

.ri-camera-2-line:before {
  content: "\eb2b";
}

.ri-camera-3-fill:before {
  content: "\eb2c";
}

.ri-camera-3-line:before {
  content: "\eb2d";
}

.ri-camera-fill:before {
  content: "\eb2e";
}

.ri-camera-lens-fill:before {
  content: "\eb2f";
}

.ri-camera-lens-line:before {
  content: "\eb30";
}

.ri-camera-line:before {
  content: "\eb31";
}

.ri-camera-off-fill:before {
  content: "\eb32";
}

.ri-camera-off-line:before {
  content: "\eb33";
}

.ri-camera-switch-fill:before {
  content: "\eb34";
}

.ri-camera-switch-line:before {
  content: "\eb35";
}

.ri-capsule-fill:before {
  content: "\eb36";
}

.ri-capsule-line:before {
  content: "\eb37";
}

.ri-car-fill:before {
  content: "\eb38";
}

.ri-car-line:before {
  content: "\eb39";
}

.ri-car-washing-fill:before {
  content: "\eb3a";
}

.ri-car-washing-line:before {
  content: "\eb3b";
}

.ri-caravan-fill:before {
  content: "\eb3c";
}

.ri-caravan-line:before {
  content: "\eb3d";
}

.ri-cast-fill:before {
  content: "\eb3e";
}

.ri-cast-line:before {
  content: "\eb3f";
}

.ri-cellphone-fill:before {
  content: "\eb40";
}

.ri-cellphone-line:before {
  content: "\eb41";
}

.ri-celsius-fill:before {
  content: "\eb42";
}

.ri-celsius-line:before {
  content: "\eb43";
}

.ri-centos-fill:before {
  content: "\eb44";
}

.ri-centos-line:before {
  content: "\eb45";
}

.ri-character-recognition-fill:before {
  content: "\eb46";
}

.ri-character-recognition-line:before {
  content: "\eb47";
}

.ri-charging-pile-2-fill:before {
  content: "\eb48";
}

.ri-charging-pile-2-line:before {
  content: "\eb49";
}

.ri-charging-pile-fill:before {
  content: "\eb4a";
}

.ri-charging-pile-line:before {
  content: "\eb4b";
}

.ri-chat-1-fill:before {
  content: "\eb4c";
}

.ri-chat-1-line:before {
  content: "\eb4d";
}

.ri-chat-2-fill:before {
  content: "\eb4e";
}

.ri-chat-2-line:before {
  content: "\eb4f";
}

.ri-chat-3-fill:before {
  content: "\eb50";
}

.ri-chat-3-line:before {
  content: "\eb51";
}

.ri-chat-4-fill:before {
  content: "\eb52";
}

.ri-chat-4-line:before {
  content: "\eb53";
}

.ri-chat-check-fill:before {
  content: "\eb54";
}

.ri-chat-check-line:before {
  content: "\eb55";
}

.ri-chat-delete-fill:before {
  content: "\eb56";
}

.ri-chat-delete-line:before {
  content: "\eb57";
}

.ri-chat-download-fill:before {
  content: "\eb58";
}

.ri-chat-download-line:before {
  content: "\eb59";
}

.ri-chat-follow-up-fill:before {
  content: "\eb5a";
}

.ri-chat-follow-up-line:before {
  content: "\eb5b";
}

.ri-chat-forward-fill:before {
  content: "\eb5c";
}

.ri-chat-forward-line:before {
  content: "\eb5d";
}

.ri-chat-heart-fill:before {
  content: "\eb5e";
}

.ri-chat-heart-line:before {
  content: "\eb5f";
}

.ri-chat-history-fill:before {
  content: "\eb60";
}

.ri-chat-history-line:before {
  content: "\eb61";
}

.ri-chat-new-fill:before {
  content: "\eb62";
}

.ri-chat-new-line:before {
  content: "\eb63";
}

.ri-chat-off-fill:before {
  content: "\eb64";
}

.ri-chat-off-line:before {
  content: "\eb65";
}

.ri-chat-poll-fill:before {
  content: "\eb66";
}

.ri-chat-poll-line:before {
  content: "\eb67";
}

.ri-chat-private-fill:before {
  content: "\eb68";
}

.ri-chat-private-line:before {
  content: "\eb69";
}

.ri-chat-quote-fill:before {
  content: "\eb6a";
}

.ri-chat-quote-line:before {
  content: "\eb6b";
}

.ri-chat-settings-fill:before {
  content: "\eb6c";
}

.ri-chat-settings-line:before {
  content: "\eb6d";
}

.ri-chat-smile-2-fill:before {
  content: "\eb6e";
}

.ri-chat-smile-2-line:before {
  content: "\eb6f";
}

.ri-chat-smile-3-fill:before {
  content: "\eb70";
}

.ri-chat-smile-3-line:before {
  content: "\eb71";
}

.ri-chat-smile-fill:before {
  content: "\eb72";
}

.ri-chat-smile-line:before {
  content: "\eb73";
}

.ri-chat-upload-fill:before {
  content: "\eb74";
}

.ri-chat-upload-line:before {
  content: "\eb75";
}

.ri-chat-voice-fill:before {
  content: "\eb76";
}

.ri-chat-voice-line:before {
  content: "\eb77";
}

.ri-check-double-fill:before {
  content: "\eb78";
}

.ri-check-double-line:before {
  content: "\eb79";
}

.ri-check-fill:before {
  content: "\eb7a";
}

.ri-check-line:before {
  content: "\eb7b";
}

.ri-checkbox-blank-circle-fill:before {
  content: "\eb7c";
}

.ri-checkbox-blank-circle-line:before {
  content: "\eb7d";
}

.ri-checkbox-blank-fill:before {
  content: "\eb7e";
}

.ri-checkbox-blank-line:before {
  content: "\eb7f";
}

.ri-checkbox-circle-fill:before {
  content: "\eb80";
}

.ri-checkbox-circle-line:before {
  content: "\eb81";
}

.ri-checkbox-fill:before {
  content: "\eb82";
}

.ri-checkbox-indeterminate-fill:before {
  content: "\eb83";
}

.ri-checkbox-indeterminate-line:before {
  content: "\eb84";
}

.ri-checkbox-line:before {
  content: "\eb85";
}

.ri-checkbox-multiple-blank-fill:before {
  content: "\eb86";
}

.ri-checkbox-multiple-blank-line:before {
  content: "\eb87";
}

.ri-checkbox-multiple-fill:before {
  content: "\eb88";
}

.ri-checkbox-multiple-line:before {
  content: "\eb89";
}

.ri-china-railway-fill:before {
  content: "\eb8a";
}

.ri-china-railway-line:before {
  content: "\eb8b";
}

.ri-chrome-fill:before {
  content: "\eb8c";
}

.ri-chrome-line:before {
  content: "\eb8d";
}

.ri-clapperboard-fill:before {
  content: "\eb8e";
}

.ri-clapperboard-line:before {
  content: "\eb8f";
}

.ri-clipboard-fill:before {
  content: "\eb90";
}

.ri-clipboard-line:before {
  content: "\eb91";
}

.ri-clockwise-2-fill:before {
  content: "\eb92";
}

.ri-clockwise-2-line:before {
  content: "\eb93";
}

.ri-clockwise-fill:before {
  content: "\eb94";
}

.ri-clockwise-line:before {
  content: "\eb95";
}

.ri-close-circle-fill:before {
  content: "\eb96";
}

.ri-close-circle-line:before {
  content: "\eb97";
}

.ri-close-fill:before {
  content: "\eb98";
}

.ri-close-line:before {
  content: "\eb99";
}

.ri-closed-captioning-fill:before {
  content: "\eb9a";
}

.ri-closed-captioning-line:before {
  content: "\eb9b";
}

.ri-cloud-fill:before {
  content: "\eb9c";
}

.ri-cloud-line:before {
  content: "\eb9d";
}

.ri-cloud-off-fill:before {
  content: "\eb9e";
}

.ri-cloud-off-line:before {
  content: "\eb9f";
}

.ri-cloud-windy-fill:before {
  content: "\eba0";
}

.ri-cloud-windy-line:before {
  content: "\eba1";
}

.ri-cloudy-2-fill:before {
  content: "\eba2";
}

.ri-cloudy-2-line:before {
  content: "\eba3";
}

.ri-cloudy-fill:before {
  content: "\eba4";
}

.ri-cloudy-line:before {
  content: "\eba5";
}

.ri-code-box-fill:before {
  content: "\eba6";
}

.ri-code-box-line:before {
  content: "\eba7";
}

.ri-code-fill:before {
  content: "\eba8";
}

.ri-code-line:before {
  content: "\eba9";
}

.ri-code-s-fill:before {
  content: "\ebaa";
}

.ri-code-s-line:before {
  content: "\ebab";
}

.ri-code-s-slash-fill:before {
  content: "\ebac";
}

.ri-code-s-slash-line:before {
  content: "\ebad";
}

.ri-code-view:before {
  content: "\ebae";
}

.ri-codepen-fill:before {
  content: "\ebaf";
}

.ri-codepen-line:before {
  content: "\ebb0";
}

.ri-coin-fill:before {
  content: "\ebb1";
}

.ri-coin-line:before {
  content: "\ebb2";
}

.ri-coins-fill:before {
  content: "\ebb3";
}

.ri-coins-line:before {
  content: "\ebb4";
}

.ri-collage-fill:before {
  content: "\ebb5";
}

.ri-collage-line:before {
  content: "\ebb6";
}

.ri-command-fill:before {
  content: "\ebb7";
}

.ri-command-line:before {
  content: "\ebb8";
}

.ri-community-fill:before {
  content: "\ebb9";
}

.ri-community-line:before {
  content: "\ebba";
}

.ri-compass-2-fill:before {
  content: "\ebbb";
}

.ri-compass-2-line:before {
  content: "\ebbc";
}

.ri-compass-3-fill:before {
  content: "\ebbd";
}

.ri-compass-3-line:before {
  content: "\ebbe";
}

.ri-compass-4-fill:before {
  content: "\ebbf";
}

.ri-compass-4-line:before {
  content: "\ebc0";
}

.ri-compass-discover-fill:before {
  content: "\ebc1";
}

.ri-compass-discover-line:before {
  content: "\ebc2";
}

.ri-compass-fill:before {
  content: "\ebc3";
}

.ri-compass-line:before {
  content: "\ebc4";
}

.ri-compasses-2-fill:before {
  content: "\ebc5";
}

.ri-compasses-2-line:before {
  content: "\ebc6";
}

.ri-compasses-fill:before {
  content: "\ebc7";
}

.ri-compasses-line:before {
  content: "\ebc8";
}

.ri-computer-fill:before {
  content: "\ebc9";
}

.ri-computer-line:before {
  content: "\ebca";
}

.ri-contacts-book-2-fill:before {
  content: "\ebcb";
}

.ri-contacts-book-2-line:before {
  content: "\ebcc";
}

.ri-contacts-book-fill:before {
  content: "\ebcd";
}

.ri-contacts-book-line:before {
  content: "\ebce";
}

.ri-contacts-book-upload-fill:before {
  content: "\ebcf";
}

.ri-contacts-book-upload-line:before {
  content: "\ebd0";
}

.ri-contacts-fill:before {
  content: "\ebd1";
}

.ri-contacts-line:before {
  content: "\ebd2";
}

.ri-contrast-2-fill:before {
  content: "\ebd3";
}

.ri-contrast-2-line:before {
  content: "\ebd4";
}

.ri-contrast-drop-2-fill:before {
  content: "\ebd5";
}

.ri-contrast-drop-2-line:before {
  content: "\ebd6";
}

.ri-contrast-drop-fill:before {
  content: "\ebd7";
}

.ri-contrast-drop-line:before {
  content: "\ebd8";
}

.ri-contrast-fill:before {
  content: "\ebd9";
}

.ri-contrast-line:before {
  content: "\ebda";
}

.ri-copper-coin-fill:before {
  content: "\ebdb";
}

.ri-copper-coin-line:before {
  content: "\ebdc";
}

.ri-copper-diamond-fill:before {
  content: "\ebdd";
}

.ri-copper-diamond-line:before {
  content: "\ebde";
}

.ri-copyleft-fill:before {
  content: "\ebdf";
}

.ri-copyleft-line:before {
  content: "\ebe0";
}

.ri-copyright-fill:before {
  content: "\ebe1";
}

.ri-copyright-line:before {
  content: "\ebe2";
}

.ri-coreos-fill:before {
  content: "\ebe3";
}

.ri-coreos-line:before {
  content: "\ebe4";
}

.ri-coupon-2-fill:before {
  content: "\ebe5";
}

.ri-coupon-2-line:before {
  content: "\ebe6";
}

.ri-coupon-3-fill:before {
  content: "\ebe7";
}

.ri-coupon-3-line:before {
  content: "\ebe8";
}

.ri-coupon-4-fill:before {
  content: "\ebe9";
}

.ri-coupon-4-line:before {
  content: "\ebea";
}

.ri-coupon-5-fill:before {
  content: "\ebeb";
}

.ri-coupon-5-line:before {
  content: "\ebec";
}

.ri-coupon-fill:before {
  content: "\ebed";
}

.ri-coupon-line:before {
  content: "\ebee";
}

.ri-cpu-fill:before {
  content: "\ebef";
}

.ri-cpu-line:before {
  content: "\ebf0";
}

.ri-creative-commons-by-fill:before {
  content: "\ebf1";
}

.ri-creative-commons-by-line:before {
  content: "\ebf2";
}

.ri-creative-commons-fill:before {
  content: "\ebf3";
}

.ri-creative-commons-line:before {
  content: "\ebf4";
}

.ri-creative-commons-nc-fill:before {
  content: "\ebf5";
}

.ri-creative-commons-nc-line:before {
  content: "\ebf6";
}

.ri-creative-commons-nd-fill:before {
  content: "\ebf7";
}

.ri-creative-commons-nd-line:before {
  content: "\ebf8";
}

.ri-creative-commons-sa-fill:before {
  content: "\ebf9";
}

.ri-creative-commons-sa-line:before {
  content: "\ebfa";
}

.ri-creative-commons-zero-fill:before {
  content: "\ebfb";
}

.ri-creative-commons-zero-line:before {
  content: "\ebfc";
}

.ri-criminal-fill:before {
  content: "\ebfd";
}

.ri-criminal-line:before {
  content: "\ebfe";
}

.ri-crop-2-fill:before {
  content: "\ebff";
}

.ri-crop-2-line:before {
  content: "\ec00";
}

.ri-crop-fill:before {
  content: "\ec01";
}

.ri-crop-line:before {
  content: "\ec02";
}

.ri-css3-fill:before {
  content: "\ec03";
}

.ri-css3-line:before {
  content: "\ec04";
}

.ri-cup-fill:before {
  content: "\ec05";
}

.ri-cup-line:before {
  content: "\ec06";
}

.ri-currency-fill:before {
  content: "\ec07";
}

.ri-currency-line:before {
  content: "\ec08";
}

.ri-cursor-fill:before {
  content: "\ec09";
}

.ri-cursor-line:before {
  content: "\ec0a";
}

.ri-customer-service-2-fill:before {
  content: "\ec0b";
}

.ri-customer-service-2-line:before {
  content: "\ec0c";
}

.ri-customer-service-fill:before {
  content: "\ec0d";
}

.ri-customer-service-line:before {
  content: "\ec0e";
}

.ri-dashboard-2-fill:before {
  content: "\ec0f";
}

.ri-dashboard-2-line:before {
  content: "\ec10";
}

.ri-dashboard-3-fill:before {
  content: "\ec11";
}

.ri-dashboard-3-line:before {
  content: "\ec12";
}

.ri-dashboard-fill:before {
  content: "\ec13";
}

.ri-dashboard-line:before {
  content: "\ec14";
}

.ri-database-2-fill:before {
  content: "\ec15";
}

.ri-database-2-line:before {
  content: "\ec16";
}

.ri-database-fill:before {
  content: "\ec17";
}

.ri-database-line:before {
  content: "\ec18";
}

.ri-delete-back-2-fill:before {
  content: "\ec19";
}

.ri-delete-back-2-line:before {
  content: "\ec1a";
}

.ri-delete-back-fill:before {
  content: "\ec1b";
}

.ri-delete-back-line:before {
  content: "\ec1c";
}

.ri-delete-bin-2-fill:before {
  content: "\ec1d";
}

.ri-delete-bin-2-line:before {
  content: "\ec1e";
}

.ri-delete-bin-3-fill:before {
  content: "\ec1f";
}

.ri-delete-bin-3-line:before {
  content: "\ec20";
}

.ri-delete-bin-4-fill:before {
  content: "\ec21";
}

.ri-delete-bin-4-line:before {
  content: "\ec22";
}

.ri-delete-bin-5-fill:before {
  content: "\ec23";
}

.ri-delete-bin-5-line:before {
  content: "\ec24";
}

.ri-delete-bin-6-fill:before {
  content: "\ec25";
}

.ri-delete-bin-6-line:before {
  content: "\ec26";
}

.ri-delete-bin-7-fill:before {
  content: "\ec27";
}

.ri-delete-bin-7-line:before {
  content: "\ec28";
}

.ri-delete-bin-fill:before {
  content: "\ec29";
}

.ri-delete-bin-line:before {
  content: "\ec2a";
}

.ri-delete-column:before {
  content: "\ec2b";
}

.ri-delete-row:before {
  content: "\ec2c";
}

.ri-device-fill:before {
  content: "\ec2d";
}

.ri-device-line:before {
  content: "\ec2e";
}

.ri-device-recover-fill:before {
  content: "\ec2f";
}

.ri-device-recover-line:before {
  content: "\ec30";
}

.ri-dingding-fill:before {
  content: "\ec31";
}

.ri-dingding-line:before {
  content: "\ec32";
}

.ri-direction-fill:before {
  content: "\ec33";
}

.ri-direction-line:before {
  content: "\ec34";
}

.ri-disc-fill:before {
  content: "\ec35";
}

.ri-disc-line:before {
  content: "\ec36";
}

.ri-discord-fill:before {
  content: "\ec37";
}

.ri-discord-line:before {
  content: "\ec38";
}

.ri-discuss-fill:before {
  content: "\ec39";
}

.ri-discuss-line:before {
  content: "\ec3a";
}

.ri-dislike-fill:before {
  content: "\ec3b";
}

.ri-dislike-line:before {
  content: "\ec3c";
}

.ri-disqus-fill:before {
  content: "\ec3d";
}

.ri-disqus-line:before {
  content: "\ec3e";
}

.ri-divide-fill:before {
  content: "\ec3f";
}

.ri-divide-line:before {
  content: "\ec40";
}

.ri-donut-chart-fill:before {
  content: "\ec41";
}

.ri-donut-chart-line:before {
  content: "\ec42";
}

.ri-door-closed-fill:before {
  content: "\ec43";
}

.ri-door-closed-line:before {
  content: "\ec44";
}

.ri-door-fill:before {
  content: "\ec45";
}

.ri-door-line:before {
  content: "\ec46";
}

.ri-door-lock-box-fill:before {
  content: "\ec47";
}

.ri-door-lock-box-line:before {
  content: "\ec48";
}

.ri-door-lock-fill:before {
  content: "\ec49";
}

.ri-door-lock-line:before {
  content: "\ec4a";
}

.ri-door-open-fill:before {
  content: "\ec4b";
}

.ri-door-open-line:before {
  content: "\ec4c";
}

.ri-dossier-fill:before {
  content: "\ec4d";
}

.ri-dossier-line:before {
  content: "\ec4e";
}

.ri-douban-fill:before {
  content: "\ec4f";
}

.ri-douban-line:before {
  content: "\ec50";
}

.ri-double-quotes-l:before {
  content: "\ec51";
}

.ri-double-quotes-r:before {
  content: "\ec52";
}

.ri-download-2-line:before {
  content: "\ec53";
}

.ri-download-2-line:before {
  content: "\ec54";
}

.ri-download-cloud-2-fill:before {
  content: "\ec55";
}

.ri-download-cloud-2-line:before {
  content: "\ec56";
}

.ri-download-cloud-fill:before {
  content: "\ec57";
}

.ri-download-cloud-line:before {
  content: "\ec58";
}

.ri-download-fill:before {
  content: "\ec59";
}

.ri-download-line:before {
  content: "\ec5a";
}

.ri-draft-fill:before {
  content: "\ec5b";
}

.ri-draft-line:before {
  content: "\ec5c";
}

.ri-drag-drop-fill:before {
  content: "\ec5d";
}

.ri-drag-drop-line:before {
  content: "\ec5e";
}

.ri-drag-move-2-fill:before {
  content: "\ec5f";
}

.ri-drag-move-2-line:before {
  content: "\ec60";
}

.ri-drag-move-fill:before {
  content: "\ec61";
}

.ri-drag-move-line:before {
  content: "\ec62";
}

.ri-dribbble-fill:before {
  content: "\ec63";
}

.ri-dribbble-line:before {
  content: "\ec64";
}

.ri-drive-fill:before {
  content: "\ec65";
}

.ri-drive-line:before {
  content: "\ec66";
}

.ri-drizzle-fill:before {
  content: "\ec67";
}

.ri-drizzle-line:before {
  content: "\ec68";
}

.ri-drop-fill:before {
  content: "\ec69";
}

.ri-drop-line:before {
  content: "\ec6a";
}

.ri-dropbox-fill:before {
  content: "\ec6b";
}

.ri-dropbox-line:before {
  content: "\ec6c";
}

.ri-dual-sim-1-fill:before {
  content: "\ec6d";
}

.ri-dual-sim-1-line:before {
  content: "\ec6e";
}

.ri-dual-sim-2-fill:before {
  content: "\ec6f";
}

.ri-dual-sim-2-line:before {
  content: "\ec70";
}

.ri-dv-fill:before {
  content: "\ec71";
}

.ri-dv-line:before {
  content: "\ec72";
}

.ri-dvd-fill:before {
  content: "\ec73";
}

.ri-dvd-line:before {
  content: "\ec74";
}

.ri-e-bike-2-fill:before {
  content: "\ec75";
}

.ri-e-bike-2-line:before {
  content: "\ec76";
}

.ri-e-bike-fill:before {
  content: "\ec77";
}

.ri-e-bike-line:before {
  content: "\ec78";
}

.ri-earth-fill:before {
  content: "\ec79";
}

.ri-earth-line:before {
  content: "\ec7a";
}

.ri-earthquake-fill:before {
  content: "\ec7b";
}

.ri-earthquake-line:before {
  content: "\ec7c";
}

.ri-edge-fill:before {
  content: "\ec7d";
}

.ri-edge-line:before {
  content: "\ec7e";
}

.ri-edit-2-fill:before {
  content: "\ec7f";
}

.ri-edit-2-line:before {
  content: "\ec80";
}

.ri-edit-box-fill:before {
  content: "\ec81";
}

.ri-edit-box-line:before {
  content: "\ec82";
}

.ri-edit-circle-fill:before {
  content: "\ec83";
}

.ri-edit-circle-line:before {
  content: "\ec84";
}

.ri-edit-fill:before {
  content: "\ec85";
}

.ri-edit-line:before {
  content: "\ec86";
}

.ri-eject-fill:before {
  content: "\ec87";
}

.ri-eject-line:before {
  content: "\ec88";
}

.ri-emotion-2-fill:before {
  content: "\ec89";
}

.ri-emotion-2-line:before {
  content: "\ec8a";
}

.ri-emotion-fill:before {
  content: "\ec8b";
}

.ri-emotion-happy-fill:before {
  content: "\ec8c";
}

.ri-emotion-happy-line:before {
  content: "\ec8d";
}

.ri-emotion-laugh-fill:before {
  content: "\ec8e";
}

.ri-emotion-laugh-line:before {
  content: "\ec8f";
}

.ri-emotion-line:before {
  content: "\ec90";
}

.ri-emotion-normal-fill:before {
  content: "\ec91";
}

.ri-emotion-normal-line:before {
  content: "\ec92";
}

.ri-emotion-sad-fill:before {
  content: "\ec93";
}

.ri-emotion-sad-line:before {
  content: "\ec94";
}

.ri-emotion-unhappy-fill:before {
  content: "\ec95";
}

.ri-emotion-unhappy-line:before {
  content: "\ec96";
}

.ri-empathize-fill:before {
  content: "\ec97";
}

.ri-empathize-line:before {
  content: "\ec98";
}

.ri-emphasis-cn:before {
  content: "\ec99";
}

.ri-emphasis:before {
  content: "\ec9a";
}

.ri-english-input:before {
  content: "\ec9b";
}

.ri-equalizer-fill:before {
  content: "\ec9c";
}

.ri-equalizer-line:before {
  content: "\ec9d";
}

.ri-eraser-fill:before {
  content: "\ec9e";
}

.ri-eraser-line:before {
  content: "\ec9f";
}

.ri-error-warning-fill:before {
  content: "\eca0";
}

.ri-error-warning-line:before {
  content: "\eca1";
}

.ri-evernote-fill:before {
  content: "\eca2";
}

.ri-evernote-line:before {
  content: "\eca3";
}

.ri-exchange-box-fill:before {
  content: "\eca4";
}

.ri-exchange-box-line:before {
  content: "\eca5";
}

.ri-exchange-cny-fill:before {
  content: "\eca6";
}

.ri-exchange-cny-line:before {
  content: "\eca7";
}

.ri-exchange-dollar-fill:before {
  content: "\eca8";
}

.ri-exchange-dollar-line:before {
  content: "\eca9";
}

.ri-exchange-fill:before {
  content: "\ecaa";
}

.ri-exchange-funds-fill:before {
  content: "\ecab";
}

.ri-exchange-funds-line:before {
  content: "\ecac";
}

.ri-exchange-line:before {
  content: "\ecad";
}

.ri-external-link-fill:before {
  content: "\ecae";
}

.ri-external-link-line:before {
  content: "\ecaf";
}

.ri-eye-2-fill:before {
  content: "\ecb0";
}

.ri-eye-2-line:before {
  content: "\ecb1";
}

.ri-eye-close-fill:before {
  content: "\ecb2";
}

.ri-eye-close-line:before {
  content: "\ecb3";
}

.ri-eye-fill:before {
  content: "\ecb4";
}

.ri-eye-line:before {
  content: "\ecb5";
}

.ri-eye-off-fill:before {
  content: "\ecb6";
}

.ri-eye-off-line:before {
  content: "\ecb7";
}

.ri-facebook-box-fill:before {
  content: "\ecb8";
}

.ri-facebook-box-line:before {
  content: "\ecb9";
}

.ri-facebook-circle-fill:before {
  content: "\ecba";
}

.ri-facebook-circle-line:before {
  content: "\ecbb";
}

.ri-facebook-fill:before {
  content: "\ecbc";
}

.ri-facebook-line:before {
  content: "\ecbd";
}

.ri-fahrenheit-fill:before {
  content: "\ecbe";
}

.ri-fahrenheit-line:before {
  content: "\ecbf";
}

.ri-feedback-fill:before {
  content: "\ecc0";
}

.ri-feedback-line:before {
  content: "\ecc1";
}

.ri-file-2-fill:before {
  content: "\ecc2";
}

.ri-file-2-line:before {
  content: "\ecc3";
}

.ri-file-3-fill:before {
  content: "\ecc4";
}

.ri-file-3-line:before {
  content: "\ecc5";
}

.ri-file-4-fill:before {
  content: "\ecc6";
}

.ri-file-4-line:before {
  content: "\ecc7";
}

.ri-file-add-fill:before {
  content: "\ecc8";
}

.ri-file-add-line:before {
  content: "\ecc9";
}

.ri-file-chart-2-fill:before {
  content: "\ecca";
}

.ri-file-chart-2-line:before {
  content: "\eccb";
}

.ri-file-chart-fill:before {
  content: "\eccc";
}

.ri-file-chart-line:before {
  content: "\eccd";
}

.ri-file-cloud-fill:before {
  content: "\ecce";
}

.ri-file-cloud-line:before {
  content: "\eccf";
}

.ri-file-code-fill:before {
  content: "\ecd0";
}

.ri-file-code-line:before {
  content: "\ecd1";
}

.ri-file-copy-2-fill:before {
  content: "\ecd2";
}

.ri-file-copy-2-line:before {
  content: "\ecd3";
}

.ri-file-copy-fill:before {
  content: "\ecd4";
}

.ri-file-copy-line:before {
  content: "\ecd5";
}

.ri-file-damage-fill:before {
  content: "\ecd6";
}

.ri-file-damage-line:before {
  content: "\ecd7";
}

.ri-file-download-fill:before {
  content: "\ecd8";
}

.ri-file-download-line:before {
  content: "\ecd9";
}

.ri-file-edit-fill:before {
  content: "\ecda";
}

.ri-file-edit-line:before {
  content: "\ecdb";
}

.ri-file-excel-2-fill:before {
  content: "\ecdc";
}

.ri-file-excel-2-line:before {
  content: "\ecdd";
}

.ri-file-excel-fill:before {
  content: "\ecde";
}

.ri-file-excel-line:before {
  content: "\ecdf";
}

.ri-file-fill:before {
  content: "\ece0";
}

.ri-file-forbid-fill:before {
  content: "\ece1";
}

.ri-file-forbid-line:before {
  content: "\ece2";
}

.ri-file-gif-fill:before {
  content: "\ece3";
}

.ri-file-gif-line:before {
  content: "\ece4";
}

.ri-file-history-fill:before {
  content: "\ece5";
}

.ri-file-history-line:before {
  content: "\ece6";
}

.ri-file-hwp-fill:before {
  content: "\ece7";
}

.ri-file-hwp-line:before {
  content: "\ece8";
}

.ri-file-info-fill:before {
  content: "\ece9";
}

.ri-file-info-line:before {
  content: "\ecea";
}

.ri-file-line:before {
  content: "\eceb";
}

.ri-file-list-2-fill:before {
  content: "\ecec";
}

.ri-file-list-2-line:before {
  content: "\eced";
}

.ri-file-list-3-fill:before {
  content: "\ecee";
}

.ri-file-list-3-line:before {
  content: "\ecef";
}

.ri-file-list-fill:before {
  content: "\ecf0";
}

.ri-file-list-line:before {
  content: "\ecf1";
}

.ri-file-lock-fill:before {
  content: "\ecf2";
}

.ri-file-lock-line:before {
  content: "\ecf3";
}

.ri-file-mark-fill:before {
  content: "\ecf4";
}

.ri-file-mark-line:before {
  content: "\ecf5";
}

.ri-file-music-fill:before {
  content: "\ecf6";
}

.ri-file-music-line:before {
  content: "\ecf7";
}

.ri-file-paper-2-fill:before {
  content: "\ecf8";
}

.ri-file-paper-2-line:before {
  content: "\ecf9";
}

.ri-file-paper-fill:before {
  content: "\ecfa";
}

.ri-file-paper-line:before {
  content: "\ecfb";
}

.ri-file-pdf-fill:before {
  content: "\ecfc";
}

.ri-file-pdf-line:before {
  content: "\ecfd";
}

.ri-file-ppt-2-fill:before {
  content: "\ecfe";
}

.ri-file-ppt-2-line:before {
  content: "\ecff";
}

.ri-file-ppt-fill:before {
  content: "\ed00";
}

.ri-file-ppt-line:before {
  content: "\ed01";
}

.ri-file-reduce-fill:before {
  content: "\ed02";
}

.ri-file-reduce-line:before {
  content: "\ed03";
}

.ri-file-search-fill:before {
  content: "\ed04";
}

.ri-file-search-line:before {
  content: "\ed05";
}

.ri-file-settings-fill:before {
  content: "\ed06";
}

.ri-file-settings-line:before {
  content: "\ed07";
}

.ri-file-shield-2-fill:before {
  content: "\ed08";
}

.ri-file-shield-2-line:before {
  content: "\ed09";
}

.ri-file-shield-fill:before {
  content: "\ed0a";
}

.ri-file-shield-line:before {
  content: "\ed0b";
}

.ri-file-shred-fill:before {
  content: "\ed0c";
}

.ri-file-shred-line:before {
  content: "\ed0d";
}

.ri-file-text-fill:before {
  content: "\ed0e";
}

.ri-file-text-line:before {
  content: "\ed0f";
}

.ri-file-transfer-fill:before {
  content: "\ed10";
}

.ri-file-transfer-line:before {
  content: "\ed11";
}

.ri-file-unknow-fill:before {
  content: "\ed12";
}

.ri-file-unknow-line:before {
  content: "\ed13";
}

.ri-file-upload-fill:before {
  content: "\ed14";
}

.ri-file-upload-line:before {
  content: "\ed15";
}

.ri-file-user-fill:before {
  content: "\ed16";
}

.ri-file-user-line:before {
  content: "\ed17";
}

.ri-file-warning-fill:before {
  content: "\ed18";
}

.ri-file-warning-line:before {
  content: "\ed19";
}

.ri-file-word-2-fill:before {
  content: "\ed1a";
}

.ri-file-word-2-line:before {
  content: "\ed1b";
}

.ri-file-word-fill:before {
  content: "\ed1c";
}

.ri-file-word-line:before {
  content: "\ed1d";
}

.ri-file-zip-fill:before {
  content: "\ed1e";
}

.ri-file-zip-line:before {
  content: "\ed1f";
}

.ri-film-fill:before {
  content: "\ed20";
}

.ri-film-line:before {
  content: "\ed21";
}

.ri-filter-2-fill:before {
  content: "\ed22";
}

.ri-filter-2-line:before {
  content: "\ed23";
}

.ri-filter-3-fill:before {
  content: "\ed24";
}

.ri-filter-3-line:before {
  content: "\ed25";
}

.ri-filter-fill:before {
  content: "\ed26";
}

.ri-filter-line:before {
  content: "\ed27";
}

.ri-filter-off-fill:before {
  content: "\ed28";
}

.ri-filter-off-line:before {
  content: "\ed29";
}

.ri-find-replace-fill:before {
  content: "\ed2a";
}

.ri-find-replace-line:before {
  content: "\ed2b";
}

.ri-finder-fill:before {
  content: "\ed2c";
}

.ri-finder-line:before {
  content: "\ed2d";
}

.ri-fingerprint-2-fill:before {
  content: "\ed2e";
}

.ri-fingerprint-2-line:before {
  content: "\ed2f";
}

.ri-fingerprint-fill:before {
  content: "\ed30";
}

.ri-fingerprint-line:before {
  content: "\ed31";
}

.ri-fire-fill:before {
  content: "\ed32";
}

.ri-fire-line:before {
  content: "\ed33";
}

.ri-firefox-fill:before {
  content: "\ed34";
}

.ri-firefox-line:before {
  content: "\ed35";
}

.ri-first-aid-kit-fill:before {
  content: "\ed36";
}

.ri-first-aid-kit-line:before {
  content: "\ed37";
}

.ri-flag-2-fill:before {
  content: "\ed38";
}

.ri-flag-2-line:before {
  content: "\ed39";
}

.ri-flag-fill:before {
  content: "\ed3a";
}

.ri-flag-line:before {
  content: "\ed3b";
}

.ri-flashlight-fill:before {
  content: "\ed3c";
}

.ri-flashlight-line:before {
  content: "\ed3d";
}

.ri-flask-fill:before {
  content: "\ed3e";
}

.ri-flask-line:before {
  content: "\ed3f";
}

.ri-flight-land-fill:before {
  content: "\ed40";
}

.ri-flight-land-line:before {
  content: "\ed41";
}

.ri-flight-takeoff-fill:before {
  content: "\ed42";
}

.ri-flight-takeoff-line:before {
  content: "\ed43";
}

.ri-flood-fill:before {
  content: "\ed44";
}

.ri-flood-line:before {
  content: "\ed45";
}

.ri-flow-chart:before {
  content: "\ed46";
}

.ri-flutter-fill:before {
  content: "\ed47";
}

.ri-flutter-line:before {
  content: "\ed48";
}

.ri-focus-2-fill:before {
  content: "\ed49";
}

.ri-focus-2-line:before {
  content: "\ed4a";
}

.ri-focus-3-fill:before {
  content: "\ed4b";
}

.ri-focus-3-line:before {
  content: "\ed4c";
}

.ri-focus-fill:before {
  content: "\ed4d";
}

.ri-focus-line:before {
  content: "\ed4e";
}

.ri-foggy-fill:before {
  content: "\ed4f";
}

.ri-foggy-line:before {
  content: "\ed50";
}

.ri-folder-2-fill:before {
  content: "\ed51";
}

.ri-folder-2-line:before {
  content: "\ed52";
}

.ri-folder-3-fill:before {
  content: "\ed53";
}

.ri-folder-3-line:before {
  content: "\ed54";
}

.ri-folder-4-fill:before {
  content: "\ed55";
}

.ri-folder-4-line:before {
  content: "\ed56";
}

.ri-folder-5-fill:before {
  content: "\ed57";
}

.ri-folder-5-line:before {
  content: "\ed58";
}

.ri-folder-add-fill:before {
  content: "\ed59";
}

.ri-folder-add-line:before {
  content: "\ed5a";
}

.ri-folder-chart-2-fill:before {
  content: "\ed5b";
}

.ri-folder-chart-2-line:before {
  content: "\ed5c";
}

.ri-folder-chart-fill:before {
  content: "\ed5d";
}

.ri-folder-chart-line:before {
  content: "\ed5e";
}

.ri-folder-download-fill:before {
  content: "\ed5f";
}

.ri-folder-download-line:before {
  content: "\ed60";
}

.ri-folder-fill:before {
  content: "\ed61";
}

.ri-folder-forbid-fill:before {
  content: "\ed62";
}

.ri-folder-forbid-line:before {
  content: "\ed63";
}

.ri-folder-history-fill:before {
  content: "\ed64";
}

.ri-folder-history-line:before {
  content: "\ed65";
}

.ri-folder-info-fill:before {
  content: "\ed66";
}

.ri-folder-info-line:before {
  content: "\ed67";
}

.ri-folder-keyhole-fill:before {
  content: "\ed68";
}

.ri-folder-keyhole-line:before {
  content: "\ed69";
}

.ri-folder-line:before {
  content: "\ed6a";
}

.ri-folder-lock-fill:before {
  content: "\ed6b";
}

.ri-folder-lock-line:before {
  content: "\ed6c";
}

.ri-folder-music-fill:before {
  content: "\ed6d";
}

.ri-folder-music-line:before {
  content: "\ed6e";
}

.ri-folder-open-fill:before {
  content: "\ed6f";
}

.ri-folder-open-line:before {
  content: "\ed70";
}

.ri-folder-received-fill:before {
  content: "\ed71";
}

.ri-folder-received-line:before {
  content: "\ed72";
}

.ri-folder-reduce-fill:before {
  content: "\ed73";
}

.ri-folder-reduce-line:before {
  content: "\ed74";
}

.ri-folder-settings-fill:before {
  content: "\ed75";
}

.ri-folder-settings-line:before {
  content: "\ed76";
}

.ri-folder-shared-fill:before {
  content: "\ed77";
}

.ri-folder-shared-line:before {
  content: "\ed78";
}

.ri-folder-shield-2-fill:before {
  content: "\ed79";
}

.ri-folder-shield-2-line:before {
  content: "\ed7a";
}

.ri-folder-shield-fill:before {
  content: "\ed7b";
}

.ri-folder-shield-line:before {
  content: "\ed7c";
}

.ri-folder-transfer-fill:before {
  content: "\ed7d";
}

.ri-folder-transfer-line:before {
  content: "\ed7e";
}

.ri-folder-unknow-fill:before {
  content: "\ed7f";
}

.ri-folder-unknow-line:before {
  content: "\ed80";
}

.ri-folder-upload-fill:before {
  content: "\ed81";
}

.ri-folder-upload-line:before {
  content: "\ed82";
}

.ri-folder-user-fill:before {
  content: "\ed83";
}

.ri-folder-user-line:before {
  content: "\ed84";
}

.ri-folder-warning-fill:before {
  content: "\ed85";
}

.ri-folder-warning-line:before {
  content: "\ed86";
}

.ri-folder-zip-fill:before {
  content: "\ed87";
}

.ri-folder-zip-line:before {
  content: "\ed88";
}

.ri-folders-fill:before {
  content: "\ed89";
}

.ri-folders-line:before {
  content: "\ed8a";
}

.ri-font-color:before {
  content: "\ed8b";
}

.ri-font-size-2:before {
  content: "\ed8c";
}

.ri-font-size:before {
  content: "\ed8d";
}

.ri-football-fill:before {
  content: "\ed8e";
}

.ri-football-line:before {
  content: "\ed8f";
}

.ri-footprint-fill:before {
  content: "\ed90";
}

.ri-footprint-line:before {
  content: "\ed91";
}

.ri-forbid-2-fill:before {
  content: "\ed92";
}

.ri-forbid-2-line:before {
  content: "\ed93";
}

.ri-forbid-fill:before {
  content: "\ed94";
}

.ri-forbid-line:before {
  content: "\ed95";
}

.ri-format-clear:before {
  content: "\ed96";
}

.ri-fridge-fill:before {
  content: "\ed97";
}

.ri-fridge-line:before {
  content: "\ed98";
}

.ri-fullscreen-exit-fill:before {
  content: "\ed99";
}

.ri-fullscreen-exit-line:before {
  content: "\ed9a";
}

.ri-fullscreen-fill:before {
  content: "\ed9b";
}

.ri-fullscreen-line:before {
  content: "\ed9c";
}

.ri-function-fill:before {
  content: "\ed9d";
}

.ri-function-line:before {
  content: "\ed9e";
}

.ri-functions:before {
  content: "\ed9f";
}

.ri-funds-box-fill:before {
  content: "\eda0";
}

.ri-funds-box-line:before {
  content: "\eda1";
}

.ri-funds-fill:before {
  content: "\eda2";
}

.ri-funds-line:before {
  content: "\eda3";
}

.ri-gallery-fill:before {
  content: "\eda4";
}

.ri-gallery-line:before {
  content: "\eda5";
}

.ri-gallery-upload-fill:before {
  content: "\eda6";
}

.ri-gallery-upload-line:before {
  content: "\eda7";
}

.ri-game-fill:before {
  content: "\eda8";
}

.ri-game-line:before {
  content: "\eda9";
}

.ri-gamepad-fill:before {
  content: "\edaa";
}

.ri-gamepad-line:before {
  content: "\edab";
}

.ri-gas-station-fill:before {
  content: "\edac";
}

.ri-gas-station-line:before {
  content: "\edad";
}

.ri-gatsby-fill:before {
  content: "\edae";
}

.ri-gatsby-line:before {
  content: "\edaf";
}

.ri-genderless-fill:before {
  content: "\edb0";
}

.ri-genderless-line:before {
  content: "\edb1";
}

.ri-ghost-2-fill:before {
  content: "\edb2";
}

.ri-ghost-2-line:before {
  content: "\edb3";
}

.ri-ghost-fill:before {
  content: "\edb4";
}

.ri-ghost-line:before {
  content: "\edb5";
}

.ri-ghost-smile-fill:before {
  content: "\edb6";
}

.ri-ghost-smile-line:before {
  content: "\edb7";
}

.ri-gift-2-fill:before {
  content: "\edb8";
}

.ri-gift-2-line:before {
  content: "\edb9";
}

.ri-gift-fill:before {
  content: "\edba";
}

.ri-gift-line:before {
  content: "\edbb";
}

.ri-git-branch-fill:before {
  content: "\edbc";
}

.ri-git-branch-line:before {
  content: "\edbd";
}

.ri-git-commit-fill:before {
  content: "\edbe";
}

.ri-git-commit-line:before {
  content: "\edbf";
}

.ri-git-merge-fill:before {
  content: "\edc0";
}

.ri-git-merge-line:before {
  content: "\edc1";
}

.ri-git-pull-request-fill:before {
  content: "\edc2";
}

.ri-git-pull-request-line:before {
  content: "\edc3";
}

.ri-git-repository-commits-fill:before {
  content: "\edc4";
}

.ri-git-repository-commits-line:before {
  content: "\edc5";
}

.ri-git-repository-fill:before {
  content: "\edc6";
}

.ri-git-repository-line:before {
  content: "\edc7";
}

.ri-git-repository-private-fill:before {
  content: "\edc8";
}

.ri-git-repository-private-line:before {
  content: "\edc9";
}

.ri-github-fill:before {
  content: "\edca";
}

.ri-github-line:before {
  content: "\edcb";
}

.ri-gitlab-fill:before {
  content: "\edcc";
}

.ri-gitlab-line:before {
  content: "\edcd";
}

.ri-global-fill:before {
  content: "\edce";
}

.ri-global-line:before {
  content: "\edcf";
}

.ri-globe-fill:before {
  content: "\edd0";
}

.ri-globe-line:before {
  content: "\edd1";
}

.ri-goblet-fill:before {
  content: "\edd2";
}

.ri-goblet-line:before {
  content: "\edd3";
}

.ri-google-fill:before {
  content: "\edd4";
}

.ri-google-line:before {
  content: "\edd5";
}

.ri-google-play-fill:before {
  content: "\edd6";
}

.ri-google-play-line:before {
  content: "\edd7";
}

.ri-government-fill:before {
  content: "\edd8";
}

.ri-government-line:before {
  content: "\edd9";
}

.ri-gps-fill:before {
  content: "\edda";
}

.ri-gps-line:before {
  content: "\eddb";
}

.ri-gradienter-fill:before {
  content: "\eddc";
}

.ri-gradienter-line:before {
  content: "\eddd";
}

.ri-grid-fill:before {
  content: "\edde";
}

.ri-grid-line:before {
  content: "\eddf";
}

.ri-group-2-fill:before {
  content: "\ede0";
}

.ri-group-2-line:before {
  content: "\ede1";
}

.ri-group-fill:before {
  content: "\ede2";
}

.ri-group-line:before {
  content: "\ede3";
}

.ri-guide-fill:before {
  content: "\ede4";
}

.ri-guide-line:before {
  content: "\ede5";
}

.ri-h-1:before {
  content: "\ede6";
}

.ri-h-2:before {
  content: "\ede7";
}

.ri-h-3:before {
  content: "\ede8";
}

.ri-h-4:before {
  content: "\ede9";
}

.ri-h-5:before {
  content: "\edea";
}

.ri-h-6:before {
  content: "\edeb";
}

.ri-hail-fill:before {
  content: "\edec";
}

.ri-hail-line:before {
  content: "\eded";
}

.ri-hammer-fill:before {
  content: "\edee";
}

.ri-hammer-line:before {
  content: "\edef";
}

.ri-hand-coin-fill:before {
  content: "\edf0";
}

.ri-hand-coin-line:before {
  content: "\edf1";
}

.ri-hand-heart-fill:before {
  content: "\edf2";
}

.ri-hand-heart-line:before {
  content: "\edf3";
}

.ri-hand-sanitizer-fill:before {
  content: "\edf4";
}

.ri-hand-sanitizer-line:before {
  content: "\edf5";
}

.ri-handbag-fill:before {
  content: "\edf6";
}

.ri-handbag-line:before {
  content: "\edf7";
}

.ri-hard-drive-2-fill:before {
  content: "\edf8";
}

.ri-hard-drive-2-line:before {
  content: "\edf9";
}

.ri-hard-drive-fill:before {
  content: "\edfa";
}

.ri-hard-drive-line:before {
  content: "\edfb";
}

.ri-hashtag:before {
  content: "\edfc";
}

.ri-haze-2-fill:before {
  content: "\edfd";
}

.ri-haze-2-line:before {
  content: "\edfe";
}

.ri-haze-fill:before {
  content: "\edff";
}

.ri-haze-line:before {
  content: "\ee00";
}

.ri-hd-fill:before {
  content: "\ee01";
}

.ri-hd-line:before {
  content: "\ee02";
}

.ri-heading:before {
  content: "\ee03";
}

.ri-headphone-fill:before {
  content: "\ee04";
}

.ri-headphone-line:before {
  content: "\ee05";
}

.ri-health-book-fill:before {
  content: "\ee06";
}

.ri-health-book-line:before {
  content: "\ee07";
}

.ri-heart-2-fill:before {
  content: "\ee08";
}

.ri-heart-2-line:before {
  content: "\ee09";
}

.ri-heart-3-fill:before {
  content: "\ee0a";
}

.ri-heart-3-line:before {
  content: "\ee0b";
}

.ri-heart-add-fill:before {
  content: "\ee0c";
}

.ri-heart-add-line:before {
  content: "\ee0d";
}

.ri-heart-fill:before {
  content: "\ee0e";
}

.ri-heart-line:before {
  content: "\ee0f";
}

.ri-heart-pulse-fill:before {
  content: "\ee10";
}

.ri-heart-pulse-line:before {
  content: "\ee11";
}

.ri-hearts-fill:before {
  content: "\ee12";
}

.ri-hearts-line:before {
  content: "\ee13";
}

.ri-heavy-showers-fill:before {
  content: "\ee14";
}

.ri-heavy-showers-line:before {
  content: "\ee15";
}

.ri-history-fill:before {
  content: "\ee16";
}

.ri-history-line:before {
  content: "\ee17";
}

.ri-home-2-fill:before {
  content: "\ee18";
}

.ri-home-2-line:before {
  content: "\ee19";
}

.ri-home-3-fill:before {
  content: "\ee1a";
}

.ri-home-3-line:before {
  content: "\ee1b";
}

.ri-home-4-fill:before {
  content: "\ee1c";
}

.ri-home-4-line:before {
  content: "\ee1d";
}

.ri-home-5-fill:before {
  content: "\ee1e";
}

.ri-home-5-line:before {
  content: "\ee1f";
}

.ri-home-6-fill:before {
  content: "\ee20";
}

.ri-home-6-line:before {
  content: "\ee21";
}

.ri-home-7-fill:before {
  content: "\ee22";
}

.ri-home-7-line:before {
  content: "\ee23";
}

.ri-home-8-fill:before {
  content: "\ee24";
}

.ri-home-8-line:before {
  content: "\ee25";
}

.ri-home-fill:before {
  content: "\ee26";
}

.ri-home-gear-fill:before {
  content: "\ee27";
}

.ri-home-gear-line:before {
  content: "\ee28";
}

.ri-home-heart-fill:before {
  content: "\ee29";
}

.ri-home-heart-line:before {
  content: "\ee2a";
}

.ri-home-line:before {
  content: "\ee2b";
}

.ri-home-smile-2-fill:before {
  content: "\ee2c";
}

.ri-home-smile-2-line:before {
  content: "\ee2d";
}

.ri-home-smile-fill:before {
  content: "\ee2e";
}

.ri-home-smile-line:before {
  content: "\ee2f";
}

.ri-home-wifi-fill:before {
  content: "\ee30";
}

.ri-home-wifi-line:before {
  content: "\ee31";
}

.ri-honor-of-kings-fill:before {
  content: "\ee32";
}

.ri-honor-of-kings-line:before {
  content: "\ee33";
}

.ri-honour-fill:before {
  content: "\ee34";
}

.ri-honour-line:before {
  content: "\ee35";
}

.ri-hospital-fill:before {
  content: "\ee36";
}

.ri-hospital-line:before {
  content: "\ee37";
}

.ri-hotel-bed-fill:before {
  content: "\ee38";
}

.ri-hotel-bed-line:before {
  content: "\ee39";
}

.ri-hotel-fill:before {
  content: "\ee3a";
}

.ri-hotel-line:before {
  content: "\ee3b";
}

.ri-hotspot-fill:before {
  content: "\ee3c";
}

.ri-hotspot-line:before {
  content: "\ee3d";
}

.ri-hq-fill:before {
  content: "\ee3e";
}

.ri-hq-line:before {
  content: "\ee3f";
}

.ri-html5-fill:before {
  content: "\ee40";
}

.ri-html5-line:before {
  content: "\ee41";
}

.ri-ie-fill:before {
  content: "\ee42";
}

.ri-ie-line:before {
  content: "\ee43";
}

.ri-image-2-fill:before {
  content: "\ee44";
}

.ri-image-2-line:before {
  content: "\ee45";
}

.ri-image-add-fill:before {
  content: "\ee46";
}

.ri-image-add-line:before {
  content: "\ee47";
}

.ri-image-edit-fill:before {
  content: "\ee48";
}

.ri-image-edit-line:before {
  content: "\ee49";
}

.ri-image-fill:before {
  content: "\ee4a";
}

.ri-image-line:before {
  content: "\ee4b";
}

.ri-inbox-archive-fill:before {
  content: "\ee4c";
}

.ri-inbox-archive-line:before {
  content: "\ee4d";
}

.ri-inbox-fill:before {
  content: "\ee4e";
}

.ri-inbox-line:before {
  content: "\ee4f";
}

.ri-inbox-unarchive-fill:before {
  content: "\ee50";
}

.ri-inbox-unarchive-line:before {
  content: "\ee51";
}

.ri-increase-decrease-fill:before {
  content: "\ee52";
}

.ri-increase-decrease-line:before {
  content: "\ee53";
}

.ri-indent-decrease:before {
  content: "\ee54";
}

.ri-indent-increase:before {
  content: "\ee55";
}

.ri-indeterminate-circle-fill:before {
  content: "\ee56";
}

.ri-indeterminate-circle-line:before {
  content: "\ee57";
}

.ri-information-fill:before {
  content: "\ee58";
}

.ri-information-line:before {
  content: "\ee59";
}

.ri-infrared-thermometer-fill:before {
  content: "\ee5a";
}

.ri-infrared-thermometer-line:before {
  content: "\ee5b";
}

.ri-ink-bottle-fill:before {
  content: "\ee5c";
}

.ri-ink-bottle-line:before {
  content: "\ee5d";
}

.ri-input-cursor-move:before {
  content: "\ee5e";
}

.ri-input-method-fill:before {
  content: "\ee5f";
}

.ri-input-method-line:before {
  content: "\ee60";
}

.ri-insert-column-left:before {
  content: "\ee61";
}

.ri-insert-column-right:before {
  content: "\ee62";
}

.ri-insert-row-bottom:before {
  content: "\ee63";
}

.ri-insert-row-top:before {
  content: "\ee64";
}

.ri-instagram-fill:before {
  content: "\ee65";
}

.ri-instagram-line:before {
  content: "\ee66";
}

.ri-install-fill:before {
  content: "\ee67";
}

.ri-install-line:before {
  content: "\ee68";
}

.ri-invision-fill:before {
  content: "\ee69";
}

.ri-invision-line:before {
  content: "\ee6a";
}

.ri-italic:before {
  content: "\ee6b";
}

.ri-kakao-talk-fill:before {
  content: "\ee6c";
}

.ri-kakao-talk-line:before {
  content: "\ee6d";
}

.ri-key-2-fill:before {
  content: "\ee6e";
}

.ri-key-2-line:before {
  content: "\ee6f";
}

.ri-key-fill:before {
  content: "\ee70";
}

.ri-key-line:before {
  content: "\ee71";
}

.ri-keyboard-box-fill:before {
  content: "\ee72";
}

.ri-keyboard-box-line:before {
  content: "\ee73";
}

.ri-keyboard-fill:before {
  content: "\ee74";
}

.ri-keyboard-line:before {
  content: "\ee75";
}

.ri-keynote-fill:before {
  content: "\ee76";
}

.ri-keynote-line:before {
  content: "\ee77";
}

.ri-knife-blood-fill:before {
  content: "\ee78";
}

.ri-knife-blood-line:before {
  content: "\ee79";
}

.ri-knife-fill:before {
  content: "\ee7a";
}

.ri-knife-line:before {
  content: "\ee7b";
}

.ri-landscape-fill:before {
  content: "\ee7c";
}

.ri-landscape-line:before {
  content: "\ee7d";
}

.ri-layout-2-fill:before {
  content: "\ee7e";
}

.ri-layout-2-line:before {
  content: "\ee7f";
}

.ri-layout-3-fill:before {
  content: "\ee80";
}

.ri-layout-3-line:before {
  content: "\ee81";
}

.ri-layout-4-fill:before {
  content: "\ee82";
}

.ri-layout-4-line:before {
  content: "\ee83";
}

.ri-layout-5-fill:before {
  content: "\ee84";
}

.ri-layout-5-line:before {
  content: "\ee85";
}

.ri-layout-6-fill:before {
  content: "\ee86";
}

.ri-layout-6-line:before {
  content: "\ee87";
}

.ri-layout-bottom-2-fill:before {
  content: "\ee88";
}

.ri-layout-bottom-2-line:before {
  content: "\ee89";
}

.ri-layout-bottom-fill:before {
  content: "\ee8a";
}

.ri-layout-bottom-line:before {
  content: "\ee8b";
}

.ri-layout-column-fill:before {
  content: "\ee8c";
}

.ri-layout-column-line:before {
  content: "\ee8d";
}

.ri-layout-fill:before {
  content: "\ee8e";
}

.ri-layout-grid-fill:before {
  content: "\ee8f";
}

.ri-layout-grid-line:before {
  content: "\ee90";
}

.ri-layout-left-2-fill:before {
  content: "\ee91";
}

.ri-layout-left-2-line:before {
  content: "\ee92";
}

.ri-layout-left-fill:before {
  content: "\ee93";
}

.ri-layout-left-line:before {
  content: "\ee94";
}

.ri-layout-line:before {
  content: "\ee95";
}

.ri-layout-masonry-fill:before {
  content: "\ee96";
}

.ri-layout-masonry-line:before {
  content: "\ee97";
}

.ri-layout-right-2-fill:before {
  content: "\ee98";
}

.ri-layout-right-2-line:before {
  content: "\ee99";
}

.ri-layout-right-fill:before {
  content: "\ee9a";
}

.ri-layout-right-line:before {
  content: "\ee9b";
}

.ri-layout-row-fill:before {
  content: "\ee9c";
}

.ri-layout-row-line:before {
  content: "\ee9d";
}

.ri-layout-top-2-fill:before {
  content: "\ee9e";
}

.ri-layout-top-2-line:before {
  content: "\ee9f";
}

.ri-layout-top-fill:before {
  content: "\eea0";
}

.ri-layout-top-line:before {
  content: "\eea1";
}

.ri-leaf-fill:before {
  content: "\eea2";
}

.ri-leaf-line:before {
  content: "\eea3";
}

.ri-lifebuoy-fill:before {
  content: "\eea4";
}

.ri-lifebuoy-line:before {
  content: "\eea5";
}

.ri-lightbulb-fill:before {
  content: "\eea6";
}

.ri-lightbulb-flash-fill:before {
  content: "\eea7";
}

.ri-lightbulb-flash-line:before {
  content: "\eea8";
}

.ri-lightbulb-line:before {
  content: "\eea9";
}

.ri-line-chart-fill:before {
  content: "\eeaa";
}

.ri-line-chart-line:before {
  content: "\eeab";
}

.ri-line-fill:before {
  content: "\eeac";
}

.ri-line-height:before {
  content: "\eead";
}

.ri-line-line:before {
  content: "\eeae";
}

.ri-link-m:before {
  content: "\eeaf";
}

.ri-link-unlink-m:before {
  content: "\eeb0";
}

.ri-link-unlink:before {
  content: "\eeb1";
}

.ri-link:before {
  content: "\eeb2";
}

.ri-linkedin-box-fill:before {
  content: "\eeb3";
}

.ri-linkedin-box-line:before {
  content: "\eeb4";
}

.ri-linkedin-fill:before {
  content: "\eeb5";
}

.ri-linkedin-line:before {
  content: "\eeb6";
}

.ri-links-fill:before {
  content: "\eeb7";
}

.ri-links-line:before {
  content: "\eeb8";
}

.ri-list-check-2:before {
  content: "\eeb9";
}

.ri-list-check:before {
  content: "\eeba";
}

.ri-list-ordered:before {
  content: "\eebb";
}

.ri-list-settings-fill:before {
  content: "\eebc";
}

.ri-list-settings-line:before {
  content: "\eebd";
}

.ri-list-unordered:before {
  content: "\eebe";
}

.ri-live-fill:before {
  content: "\eebf";
}

.ri-live-line:before {
  content: "\eec0";
}

.ri-loader-2-fill:before {
  content: "\eec1";
}

.ri-loader-2-line:before {
  content: "\eec2";
}

.ri-loader-3-fill:before {
  content: "\eec3";
}

.ri-loader-3-line:before {
  content: "\eec4";
}

.ri-loader-4-fill:before {
  content: "\eec5";
}

.ri-loader-4-line:before {
  content: "\eec6";
}

.ri-loader-5-fill:before {
  content: "\eec7";
}

.ri-loader-5-line:before {
  content: "\eec8";
}

.ri-loader-fill:before {
  content: "\eec9";
}

.ri-loader-line:before {
  content: "\eeca";
}

.ri-lock-2-fill:before {
  content: "\eecb";
}

.ri-lock-2-line:before {
  content: "\eecc";
}

.ri-lock-fill:before {
  content: "\eecd";
}

.ri-lock-line:before {
  content: "\eece";
}

.ri-lock-password-fill:before {
  content: "\eecf";
}

.ri-lock-password-line:before {
  content: "\eed0";
}

.ri-lock-unlock-fill:before {
  content: "\eed1";
}

.ri-lock-unlock-line:before {
  content: "\eed2";
}

.ri-login-box-fill:before {
  content: "\eed3";
}

.ri-login-box-line:before {
  content: "\eed4";
}

.ri-login-circle-fill:before {
  content: "\eed5";
}

.ri-login-circle-line:before {
  content: "\eed6";
}

.ri-logout-box-fill:before {
  content: "\eed7";
}

.ri-logout-box-line:before {
  content: "\eed8";
}

.ri-logout-box-r-fill:before {
  content: "\eed9";
}

.ri-logout-box-r-line:before {
  content: "\eeda";
}

.ri-logout-circle-fill:before {
  content: "\eedb";
}

.ri-logout-circle-line:before {
  content: "\eedc";
}

.ri-logout-circle-r-fill:before {
  content: "\eedd";
}

.ri-logout-circle-r-line:before {
  content: "\eede";
}

.ri-luggage-cart-fill:before {
  content: "\eedf";
}

.ri-luggage-cart-line:before {
  content: "\eee0";
}

.ri-luggage-deposit-fill:before {
  content: "\eee1";
}

.ri-luggage-deposit-line:before {
  content: "\eee2";
}

.ri-lungs-fill:before {
  content: "\eee3";
}

.ri-lungs-line:before {
  content: "\eee4";
}

.ri-mac-fill:before {
  content: "\eee5";
}

.ri-mac-line:before {
  content: "\eee6";
}

.ri-macbook-fill:before {
  content: "\eee7";
}

.ri-macbook-line:before {
  content: "\eee8";
}

.ri-magic-fill:before {
  content: "\eee9";
}

.ri-magic-line:before {
  content: "\eeea";
}

.ri-mail-add-fill:before {
  content: "\eeeb";
}

.ri-mail-add-line:before {
  content: "\eeec";
}

.ri-mail-check-fill:before {
  content: "\eeed";
}

.ri-mail-check-line:before {
  content: "\eeee";
}

.ri-mail-close-fill:before {
  content: "\eeef";
}

.ri-mail-close-line:before {
  content: "\eef0";
}

.ri-mail-download-fill:before {
  content: "\eef1";
}

.ri-mail-download-line:before {
  content: "\eef2";
}

.ri-mail-fill:before {
  content: "\eef3";
}

.ri-mail-forbid-fill:before {
  content: "\eef4";
}

.ri-mail-forbid-line:before {
  content: "\eef5";
}

.ri-mail-line:before {
  content: "\eef6";
}

.ri-mail-lock-fill:before {
  content: "\eef7";
}

.ri-mail-lock-line:before {
  content: "\eef8";
}

.ri-mail-open-fill:before {
  content: "\eef9";
}

.ri-mail-open-line:before {
  content: "\eefa";
}

.ri-mail-send-fill:before {
  content: "\eefb";
}

.ri-mail-send-line:before {
  content: "\eefc";
}

.ri-mail-settings-fill:before {
  content: "\eefd";
}

.ri-mail-settings-line:before {
  content: "\eefe";
}

.ri-mail-star-fill:before {
  content: "\eeff";
}

.ri-mail-star-line:before {
  content: "\ef00";
}

.ri-mail-unread-fill:before {
  content: "\ef01";
}

.ri-mail-unread-line:before {
  content: "\ef02";
}

.ri-mail-volume-fill:before {
  content: "\ef03";
}

.ri-mail-volume-line:before {
  content: "\ef04";
}

.ri-map-2-fill:before {
  content: "\ef05";
}

.ri-map-2-line:before {
  content: "\ef06";
}

.ri-map-fill:before {
  content: "\ef07";
}

.ri-map-line:before {
  content: "\ef08";
}

.ri-map-pin-2-fill:before {
  content: "\ef09";
}

.ri-map-pin-2-line:before {
  content: "\ef0a";
}

.ri-map-pin-3-fill:before {
  content: "\ef0b";
}

.ri-map-pin-3-line:before {
  content: "\ef0c";
}

.ri-map-pin-4-fill:before {
  content: "\ef0d";
}

.ri-map-pin-4-line:before {
  content: "\ef0e";
}

.ri-map-pin-5-fill:before {
  content: "\ef0f";
}

.ri-map-pin-5-line:before {
  content: "\ef10";
}

.ri-map-pin-add-fill:before {
  content: "\ef11";
}

.ri-map-pin-add-line:before {
  content: "\ef12";
}

.ri-map-pin-fill:before {
  content: "\ef13";
}

.ri-map-pin-line:before {
  content: "\ef14";
}

.ri-map-pin-range-fill:before {
  content: "\ef15";
}

.ri-map-pin-range-line:before {
  content: "\ef16";
}

.ri-map-pin-time-fill:before {
  content: "\ef17";
}

.ri-map-pin-time-line:before {
  content: "\ef18";
}

.ri-map-pin-user-fill:before {
  content: "\ef19";
}

.ri-map-pin-user-line:before {
  content: "\ef1a";
}

.ri-mark-pen-fill:before {
  content: "\ef1b";
}

.ri-mark-pen-line:before {
  content: "\ef1c";
}

.ri-markdown-fill:before {
  content: "\ef1d";
}

.ri-markdown-line:before {
  content: "\ef1e";
}

.ri-markup-fill:before {
  content: "\ef1f";
}

.ri-markup-line:before {
  content: "\ef20";
}

.ri-mastercard-fill:before {
  content: "\ef21";
}

.ri-mastercard-line:before {
  content: "\ef22";
}

.ri-mastodon-fill:before {
  content: "\ef23";
}

.ri-mastodon-line:before {
  content: "\ef24";
}

.ri-medal-2-fill:before {
  content: "\ef25";
}

.ri-medal-2-line:before {
  content: "\ef26";
}

.ri-medal-fill:before {
  content: "\ef27";
}

.ri-medal-line:before {
  content: "\ef28";
}

.ri-medicine-bottle-fill:before {
  content: "\ef29";
}

.ri-medicine-bottle-line:before {
  content: "\ef2a";
}

.ri-medium-fill:before {
  content: "\ef2b";
}

.ri-medium-line:before {
  content: "\ef2c";
}

.ri-men-fill:before {
  content: "\ef2d";
}

.ri-men-line:before {
  content: "\ef2e";
}

.ri-mental-health-fill:before {
  content: "\ef2f";
}

.ri-mental-health-line:before {
  content: "\ef30";
}

.ri-menu-2-fill:before {
  content: "\ef31";
}

.ri-menu-2-line:before {
  content: "\ef32";
}

.ri-menu-3-fill:before {
  content: "\ef33";
}

.ri-menu-3-line:before {
  content: "\ef34";
}

.ri-menu-4-fill:before {
  content: "\ef35";
}

.ri-menu-4-line:before {
  content: "\ef36";
}

.ri-menu-5-fill:before {
  content: "\ef37";
}

.ri-menu-5-line:before {
  content: "\ef38";
}

.ri-menu-add-fill:before {
  content: "\ef39";
}

.ri-menu-add-line:before {
  content: "\ef3a";
}

.ri-menu-fill:before {
  content: "\ef3b";
}

.ri-menu-fold-fill:before {
  content: "\ef3c";
}

.ri-menu-fold-line:before {
  content: "\ef3d";
}

.ri-menu-line:before {
  content: "\ef3e";
}

.ri-menu-unfold-fill:before {
  content: "\ef3f";
}

.ri-menu-unfold-line:before {
  content: "\ef40";
}

.ri-merge-cells-horizontal:before {
  content: "\ef41";
}

.ri-merge-cells-vertical:before {
  content: "\ef42";
}

.ri-message-2-fill:before {
  content: "\ef43";
}

.ri-message-2-line:before {
  content: "\ef44";
}

.ri-message-3-fill:before {
  content: "\ef45";
}

.ri-message-3-line:before {
  content: "\ef46";
}

.ri-message-fill:before {
  content: "\ef47";
}

.ri-message-line:before {
  content: "\ef48";
}

.ri-messenger-fill:before {
  content: "\ef49";
}

.ri-messenger-line:before {
  content: "\ef4a";
}

.ri-meteor-fill:before {
  content: "\ef4b";
}

.ri-meteor-line:before {
  content: "\ef4c";
}

.ri-mic-2-fill:before {
  content: "\ef4d";
}

.ri-mic-2-line:before {
  content: "\ef4e";
}

.ri-mic-fill:before {
  content: "\ef4f";
}

.ri-mic-line:before {
  content: "\ef50";
}

.ri-mic-off-fill:before {
  content: "\ef51";
}

.ri-mic-off-line:before {
  content: "\ef52";
}

.ri-mickey-fill:before {
  content: "\ef53";
}

.ri-mickey-line:before {
  content: "\ef54";
}

.ri-microscope-fill:before {
  content: "\ef55";
}

.ri-microscope-line:before {
  content: "\ef56";
}

.ri-microsoft-fill:before {
  content: "\ef57";
}

.ri-microsoft-line:before {
  content: "\ef58";
}

.ri-mind-map:before {
  content: "\ef59";
}

.ri-mini-program-fill:before {
  content: "\ef5a";
}

.ri-mini-program-line:before {
  content: "\ef5b";
}

.ri-mist-fill:before {
  content: "\ef5c";
}

.ri-mist-line:before {
  content: "\ef5d";
}

.ri-money-cny-box-fill:before {
  content: "\ef5e";
}

.ri-money-cny-box-line:before {
  content: "\ef5f";
}

.ri-money-cny-circle-fill:before {
  content: "\ef60";
}

.ri-money-cny-circle-line:before {
  content: "\ef61";
}

.ri-money-dollar-box-fill:before {
  content: "\ef62";
}

.ri-money-dollar-box-line:before {
  content: "\ef63";
}

.ri-money-dollar-circle-fill:before {
  content: "\ef64";
}

.ri-money-dollar-circle-line:before {
  content: "\ef65";
}

.ri-money-euro-box-fill:before {
  content: "\ef66";
}

.ri-money-euro-box-line:before {
  content: "\ef67";
}

.ri-money-euro-circle-fill:before {
  content: "\ef68";
}

.ri-money-euro-circle-line:before {
  content: "\ef69";
}

.ri-money-pound-box-fill:before {
  content: "\ef6a";
}

.ri-money-pound-box-line:before {
  content: "\ef6b";
}

.ri-money-pound-circle-fill:before {
  content: "\ef6c";
}

.ri-money-pound-circle-line:before {
  content: "\ef6d";
}

.ri-moon-clear-fill:before {
  content: "\ef6e";
}

.ri-moon-clear-line:before {
  content: "\ef6f";
}

.ri-moon-cloudy-fill:before {
  content: "\ef70";
}

.ri-moon-cloudy-line:before {
  content: "\ef71";
}

.ri-moon-fill:before {
  content: "\ef72";
}

.ri-moon-foggy-fill:before {
  content: "\ef73";
}

.ri-moon-foggy-line:before {
  content: "\ef74";
}

.ri-moon-line:before {
  content: "\ef75";
}

.ri-more-2-fill:before {
  content: "\ef76";
}

.ri-more-2-line:before {
  content: "\ef77";
}

.ri-more-fill:before {
  content: "\ef78";
}

.ri-more-fill:before {
  content: "\ef79";
}

.ri-motorbike-fill:before {
  content: "\ef7a";
}

.ri-motorbike-line:before {
  content: "\ef7b";
}

.ri-mouse-fill:before {
  content: "\ef7c";
}

.ri-mouse-line:before {
  content: "\ef7d";
}

.ri-movie-2-fill:before {
  content: "\ef7e";
}

.ri-movie-2-line:before {
  content: "\ef7f";
}

.ri-movie-fill:before {
  content: "\ef80";
}

.ri-movie-line:before {
  content: "\ef81";
}

.ri-music-2-fill:before {
  content: "\ef82";
}

.ri-music-2-line:before {
  content: "\ef83";
}

.ri-music-fill:before {
  content: "\ef84";
}

.ri-music-line:before {
  content: "\ef85";
}

.ri-mv-fill:before {
  content: "\ef86";
}

.ri-mv-line:before {
  content: "\ef87";
}

.ri-navigation-fill:before {
  content: "\ef88";
}

.ri-navigation-line:before {
  content: "\ef89";
}

.ri-netease-cloud-music-fill:before {
  content: "\ef8a";
}

.ri-netease-cloud-music-line:before {
  content: "\ef8b";
}

.ri-netflix-fill:before {
  content: "\ef8c";
}

.ri-netflix-line:before {
  content: "\ef8d";
}

.ri-newspaper-fill:before {
  content: "\ef8e";
}

.ri-newspaper-line:before {
  content: "\ef8f";
}

.ri-node-tree:before {
  content: "\ef90";
}

.ri-notification-2-fill:before {
  content: "\ef91";
}

.ri-notification-2-line:before {
  content: "\ef92";
}

.ri-notification-3-fill:before {
  content: "\ef93";
}

.ri-notification-3-line:before {
  content: "\ef94";
}

.ri-notification-4-fill:before {
  content: "\ef95";
}

.ri-notification-4-line:before {
  content: "\ef96";
}

.ri-notification-badge-fill:before {
  content: "\ef97";
}

.ri-notification-badge-line:before {
  content: "\ef98";
}

.ri-notification-fill:before {
  content: "\ef99";
}

.ri-notification-line:before {
  content: "\ef9a";
}

.ri-notification-off-fill:before {
  content: "\ef9b";
}

.ri-notification-off-line:before {
  content: "\ef9c";
}

.ri-npmjs-fill:before {
  content: "\ef9d";
}

.ri-npmjs-line:before {
  content: "\ef9e";
}

.ri-number-0:before {
  content: "\ef9f";
}

.ri-number-1:before {
  content: "\efa0";
}

.ri-number-2:before {
  content: "\efa1";
}

.ri-number-3:before {
  content: "\efa2";
}

.ri-number-4:before {
  content: "\efa3";
}

.ri-number-5:before {
  content: "\efa4";
}

.ri-number-6:before {
  content: "\efa5";
}

.ri-number-7:before {
  content: "\efa6";
}

.ri-number-8:before {
  content: "\efa7";
}

.ri-number-9:before {
  content: "\efa8";
}

.ri-numbers-fill:before {
  content: "\efa9";
}

.ri-numbers-line:before {
  content: "\efaa";
}

.ri-nurse-fill:before {
  content: "\efab";
}

.ri-nurse-line:before {
  content: "\efac";
}

.ri-oil-fill:before {
  content: "\efad";
}

.ri-oil-line:before {
  content: "\efae";
}

.ri-omega:before {
  content: "\efaf";
}

.ri-open-arm-fill:before {
  content: "\efb0";
}

.ri-open-arm-line:before {
  content: "\efb1";
}

.ri-open-source-fill:before {
  content: "\efb2";
}

.ri-open-source-line:before {
  content: "\efb3";
}

.ri-opera-fill:before {
  content: "\efb4";
}

.ri-opera-line:before {
  content: "\efb5";
}

.ri-order-play-fill:before {
  content: "\efb6";
}

.ri-order-play-line:before {
  content: "\efb7";
}

.ri-organization-chart:before {
  content: "\efb8";
}

.ri-outlet-2-fill:before {
  content: "\efb9";
}

.ri-outlet-2-line:before {
  content: "\efba";
}

.ri-outlet-fill:before {
  content: "\efbb";
}

.ri-outlet-line:before {
  content: "\efbc";
}

.ri-page-separator:before {
  content: "\efbd";
}

.ri-pages-fill:before {
  content: "\efbe";
}

.ri-pages-line:before {
  content: "\efbf";
}

.ri-paint-brush-fill:before {
  content: "\efc0";
}

.ri-paint-brush-line:before {
  content: "\efc1";
}

.ri-paint-fill:before {
  content: "\efc2";
}

.ri-paint-line:before {
  content: "\efc3";
}

.ri-palette-fill:before {
  content: "\efc4";
}

.ri-palette-line:before {
  content: "\efc5";
}

.ri-pantone-fill:before {
  content: "\efc6";
}

.ri-pantone-line:before {
  content: "\efc7";
}

.ri-paragraph:before {
  content: "\efc8";
}

.ri-parent-fill:before {
  content: "\efc9";
}

.ri-parent-line:before {
  content: "\efca";
}

.ri-parentheses-fill:before {
  content: "\efcb";
}

.ri-parentheses-line:before {
  content: "\efcc";
}

.ri-parking-box-fill:before {
  content: "\efcd";
}

.ri-parking-box-line:before {
  content: "\efce";
}

.ri-parking-fill:before {
  content: "\efcf";
}

.ri-parking-line:before {
  content: "\efd0";
}

.ri-passport-fill:before {
  content: "\efd1";
}

.ri-passport-line:before {
  content: "\efd2";
}

.ri-patreon-fill:before {
  content: "\efd3";
}

.ri-patreon-line:before {
  content: "\efd4";
}

.ri-pause-circle-fill:before {
  content: "\efd5";
}

.ri-pause-circle-line:before {
  content: "\efd6";
}

.ri-pause-fill:before {
  content: "\efd7";
}

.ri-pause-line:before {
  content: "\efd8";
}

.ri-pause-mini-fill:before {
  content: "\efd9";
}

.ri-pause-mini-line:before {
  content: "\efda";
}

.ri-paypal-fill:before {
  content: "\efdb";
}

.ri-paypal-line:before {
  content: "\efdc";
}

.ri-pen-nib-fill:before {
  content: "\efdd";
}

.ri-pen-nib-line:before {
  content: "\efde";
}

.ri-pencil-fill:before {
  content: "\efdf";
}

.ri-pencil-line:before {
  content: "\efe0";
}

.ri-pencil-ruler-2-fill:before {
  content: "\efe1";
}

.ri-pencil-ruler-2-line:before {
  content: "\efe2";
}

.ri-pencil-ruler-fill:before {
  content: "\efe3";
}

.ri-pencil-ruler-line:before {
  content: "\efe4";
}

.ri-percent-fill:before {
  content: "\efe5";
}

.ri-percent-line:before {
  content: "\efe6";
}

.ri-phone-camera-fill:before {
  content: "\efe7";
}

.ri-phone-camera-line:before {
  content: "\efe8";
}

.ri-phone-fill:before {
  content: "\efe9";
}

.ri-phone-find-fill:before {
  content: "\efea";
}

.ri-phone-find-line:before {
  content: "\efeb";
}

.ri-phone-line:before {
  content: "\efec";
}

.ri-phone-lock-fill:before {
  content: "\efed";
}

.ri-phone-lock-line:before {
  content: "\efee";
}

.ri-picture-in-picture-2-fill:before {
  content: "\efef";
}

.ri-picture-in-picture-2-line:before {
  content: "\eff0";
}

.ri-picture-in-picture-exit-fill:before {
  content: "\eff1";
}

.ri-picture-in-picture-exit-line:before {
  content: "\eff2";
}

.ri-picture-in-picture-fill:before {
  content: "\eff3";
}

.ri-picture-in-picture-line:before {
  content: "\eff4";
}

.ri-pie-chart-2-fill:before {
  content: "\eff5";
}

.ri-pie-chart-2-line:before {
  content: "\eff6";
}

.ri-pie-chart-box-fill:before {
  content: "\eff7";
}

.ri-pie-chart-box-line:before {
  content: "\eff8";
}

.ri-pie-chart-fill:before {
  content: "\eff9";
}

.ri-pie-chart-line:before {
  content: "\effa";
}

.ri-pin-distance-fill:before {
  content: "\effb";
}

.ri-pin-distance-line:before {
  content: "\effc";
}

.ri-ping-pong-fill:before {
  content: "\effd";
}

.ri-ping-pong-line:before {
  content: "\effe";
}

.ri-pinterest-fill:before {
  content: "\efff";
}

.ri-pinterest-line:before {
  content: "\f000";
}

.ri-pinyin-input:before {
  content: "\f001";
}

.ri-pixelfed-fill:before {
  content: "\f002";
}

.ri-pixelfed-line:before {
  content: "\f003";
}

.ri-plane-fill:before {
  content: "\f004";
}

.ri-plane-line:before {
  content: "\f005";
}

.ri-plant-fill:before {
  content: "\f006";
}

.ri-plant-line:before {
  content: "\f007";
}

.ri-play-circle-fill:before {
  content: "\f008";
}

.ri-play-circle-line:before {
  content: "\f009";
}

.ri-play-fill:before {
  content: "\f00a";
}

.ri-play-line:before {
  content: "\f00b";
}

.ri-play-list-2-fill:before {
  content: "\f00c";
}

.ri-play-list-2-line:before {
  content: "\f00d";
}

.ri-play-list-add-fill:before {
  content: "\f00e";
}

.ri-play-list-add-line:before {
  content: "\f00f";
}

.ri-play-list-fill:before {
  content: "\f010";
}

.ri-play-list-line:before {
  content: "\f011";
}

.ri-play-mini-fill:before {
  content: "\f012";
}

.ri-play-mini-line:before {
  content: "\f013";
}

.ri-playstation-fill:before {
  content: "\f014";
}

.ri-playstation-line:before {
  content: "\f015";
}

.ri-plug-2-fill:before {
  content: "\f016";
}

.ri-plug-2-line:before {
  content: "\f017";
}

.ri-plug-fill:before {
  content: "\f018";
}

.ri-plug-line:before {
  content: "\f019";
}

.ri-polaroid-2-fill:before {
  content: "\f01a";
}

.ri-polaroid-2-line:before {
  content: "\f01b";
}

.ri-polaroid-fill:before {
  content: "\f01c";
}

.ri-polaroid-line:before {
  content: "\f01d";
}

.ri-police-car-fill:before {
  content: "\f01e";
}

.ri-police-car-line:before {
  content: "\f01f";
}

.ri-price-tag-2-fill:before {
  content: "\f020";
}

.ri-price-tag-2-line:before {
  content: "\f021";
}

.ri-price-tag-3-fill:before {
  content: "\f022";
}

.ri-price-tag-3-line:before {
  content: "\f023";
}

.ri-price-tag-fill:before {
  content: "\f024";
}

.ri-price-tag-line:before {
  content: "\f025";
}

.ri-printer-cloud-fill:before {
  content: "\f026";
}

.ri-printer-cloud-line:before {
  content: "\f027";
}

.ri-printer-fill:before {
  content: "\f028";
}

.ri-printer-line:before {
  content: "\f029";
}

.ri-product-hunt-fill:before {
  content: "\f02a";
}

.ri-product-hunt-line:before {
  content: "\f02b";
}

.ri-profile-fill:before {
  content: "\f02c";
}

.ri-profile-line:before {
  content: "\f02d";
}

.ri-projector-2-fill:before {
  content: "\f02e";
}

.ri-projector-2-line:before {
  content: "\f02f";
}

.ri-projector-fill:before {
  content: "\f030";
}

.ri-projector-line:before {
  content: "\f031";
}

.ri-psychotherapy-fill:before {
  content: "\f032";
}

.ri-psychotherapy-line:before {
  content: "\f033";
}

.ri-pulse-fill:before {
  content: "\f034";
}

.ri-pulse-line:before {
  content: "\f035";
}

.ri-pushpin-2-fill:before {
  content: "\f036";
}

.ri-pushpin-2-line:before {
  content: "\f037";
}

.ri-pushpin-fill:before {
  content: "\f038";
}

.ri-pushpin-line:before {
  content: "\f039";
}

.ri-qq-fill:before {
  content: "\f03a";
}

.ri-qq-line:before {
  content: "\f03b";
}

.ri-qr-code-fill:before {
  content: "\f03c";
}

.ri-qr-code-line:before {
  content: "\f03d";
}

.ri-qr-scan-2-fill:before {
  content: "\f03e";
}

.ri-qr-scan-2-line:before {
  content: "\f03f";
}

.ri-qr-scan-fill:before {
  content: "\f040";
}

.ri-qr-scan-line:before {
  content: "\f041";
}

.ri-question-answer-fill:before {
  content: "\f042";
}

.ri-question-answer-line:before {
  content: "\f043";
}

.ri-question-fill:before {
  content: "\f044";
}

.ri-question-line:before {
  content: "\f045";
}

.ri-question-mark:before {
  content: "\f046";
}

.ri-questionnaire-fill:before {
  content: "\f047";
}

.ri-questionnaire-line:before {
  content: "\f048";
}

.ri-quill-pen-fill:before {
  content: "\f049";
}

.ri-quill-pen-line:before {
  content: "\f04a";
}

.ri-radar-fill:before {
  content: "\f04b";
}

.ri-radar-line:before {
  content: "\f04c";
}

.ri-radio-2-fill:before {
  content: "\f04d";
}

.ri-radio-2-line:before {
  content: "\f04e";
}

.ri-radio-button-fill:before {
  content: "\f04f";
}

.ri-radio-button-line:before {
  content: "\f050";
}

.ri-radio-fill:before {
  content: "\f051";
}

.ri-radio-line:before {
  content: "\f052";
}

.ri-rainbow-fill:before {
  content: "\f053";
}

.ri-rainbow-line:before {
  content: "\f054";
}

.ri-rainy-fill:before {
  content: "\f055";
}

.ri-rainy-line:before {
  content: "\f056";
}

.ri-reactjs-fill:before {
  content: "\f057";
}

.ri-reactjs-line:before {
  content: "\f058";
}

.ri-record-circle-fill:before {
  content: "\f059";
}

.ri-record-circle-line:before {
  content: "\f05a";
}

.ri-record-mail-fill:before {
  content: "\f05b";
}

.ri-record-mail-line:before {
  content: "\f05c";
}

.ri-recycle-fill:before {
  content: "\f05d";
}

.ri-recycle-line:before {
  content: "\f05e";
}

.ri-red-packet-fill:before {
  content: "\f05f";
}

.ri-red-packet-line:before {
  content: "\f060";
}

.ri-reddit-fill:before {
  content: "\f061";
}

.ri-reddit-line:before {
  content: "\f062";
}

.ri-refresh-fill:before {
  content: "\f063";
}

.ri-refresh-line:before {
  content: "\f064";
}

.ri-refund-2-fill:before {
  content: "\f065";
}

.ri-refund-2-line:before {
  content: "\f066";
}

.ri-refund-fill:before {
  content: "\f067";
}

.ri-refund-line:before {
  content: "\f068";
}

.ri-registered-fill:before {
  content: "\f069";
}

.ri-registered-line:before {
  content: "\f06a";
}

.ri-remixicon-fill:before {
  content: "\f06b";
}

.ri-remixicon-line:before {
  content: "\f06c";
}

.ri-remote-control-2-fill:before {
  content: "\f06d";
}

.ri-remote-control-2-line:before {
  content: "\f06e";
}

.ri-remote-control-fill:before {
  content: "\f06f";
}

.ri-remote-control-line:before {
  content: "\f070";
}

.ri-repeat-2-fill:before {
  content: "\f071";
}

.ri-repeat-2-line:before {
  content: "\f072";
}

.ri-repeat-fill:before {
  content: "\f073";
}

.ri-repeat-line:before {
  content: "\f074";
}

.ri-repeat-one-fill:before {
  content: "\f075";
}

.ri-repeat-one-line:before {
  content: "\f076";
}

.ri-reply-all-fill:before {
  content: "\f077";
}

.ri-reply-all-line:before {
  content: "\f078";
}

.ri-reply-fill:before {
  content: "\f079";
}

.ri-reply-line:before {
  content: "\f07a";
}

.ri-reserved-fill:before {
  content: "\f07b";
}

.ri-reserved-line:before {
  content: "\f07c";
}

.ri-rest-time-fill:before {
  content: "\f07d";
}

.ri-rest-time-line:before {
  content: "\f07e";
}

.ri-restart-fill:before {
  content: "\f07f";
}

.ri-restart-line:before {
  content: "\f080";
}

.ri-restaurant-2-fill:before {
  content: "\f081";
}

.ri-restaurant-2-line:before {
  content: "\f082";
}

.ri-restaurant-fill:before {
  content: "\f083";
}

.ri-restaurant-line:before {
  content: "\f084";
}

.ri-rewind-fill:before {
  content: "\f085";
}

.ri-rewind-line:before {
  content: "\f086";
}

.ri-rewind-mini-fill:before {
  content: "\f087";
}

.ri-rewind-mini-line:before {
  content: "\f088";
}

.ri-rhythm-fill:before {
  content: "\f089";
}

.ri-rhythm-line:before {
  content: "\f08a";
}

.ri-riding-fill:before {
  content: "\f08b";
}

.ri-riding-line:before {
  content: "\f08c";
}

.ri-road-map-fill:before {
  content: "\f08d";
}

.ri-road-map-line:before {
  content: "\f08e";
}

.ri-roadster-fill:before {
  content: "\f08f";
}

.ri-roadster-line:before {
  content: "\f090";
}

.ri-robot-fill:before {
  content: "\f091";
}

.ri-robot-line:before {
  content: "\f092";
}

.ri-rocket-2-fill:before {
  content: "\f093";
}

.ri-rocket-2-line:before {
  content: "\f094";
}

.ri-rocket-fill:before {
  content: "\f095";
}

.ri-rocket-line:before {
  content: "\f096";
}

.ri-rotate-lock-fill:before {
  content: "\f097";
}

.ri-rotate-lock-line:before {
  content: "\f098";
}

.ri-rounded-corner:before {
  content: "\f099";
}

.ri-route-fill:before {
  content: "\f09a";
}

.ri-route-line:before {
  content: "\f09b";
}

.ri-router-fill:before {
  content: "\f09c";
}

.ri-router-line:before {
  content: "\f09d";
}

.ri-rss-fill:before {
  content: "\f09e";
}

.ri-rss-line:before {
  content: "\f09f";
}

.ri-ruler-2-fill:before {
  content: "\f0a0";
}

.ri-ruler-2-line:before {
  content: "\f0a1";
}

.ri-ruler-fill:before {
  content: "\f0a2";
}

.ri-ruler-line:before {
  content: "\f0a3";
}

.ri-run-fill:before {
  content: "\f0a4";
}

.ri-run-line:before {
  content: "\f0a5";
}

.ri-safari-fill:before {
  content: "\f0a6";
}

.ri-safari-line:before {
  content: "\f0a7";
}

.ri-safe-2-fill:before {
  content: "\f0a8";
}

.ri-safe-2-line:before {
  content: "\f0a9";
}

.ri-safe-fill:before {
  content: "\f0aa";
}

.ri-safe-line:before {
  content: "\f0ab";
}

.ri-sailboat-fill:before {
  content: "\f0ac";
}

.ri-sailboat-line:before {
  content: "\f0ad";
}

.ri-save-2-fill:before {
  content: "\f0ae";
}

.ri-save-2-line:before {
  content: "\f0af";
}

.ri-save-3-fill:before {
  content: "\f0b0";
}

.ri-save-3-line:before {
  content: "\f0b1";
}

.ri-save-fill:before {
  content: "\f0b2";
}

.ri-save-line:before {
  content: "\f0b3";
}

.ri-scales-2-fill:before {
  content: "\f0b4";
}

.ri-scales-2-line:before {
  content: "\f0b5";
}

.ri-scales-3-fill:before {
  content: "\f0b6";
}

.ri-scales-3-line:before {
  content: "\f0b7";
}

.ri-scales-fill:before {
  content: "\f0b8";
}

.ri-scales-line:before {
  content: "\f0b9";
}

.ri-scan-2-fill:before {
  content: "\f0ba";
}

.ri-scan-2-line:before {
  content: "\f0bb";
}

.ri-scan-fill:before {
  content: "\f0bc";
}

.ri-scan-line:before {
  content: "\f0bd";
}

.ri-scissors-2-fill:before {
  content: "\f0be";
}

.ri-scissors-2-line:before {
  content: "\f0bf";
}

.ri-scissors-cut-fill:before {
  content: "\f0c0";
}

.ri-scissors-cut-line:before {
  content: "\f0c1";
}

.ri-scissors-fill:before {
  content: "\f0c2";
}

.ri-scissors-line:before {
  content: "\f0c3";
}

.ri-screenshot-2-fill:before {
  content: "\f0c4";
}

.ri-screenshot-2-line:before {
  content: "\f0c5";
}

.ri-screenshot-fill:before {
  content: "\f0c6";
}

.ri-screenshot-line:before {
  content: "\f0c7";
}

.ri-sd-card-fill:before {
  content: "\f0c8";
}

.ri-sd-card-line:before {
  content: "\f0c9";
}

.ri-sd-card-mini-fill:before {
  content: "\f0ca";
}

.ri-sd-card-mini-line:before {
  content: "\f0cb";
}

.ri-search-2-fill:before {
  content: "\f0cc";
}

.ri-search-2-line:before {
  content: "\f0cd";
}

.ri-search-eye-fill:before {
  content: "\f0ce";
}

.ri-search-eye-line:before {
  content: "\f0cf";
}

.ri-search-fill:before {
  content: "\f0d0";
}

.ri-search-line:before {
  content: "\f0d1";
}

.ri-secure-payment-fill:before {
  content: "\f0d2";
}

.ri-secure-payment-line:before {
  content: "\f0d3";
}

.ri-seedling-fill:before {
  content: "\f0d4";
}

.ri-seedling-line:before {
  content: "\f0d5";
}

.ri-send-backward:before {
  content: "\f0d6";
}

.ri-send-plane-2-fill:before {
  content: "\f0d7";
}

.ri-send-plane-2-line:before {
  content: "\f0d8";
}

.ri-send-plane-fill:before {
  content: "\f0d9";
}

.ri-send-plane-line:before {
  content: "\f0da";
}

.ri-send-to-back:before {
  content: "\f0db";
}

.ri-sensor-fill:before {
  content: "\f0dc";
}

.ri-sensor-line:before {
  content: "\f0dd";
}

.ri-separator:before {
  content: "\f0de";
}

.ri-server-fill:before {
  content: "\f0df";
}

.ri-server-line:before {
  content: "\f0e0";
}

.ri-service-fill:before {
  content: "\f0e1";
}

.ri-service-line:before {
  content: "\f0e2";
}

.ri-settings-2-fill:before {
  content: "\f0e3";
}

.ri-settings-2-line:before {
  content: "\f0e4";
}

.ri-settings-3-fill:before {
  content: "\f0e5";
}

.ri-settings-3-line:before {
  content: "\f0e6";
}

.ri-settings-4-fill:before {
  content: "\f0e7";
}

.ri-settings-4-line:before {
  content: "\f0e8";
}

.ri-settings-5-fill:before {
  content: "\f0e9";
}

.ri-settings-5-line:before {
  content: "\f0ea";
}

.ri-settings-6-fill:before {
  content: "\f0eb";
}

.ri-settings-6-line:before {
  content: "\f0ec";
}

.ri-settings-fill:before {
  content: "\f0ed";
}

.ri-settings-line:before {
  content: "\f0ee";
}

.ri-shape-2-fill:before {
  content: "\f0ef";
}

.ri-shape-2-line:before {
  content: "\f0f0";
}

.ri-shape-fill:before {
  content: "\f0f1";
}

.ri-shape-line:before {
  content: "\f0f2";
}

.ri-share-box-fill:before {
  content: "\f0f3";
}

.ri-share-box-line:before {
  content: "\f0f4";
}

.ri-share-circle-fill:before {
  content: "\f0f5";
}

.ri-share-circle-line:before {
  content: "\f0f6";
}

.ri-share-fill:before {
  content: "\f0f7";
}

.ri-share-forward-2-fill:before {
  content: "\f0f8";
}

.ri-share-forward-2-line:before {
  content: "\f0f9";
}

.ri-share-forward-box-fill:before {
  content: "\f0fa";
}

.ri-share-forward-box-line:before {
  content: "\f0fb";
}

.ri-share-forward-fill:before {
  content: "\f0fc";
}

.ri-share-forward-line:before {
  content: "\f0fd";
}

.ri-share-line:before {
  content: "\f0fe";
}

.ri-shield-check-fill:before {
  content: "\f0ff";
}

.ri-shield-check-line:before {
  content: "\f100";
}

.ri-shield-cross-fill:before {
  content: "\f101";
}

.ri-shield-cross-line:before {
  content: "\f102";
}

.ri-shield-fill:before {
  content: "\f103";
}

.ri-shield-flash-fill:before {
  content: "\f104";
}

.ri-shield-flash-line:before {
  content: "\f105";
}

.ri-shield-keyhole-fill:before {
  content: "\f106";
}

.ri-shield-keyhole-line:before {
  content: "\f107";
}

.ri-shield-line:before {
  content: "\f108";
}

.ri-shield-star-fill:before {
  content: "\f109";
}

.ri-shield-star-line:before {
  content: "\f10a";
}

.ri-shield-user-fill:before {
  content: "\f10b";
}

.ri-shield-user-line:before {
  content: "\f10c";
}

.ri-ship-2-fill:before {
  content: "\f10d";
}

.ri-ship-2-line:before {
  content: "\f10e";
}

.ri-ship-fill:before {
  content: "\f10f";
}

.ri-ship-line:before {
  content: "\f110";
}

.ri-shirt-fill:before {
  content: "\f111";
}

.ri-shirt-line:before {
  content: "\f112";
}

.ri-shopping-bag-2-fill:before {
  content: "\f113";
}

.ri-shopping-bag-2-line:before {
  content: "\f114";
}

.ri-shopping-bag-3-fill:before {
  content: "\f115";
}

.ri-shopping-bag-3-line:before {
  content: "\f116";
}

.ri-shopping-bag-fill:before {
  content: "\f117";
}

.ri-shopping-bag-line:before {
  content: "\f118";
}

.ri-shopping-basket-2-fill:before {
  content: "\f119";
}

.ri-shopping-basket-2-line:before {
  content: "\f11a";
}

.ri-shopping-basket-fill:before {
  content: "\f11b";
}

.ri-shopping-basket-line:before {
  content: "\f11c";
}

.ri-shopping-cart-2-fill:before {
  content: "\f11d";
}

.ri-shopping-cart-2-line:before {
  content: "\f11e";
}

.ri-shopping-cart-fill:before {
  content: "\f11f";
}

.ri-shopping-cart-line:before {
  content: "\f120";
}

.ri-showers-fill:before {
  content: "\f121";
}

.ri-showers-line:before {
  content: "\f122";
}

.ri-shuffle-fill:before {
  content: "\f123";
}

.ri-shuffle-line:before {
  content: "\f124";
}

.ri-shut-down-fill:before {
  content: "\f125";
}

.ri-shut-down-line:before {
  content: "\f126";
}

.ri-side-bar-fill:before {
  content: "\f127";
}

.ri-side-bar-line:before {
  content: "\f128";
}

.ri-signal-tower-fill:before {
  content: "\f129";
}

.ri-signal-tower-line:before {
  content: "\f12a";
}

.ri-signal-wifi-1-fill:before {
  content: "\f12b";
}

.ri-signal-wifi-1-line:before {
  content: "\f12c";
}

.ri-signal-wifi-2-fill:before {
  content: "\f12d";
}

.ri-signal-wifi-2-line:before {
  content: "\f12e";
}

.ri-signal-wifi-3-fill:before {
  content: "\f12f";
}

.ri-signal-wifi-3-line:before {
  content: "\f130";
}

.ri-signal-wifi-error-fill:before {
  content: "\f131";
}

.ri-signal-wifi-error-line:before {
  content: "\f132";
}

.ri-signal-wifi-fill:before {
  content: "\f133";
}

.ri-signal-wifi-line:before {
  content: "\f134";
}

.ri-signal-wifi-off-fill:before {
  content: "\f135";
}

.ri-signal-wifi-off-line:before {
  content: "\f136";
}

.ri-sim-card-2-fill:before {
  content: "\f137";
}

.ri-sim-card-2-line:before {
  content: "\f138";
}

.ri-sim-card-fill:before {
  content: "\f139";
}

.ri-sim-card-line:before {
  content: "\f13a";
}

.ri-single-quotes-l:before {
  content: "\f13b";
}

.ri-single-quotes-r:before {
  content: "\f13c";
}

.ri-sip-fill:before {
  content: "\f13d";
}

.ri-sip-line:before {
  content: "\f13e";
}

.ri-skip-back-fill:before {
  content: "\f13f";
}

.ri-skip-back-line:before {
  content: "\f140";
}

.ri-skip-back-mini-fill:before {
  content: "\f141";
}

.ri-skip-back-mini-line:before {
  content: "\f142";
}

.ri-skip-forward-fill:before {
  content: "\f143";
}

.ri-skip-forward-line:before {
  content: "\f144";
}

.ri-skip-forward-mini-fill:before {
  content: "\f145";
}

.ri-skip-forward-mini-line:before {
  content: "\f146";
}

.ri-skull-2-fill:before {
  content: "\f147";
}

.ri-skull-2-line:before {
  content: "\f148";
}

.ri-skull-fill:before {
  content: "\f149";
}

.ri-skull-line:before {
  content: "\f14a";
}

.ri-skype-fill:before {
  content: "\f14b";
}

.ri-skype-line:before {
  content: "\f14c";
}

.ri-slack-fill:before {
  content: "\f14d";
}

.ri-slack-line:before {
  content: "\f14e";
}

.ri-slice-fill:before {
  content: "\f14f";
}

.ri-slice-line:before {
  content: "\f150";
}

.ri-slideshow-2-fill:before {
  content: "\f151";
}

.ri-slideshow-2-line:before {
  content: "\f152";
}

.ri-slideshow-3-fill:before {
  content: "\f153";
}

.ri-slideshow-3-line:before {
  content: "\f154";
}

.ri-slideshow-4-fill:before {
  content: "\f155";
}

.ri-slideshow-4-line:before {
  content: "\f156";
}

.ri-slideshow-fill:before {
  content: "\f157";
}

.ri-slideshow-line:before {
  content: "\f158";
}

.ri-smartphone-fill:before {
  content: "\f159";
}

.ri-smartphone-line:before {
  content: "\f15a";
}

.ri-snapchat-fill:before {
  content: "\f15b";
}

.ri-snapchat-line:before {
  content: "\f15c";
}

.ri-snowy-fill:before {
  content: "\f15d";
}

.ri-snowy-line:before {
  content: "\f15e";
}

.ri-sort-asc:before {
  content: "\f15f";
}

.ri-sort-desc:before {
  content: "\f160";
}

.ri-sound-module-fill:before {
  content: "\f161";
}

.ri-sound-module-line:before {
  content: "\f162";
}

.ri-soundcloud-fill:before {
  content: "\f163";
}

.ri-soundcloud-line:before {
  content: "\f164";
}

.ri-space-ship-fill:before {
  content: "\f165";
}

.ri-space-ship-line:before {
  content: "\f166";
}

.ri-space:before {
  content: "\f167";
}

.ri-spam-2-fill:before {
  content: "\f168";
}

.ri-spam-2-line:before {
  content: "\f169";
}

.ri-spam-3-fill:before {
  content: "\f16a";
}

.ri-spam-3-line:before {
  content: "\f16b";
}

.ri-spam-fill:before {
  content: "\f16c";
}

.ri-spam-line:before {
  content: "\f16d";
}

.ri-speaker-2-fill:before {
  content: "\f16e";
}

.ri-speaker-2-line:before {
  content: "\f16f";
}

.ri-speaker-3-fill:before {
  content: "\f170";
}

.ri-speaker-3-line:before {
  content: "\f171";
}

.ri-speaker-fill:before {
  content: "\f172";
}

.ri-speaker-line:before {
  content: "\f173";
}

.ri-spectrum-fill:before {
  content: "\f174";
}

.ri-spectrum-line:before {
  content: "\f175";
}

.ri-speed-fill:before {
  content: "\f176";
}

.ri-speed-line:before {
  content: "\f177";
}

.ri-speed-mini-fill:before {
  content: "\f178";
}

.ri-speed-mini-line:before {
  content: "\f179";
}

.ri-split-cells-horizontal:before {
  content: "\f17a";
}

.ri-split-cells-vertical:before {
  content: "\f17b";
}

.ri-spotify-fill:before {
  content: "\f17c";
}

.ri-spotify-line:before {
  content: "\f17d";
}

.ri-spy-fill:before {
  content: "\f17e";
}

.ri-spy-line:before {
  content: "\f17f";
}

.ri-stack-fill:before {
  content: "\f180";
}

.ri-stack-line:before {
  content: "\f181";
}

.ri-stack-overflow-fill:before {
  content: "\f182";
}

.ri-stack-overflow-line:before {
  content: "\f183";
}

.ri-stackshare-fill:before {
  content: "\f184";
}

.ri-stackshare-line:before {
  content: "\f185";
}

.ri-star-fill:before {
  content: "\f186";
}

.ri-star-half-fill:before {
  content: "\f187";
}

.ri-star-half-line:before {
  content: "\f188";
}

.ri-star-half-s-fill:before {
  content: "\f189";
}

.ri-star-half-s-line:before {
  content: "\f18a";
}

.ri-star-line:before {
  content: "\f18b";
}

.ri-star-s-fill:before {
  content: "\f18c";
}

.ri-star-s-line:before {
  content: "\f18d";
}

.ri-star-smile-fill:before {
  content: "\f18e";
}

.ri-star-smile-line:before {
  content: "\f18f";
}

.ri-steam-fill:before {
  content: "\f190";
}

.ri-steam-line:before {
  content: "\f191";
}

.ri-steering-2-fill:before {
  content: "\f192";
}

.ri-steering-2-line:before {
  content: "\f193";
}

.ri-steering-fill:before {
  content: "\f194";
}

.ri-steering-line:before {
  content: "\f195";
}

.ri-stethoscope-fill:before {
  content: "\f196";
}

.ri-stethoscope-line:before {
  content: "\f197";
}

.ri-sticky-note-2-fill:before {
  content: "\f198";
}

.ri-sticky-note-2-line:before {
  content: "\f199";
}

.ri-sticky-note-fill:before {
  content: "\f19a";
}

.ri-sticky-note-line:before {
  content: "\f19b";
}

.ri-stock-fill:before {
  content: "\f19c";
}

.ri-stock-line:before {
  content: "\f19d";
}

.ri-stop-circle-fill:before {
  content: "\f19e";
}

.ri-stop-circle-line:before {
  content: "\f19f";
}

.ri-stop-fill:before {
  content: "\f1a0";
}

.ri-stop-line:before {
  content: "\f1a1";
}

.ri-stop-mini-fill:before {
  content: "\f1a2";
}

.ri-stop-mini-line:before {
  content: "\f1a3";
}

.ri-store-2-fill:before {
  content: "\f1a4";
}

.ri-store-2-line:before {
  content: "\f1a5";
}

.ri-store-3-fill:before {
  content: "\f1a6";
}

.ri-store-3-line:before {
  content: "\f1a7";
}

.ri-store-fill:before {
  content: "\f1a8";
}

.ri-store-line:before {
  content: "\f1a9";
}

.ri-strikethrough-2:before {
  content: "\f1aa";
}

.ri-strikethrough:before {
  content: "\f1ab";
}

.ri-subscript-2:before {
  content: "\f1ac";
}

.ri-subscript:before {
  content: "\f1ad";
}

.ri-subtract-fill:before {
  content: "\f1ae";
}

.ri-subtract-line:before {
  content: "\f1af";
}

.ri-subway-fill:before {
  content: "\f1b0";
}

.ri-subway-line:before {
  content: "\f1b1";
}

.ri-subway-wifi-fill:before {
  content: "\f1b2";
}

.ri-subway-wifi-line:before {
  content: "\f1b3";
}

.ri-suitcase-2-fill:before {
  content: "\f1b4";
}

.ri-suitcase-2-line:before {
  content: "\f1b5";
}

.ri-suitcase-3-fill:before {
  content: "\f1b6";
}

.ri-suitcase-3-line:before {
  content: "\f1b7";
}

.ri-suitcase-fill:before {
  content: "\f1b8";
}

.ri-suitcase-line:before {
  content: "\f1b9";
}

.ri-sun-cloudy-fill:before {
  content: "\f1ba";
}

.ri-sun-cloudy-line:before {
  content: "\f1bb";
}

.ri-sun-fill:before {
  content: "\f1bc";
}

.ri-sun-foggy-fill:before {
  content: "\f1bd";
}

.ri-sun-foggy-line:before {
  content: "\f1be";
}

.ri-sun-line:before {
  content: "\f1bf";
}

.ri-superscript-2:before {
  content: "\f1c0";
}

.ri-superscript:before {
  content: "\f1c1";
}

.ri-surgical-mask-fill:before {
  content: "\f1c2";
}

.ri-surgical-mask-line:before {
  content: "\f1c3";
}

.ri-surround-sound-fill:before {
  content: "\f1c4";
}

.ri-surround-sound-line:before {
  content: "\f1c5";
}

.ri-survey-fill:before {
  content: "\f1c6";
}

.ri-survey-line:before {
  content: "\f1c7";
}

.ri-swap-box-fill:before {
  content: "\f1c8";
}

.ri-swap-box-line:before {
  content: "\f1c9";
}

.ri-swap-fill:before {
  content: "\f1ca";
}

.ri-swap-line:before {
  content: "\f1cb";
}

.ri-switch-fill:before {
  content: "\f1cc";
}

.ri-switch-line:before {
  content: "\f1cd";
}

.ri-sword-fill:before {
  content: "\f1ce";
}

.ri-sword-line:before {
  content: "\f1cf";
}

.ri-syringe-fill:before {
  content: "\f1d0";
}

.ri-syringe-line:before {
  content: "\f1d1";
}

.ri-t-box-fill:before {
  content: "\f1d2";
}

.ri-t-box-line:before {
  content: "\f1d3";
}

.ri-t-shirt-2-fill:before {
  content: "\f1d4";
}

.ri-t-shirt-2-line:before {
  content: "\f1d5";
}

.ri-t-shirt-air-fill:before {
  content: "\f1d6";
}

.ri-t-shirt-air-line:before {
  content: "\f1d7";
}

.ri-t-shirt-fill:before {
  content: "\f1d8";
}

.ri-t-shirt-line:before {
  content: "\f1d9";
}

.ri-table-2:before {
  content: "\f1da";
}

.ri-table-alt-fill:before {
  content: "\f1db";
}

.ri-table-alt-line:before {
  content: "\f1dc";
}

.ri-table-fill:before {
  content: "\f1dd";
}

.ri-table-line:before {
  content: "\f1de";
}

.ri-tablet-fill:before {
  content: "\f1df";
}

.ri-tablet-line:before {
  content: "\f1e0";
}

.ri-takeaway-fill:before {
  content: "\f1e1";
}

.ri-takeaway-line:before {
  content: "\f1e2";
}

.ri-taobao-fill:before {
  content: "\f1e3";
}

.ri-taobao-line:before {
  content: "\f1e4";
}

.ri-tape-fill:before {
  content: "\f1e5";
}

.ri-tape-line:before {
  content: "\f1e6";
}

.ri-task-fill:before {
  content: "\f1e7";
}

.ri-task-line:before {
  content: "\f1e8";
}

.ri-taxi-fill:before {
  content: "\f1e9";
}

.ri-taxi-line:before {
  content: "\f1ea";
}

.ri-taxi-wifi-fill:before {
  content: "\f1eb";
}

.ri-taxi-wifi-line:before {
  content: "\f1ec";
}

.ri-team-fill:before {
  content: "\f1ed";
}

.ri-team-line:before {
  content: "\f1ee";
}

.ri-telegram-fill:before {
  content: "\f1ef";
}

.ri-telegram-line:before {
  content: "\f1f0";
}

.ri-temp-cold-fill:before {
  content: "\f1f1";
}

.ri-temp-cold-line:before {
  content: "\f1f2";
}

.ri-temp-hot-fill:before {
  content: "\f1f3";
}

.ri-temp-hot-line:before {
  content: "\f1f4";
}

.ri-terminal-box-fill:before {
  content: "\f1f5";
}

.ri-terminal-box-line:before {
  content: "\f1f6";
}

.ri-terminal-fill:before {
  content: "\f1f7";
}

.ri-terminal-line:before {
  content: "\f1f8";
}

.ri-terminal-window-fill:before {
  content: "\f1f9";
}

.ri-terminal-window-line:before {
  content: "\f1fa";
}

.ri-test-tube-fill:before {
  content: "\f1fb";
}

.ri-test-tube-line:before {
  content: "\f1fc";
}

.ri-text-direction-l:before {
  content: "\f1fd";
}

.ri-text-direction-r:before {
  content: "\f1fe";
}

.ri-text-spacing:before {
  content: "\f1ff";
}

.ri-text-wrap:before {
  content: "\f200";
}

.ri-text:before {
  content: "\f201";
}

.ri-thermometer-fill:before {
  content: "\f202";
}

.ri-thermometer-line:before {
  content: "\f203";
}

.ri-thumb-down-fill:before {
  content: "\f204";
}

.ri-thumb-down-line:before {
  content: "\f205";
}

.ri-thumb-up-fill:before {
  content: "\f206";
}

.ri-thumb-up-line:before {
  content: "\f207";
}

.ri-thunderstorms-fill:before {
  content: "\f208";
}

.ri-thunderstorms-line:before {
  content: "\f209";
}

.ri-ticket-2-fill:before {
  content: "\f20a";
}

.ri-ticket-2-line:before {
  content: "\f20b";
}

.ri-ticket-fill:before {
  content: "\f20c";
}

.ri-ticket-line:before {
  content: "\f20d";
}

.ri-time-fill:before {
  content: "\f20e";
}

.ri-time-line:before {
  content: "\f20f";
}

.ri-timer-2-fill:before {
  content: "\f210";
}

.ri-timer-2-line:before {
  content: "\f211";
}

.ri-timer-fill:before {
  content: "\f212";
}

.ri-timer-flash-fill:before {
  content: "\f213";
}

.ri-timer-flash-line:before {
  content: "\f214";
}

.ri-timer-line:before {
  content: "\f215";
}

.ri-todo-fill:before {
  content: "\f216";
}

.ri-todo-line:before {
  content: "\f217";
}

.ri-toggle-fill:before {
  content: "\f218";
}

.ri-toggle-line:before {
  content: "\f219";
}

.ri-tools-fill:before {
  content: "\f21a";
}

.ri-tools-line:before {
  content: "\f21b";
}

.ri-tornado-fill:before {
  content: "\f21c";
}

.ri-tornado-line:before {
  content: "\f21d";
}

.ri-trademark-fill:before {
  content: "\f21e";
}

.ri-trademark-line:before {
  content: "\f21f";
}

.ri-traffic-light-fill:before {
  content: "\f220";
}

.ri-traffic-light-line:before {
  content: "\f221";
}

.ri-train-fill:before {
  content: "\f222";
}

.ri-train-line:before {
  content: "\f223";
}

.ri-train-wifi-fill:before {
  content: "\f224";
}

.ri-train-wifi-line:before {
  content: "\f225";
}

.ri-translate-2:before {
  content: "\f226";
}

.ri-translate:before {
  content: "\f227";
}

.ri-travesti-fill:before {
  content: "\f228";
}

.ri-travesti-line:before {
  content: "\f229";
}

.ri-treasure-map-fill:before {
  content: "\f22a";
}

.ri-treasure-map-line:before {
  content: "\f22b";
}

.ri-trello-fill:before {
  content: "\f22c";
}

.ri-trello-line:before {
  content: "\f22d";
}

.ri-trophy-fill:before {
  content: "\f22e";
}

.ri-trophy-line:before {
  content: "\f22f";
}

.ri-truck-fill:before {
  content: "\f230";
}

.ri-truck-line:before {
  content: "\f231";
}

.ri-tumblr-fill:before {
  content: "\f232";
}

.ri-tumblr-line:before {
  content: "\f233";
}

.ri-tv-2-fill:before {
  content: "\f234";
}

.ri-tv-2-line:before {
  content: "\f235";
}

.ri-tv-fill:before {
  content: "\f236";
}

.ri-tv-line:before {
  content: "\f237";
}

.ri-twitch-fill:before {
  content: "\f238";
}

.ri-twitch-line:before {
  content: "\f239";
}

.ri-twitter-fill:before {
  content: "\f23a";
}

.ri-twitter-line:before {
  content: "\f23b";
}

.ri-typhoon-fill:before {
  content: "\f23c";
}

.ri-typhoon-line:before {
  content: "\f23d";
}

.ri-u-disk-fill:before {
  content: "\f23e";
}

.ri-u-disk-line:before {
  content: "\f23f";
}

.ri-ubuntu-fill:before {
  content: "\f240";
}

.ri-ubuntu-line:before {
  content: "\f241";
}

.ri-umbrella-fill:before {
  content: "\f242";
}

.ri-umbrella-line:before {
  content: "\f243";
}

.ri-underline:before {
  content: "\f244";
}

.ri-uninstall-fill:before {
  content: "\f245";
}

.ri-uninstall-line:before {
  content: "\f246";
}

.ri-unsplash-fill:before {
  content: "\f247";
}

.ri-unsplash-line:before {
  content: "\f248";
}

.ri-upload-2-fill:before {
  content: "\f249";
}

.ri-upload-2-line:before {
  content: "\f24a";
}

.ri-upload-cloud-2-fill:before {
  content: "\f24b";
}

.ri-upload-cloud-2-line:before {
  content: "\f24c";
}

.ri-upload-cloud-fill:before {
  content: "\f24d";
}

.ri-upload-cloud-line:before {
  content: "\f24e";
}

.ri-upload-fill:before {
  content: "\f24f";
}

.ri-upload-line:before {
  content: "\f250";
}

.ri-usb-fill:before {
  content: "\f251";
}

.ri-usb-line:before {
  content: "\f252";
}

.ri-user-2-fill:before {
  content: "\f253";
}

.ri-user-2-line:before {
  content: "\f254";
}

.ri-user-3-fill:before {
  content: "\f255";
}

.ri-user-3-line:before {
  content: "\f256";
}

.ri-user-4-fill:before {
  content: "\f257";
}

.ri-user-4-line:before {
  content: "\f258";
}

.ri-user-5-fill:before {
  content: "\f259";
}

.ri-user-5-line:before {
  content: "\f25a";
}

.ri-user-6-fill:before {
  content: "\f25b";
}

.ri-user-6-line:before {
  content: "\f25c";
}

.ri-user-add-fill:before {
  content: "\f25d";
}

.ri-user-add-line:before {
  content: "\f25e";
}

.ri-user-fill:before {
  content: "\f25f";
}

.ri-user-follow-fill:before {
  content: "\f260";
}

.ri-user-follow-line:before {
  content: "\f261";
}

.ri-user-heart-fill:before {
  content: "\f262";
}

.ri-user-heart-line:before {
  content: "\f263";
}

.ri-user-line:before {
  content: "\f264";
}

.ri-user-location-fill:before {
  content: "\f265";
}

.ri-user-location-line:before {
  content: "\f266";
}

.ri-user-received-2-fill:before {
  content: "\f267";
}

.ri-user-received-2-line:before {
  content: "\f268";
}

.ri-user-received-fill:before {
  content: "\f269";
}

.ri-user-received-line:before {
  content: "\f26a";
}

.ri-user-search-fill:before {
  content: "\f26b";
}

.ri-user-search-line:before {
  content: "\f26c";
}

.ri-user-settings-fill:before {
  content: "\f26d";
}

.ri-user-settings-line:before {
  content: "\f26e";
}

.ri-user-shared-2-fill:before {
  content: "\f26f";
}

.ri-user-shared-2-line:before {
  content: "\f270";
}

.ri-user-shared-fill:before {
  content: "\f271";
}

.ri-user-shared-line:before {
  content: "\f272";
}

.ri-user-smile-fill:before {
  content: "\f273";
}

.ri-user-smile-line:before {
  content: "\f274";
}

.ri-user-star-fill:before {
  content: "\f275";
}

.ri-user-star-line:before {
  content: "\f276";
}

.ri-user-unfollow-fill:before {
  content: "\f277";
}

.ri-user-unfollow-line:before {
  content: "\f278";
}

.ri-user-voice-fill:before {
  content: "\f279";
}

.ri-user-voice-line:before {
  content: "\f27a";
}

.ri-video-add-fill:before {
  content: "\f27b";
}

.ri-video-add-line:before {
  content: "\f27c";
}

.ri-video-chat-fill:before {
  content: "\f27d";
}

.ri-video-chat-line:before {
  content: "\f27e";
}

.ri-video-download-fill:before {
  content: "\f27f";
}

.ri-video-download-line:before {
  content: "\f280";
}

.ri-video-fill:before {
  content: "\f281";
}

.ri-video-line:before {
  content: "\f282";
}

.ri-video-upload-fill:before {
  content: "\f283";
}

.ri-video-upload-line:before {
  content: "\f284";
}

.ri-vidicon-2-fill:before {
  content: "\f285";
}

.ri-vidicon-2-line:before {
  content: "\f286";
}

.ri-vidicon-fill:before {
  content: "\f287";
}

.ri-vidicon-line:before {
  content: "\f288";
}

.ri-vimeo-fill:before {
  content: "\f289";
}

.ri-vimeo-line:before {
  content: "\f28a";
}

.ri-vip-crown-2-fill:before {
  content: "\f28b";
}

.ri-vip-crown-2-line:before {
  content: "\f28c";
}

.ri-vip-crown-fill:before {
  content: "\f28d";
}

.ri-vip-crown-line:before {
  content: "\f28e";
}

.ri-vip-diamond-fill:before {
  content: "\f28f";
}

.ri-vip-diamond-line:before {
  content: "\f290";
}

.ri-vip-fill:before {
  content: "\f291";
}

.ri-vip-line:before {
  content: "\f292";
}

.ri-virus-fill:before {
  content: "\f293";
}

.ri-virus-line:before {
  content: "\f294";
}

.ri-visa-fill:before {
  content: "\f295";
}

.ri-visa-line:before {
  content: "\f296";
}

.ri-voice-recognition-fill:before {
  content: "\f297";
}

.ri-voice-recognition-line:before {
  content: "\f298";
}

.ri-voiceprint-fill:before {
  content: "\f299";
}

.ri-voiceprint-line:before {
  content: "\f29a";
}

.ri-volume-down-fill:before {
  content: "\f29b";
}

.ri-volume-down-line:before {
  content: "\f29c";
}

.ri-volume-mute-fill:before {
  content: "\f29d";
}

.ri-volume-mute-line:before {
  content: "\f29e";
}

.ri-volume-off-vibrate-fill:before {
  content: "\f29f";
}

.ri-volume-off-vibrate-line:before {
  content: "\f2a0";
}

.ri-volume-up-fill:before {
  content: "\f2a1";
}

.ri-volume-up-line:before {
  content: "\f2a2";
}

.ri-volume-vibrate-fill:before {
  content: "\f2a3";
}

.ri-volume-vibrate-line:before {
  content: "\f2a4";
}

.ri-vuejs-fill:before {
  content: "\f2a5";
}

.ri-vuejs-line:before {
  content: "\f2a6";
}

.ri-walk-fill:before {
  content: "\f2a7";
}

.ri-walk-line:before {
  content: "\f2a8";
}

.ri-wallet-2-fill:before {
  content: "\f2a9";
}

.ri-wallet-2-line:before {
  content: "\f2aa";
}

.ri-wallet-3-fill:before {
  content: "\f2ab";
}

.ri-wallet-3-line:before {
  content: "\f2ac";
}

.ri-wallet-fill:before {
  content: "\f2ad";
}

.ri-wallet-line:before {
  content: "\f2ae";
}

.ri-water-flash-fill:before {
  content: "\f2af";
}

.ri-water-flash-line:before {
  content: "\f2b0";
}

.ri-webcam-fill:before {
  content: "\f2b1";
}

.ri-webcam-line:before {
  content: "\f2b2";
}

.ri-wechat-2-fill:before {
  content: "\f2b3";
}

.ri-wechat-2-line:before {
  content: "\f2b4";
}

.ri-wechat-fill:before {
  content: "\f2b5";
}

.ri-wechat-line:before {
  content: "\f2b6";
}

.ri-wechat-pay-fill:before {
  content: "\f2b7";
}

.ri-wechat-pay-line:before {
  content: "\f2b8";
}

.ri-weibo-fill:before {
  content: "\f2b9";
}

.ri-weibo-line:before {
  content: "\f2ba";
}

.ri-whatsapp-fill:before {
  content: "\f2bb";
}

.ri-whatsapp-line:before {
  content: "\f2bc";
}

.ri-wheelchair-fill:before {
  content: "\f2bd";
}

.ri-wheelchair-line:before {
  content: "\f2be";
}

.ri-wifi-fill:before {
  content: "\f2bf";
}

.ri-wifi-line:before {
  content: "\f2c0";
}

.ri-wifi-off-fill:before {
  content: "\f2c1";
}

.ri-wifi-off-line:before {
  content: "\f2c2";
}

.ri-window-2-fill:before {
  content: "\f2c3";
}

.ri-window-2-line:before {
  content: "\f2c4";
}

.ri-window-fill:before {
  content: "\f2c5";
}

.ri-window-line:before {
  content: "\f2c6";
}

.ri-windows-fill:before {
  content: "\f2c7";
}

.ri-windows-line:before {
  content: "\f2c8";
}

.ri-windy-fill:before {
  content: "\f2c9";
}

.ri-windy-line:before {
  content: "\f2ca";
}

.ri-wireless-charging-fill:before {
  content: "\f2cb";
}

.ri-wireless-charging-line:before {
  content: "\f2cc";
}

.ri-women-fill:before {
  content: "\f2cd";
}

.ri-women-line:before {
  content: "\f2ce";
}

.ri-wubi-input:before {
  content: "\f2cf";
}

.ri-xbox-fill:before {
  content: "\f2d0";
}

.ri-xbox-line:before {
  content: "\f2d1";
}

.ri-xing-fill:before {
  content: "\f2d2";
}

.ri-xing-line:before {
  content: "\f2d3";
}

.ri-youtube-fill:before {
  content: "\f2d4";
}

.ri-youtube-line:before {
  content: "\f2d5";
}

.ri-zcool-fill:before {
  content: "\f2d6";
}

.ri-zcool-line:before {
  content: "\f2d7";
}

.ri-zhihu-fill:before {
  content: "\f2d8";
}

.ri-zhihu-line:before {
  content: "\f2d9";
}

.ri-zoom-in-fill:before {
  content: "\f2da";
}

.ri-zoom-in-line:before {
  content: "\f2db";
}

.ri-zoom-out-fill:before {
  content: "\f2dc";
}

.ri-zoom-out-line:before {
  content: "\f2dd";
}

.ri-zzz-fill:before {
  content: "\f2de";
}

.ri-zzz-line:before {
  content: "\f2df";
}

@font-face {
  font-family: "unicons";
  src: url("../fonts/unicons.eot?34404611");
  src: url("../fonts/unicons.eot?34404611#iefix") format("embedded-opentype"), url("../fonts/unicons.woff2?34404611") format("woff2"), url("../fonts/unicons.woff?34404611") format("woff"), url("../fonts/unicons.ttf?34404611") format("truetype"), url("../fonts/unicons.svg?34404611#unicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=uil-]:before,
[class*=" uil-"]:before {
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.uil-0-plus:before {
  content: "\e800";
} /* '' */
.uil-10-plus:before {
  content: "\e801";
} /* '' */
.uil-12-plus:before {
  content: "\e802";
} /* '' */
.uil-13-plus:before {
  content: "\e803";
} /* '' */
.uil-16-plus:before {
  content: "\e804";
} /* '' */
.uil-17-plus:before {
  content: "\e805";
} /* '' */
.uil-18-plus:before {
  content: "\e806";
} /* '' */
.uil-21-plus:before {
  content: "\e807";
} /* '' */
.uil-3-plus:before {
  content: "\e808";
} /* '' */
.uil-500px:before {
  content: "\e809";
} /* '' */
.uil-6-plus:before {
  content: "\e80a";
} /* '' */
.uil-abacus:before {
  content: "\e80b";
} /* '' */
.uil-accessible-icon-alt:before {
  content: "\e80c";
} /* '' */
.uil-adjust-alt:before {
  content: "\e80d";
} /* '' */
.uil-adjust-circle:before {
  content: "\e80e";
} /* '' */
.uil-adjust-half:before {
  content: "\e80f";
} /* '' */
.uil-adjust:before {
  content: "\e810";
} /* '' */
.uil-adobe:before {
  content: "\e811";
} /* '' */
.uil-airplay:before {
  content: "\e812";
} /* '' */
.uil-align-alt:before {
  content: "\e813";
} /* '' */
.uil-align-center-alt:before {
  content: "\e814";
} /* '' */
.uil-align-center-h:before {
  content: "\e815";
} /* '' */
.uil-align-center-justify:before {
  content: "\e816";
} /* '' */
.uil-align-center-v:before {
  content: "\e817";
} /* '' */
.uil-align-center:before {
  content: "\e818";
} /* '' */
.uil-align-justify:before {
  content: "\e819";
} /* '' */
.uil-align-left-justify:before {
  content: "\e81a";
} /* '' */
.uil-align-left:before {
  content: "\e81b";
} /* '' */
.uil-align-letter-right:before {
  content: "\e81c";
} /* '' */
.uil-align-right-justify:before {
  content: "\e81d";
} /* '' */
.uil-align-right:before {
  content: "\e81e";
} /* '' */
.uil-align:before {
  content: "\e81f";
} /* '' */
.uil-ambulance:before {
  content: "\e820";
} /* '' */
.uil-analysis:before {
  content: "\e821";
} /* '' */
.uil-analytics:before {
  content: "\e822";
} /* '' */
.uil-anchor:before {
  content: "\e823";
} /* '' */
.uil-android-phone-slash:before {
  content: "\e824";
} /* '' */
.uil-angle-double-down:before {
  content: "\e825";
} /* '' */
.uil-angle-double-left:before {
  content: "\e826";
} /* '' */
.uil-angle-double-right:before {
  content: "\e827";
} /* '' */
.uil-angle-double-up:before {
  content: "\e828";
} /* '' */
.uil-angle-down:before {
  content: "\e829";
} /* '' */
.uil-angle-left:before {
  content: "\e82a";
} /* '' */
.uil-angle-right-b:before {
  content: "\e82b";
} /* '' */
.uil-angle-right:before {
  content: "\e82c";
} /* '' */
.uil-angle-up:before {
  content: "\e82d";
} /* '' */
.uil-angry:before {
  content: "\e82e";
} /* '' */
.uil-ankh:before {
  content: "\e82f";
} /* '' */
.uil-annoyed-alt:before {
  content: "\e830";
} /* '' */
.uil-annoyed:before {
  content: "\e831";
} /* '' */
.uil-apple:before {
  content: "\e832";
} /* '' */
.uil-apps:before {
  content: "\e833";
} /* '' */
.uil-archive-alt:before {
  content: "\e834";
} /* '' */
.uil-archive:before {
  content: "\e835";
} /* '' */
.uil-archway:before {
  content: "\e836";
} /* '' */
.uil-arrow-break:before {
  content: "\e837";
} /* '' */
.uil-arrow-circle-down:before {
  content: "\e838";
} /* '' */
.uil-arrow-circle-left:before {
  content: "\e839";
} /* '' */
.uil-arrow-circle-right:before {
  content: "\e83a";
} /* '' */
.uil-arrow-circle-up:before {
  content: "\e83b";
} /* '' */
.uil-arrow-compress-h:before {
  content: "\e83c";
} /* '' */
.uil-arrow-down-left:before {
  content: "\e83d";
} /* '' */
.uil-arrow-down-right:before {
  content: "\e83e";
} /* '' */
.uil-arrow-down:before {
  content: "\e83f";
} /* '' */
.uil-arrow-from-right:before {
  content: "\e840";
} /* '' */
.uil-arrow-from-top:before {
  content: "\e841";
} /* '' */
.uil-arrow-growth:before {
  content: "\e842";
} /* '' */
.uil-arrow-left:before {
  content: "\e843";
} /* '' */
.uil-arrow-random:before {
  content: "\e844";
} /* '' */
.uil-arrow-resize-diagonal:before {
  content: "\e845";
} /* '' */
.uil-arrow-right:before {
  content: "\e846";
} /* '' */
.uil-arrow-to-bottom:before {
  content: "\e847";
} /* '' */
.uil-arrow-to-right:before {
  content: "\e848";
} /* '' */
.uil-arrow-up-left:before {
  content: "\e849";
} /* '' */
.uil-arrow-up-right:before {
  content: "\e84a";
} /* '' */
.uil-arrow-up:before {
  content: "\e84b";
} /* '' */
.uil-arrows-h-alt:before {
  content: "\e84c";
} /* '' */
.uil-arrows-h:before {
  content: "\e84d";
} /* '' */
.uil-arrows-left-down:before {
  content: "\e84e";
} /* '' */
.uil-arrows-maximize:before {
  content: "\e84f";
} /* '' */
.uil-arrows-merge:before {
  content: "\e850";
} /* '' */
.uil-arrows-resize-h:before {
  content: "\e851";
} /* '' */
.uil-arrows-resize-v:before {
  content: "\e852";
} /* '' */
.uil-arrows-resize:before {
  content: "\e853";
} /* '' */
.uil-arrows-right-down:before {
  content: "\e854";
} /* '' */
.uil-arrows-shrink-h:before {
  content: "\e855";
} /* '' */
.uil-arrows-shrink-v:before {
  content: "\e856";
} /* '' */
.uil-arrows-up-right:before {
  content: "\e857";
} /* '' */
.uil-arrows-v-alt:before {
  content: "\e858";
} /* '' */
.uil-arrows-v:before {
  content: "\e859";
} /* '' */
.uil-assistive-listening-systems:before {
  content: "\e85a";
} /* '' */
.uil-asterisk:before {
  content: "\e85b";
} /* '' */
.uil-at:before {
  content: "\e85c";
} /* '' */
.uil-atm-card:before {
  content: "\e85d";
} /* '' */
.uil-atom:before {
  content: "\e85e";
} /* '' */
.uil-auto-flash:before {
  content: "\e85f";
} /* '' */
.uil-award-alt:before {
  content: "\e860";
} /* '' */
.uil-award:before {
  content: "\e861";
} /* '' */
.uil-baby-carriage:before {
  content: "\e862";
} /* '' */
.uil-backpack:before {
  content: "\e863";
} /* '' */
.uil-backspace:before {
  content: "\e864";
} /* '' */
.uil-backward:before {
  content: "\e865";
} /* '' */
.uil-bag-alt:before {
  content: "\e866";
} /* '' */
.uil-bag-slash:before {
  content: "\e867";
} /* '' */
.uil-bag:before {
  content: "\e868";
} /* '' */
.uil-balance-scale:before {
  content: "\e869";
} /* '' */
.uil-ball:before {
  content: "\e86a";
} /* '' */
.uil-ban:before {
  content: "\e86b";
} /* '' */
.uil-bars:before {
  content: "\e86c";
} /* '' */
.uil-basketball-hoop:before {
  content: "\e86d";
} /* '' */
.uil-basketball:before {
  content: "\e86e";
} /* '' */
.uil-battery-bolt:before {
  content: "\e86f";
} /* '' */
.uil-battery-empty:before {
  content: "\e870";
} /* '' */
.uil-bed-double:before {
  content: "\e871";
} /* '' */
.uil-bed:before {
  content: "\e872";
} /* '' */
.uil-behance:before {
  content: "\e873";
} /* '' */
.uil-bell-school:before {
  content: "\e874";
} /* '' */
.uil-bell-slash:before {
  content: "\e875";
} /* '' */
.uil-bell:before {
  content: "\e876";
} /* '' */
.uil-bill:before {
  content: "\e877";
} /* '' */
.uil-bitcoin-circle:before {
  content: "\e878";
} /* '' */
.uil-bitcoin:before {
  content: "\e879";
} /* '' */
.uil-black-berry:before {
  content: "\e87a";
} /* '' */
.uil-bluetooth-b:before {
  content: "\e87b";
} /* '' */
.uil-bold:before {
  content: "\e87c";
} /* '' */
.uil-bolt-alt:before {
  content: "\e87d";
} /* '' */
.uil-bolt-slash:before {
  content: "\e87e";
} /* '' */
.uil-bolt:before {
  content: "\e87f";
} /* '' */
.uil-book-alt:before {
  content: "\e880";
} /* '' */
.uil-book-medical:before {
  content: "\e881";
} /* '' */
.uil-book-open:before {
  content: "\e882";
} /* '' */
.uil-book-reader:before {
  content: "\e883";
} /* '' */
.uil-book:before {
  content: "\e884";
} /* '' */
.uil-bookmark-full:before {
  content: "\e885";
} /* '' */
.uil-bookmark:before {
  content: "\e886";
} /* '' */
.uil-books:before {
  content: "\e887";
} /* '' */
.uil-boombox:before {
  content: "\e888";
} /* '' */
.uil-border-alt:before {
  content: "\e889";
} /* '' */
.uil-border-bottom:before {
  content: "\e88a";
} /* '' */
.uil-border-clear:before {
  content: "\e88b";
} /* '' */
.uil-border-horizontal:before {
  content: "\e88c";
} /* '' */
.uil-border-inner:before {
  content: "\e88d";
} /* '' */
.uil-border-left:before {
  content: "\e88e";
} /* '' */
.uil-border-out:before {
  content: "\e88f";
} /* '' */
.uil-border-right:before {
  content: "\e890";
} /* '' */
.uil-border-top:before {
  content: "\e891";
} /* '' */
.uil-border-vertical:before {
  content: "\e892";
} /* '' */
.uil-box:before {
  content: "\e893";
} /* '' */
.uil-briefcase-alt:before {
  content: "\e894";
} /* '' */
.uil-briefcase:before {
  content: "\e895";
} /* '' */
.uil-bright:before {
  content: "\e896";
} /* '' */
.uil-brightness-empty:before {
  content: "\e897";
} /* '' */
.uil-brightness-half:before {
  content: "\e898";
} /* '' */
.uil-brightness-low:before {
  content: "\e899";
} /* '' */
.uil-brightness-minus:before {
  content: "\e89a";
} /* '' */
.uil-brightness-plus:before {
  content: "\e89b";
} /* '' */
.uil-brightness:before {
  content: "\e89c";
} /* '' */
.uil-bring-bottom:before {
  content: "\e89d";
} /* '' */
.uil-bring-front:before {
  content: "\e89e";
} /* '' */
.uil-brush-alt:before {
  content: "\e89f";
} /* '' */
.uil-bug:before {
  content: "\e8a0";
} /* '' */
.uil-building:before {
  content: "\e8a1";
} /* '' */
.uil-bullseye:before {
  content: "\e8a2";
} /* '' */
.uil-bus-alt:before {
  content: "\e8a3";
} /* '' */
.uil-bus-school:before {
  content: "\e8a4";
} /* '' */
.uil-bus:before {
  content: "\e8a5";
} /* '' */
.uil-calcualtor:before {
  content: "\e8a6";
} /* '' */
.uil-calculator-alt:before {
  content: "\e8a7";
} /* '' */
.uil-calendar-alt:before {
  content: "\e8a8";
} /* '' */
.uil-calendar-slash:before {
  content: "\e8a9";
} /* '' */
.uil-calender:before {
  content: "\e8aa";
} /* '' */
.uil-calling:before {
  content: "\e8ab";
} /* '' */
.uil-camera-change:before {
  content: "\e8ac";
} /* '' */
.uil-camera-plus:before {
  content: "\e8ad";
} /* '' */
.uil-camera-slash:before {
  content: "\e8ae";
} /* '' */
.uil-camera:before {
  content: "\e8af";
} /* '' */
.uil-cancel:before {
  content: "\e8b0";
} /* '' */
.uil-capsule:before {
  content: "\e8b1";
} /* '' */
.uil-capture:before {
  content: "\e8b2";
} /* '' */
.uil-car-sideview:before {
  content: "\e8b3";
} /* '' */
.uil-car-slash:before {
  content: "\e8b4";
} /* '' */
.uil-car-wash:before {
  content: "\e8b5";
} /* '' */
.uil-car:before {
  content: "\e8b6";
} /* '' */
.uil-card-atm:before {
  content: "\e8b7";
} /* '' */
.uil-caret-right:before {
  content: "\e8b8";
} /* '' */
.uil-cart:before {
  content: "\e8b9";
} /* '' */
.uil-cell:before {
  content: "\e8ba";
} /* '' */
.uil-celsius:before {
  content: "\e8bb";
} /* '' */
.uil-chart-bar-alt:before {
  content: "\e8bc";
} /* '' */
.uil-chart-bar:before {
  content: "\e8bd";
} /* '' */
.uil-chart-down:before {
  content: "\e8be";
} /* '' */
.uil-chart-growth-alt:before {
  content: "\e8bf";
} /* '' */
.uil-chart-growth:before {
  content: "\e8c0";
} /* '' */
.uil-chart-line:before {
  content: "\e8c1";
} /* '' */
.uil-chart-pie-alt:before {
  content: "\e8c2";
} /* '' */
.uil-chart-pie:before {
  content: "\e8c3";
} /* '' */
.uil-chart:before {
  content: "\e8c4";
} /* '' */
.uil-chat-bubble-user:before {
  content: "\e8c5";
} /* '' */
.uil-chat-info:before {
  content: "\e8c6";
} /* '' */
.uil-chat:before {
  content: "\e8c7";
} /* '' */
.uil-check-circle:before {
  content: "\e8c8";
} /* '' */
.uil-check-square:before {
  content: "\e8c9";
} /* '' */
.uil-check:before {
  content: "\e8ca";
} /* '' */
.uil-circle-layer:before {
  content: "\e8cb";
} /* '' */
.uil-circle:before {
  content: "\e8cc";
} /* '' */
.uil-circuit:before {
  content: "\e8cd";
} /* '' */
.uil-clapper-board:before {
  content: "\e8ce";
} /* '' */
.uil-clipboard-alt:before {
  content: "\e8cf";
} /* '' */
.uil-clipboard-blank:before {
  content: "\e8d0";
} /* '' */
.uil-clipboard-notes:before {
  content: "\e8d1";
} /* '' */
.uil-clipboard:before {
  content: "\e8d2";
} /* '' */
.uil-clock-eight:before {
  content: "\e8d3";
} /* '' */
.uil-clock-five:before {
  content: "\e8d4";
} /* '' */
.uil-clock-nine:before {
  content: "\e8d5";
} /* '' */
.uil-clock-seven:before {
  content: "\e8d6";
} /* '' */
.uil-clock-ten:before {
  content: "\e8d7";
} /* '' */
.uil-clock-three:before {
  content: "\e8d8";
} /* '' */
.uil-clock-two:before {
  content: "\e8d9";
} /* '' */
.uil-clock:before {
  content: "\e8da";
} /* '' */
.uil-closed-captioning:before {
  content: "\e8db";
} /* '' */
.uil-cloud-block:before {
  content: "\e8dc";
} /* '' */
.uil-cloud-bookmark:before {
  content: "\e8dd";
} /* '' */
.uil-cloud-check:before {
  content: "\e8de";
} /* '' */
.uil-cloud-computing:before {
  content: "\e8df";
} /* '' */
.uil-cloud-data-connection:before {
  content: "\e8e0";
} /* '' */
.uil-cloud-database-tree:before {
  content: "\e8e1";
} /* '' */
.uil-cloud-download:before {
  content: "\e8e2";
} /* '' */
.uil-cloud-drizzle:before {
  content: "\e8e3";
} /* '' */
.uil-cloud-exclamation:before {
  content: "\e8e4";
} /* '' */
.uil-cloud-hail:before {
  content: "\e8e5";
} /* '' */
.uil-cloud-heart:before {
  content: "\e8e6";
} /* '' */
.uil-cloud-info:before {
  content: "\e8e7";
} /* '' */
.uil-cloud-lock:before {
  content: "\e8e8";
} /* '' */
.uil-cloud-meatball:before {
  content: "\e8e9";
} /* '' */
.uil-cloud-moon-hail:before {
  content: "\e8ea";
} /* '' */
.uil-cloud-moon-meatball:before {
  content: "\e8eb";
} /* '' */
.uil-cloud-moon-rain:before {
  content: "\e8ec";
} /* '' */
.uil-cloud-moon-showers:before {
  content: "\e8ed";
} /* '' */
.uil-cloud-moon:before {
  content: "\e8ee";
} /* '' */
.uil-cloud-question:before {
  content: "\e8ef";
} /* '' */
.uil-cloud-rain-sun:before {
  content: "\e8f0";
} /* '' */
.uil-cloud-rain:before {
  content: "\e8f1";
} /* '' */
.uil-cloud-redo:before {
  content: "\e8f2";
} /* '' */
.uil-cloud-set:before {
  content: "\e8f3";
} /* '' */
.uil-cloud-share:before {
  content: "\e8f4";
} /* '' */
.uil-cloud-shield:before {
  content: "\e8f5";
} /* '' */
.uil-cloud-showers-alt:before {
  content: "\e8f6";
} /* '' */
.uil-cloud-showers-heavy:before {
  content: "\e8f7";
} /* '' */
.uil-cloud-showers:before {
  content: "\e8f8";
} /* '' */
.uil-cloud-slash:before {
  content: "\e8f9";
} /* '' */
.uil-cloud-sun-hail:before {
  content: "\e8fa";
} /* '' */
.uil-cloud-sun-meatball:before {
  content: "\e8fb";
} /* '' */
.uil-cloud-sun-rain-alt:before {
  content: "\e8fc";
} /* '' */
.uil-cloud-sun-rain:before {
  content: "\e8fd";
} /* '' */
.uil-cloud-sun-tear:before {
  content: "\e8fe";
} /* '' */
.uil-cloud-sun:before {
  content: "\e8ff";
} /* '' */
.uil-cloud-times:before {
  content: "\e900";
} /* '' */
.uil-cloud-unlock:before {
  content: "\e901";
} /* '' */
.uil-cloud-upload:before {
  content: "\e902";
} /* '' */
.uil-cloud-wifi:before {
  content: "\e903";
} /* '' */
.uil-cloud-wind:before {
  content: "\e904";
} /* '' */
.uil-cloud:before {
  content: "\e905";
} /* '' */
.uil-clouds:before {
  content: "\e906";
} /* '' */
.uil-club:before {
  content: "\e907";
} /* '' */
.uil-code:before {
  content: "\e908";
} /* '' */
.uil-coffee:before {
  content: "\e909";
} /* '' */
.uil-cog:before {
  content: "\e90a";
} /* '' */
.uil-coins:before {
  content: "\e90b";
} /* '' */
.uil-columns:before {
  content: "\e90c";
} /* '' */
.uil-comment-alt-block:before {
  content: "\e90d";
} /* '' */
.uil-comment-alt-chart-lines:before {
  content: "\e90e";
} /* '' */
.uil-comment-alt-check:before {
  content: "\e90f";
} /* '' */
.uil-comment-alt-dots:before {
  content: "\e910";
} /* '' */
.uil-comment-alt-download:before {
  content: "\e911";
} /* '' */
.uil-comment-alt-edit:before {
  content: "\e912";
} /* '' */
.uil-comment-alt-exclamation:before {
  content: "\e913";
} /* '' */
.uil-comment-alt-heart:before {
  content: "\e914";
} /* '' */
.uil-comment-alt-image:before {
  content: "\e915";
} /* '' */
.uil-comment-alt-info:before {
  content: "\e916";
} /* '' */
.uil-comment-alt-lines:before {
  content: "\e917";
} /* '' */
.uil-comment-alt-lock:before {
  content: "\e918";
} /* '' */
.uil-comment-alt-medical:before {
  content: "\e919";
} /* '' */
.uil-comment-alt-message:before {
  content: "\e91a";
} /* '' */
.uil-comment-alt-notes:before {
  content: "\e91b";
} /* '' */
.uil-comment-alt-plus:before {
  content: "\e91c";
} /* '' */
.uil-comment-alt-question:before {
  content: "\e91d";
} /* '' */
.uil-comment-alt-redo:before {
  content: "\e91e";
} /* '' */
.uil-comment-alt-search:before {
  content: "\e91f";
} /* '' */
.uil-comment-alt-share:before {
  content: "\e920";
} /* '' */
.uil-comment-alt-shield:before {
  content: "\e921";
} /* '' */
.uil-comment-alt-slash:before {
  content: "\e922";
} /* '' */
.uil-comment-alt-upload:before {
  content: "\e923";
} /* '' */
.uil-comment-alt-verify:before {
  content: "\e924";
} /* '' */
.uil-comment-alt:before {
  content: "\e925";
} /* '' */
.uil-comment-block:before {
  content: "\e926";
} /* '' */
.uil-comment-chart-line:before {
  content: "\e927";
} /* '' */
.uil-comment-check:before {
  content: "\e928";
} /* '' */
.uil-comment-dots:before {
  content: "\e929";
} /* '' */
.uil-comment-download:before {
  content: "\e92a";
} /* '' */
.uil-comment-edit:before {
  content: "\e92b";
} /* '' */
.uil-comment-exclamation:before {
  content: "\e92c";
} /* '' */
.uil-comment-heart:before {
  content: "\e92d";
} /* '' */
.uil-comment-image:before {
  content: "\e92e";
} /* '' */
.uil-comment-info-alt:before {
  content: "\e92f";
} /* '' */
.uil-comment-info:before {
  content: "\e930";
} /* '' */
.uil-comment-lines:before {
  content: "\e931";
} /* '' */
.uil-comment-lock:before {
  content: "\e932";
} /* '' */
.uil-comment-medical:before {
  content: "\e933";
} /* '' */
.uil-comment-message:before {
  content: "\e934";
} /* '' */
.uil-comment-notes:before {
  content: "\e935";
} /* '' */
.uil-comment-plus:before {
  content: "\e936";
} /* '' */
.uil-comment-question:before {
  content: "\e937";
} /* '' */
.uil-comment-redo:before {
  content: "\e938";
} /* '' */
.uil-comment-search:before {
  content: "\e939";
} /* '' */
.uil-comment-share:before {
  content: "\e93a";
} /* '' */
.uil-comment-shield:before {
  content: "\e93b";
} /* '' */
.uil-comment-slash:before {
  content: "\e93c";
} /* '' */
.uil-comment-upload:before {
  content: "\e93d";
} /* '' */
.uil-comment-verify:before {
  content: "\e93e";
} /* '' */
.uil-comment:before {
  content: "\e93f";
} /* '' */
.uil-comments-alt:before {
  content: "\e940";
} /* '' */
.uil-comments:before {
  content: "\e941";
} /* '' */
.uil-commnet-alt-slash:before {
  content: "\e942";
} /* '' */
.uil-compact-disc:before {
  content: "\e943";
} /* '' */
.uil-compass:before {
  content: "\e944";
} /* '' */
.uil-compress-alt-left:before {
  content: "\e945";
} /* '' */
.uil-compress-alt:before {
  content: "\e946";
} /* '' */
.uil-compress-arrows:before {
  content: "\e947";
} /* '' */
.uil-compress-lines:before {
  content: "\e948";
} /* '' */
.uil-compress-point:before {
  content: "\e949";
} /* '' */
.uil-compress-v:before {
  content: "\e94a";
} /* '' */
.uil-compress:before {
  content: "\e94b";
} /* '' */
.uil-computer-mouse:before {
  content: "\e94c";
} /* '' */
.uil-confused:before {
  content: "\e94d";
} /* '' */
.uil-constructor:before {
  content: "\e94e";
} /* '' */
.uil-copy-alt:before {
  content: "\e94f";
} /* '' */
.uil-copy-landscape:before {
  content: "\e950";
} /* '' */
.uil-copy:before {
  content: "\e951";
} /* '' */
.uil-copyright:before {
  content: "\e952";
} /* '' */
.uil-corner-down-left:before {
  content: "\e953";
} /* '' */
.uil-corner-down-right-alt:before {
  content: "\e954";
} /* '' */
.uil-corner-down-right:before {
  content: "\e955";
} /* '' */
.uil-corner-left-down:before {
  content: "\e956";
} /* '' */
.uil-corner-right-down:before {
  content: "\e957";
} /* '' */
.uil-corner-up-left-alt:before {
  content: "\e958";
} /* '' */
.uil-corner-up-left:before {
  content: "\e959";
} /* '' */
.uil-corner-up-right-alt:before {
  content: "\e95a";
} /* '' */
.uil-corner-up-right:before {
  content: "\e95b";
} /* '' */
.uil-creative-commons-pd-alt:before {
  content: "\e95c";
} /* '' */
.uil-creative-commons-pd:before {
  content: "\e95d";
} /* '' */
.uil-crockery:before {
  content: "\e95e";
} /* '' */
.uil-crop-alt-rotate-left:before {
  content: "\e95f";
} /* '' */
.uil-crop-alt-rotate-right:before {
  content: "\e960";
} /* '' */
.uil-crop-alt:before {
  content: "\e961";
} /* '' */
.uil-crosshair-alt:before {
  content: "\e962";
} /* '' */
.uil-crosshair:before {
  content: "\e963";
} /* '' */
.uil-crosshairs:before {
  content: "\e964";
} /* '' */
.uil-cube:before {
  content: "\e965";
} /* '' */
.uil-dashboard:before {
  content: "\e966";
} /* '' */
.uil-data-sharing:before {
  content: "\e967";
} /* '' */
.uil-database-alt:before {
  content: "\e968";
} /* '' */
.uil-database:before {
  content: "\e969";
} /* '' */
.uil-desert:before {
  content: "\e96a";
} /* '' */
.uil-desktop-alt-slash:before {
  content: "\e96b";
} /* '' */
.uil-desktop-alt:before {
  content: "\e96c";
} /* '' */
.uil-desktop-cloud-alt:before {
  content: "\e96d";
} /* '' */
.uil-desktop-slash:before {
  content: "\e96e";
} /* '' */
.uil-desktop:before {
  content: "\e96f";
} /* '' */
.uil-dialpad-alt:before {
  content: "\e970";
} /* '' */
.uil-dialpad:before {
  content: "\e971";
} /* '' */
.uil-diamond:before {
  content: "\e972";
} /* '' */
.uil-diary-alt:before {
  content: "\e973";
} /* '' */
.uil-diary:before {
  content: "\e974";
} /* '' */
.uil-dice-five:before {
  content: "\e975";
} /* '' */
.uil-dice-four:before {
  content: "\e976";
} /* '' */
.uil-dice-one:before {
  content: "\e977";
} /* '' */
.uil-dice-six:before {
  content: "\e978";
} /* '' */
.uil-dice-three:before {
  content: "\e979";
} /* '' */
.uil-dice-two:before {
  content: "\e97a";
} /* '' */
.uil-direction:before {
  content: "\e97b";
} /* '' */
.uil-directions:before {
  content: "\e97c";
} /* '' */
.uil-dizzy-meh:before {
  content: "\e97d";
} /* '' */
.uil-dna:before {
  content: "\e97e";
} /* '' */
.uil-document-layout-center:before {
  content: "\e97f";
} /* '' */
.uil-document-layout-left:before {
  content: "\e980";
} /* '' */
.uil-document-layout-right:before {
  content: "\e981";
} /* '' */
.uil-document:before {
  content: "\e982";
} /* '' */
.uil-dollar-alt:before {
  content: "\e983";
} /* '' */
.uil-dollar-sign-alt:before {
  content: "\e984";
} /* '' */
.uil-dollar-sign:before {
  content: "\e985";
} /* '' */
.uil-down-arrow:before {
  content: "\e986";
} /* '' */
.uil-download-alt:before {
  content: "\e987";
} /* '' */
.uil-dribbble:before {
  content: "\e988";
} /* '' */
.uil-drill:before {
  content: "\e989";
} /* '' */
.uil-dropbox:before {
  content: "\e98a";
} /* '' */
.uil-dumbbell:before {
  content: "\e98b";
} /* '' */
.uil-ear:before {
  content: "\e98c";
} /* '' */
.uil-edit-alt:before {
  content: "\e98d";
} /* '' */
.uil-edit:before {
  content: "\e98e";
} /* '' */
.uil-ellipsis-h:before {
  content: "\e98f";
} /* '' */
.uil-ellipsis-v:before {
  content: "\e990";
} /* '' */
.uil-emoji:before {
  content: "\e991";
} /* '' */
.uil-enter:before {
  content: "\e992";
} /* '' */
.uil-entry:before {
  content: "\e993";
} /* '' */
.uil-envelope-add:before {
  content: "\e994";
} /* '' */
.uil-envelope-alt:before {
  content: "\e995";
} /* '' */
.uil-envelope-block:before {
  content: "\e996";
} /* '' */
.uil-envelope-bookmark:before {
  content: "\e997";
} /* '' */
.uil-envelope-check:before {
  content: "\e998";
} /* '' */
.uil-envelope-download-alt:before {
  content: "\e999";
} /* '' */
.uil-envelope-download:before {
  content: "\e99a";
} /* '' */
.uil-envelope-edit:before {
  content: "\e99b";
} /* '' */
.uil-envelope-exclamation:before {
  content: "\e99c";
} /* '' */
.uil-envelope-heart:before {
  content: "\e99d";
} /* '' */
.uil-envelope-info:before {
  content: "\e99e";
} /* '' */
.uil-envelope-lock:before {
  content: "\e99f";
} /* '' */
.uil-envelope-minus:before {
  content: "\e9a0";
} /* '' */
.uil-envelope-open:before {
  content: "\e9a1";
} /* '' */
.uil-envelope-question:before {
  content: "\e9a2";
} /* '' */
.uil-envelope-receive:before {
  content: "\e9a3";
} /* '' */
.uil-envelope-redo:before {
  content: "\e9a4";
} /* '' */
.uil-envelope-search:before {
  content: "\e9a5";
} /* '' */
.uil-envelope-send:before {
  content: "\e9a6";
} /* '' */
.uil-envelope-share:before {
  content: "\e9a7";
} /* '' */
.uil-envelope-shield:before {
  content: "\e9a8";
} /* '' */
.uil-envelope-star:before {
  content: "\e9a9";
} /* '' */
.uil-envelope-times:before {
  content: "\e9aa";
} /* '' */
.uil-envelope-upload-alt:before {
  content: "\e9ab";
} /* '' */
.uil-envelope-upload:before {
  content: "\e9ac";
} /* '' */
.uil-envelope:before {
  content: "\e9ad";
} /* '' */
.uil-envelopes:before {
  content: "\e9ae";
} /* '' */
.uil-equal-circle:before {
  content: "\e9af";
} /* '' */
.uil-euro-circle:before {
  content: "\e9b0";
} /* '' */
.uil-euro:before {
  content: "\e9b1";
} /* '' */
.uil-exchange-alt:before {
  content: "\e9b2";
} /* '' */
.uil-exchange:before {
  content: "\e9b3";
} /* '' */
.uil-exclamation-circle:before {
  content: "\e9b4";
} /* '' */
.uil-exclamation-octagon:before {
  content: "\e9b5";
} /* '' */
.uil-exclamation-triangle:before {
  content: "\e9b6";
} /* '' */
.uil-exclude:before {
  content: "\e9b7";
} /* '' */
.uil-exit:before {
  content: "\e9b8";
} /* '' */
.uil-expand-alt:before {
  content: "\e9b9";
} /* '' */
.uil-expand-arrows-alt:before {
  content: "\e9ba";
} /* '' */
.uil-expand-arrows:before {
  content: "\e9bb";
} /* '' */
.uil-expand-from-corner:before {
  content: "\e9bc";
} /* '' */
.uil-expand-left:before {
  content: "\e9bd";
} /* '' */
.uil-expand-right:before {
  content: "\e9be";
} /* '' */
.uil-export:before {
  content: "\e9bf";
} /* '' */
.uil-exposure-alt:before {
  content: "\e9c0";
} /* '' */
.uil-exposure-increase:before {
  content: "\e9c1";
} /* '' */
.uil-external-link-alt:before {
  content: "\e9c2";
} /* '' */
.uil-eye-slash:before {
  content: "\e9c3";
} /* '' */
.uil-eye:before {
  content: "\e9c4";
} /* '' */
.uil-facebook-f:before {
  content: "\e9c5";
} /* '' */
.uil-facebook-messenger:before {
  content: "\e9c6";
} /* '' */
.uil-facebook:before {
  content: "\e9c7";
} /* '' */
.uil-fahrenheit:before {
  content: "\e9c8";
} /* '' */
.uil-fast-mail-alt:before {
  content: "\e9c9";
} /* '' */
.uil-fast-mail:before {
  content: "\e9ca";
} /* '' */
.uil-favorite:before {
  content: "\e9cb";
} /* '' */
.uil-feedback:before {
  content: "\e9cc";
} /* '' */
.uil-file-alt:before {
  content: "\e9cd";
} /* '' */
.uil-file-blank:before {
  content: "\e9ce";
} /* '' */
.uil-file-block-alt:before {
  content: "\e9cf";
} /* '' */
.uil-file-bookmark-alt:before {
  content: "\e9d0";
} /* '' */
.uil-file-check-alt:before {
  content: "\e9d1";
} /* '' */
.uil-file-check:before {
  content: "\e9d2";
} /* '' */
.uil-file-contract-dollar:before {
  content: "\e9d3";
} /* '' */
.uil-file-copy-alt:before {
  content: "\e9d4";
} /* '' */
.uil-file-download-alt:before {
  content: "\e9d5";
} /* '' */
.uil-file-download:before {
  content: "\e9d6";
} /* '' */
.uil-file-edit-alt:before {
  content: "\e9d7";
} /* '' */
.uil-file-exclamation-alt:before {
  content: "\e9d8";
} /* '' */
.uil-file-exclamation:before {
  content: "\e9d9";
} /* '' */
.uil-file-heart:before {
  content: "\e9da";
} /* '' */
.uil-file-info-alt:before {
  content: "\e9db";
} /* '' */
.uil-file-landscape-alt:before {
  content: "\e9dc";
} /* '' */
.uil-file-landscape:before {
  content: "\e9dd";
} /* '' */
.uil-file-lanscape-slash:before {
  content: "\e9de";
} /* '' */
.uil-file-lock-alt:before {
  content: "\e9df";
} /* '' */
.uil-file-medical-alt:before {
  content: "\e9e0";
} /* '' */
.uil-file-medical:before {
  content: "\e9e1";
} /* '' */
.uil-file-minus-alt:before {
  content: "\e9e2";
} /* '' */
.uil-file-minus:before {
  content: "\e9e3";
} /* '' */
.uil-file-network:before {
  content: "\e9e4";
} /* '' */
.uil-file-plus-alt:before {
  content: "\e9e5";
} /* '' */
.uil-file-plus:before {
  content: "\e9e6";
} /* '' */
.uil-file-question-alt:before {
  content: "\e9e7";
} /* '' */
.uil-file-question:before {
  content: "\e9e8";
} /* '' */
.uil-file-redo-alt:before {
  content: "\e9e9";
} /* '' */
.uil-file-search-alt:before {
  content: "\e9ea";
} /* '' */
.uil-file-share-alt:before {
  content: "\e9eb";
} /* '' */
.uil-file-shield-alt:before {
  content: "\e9ec";
} /* '' */
.uil-file-slash:before {
  content: "\e9ed";
} /* '' */
.uil-file-times-alt:before {
  content: "\e9ee";
} /* '' */
.uil-file-times:before {
  content: "\e9ef";
} /* '' */
.uil-file-upload-alt:before {
  content: "\e9f0";
} /* '' */
.uil-file-upload:before {
  content: "\e9f1";
} /* '' */
.uil-file:before {
  content: "\e9f2";
} /* '' */
.uil-files-landscapes-alt:before {
  content: "\e9f3";
} /* '' */
.uil-files-landscapes:before {
  content: "\e9f4";
} /* '' */
.uil-film:before {
  content: "\e9f5";
} /* '' */
.uil-filter-slash:before {
  content: "\e9f6";
} /* '' */
.uil-filter:before {
  content: "\e9f7";
} /* '' */
.uil-flask-potion:before {
  content: "\e9f8";
} /* '' */
.uil-flask:before {
  content: "\e9f9";
} /* '' */
.uil-flip-h-alt:before {
  content: "\e9fa";
} /* '' */
.uil-flip-h:before {
  content: "\e9fb";
} /* '' */
.uil-flip-v-alt:before {
  content: "\e9fc";
} /* '' */
.uil-flip-v:before {
  content: "\e9fd";
} /* '' */
.uil-flower:before {
  content: "\e9fe";
} /* '' */
.uil-focus-add:before {
  content: "\e9ff";
} /* '' */
.uil-focus-target:before {
  content: "\ea00";
} /* '' */
.uil-focus:before {
  content: "\ea01";
} /* '' */
.uil-folder-check:before {
  content: "\ea02";
} /* '' */
.uil-folder-download:before {
  content: "\ea03";
} /* '' */
.uil-folder-exclamation:before {
  content: "\ea04";
} /* '' */
.uil-folder-heart:before {
  content: "\ea05";
} /* '' */
.uil-folder-info:before {
  content: "\ea06";
} /* '' */
.uil-folder-lock:before {
  content: "\ea07";
} /* '' */
.uil-folder-medical:before {
  content: "\ea08";
} /* '' */
.uil-folder-minus:before {
  content: "\ea09";
} /* '' */
.uil-folder-network:before {
  content: "\ea0a";
} /* '' */
.uil-folder-plus:before {
  content: "\ea0b";
} /* '' */
.uil-folder-question:before {
  content: "\ea0c";
} /* '' */
.uil-folder-slash:before {
  content: "\ea0d";
} /* '' */
.uil-folder-times:before {
  content: "\ea0e";
} /* '' */
.uil-folder-upload:before {
  content: "\ea0f";
} /* '' */
.uil-folder:before {
  content: "\ea10";
} /* '' */
.uil-food:before {
  content: "\ea11";
} /* '' */
.uil-football-american:before {
  content: "\ea12";
} /* '' */
.uil-football-ball:before {
  content: "\ea13";
} /* '' */
.uil-football:before {
  content: "\ea14";
} /* '' */
.uil-forecastcloud-moon-tear:before {
  content: "\ea15";
} /* '' */
.uil-forwaded-call:before {
  content: "\ea16";
} /* '' */
.uil-forward:before {
  content: "\ea17";
} /* '' */
.uil-frown:before {
  content: "\ea18";
} /* '' */
.uil-game-structure:before {
  content: "\ea19";
} /* '' */
.uil-game:before {
  content: "\ea1a";
} /* '' */
.uil-gift:before {
  content: "\ea1b";
} /* '' */
.uil-github-alt:before {
  content: "\ea1c";
} /* '' */
.uil-github:before {
  content: "\ea1d";
} /* '' */
.uil-glass-martini-alt-slash:before {
  content: "\ea1e";
} /* '' */
.uil-glass-martini-alt:before {
  content: "\ea1f";
} /* '' */
.uil-glass-martini:before {
  content: "\ea20";
} /* '' */
.uil-glass-tea:before {
  content: "\ea21";
} /* '' */
.uil-glass:before {
  content: "\ea22";
} /* '' */
.uil-globe:before {
  content: "\ea23";
} /* '' */
.uil-gold:before {
  content: "\ea24";
} /* '' */
.uil-google-drive-alt:before {
  content: "\ea25";
} /* '' */
.uil-google-drive:before {
  content: "\ea26";
} /* '' */
.uil-google-hangouts:before {
  content: "\ea27";
} /* '' */
.uil-google-play:before {
  content: "\ea28";
} /* '' */
.uil-google:before {
  content: "\ea29";
} /* '' */
.uil-graduation-hat:before {
  content: "\ea2a";
} /* '' */
.uil-graph-bar:before {
  content: "\ea2b";
} /* '' */
.uil-grid:before {
  content: "\ea2c";
} /* '' */
.uil-grids:before {
  content: "\ea2d";
} /* '' */
.uil-grin-tongue-wink-alt:before {
  content: "\ea2e";
} /* '' */
.uil-grin-tongue-wink:before {
  content: "\ea2f";
} /* '' */
.uil-grin:before {
  content: "\ea30";
} /* '' */
.uil-grip-horizontal-line:before {
  content: "\ea31";
} /* '' */
.uil-hdd:before {
  content: "\ea32";
} /* '' */
.uil-headphones-alt:before {
  content: "\ea33";
} /* '' */
.uil-headphones:before {
  content: "\ea34";
} /* '' */
.uil-heart-alt:before {
  content: "\ea35";
} /* '' */
.uil-heart-medical:before {
  content: "\ea36";
} /* '' */
.uil-heart-rate:before {
  content: "\ea37";
} /* '' */
.uil-heart-sign:before {
  content: "\ea38";
} /* '' */
.uil-heart:before {
  content: "\ea39";
} /* '' */
.uil-heartbeat:before {
  content: "\ea3a";
} /* '' */
.uil-history-alt:before {
  content: "\ea3b";
} /* '' */
.uil-history:before {
  content: "\ea3c";
} /* '' */
.uil-home-alt:before {
  content: "\ea3d";
} /* '' */
.uil-home:before {
  content: "\ea3e";
} /* '' */
.uil-horizontal-align-center:before {
  content: "\ea3f";
} /* '' */
.uil-horizontal-align-left:before {
  content: "\ea40";
} /* '' */
.uil-horizontal-align-right:before {
  content: "\ea41";
} /* '' */
.uil-horizontal-distribution-center:before {
  content: "\ea42";
} /* '' */
.uil-horizontal-distribution-left:before {
  content: "\ea43";
} /* '' */
.uil-horizontal-distribution-right:before {
  content: "\ea44";
} /* '' */
.uil-hunting:before {
  content: "\ea45";
} /* '' */
.uil-image-alt-slash:before {
  content: "\ea46";
} /* '' */
.uil-image-block:before {
  content: "\ea47";
} /* '' */
.uil-image-broken:before {
  content: "\ea48";
} /* '' */
.uil-image-check:before {
  content: "\ea49";
} /* '' */
.uil-image-download:before {
  content: "\ea4a";
} /* '' */
.uil-image-edit:before {
  content: "\ea4b";
} /* '' */
.uil-image-lock:before {
  content: "\ea4c";
} /* '' */
.uil-image-minus:before {
  content: "\ea4d";
} /* '' */
.uil-image-plus:before {
  content: "\ea4e";
} /* '' */
.uil-image-question:before {
  content: "\ea4f";
} /* '' */
.uil-image-redo:before {
  content: "\ea50";
} /* '' */
.uil-image-resize-landscape:before {
  content: "\ea51";
} /* '' */
.uil-image-resize-square:before {
  content: "\ea52";
} /* '' */
.uil-image-search:before {
  content: "\ea53";
} /* '' */
.uil-image-share:before {
  content: "\ea54";
} /* '' */
.uil-image-shield:before {
  content: "\ea55";
} /* '' */
.uil-image-slash:before {
  content: "\ea56";
} /* '' */
.uil-image-times:before {
  content: "\ea57";
} /* '' */
.uil-image-upload:before {
  content: "\ea58";
} /* '' */
.uil-image-v:before {
  content: "\ea59";
} /* '' */
.uil-image:before {
  content: "\ea5a";
} /* '' */
.uil-images:before {
  content: "\ea5b";
} /* '' */
.uil-incoming-call:before {
  content: "\ea5c";
} /* '' */
.uil-info-circle:before {
  content: "\ea5d";
} /* '' */
.uil-instagram-alt:before {
  content: "\ea5e";
} /* '' */
.uil-instagram:before {
  content: "\ea5f";
} /* '' */
.uil-intercom:before {
  content: "\ea60";
} /* '' */
.uil-invoice:before {
  content: "\ea61";
} /* '' */
.uil-italic:before {
  content: "\ea62";
} /* '' */
.uil-jackhammer:before {
  content: "\ea63";
} /* '' */
.uil-java-script:before {
  content: "\ea64";
} /* '' */
.uil-kayak:before {
  content: "\ea65";
} /* '' */
.uil-key-skeleton-alt:before {
  content: "\ea66";
} /* '' */
.uil-key-skeleton:before {
  content: "\ea67";
} /* '' */
.uil-keyboard-alt:before {
  content: "\ea68";
} /* '' */
.uil-keyboard-hide:before {
  content: "\ea69";
} /* '' */
.uil-keyboard-show:before {
  content: "\ea6a";
} /* '' */
.uil-keyboard:before {
  content: "\ea6b";
} /* '' */
.uil-keyhole-circle:before {
  content: "\ea6c";
} /* '' */
.uil-keyhole-square-full:before {
  content: "\ea6d";
} /* '' */
.uil-keyhole-square:before {
  content: "\ea6e";
} /* '' */
.uil-kid:before {
  content: "\ea6f";
} /* '' */
.uil-label-alt:before {
  content: "\ea70";
} /* '' */
.uil-label:before {
  content: "\ea71";
} /* '' */
.uil-lamp:before {
  content: "\ea72";
} /* '' */
.uil-laptop-cloud:before {
  content: "\ea73";
} /* '' */
.uil-laptop:before {
  content: "\ea74";
} /* '' */
.uil-laughing:before {
  content: "\ea75";
} /* '' */
.uil-layer-group-slash:before {
  content: "\ea76";
} /* '' */
.uil-layer-group:before {
  content: "\ea77";
} /* '' */
.uil-layers-alt:before {
  content: "\ea78";
} /* '' */
.uil-layers-slash:before {
  content: "\ea79";
} /* '' */
.uil-layers:before {
  content: "\ea7a";
} /* '' */
.uil-left-arrow-from-left:before {
  content: "\ea7b";
} /* '' */
.uil-left-arrow-to-left:before {
  content: "\ea7c";
} /* '' */
.uil-left-indent-alt:before {
  content: "\ea7d";
} /* '' */
.uil-left-indent:before {
  content: "\ea7e";
} /* '' */
.uil-left-to-right-text-direction:before {
  content: "\ea7f";
} /* '' */
.uil-life-ring:before {
  content: "\ea80";
} /* '' */
.uil-lightbulb-alt:before {
  content: "\ea81";
} /* '' */
.uil-lightbulb:before {
  content: "\ea82";
} /* '' */
.uil-line-alt:before {
  content: "\ea83";
} /* '' */
.uil-line-spacing:before {
  content: "\ea84";
} /* '' */
.uil-line:before {
  content: "\ea85";
} /* '' */
.uil-link-alt:before {
  content: "\ea86";
} /* '' */
.uil-link-broken:before {
  content: "\ea87";
} /* '' */
.uil-link-h:before {
  content: "\ea88";
} /* '' */
.uil-link:before {
  content: "\ea89";
} /* '' */
.uil-linkedin-alt:before {
  content: "\ea8a";
} /* '' */
.uil-linkedin:before {
  content: "\ea8b";
} /* '' */
.uil-list-ui-alt:before {
  content: "\ea8c";
} /* '' */
.uil-list-ul:before {
  content: "\ea8d";
} /* '' */
.uil-location-arrow-alt:before {
  content: "\ea8e";
} /* '' */
.uil-location-arrow:before {
  content: "\ea8f";
} /* '' */
.uil-location-pin-alt:before {
  content: "\ea90";
} /* '' */
.uil-location-point:before {
  content: "\ea91";
} /* '' */
.uil-location:before {
  content: "\ea92";
} /* '' */
.uil-lock-access:before {
  content: "\ea93";
} /* '' */
.uil-lock-alt:before {
  content: "\ea94";
} /* '' */
.uil-lock-open-alt:before {
  content: "\ea95";
} /* '' */
.uil-lock-slash:before {
  content: "\ea96";
} /* '' */
.uil-lock:before {
  content: "\ea97";
} /* '' */
.uil-mailbox-alt:before {
  content: "\ea98";
} /* '' */
.uil-mailbox:before {
  content: "\ea99";
} /* '' */
.uil-map-marker-alt:before {
  content: "\ea9a";
} /* '' */
.uil-map-marker-edit:before {
  content: "\ea9b";
} /* '' */
.uil-map-marker-info:before {
  content: "\ea9c";
} /* '' */
.uil-map-marker-minus:before {
  content: "\ea9d";
} /* '' */
.uil-map-marker-plus:before {
  content: "\ea9e";
} /* '' */
.uil-map-marker-question:before {
  content: "\ea9f";
} /* '' */
.uil-map-marker-shield:before {
  content: "\eaa0";
} /* '' */
.uil-map-marker-slash:before {
  content: "\eaa1";
} /* '' */
.uil-map-marker:before {
  content: "\eaa2";
} /* '' */
.uil-map-pin-alt:before {
  content: "\eaa3";
} /* '' */
.uil-map-pin:before {
  content: "\eaa4";
} /* '' */
.uil-map:before {
  content: "\eaa5";
} /* '' */
.uil-mars:before {
  content: "\eaa6";
} /* '' */
.uil-maximize-left:before {
  content: "\eaa7";
} /* '' */
.uil-medal:before {
  content: "\eaa8";
} /* '' */
.uil-medical-drip:before {
  content: "\eaa9";
} /* '' */
.uil-medical-square-full:before {
  content: "\eaaa";
} /* '' */
.uil-medical-square:before {
  content: "\eaab";
} /* '' */
.uil-medical:before {
  content: "\eaac";
} /* '' */
.uil-medium-m:before {
  content: "\eaad";
} /* '' */
.uil-medkit:before {
  content: "\eaae";
} /* '' */
.uil-meeting-board:before {
  content: "\eaaf";
} /* '' */
.uil-meh-alt:before {
  content: "\eab0";
} /* '' */
.uil-meh-closed-eye:before {
  content: "\eab1";
} /* '' */
.uil-meh:before {
  content: "\eab2";
} /* '' */
.uil-message:before {
  content: "\eab3";
} /* '' */
.uil-microphone-slash:before {
  content: "\eab4";
} /* '' */
.uil-microphone:before {
  content: "\eab5";
} /* '' */
.uil-minus-circle:before {
  content: "\eab6";
} /* '' */
.uil-minus-path:before {
  content: "\eab7";
} /* '' */
.uil-minus-square-full:before {
  content: "\eab8";
} /* '' */
.uil-minus-square:before {
  content: "\eab9";
} /* '' */
.uil-minus:before {
  content: "\eaba";
} /* '' */
.uil-missed-call:before {
  content: "\eabb";
} /* '' */
.uil-mobey-bill-slash:before {
  content: "\eabc";
} /* '' */
.uil-mobile-android-alt:before {
  content: "\eabd";
} /* '' */
.uil-mobile-android:before {
  content: "\eabe";
} /* '' */
.uil-mobile-vibrate:before {
  content: "\eabf";
} /* '' */
.uil-modem:before {
  content: "\eac0";
} /* '' */
.uil-money-bill-stack:before {
  content: "\eac1";
} /* '' */
.uil-money-bill:before {
  content: "\eac2";
} /* '' */
.uil-money-insert:before {
  content: "\eac3";
} /* '' */
.uil-money-stack:before {
  content: "\eac4";
} /* '' */
.uil-money-withdraw:before {
  content: "\eac5";
} /* '' */
.uil-money-withdrawal:before {
  content: "\eac6";
} /* '' */
.uil-moneybag-alt:before {
  content: "\eac7";
} /* '' */
.uil-moneybag:before {
  content: "\eac8";
} /* '' */
.uil-monitor-heart-rate:before {
  content: "\eac9";
} /* '' */
.uil-monitor:before {
  content: "\eaca";
} /* '' */
.uil-moon-eclipse:before {
  content: "\eacb";
} /* '' */
.uil-moon:before {
  content: "\eacc";
} /* '' */
.uil-moonset:before {
  content: "\eacd";
} /* '' */
.uil-mountains-sun:before {
  content: "\eace";
} /* '' */
.uil-mountains:before {
  content: "\eacf";
} /* '' */
.uil-mouse-alt:before {
  content: "\ead0";
} /* '' */
.uil-mouse:before {
  content: "\ead1";
} /* '' */
.uil-multiply:before {
  content: "\ead2";
} /* '' */
.uil-music-note:before {
  content: "\ead3";
} /* '' */
.uil-music-tune-slash:before {
  content: "\ead4";
} /* '' */
.uil-music:before {
  content: "\ead5";
} /* '' */
.uil-n-a:before {
  content: "\ead6";
} /* '' */
.uil-navigator:before {
  content: "\ead7";
} /* '' */
.uil-nerd:before {
  content: "\ead8";
} /* '' */
.uil-newspaper:before {
  content: "\ead9";
} /* '' */
.uil-ninja:before {
  content: "\eada";
} /* '' */
.uil-no-entry:before {
  content: "\eadb";
} /* '' */
.uil-notebooks:before {
  content: "\eadc";
} /* '' */
.uil-notes:before {
  content: "\eadd";
} /* '' */
.uil-object-group:before {
  content: "\eade";
} /* '' */
.uil-object-ungroup:before {
  content: "\eadf";
} /* '' */
.uil-octagon:before {
  content: "\eae0";
} /* '' */
.uil-outgoing-call:before {
  content: "\eae1";
} /* '' */
.uil-package:before {
  content: "\eae2";
} /* '' */
.uil-padlock:before {
  content: "\eae3";
} /* '' */
.uil-paint-tool:before {
  content: "\eae4";
} /* '' */
.uil-palette:before {
  content: "\eae5";
} /* '' */
.uil-panorama-h-alt:before {
  content: "\eae6";
} /* '' */
.uil-panorama-h:before {
  content: "\eae7";
} /* '' */
.uil-panorama-v:before {
  content: "\eae8";
} /* '' */
.uil-paperclip:before {
  content: "\eae9";
} /* '' */
.uil-paragraph:before {
  content: "\eaea";
} /* '' */
.uil-parcel:before {
  content: "\eaeb";
} /* '' */
.uil-parking-square:before {
  content: "\eaec";
} /* '' */
.uil-pathfinder-unite:before {
  content: "\eaed";
} /* '' */
.uil-pathfinder:before {
  content: "\eaee";
} /* '' */
.uil-pause-circle:before {
  content: "\eaef";
} /* '' */
.uil-pause:before {
  content: "\eaf0";
} /* '' */
.uil-paypal:before {
  content: "\eaf1";
} /* '' */
.uil-pen:before {
  content: "\eaf2";
} /* '' */
.uil-pentagon:before {
  content: "\eaf3";
} /* '' */
.uil-phone-alt:before {
  content: "\eaf4";
} /* '' */
.uil-phone-pause:before {
  content: "\eaf5";
} /* '' */
.uil-phone-slash:before {
  content: "\eaf6";
} /* '' */
.uil-phone-times:before {
  content: "\eaf7";
} /* '' */
.uil-phone-volume:before {
  content: "\eaf8";
} /* '' */
.uil-phone:before {
  content: "\eaf9";
} /* '' */
.uil-picture:before {
  content: "\eafa";
} /* '' */
.uil-plane-arrival:before {
  content: "\eafb";
} /* '' */
.uil-plane-departure:before {
  content: "\eafc";
} /* '' */
.uil-plane-fly:before {
  content: "\eafd";
} /* '' */
.uil-plane:before {
  content: "\eafe";
} /* '' */
.uil-play-circle:before {
  content: "\eaff";
} /* '' */
.uil-play:before {
  content: "\eb00";
} /* '' */
.uil-plug:before {
  content: "\eb01";
} /* '' */
.uil-plus-circle:before {
  content: "\eb02";
} /* '' */
.uil-plus-square:before {
  content: "\eb03";
} /* '' */
.uil-plus:before {
  content: "\eb04";
} /* '' */
.uil-podium:before {
  content: "\eb05";
} /* '' */
.uil-polygon:before {
  content: "\eb06";
} /* '' */
.uil-post-stamp:before {
  content: "\eb07";
} /* '' */
.uil-postcard:before {
  content: "\eb08";
} /* '' */
.uil-pound-circle:before {
  content: "\eb09";
} /* '' */
.uil-pound:before {
  content: "\eb0a";
} /* '' */
.uil-power:before {
  content: "\eb0b";
} /* '' */
.uil-prescription-bottle:before {
  content: "\eb0c";
} /* '' */
.uil-presentation-check:before {
  content: "\eb0d";
} /* '' */
.uil-presentation-edit:before {
  content: "\eb0e";
} /* '' */
.uil-presentation-line:before {
  content: "\eb0f";
} /* '' */
.uil-presentation-lines-alt:before {
  content: "\eb10";
} /* '' */
.uil-presentation-minus:before {
  content: "\eb11";
} /* '' */
.uil-presentation-play:before {
  content: "\eb12";
} /* '' */
.uil-presentation-plus:before {
  content: "\eb13";
} /* '' */
.uil-presentation-times:before {
  content: "\eb14";
} /* '' */
.uil-presentation:before {
  content: "\eb15";
} /* '' */
.uil-previous:before {
  content: "\eb16";
} /* '' */
.uil-pricetag-alt:before {
  content: "\eb17";
} /* '' */
.uil-print-slash:before {
  content: "\eb18";
} /* '' */
.uil-print:before {
  content: "\eb19";
} /* '' */
.uil-processor:before {
  content: "\eb1a";
} /* '' */
.uil-pump:before {
  content: "\eb1b";
} /* '' */
.uil-puzzle-piece:before {
  content: "\eb1c";
} /* '' */
.uil-question-circle:before {
  content: "\eb1d";
} /* '' */
.uil-raddit-alien-alt:before {
  content: "\eb1e";
} /* '' */
.uil-rainbow:before {
  content: "\eb1f";
} /* '' */
.uil-raindrops-alt:before {
  content: "\eb20";
} /* '' */
.uil-raindrops:before {
  content: "\eb21";
} /* '' */
.uil-receipt-alt:before {
  content: "\eb22";
} /* '' */
.uil-receipt:before {
  content: "\eb23";
} /* '' */
.uil-record-audio:before {
  content: "\eb24";
} /* '' */
.uil-redo:before {
  content: "\eb25";
} /* '' */
.uil-refresh:before {
  content: "\eb26";
} /* '' */
.uil-registered:before {
  content: "\eb27";
} /* '' */
.uil-repeat:before {
  content: "\eb28";
} /* '' */
.uil-restaurant:before {
  content: "\eb29";
} /* '' */
.uil-right-indent-alt:before {
  content: "\eb2a";
} /* '' */
.uil-right-to-left-text-direction:before {
  content: "\eb2b";
} /* '' */
.uil-robot:before {
  content: "\eb2c";
} /* '' */
.uil-rope-way:before {
  content: "\eb2d";
} /* '' */
.uil-rotate-360:before {
  content: "\eb2e";
} /* '' */
.uil-rss-alt:before {
  content: "\eb2f";
} /* '' */
.uil-rss-interface:before {
  content: "\eb30";
} /* '' */
.uil-rss:before {
  content: "\eb31";
} /* '' */
.uil-ruler-combined:before {
  content: "\eb32";
} /* '' */
.uil-ruler:before {
  content: "\eb33";
} /* '' */
.uil-sad-cry:before {
  content: "\eb34";
} /* '' */
.uil-sad-crying:before {
  content: "\eb35";
} /* '' */
.uil-sad-dizzy:before {
  content: "\eb36";
} /* '' */
.uil-sad-squint:before {
  content: "\eb37";
} /* '' */
.uil-sad:before {
  content: "\eb38";
} /* '' */
.uil-scaling-left:before {
  content: "\eb39";
} /* '' */
.uil-scaling-right:before {
  content: "\eb3a";
} /* '' */
.uil-scenery:before {
  content: "\eb3b";
} /* '' */
.uil-schedule:before {
  content: "\eb3c";
} /* '' */
.uil-science:before {
  content: "\eb3d";
} /* '' */
.uil-screw:before {
  content: "\eb3e";
} /* '' */
.uil-scroll-h:before {
  content: "\eb3f";
} /* '' */
.uil-scroll:before {
  content: "\eb40";
} /* '' */
.uil-search-alt:before {
  content: "\eb41";
} /* '' */
.uil-search-minus:before {
  content: "\eb42";
} /* '' */
.uil-search-plus:before {
  content: "\eb43";
} /* '' */
.uil-search:before {
  content: "\eb44";
} /* '' */
.uil-selfie:before {
  content: "\eb45";
} /* '' */
.uil-server-alt:before {
  content: "\eb46";
} /* '' */
.uil-server-connection:before {
  content: "\eb47";
} /* '' */
.uil-server-network-alt:before {
  content: "\eb48";
} /* '' */
.uil-server-network:before {
  content: "\eb49";
} /* '' */
.uil-server:before {
  content: "\eb4a";
} /* '' */
.uil-servers:before {
  content: "\eb4b";
} /* '' */
.uil-servicemark:before {
  content: "\eb4c";
} /* '' */
.uil-share-alt:before {
  content: "\eb4d";
} /* '' */
.uil-shield-check:before {
  content: "\eb4e";
} /* '' */
.uil-shield-exclamation:before {
  content: "\eb4f";
} /* '' */
.uil-shield-question:before {
  content: "\eb50";
} /* '' */
.uil-shield-slash:before {
  content: "\eb51";
} /* '' */
.uil-shield:before {
  content: "\eb52";
} /* '' */
.uil-ship:before {
  content: "\eb53";
} /* '' */
.uil-shop:before {
  content: "\eb54";
} /* '' */
.uil-shopping-basket:before {
  content: "\eb55";
} /* '' */
.uil-shopping-cart-alt:before {
  content: "\eb56";
} /* '' */
.uil-shopping-trolley:before {
  content: "\eb57";
} /* '' */
.uil-shovel:before {
  content: "\eb58";
} /* '' */
.uil-shrink:before {
  content: "\eb59";
} /* '' */
.uil-shuffle:before {
  content: "\eb5a";
} /* '' */
.uil-shutter-alt:before {
  content: "\eb5b";
} /* '' */
.uil-shutter:before {
  content: "\eb5c";
} /* '' */
.uil-sick:before {
  content: "\eb5d";
} /* '' */
.uil-sigma:before {
  content: "\eb5e";
} /* '' */
.uil-sign-alt:before {
  content: "\eb5f";
} /* '' */
.uil-sign-in-alt:before {
  content: "\eb60";
} /* '' */
.uil-sign-left:before {
  content: "\eb61";
} /* '' */
.uil-sign-out-alt:before {
  content: "\eb62";
} /* '' */
.uil-sign-right:before {
  content: "\eb63";
} /* '' */
.uil-signal-alt-3:before {
  content: "\eb64";
} /* '' */
.uil-signal-alt:before {
  content: "\eb65";
} /* '' */
.uil-signal:before {
  content: "\eb66";
} /* '' */
.uil-silence:before {
  content: "\eb67";
} /* '' */
.uil-silent-squint:before {
  content: "\eb68";
} /* '' */
.uil-sim-card:before {
  content: "\eb69";
} /* '' */
.uil-sitemap:before {
  content: "\eb6a";
} /* '' */
.uil-skip-forward-alt:before {
  content: "\eb6b";
} /* '' */
.uil-skip-forward-circle:before {
  content: "\eb6c";
} /* '' */
.uil-skip-forward:before {
  content: "\eb6d";
} /* '' */
.uil-slack-alt:before {
  content: "\eb6e";
} /* '' */
.uil-slack:before {
  content: "\eb6f";
} /* '' */
.uil-sliders-v-alt:before {
  content: "\eb70";
} /* '' */
.uil-sliders-v:before {
  content: "\eb71";
} /* '' */
.uil-smile-beam:before {
  content: "\eb72";
} /* '' */
.uil-smile-dizzy:before {
  content: "\eb73";
} /* '' */
.uil-smile-squint-wink-alt:before {
  content: "\eb74";
} /* '' */
.uil-smile-squint-wink:before {
  content: "\eb75";
} /* '' */
.uil-smile-wink-alt:before {
  content: "\eb76";
} /* '' */
.uil-smile-wink:before {
  content: "\eb77";
} /* '' */
.uil-smile:before {
  content: "\eb78";
} /* '' */
.uil-snapchat-ghost:before {
  content: "\eb79";
} /* '' */
.uil-snapchat-square:before {
  content: "\eb7a";
} /* '' */
.uil-snow-flake:before {
  content: "\eb7b";
} /* '' */
.uil-snowflake-alt:before {
  content: "\eb7c";
} /* '' */
.uil-snowflake:before {
  content: "\eb7d";
} /* '' */
.uil-sort-amount-down:before {
  content: "\eb7e";
} /* '' */
.uil-sort-amount-up:before {
  content: "\eb7f";
} /* '' */
.uil-sort:before {
  content: "\eb80";
} /* '' */
.uil-sorting:before {
  content: "\eb81";
} /* '' */
.uil-space-key:before {
  content: "\eb82";
} /* '' */
.uil-spade:before {
  content: "\eb83";
} /* '' */
.uil-sperms:before {
  content: "\eb84";
} /* '' */
.uil-spin:before {
  content: "\eb85";
} /* '' */
.uil-sport:before {
  content: "\eb86";
} /* '' */
.uil-square-full:before {
  content: "\eb87";
} /* '' */
.uil-square-shape:before {
  content: "\eb88";
} /* '' */
.uil-square:before {
  content: "\eb89";
} /* '' */
.uil-squint:before {
  content: "\eb8a";
} /* '' */
.uil-star-half-alt:before {
  content: "\eb8b";
} /* '' */
.uil-star:before {
  content: "\eb8c";
} /* '' */
.uil-step-backward-alt:before {
  content: "\eb8d";
} /* '' */
.uil-step-backward-circle:before {
  content: "\eb8e";
} /* '' */
.uil-step-backward:before {
  content: "\eb8f";
} /* '' */
.uil-step-forward:before {
  content: "\eb90";
} /* '' */
.uil-stop-circle:before {
  content: "\eb91";
} /* '' */
.uil-stopwatch-slash:before {
  content: "\eb92";
} /* '' */
.uil-stopwatch:before {
  content: "\eb93";
} /* '' */
.uil-store-alt:before {
  content: "\eb94";
} /* '' */
.uil-store:before {
  content: "\eb95";
} /* '' */
.uil-streering:before {
  content: "\eb96";
} /* '' */
.uil-stretcher:before {
  content: "\eb97";
} /* '' */
.uil-subject:before {
  content: "\eb98";
} /* '' */
.uil-subway-alt:before {
  content: "\eb99";
} /* '' */
.uil-subway:before {
  content: "\eb9a";
} /* '' */
.uil-suitcase-alt:before {
  content: "\eb9b";
} /* '' */
.uil-suitcase:before {
  content: "\eb9c";
} /* '' */
.uil-sun:before {
  content: "\eb9d";
} /* '' */
.uil-sunset:before {
  content: "\eb9e";
} /* '' */
.uil-surprise:before {
  content: "\eb9f";
} /* '' */
.uil-swatchbook:before {
  content: "\eba0";
} /* '' */
.uil-swimmer:before {
  content: "\eba1";
} /* '' */
.uil-symbol:before {
  content: "\eba2";
} /* '' */
.uil-sync-exclamation:before {
  content: "\eba3";
} /* '' */
.uil-sync-slash:before {
  content: "\eba4";
} /* '' */
.uil-sync:before {
  content: "\eba5";
} /* '' */
.uil-syringe:before {
  content: "\eba6";
} /* '' */
.uil-table:before {
  content: "\eba7";
} /* '' */
.uil-tablet:before {
  content: "\eba8";
} /* '' */
.uil-tablets:before {
  content: "\eba9";
} /* '' */
.uil-tachometer-fast:before {
  content: "\ebaa";
} /* '' */
.uil-tag-alt:before {
  content: "\ebab";
} /* '' */
.uil-tag:before {
  content: "\ebac";
} /* '' */
.uil-tape:before {
  content: "\ebad";
} /* '' */
.uil-taxi:before {
  content: "\ebae";
} /* '' */
.uil-tear:before {
  content: "\ebaf";
} /* '' */
.uil-technology:before {
  content: "\ebb0";
} /* '' */
.uil-telescope:before {
  content: "\ebb1";
} /* '' */
.uil-temperature-empty:before {
  content: "\ebb2";
} /* '' */
.uil-temperature-half:before {
  content: "\ebb3";
} /* '' */
.uil-temperature-minus:before {
  content: "\ebb4";
} /* '' */
.uil-temperature-plus:before {
  content: "\ebb5";
} /* '' */
.uil-temperature-quarter:before {
  content: "\ebb6";
} /* '' */
.uil-temperature-three-quarter:before {
  content: "\ebb7";
} /* '' */
.uil-temperature:before {
  content: "\ebb8";
} /* '' */
.uil-text-fields:before {
  content: "\ebb9";
} /* '' */
.uil-text-size:before {
  content: "\ebba";
} /* '' */
.uil-text-strike-through:before {
  content: "\ebbb";
} /* '' */
.uil-text:before {
  content: "\ebbc";
} /* '' */
.uil-th-large:before {
  content: "\ebbd";
} /* '' */
.uil-th-slash:before {
  content: "\ebbe";
} /* '' */
.uil-th:before {
  content: "\ebbf";
} /* '' */
.uil-thermometer:before {
  content: "\ebc0";
} /* '' */
.uil-thumbs-down:before {
  content: "\ebc1";
} /* '' */
.uil-thumbs-up:before {
  content: "\ebc2";
} /* '' */
.uil-thunderstorm-moon:before {
  content: "\ebc3";
} /* '' */
.uil-thunderstorm-sun:before {
  content: "\ebc4";
} /* '' */
.uil-thunderstorm:before {
  content: "\ebc5";
} /* '' */
.uil-ticket:before {
  content: "\ebc6";
} /* '' */
.uil-times-circle:before {
  content: "\ebc7";
} /* '' */
.uil-times-square:before {
  content: "\ebc8";
} /* '' */
.uil-times:before {
  content: "\ebc9";
} /* '' */
.uil-toggle-off:before {
  content: "\ebca";
} /* '' */
.uil-toggle-on:before {
  content: "\ebcb";
} /* '' */
.uil-top-arrow-from-top:before {
  content: "\ebcc";
} /* '' */
.uil-top-arrow-to-top:before {
  content: "\ebcd";
} /* '' */
.uil-tornado:before {
  content: "\ebce";
} /* '' */
.uil-trademark-circle:before {
  content: "\ebcf";
} /* '' */
.uil-trademark:before {
  content: "\ebd0";
} /* '' */
.uil-traffic-barrier:before {
  content: "\ebd1";
} /* '' */
.uil-trash-alt:before {
  content: "\ebd2";
} /* '' */
.uil-trash:before {
  content: "\ebd3";
} /* '' */
.uil-trees:before {
  content: "\ebd4";
} /* '' */
.uil-triangle:before {
  content: "\ebd5";
} /* '' */
.uil-trophy:before {
  content: "\ebd6";
} /* '' */
.uil-trowel:before {
  content: "\ebd7";
} /* '' */
.uil-truck-case:before {
  content: "\ebd8";
} /* '' */
.uil-truck-loading:before {
  content: "\ebd9";
} /* '' */
.uil-truck:before {
  content: "\ebda";
} /* '' */
.uil-tumblr-square:before {
  content: "\ebdb";
} /* '' */
.uil-tumblr:before {
  content: "\ebdc";
} /* '' */
.uil-tv-retro-slash:before {
  content: "\ebdd";
} /* '' */
.uil-tv-retro:before {
  content: "\ebde";
} /* '' */
.uil-twitter:before {
  content: "\ebdf";
} /* '' */
.uil-umbrella:before {
  content: "\ebe0";
} /* '' */
.uil-unamused:before {
  content: "\ebe1";
} /* '' */
.uil-underline:before {
  content: "\ebe2";
} /* '' */
.uil-unlock-alt:before {
  content: "\ebe3";
} /* '' */
.uil-unlock:before {
  content: "\ebe4";
} /* '' */
.uil-upload-alt:before {
  content: "\ebe5";
} /* '' */
.uil-upload:before {
  content: "\ebe6";
} /* '' */
.uil-usd-circle:before {
  content: "\ebe7";
} /* '' */
.uil-usd-square:before {
  content: "\ebe8";
} /* '' */
.uil-user-check:before {
  content: "\ebe9";
} /* '' */
.uil-user-circle:before {
  content: "\ebea";
} /* '' */
.uil-user-exclamation:before {
  content: "\ebeb";
} /* '' */
.uil-user-hard-hat:before {
  content: "\ebec";
} /* '' */
.uil-user-minus:before {
  content: "\ebed";
} /* '' */
.uil-user-plus:before {
  content: "\ebee";
} /* '' */
.uil-user-square:before {
  content: "\ebef";
} /* '' */
.uil-user-times:before {
  content: "\ebf0";
} /* '' */
.uil-user:before {
  content: "\ebf1";
} /* '' */
.uil-users-alt:before {
  content: "\ebf2";
} /* '' */
.uil-utensils-alt:before {
  content: "\ebf3";
} /* '' */
.uil-utensils:before {
  content: "\ebf4";
} /* '' */
.uil-vector-square-alt:before {
  content: "\ebf5";
} /* '' */
.uil-vector-square:before {
  content: "\ebf6";
} /* '' */
.uil-venus:before {
  content: "\ebf7";
} /* '' */
.uil-vertical-align-bottom:before {
  content: "\ebf8";
} /* '' */
.uil-vertical-align-center:before {
  content: "\ebf9";
} /* '' */
.uil-vertical-align-top:before {
  content: "\ebfa";
} /* '' */
.uil-vertical-distribute-bottom:before {
  content: "\ebfb";
} /* '' */
.uil-vertical-distribution-center:before {
  content: "\ebfc";
} /* '' */
.uil-vertical-distribution-top:before {
  content: "\ebfd";
} /* '' */
.uil-video-slash:before {
  content: "\ebfe";
} /* '' */
.uil-video:before {
  content: "\ebff";
} /* '' */
.uil-visual-studio:before {
  content: "\ec00";
} /* '' */
.uil-voicemail-rectangle:before {
  content: "\ec01";
} /* '' */
.uil-voicemail:before {
  content: "\ec02";
} /* '' */
.uil-volleyball:before {
  content: "\ec03";
} /* '' */
.uil-volume-down:before {
  content: "\ec04";
} /* '' */
.uil-volume-mute:before {
  content: "\ec05";
} /* '' */
.uil-volume-off:before {
  content: "\ec06";
} /* '' */
.uil-volume-up:before {
  content: "\ec07";
} /* '' */
.uil-volume:before {
  content: "\ec08";
} /* '' */
.uil-vuejs-alt:before {
  content: "\ec09";
} /* '' */
.uil-vuejs:before {
  content: "\ec0a";
} /* '' */
.uil-wall:before {
  content: "\ec0b";
} /* '' */
.uil-wallet:before {
  content: "\ec0c";
} /* '' */
.uil-watch-alt:before {
  content: "\ec0d";
} /* '' */
.uil-watch:before {
  content: "\ec0e";
} /* '' */
.uil-water-drop-slash:before {
  content: "\ec0f";
} /* '' */
.uil-water-glass:before {
  content: "\ec10";
} /* '' */
.uil-water:before {
  content: "\ec11";
} /* '' */
.uil-web-grid-alt:before {
  content: "\ec12";
} /* '' */
.uil-web-grid:before {
  content: "\ec13";
} /* '' */
.uil-web-section-alt:before {
  content: "\ec14";
} /* '' */
.uil-web-section:before {
  content: "\ec15";
} /* '' */
.uil-webcam:before {
  content: "\ec16";
} /* '' */
.uil-weight:before {
  content: "\ec17";
} /* '' */
.uil-whatsapp:before {
  content: "\ec18";
} /* '' */
.uil-wheel-barrow:before {
  content: "\ec19";
} /* '' */
.uil-wheelchair-alt:before {
  content: "\ec1a";
} /* '' */
.uil-wheelchair:before {
  content: "\ec1b";
} /* '' */
.uil-wifi-router:before {
  content: "\ec1c";
} /* '' */
.uil-wifi-slash:before {
  content: "\ec1d";
} /* '' */
.uil-wifi:before {
  content: "\ec1e";
} /* '' */
.uil-wind:before {
  content: "\ec1f";
} /* '' */
.uil-window-grid:before {
  content: "\ec20";
} /* '' */
.uil-window-maximize:before {
  content: "\ec21";
} /* '' */
.uil-window-restore:before {
  content: "\ec22";
} /* '' */
.uil-window-section:before {
  content: "\ec23";
} /* '' */
.uil-window:before {
  content: "\ec24";
} /* '' */
.uil-windsock:before {
  content: "\ec25";
} /* '' */
.uil-wrap-text:before {
  content: "\ec26";
} /* '' */
.uil-wrench:before {
  content: "\ec27";
} /* '' */
.uil-yellow:before {
  content: "\ec28";
} /* '' */
.uil-yen-circle:before {
  content: "\ec29";
} /* '' */
.uil-yen:before {
  content: "\ec2a";
} /* '' */
.uil-youtube-alt:before {
  content: "\ec2b";
} /* '' */
.uil-youtube:before {
  content: "\ec2c";
} /* '' */